<template>
    <div style="padding: 20px;">
        <div class="flex-1 flex-column" style="padding-top: 20px; background: #fff;">
            <div class="flex-column" style="width: 100%">
                <a-form :form="form" @submit="handleSubmit" style="width: 100%" labelAlign="left"
                        :label-col="{ span: 6 }">
                    <a-row type="flex" justify="space-around">
                        <a-col :span="span">
                            <a-form-item label="报告名称">
                                <a-input placeholder="请输入报告名称"
                                         v-decorator="['reportName', { rules: [{ required: true, message: '请输入报告名称!' }] }]"
                                         :allowClear="true"/>
                            </a-form-item>
                        </a-col>

                        <a-col :span="span">
                            <a-form-item label="报告编号">
                                <a-input placeholder="请输入报告编号"
                                         v-decorator="['reportNumber', { rules: [{ required: false, message: '请输入报告编号!' }] }]"
                                         :allowClear="true"/>
                            </a-form-item>
                        </a-col>
                    </a-row>

                    <a-row type="flex" justify="space-around">
                        <a-col :span="span">
                            <a-form-item label="报告类型">
                                <a-select placeholder="请选择报告类型" :allowClear="true"
                                          v-decorator="['reportType', { rules: [{ required: true, message: '请选择报告类型!' }] }]">
                                    <template v-for="item in typeList">
                                        <a-select-option :key="item.DATA_ID" :value="item.DATA_ID">
                                            {{ item.CLASS_VALUE }}
                                        </a-select-option>
                                    </template>
                                </a-select>
                            </a-form-item>
                        </a-col>
                        <a-col :span="span">
                            <a-form-item label="结构物名称">
                                <a-select placeholder="请选择结构物" :allowClear="true"
                                          v-decorator="['structureId', { rules: [{ required: true, message: '请选择结构物!' }] }]">
                                    <template v-for="item in structureList">
                                        <a-select-option :key="item.STRUCTURE_ID" :value="item.STRUCTURE_ID">
                                            {{ item.STRUCTURE_NAME }}
                                        </a-select-option>
                                    </template>
                                </a-select>
                            </a-form-item>
                        </a-col>
                    </a-row>

                    <a-row type="flex" justify="space-around">
                        <a-col :span="span" class="drop1">
                            <a-form-item label="评估时间">
                                <a-date-picker valueFormat="YYYY-MM-DD" placeholder="请选择评估时间"
                                               v-decorator="['evaluateDate', { rules: [{ required: false}]}]"
                                               :allowClear="true" style="width: 100%"/>
                            </a-form-item>
                        </a-col>
                        <a-col :span="span">

                        </a-col>
                    </a-row>

                    <a-row type="flex" justify="space-around">
                        <a-col :span="23">
                            <a-form-item label="备注">
                                <a-textarea placeholder="请输入备注"
                                            v-decorator="['remark', { rules: [{ required: false}]}]"
                                            :allowClear="true" :auto-size="{ minRows: 8}"/>
                            </a-form-item>
                        </a-col>
                    </a-row>

                    <a-row type="flex" justify="space-around" style="margin-bottom: -20px;">
                        <a-col :span="23">
                            <a-form-item label="附件">
                            </a-form-item>
                        </a-col>
                    </a-row>
                    <a-row type="flex" justify="space-around">
                        <a-col :span="23">
                            <a-form-item label="附件">
                                <a-upload-dragger
                                        :action="actionUrl2"
                                        :file-list="fileList2"
                                        @change="uploadCallback2"
                                        :multiple="false"
                                        :remove="handleRemove2"
                                >
                                    <p class="ant-upload-drag-icon">
                                        <a-icon type="inbox"/>
                                    </p>
                                    <p class="ant-upload-text">
                                        单击或拖动文件到此区域以上传附件
                                    </p>
                                    <p class="ant-upload-hint">
                                        支持单次或批量上传
                                    </p>
                                </a-upload-dragger>
                            </a-form-item>
                        </a-col>
                    </a-row>

                    <div class="flex-row-center" style="height: 50px;justify-content: flex-end;margin: 30px 50px;">
                        <a-button type="link" @click="back">
                            取消
                        </a-button>
                        <a-button type="primary" html-type="submit">
                            保存
                        </a-button>
                    </div>
                </a-form>
            </div>
        </div>
    </div>
</template>

<script>
  export default {
    data() {
      return {
        span: 11,
        form: this.$form.createForm(this, {name: 'company'}),
        structureList: [],
        typeList: [],
        actionUrl2: '/client/api/uploadFile?fileType=6',
        fileList2: [],
      }
    },
    mounted() {
      this.getTypeList();
      this.getStructureList();
    },

    methods: {
      getTypeList() {
        this.$util.get('api/dataList', {
          page: 1,
          pageSize: 100,
          classType: 'REPORT_TYPE'
        }, true).then((res) => {
          this.typeList = res.content
        })
      },

      getStructureList() {
        let self = this;
        let p = {
          page: 1,
          pageSize: 1000,
        }
        this.$util.showLoading(self);
        this.$util.get('api/structureList', p).then((res) => {
          this.$util.hideLoading();
          let bean = res.content;
          self.structureList = bean.list;
        })
      },

      handleSubmit(e) {
        e.preventDefault();
        let self = this;
        this.form.validateFields((err, values) => {
          if (!err) {
            console.log(333, values);
            let p = {
              reportName: values.reportName,
              reportNumber: values.reportNumber,
              reportType: values.reportType,
              structureId: values.structureId,
              evaluateDate: values.evaluateDate,
              remark: values.remark,
              action: 'ADD'
            }

            let picList2 = [];
            console.log(self.fileList2);
            // file.response.content.initialPreviewConfig[0].key
            if (self.fileList2.length > 0) {
              for (let i = 0; i < self.fileList2.length; i++) {
                let dao = self.fileList2[i].response.content;
                if (dao && dao.initialPreviewConfig.length > 0) {
                  picList2.push({
                    key: dao.initialPreviewConfig[0].key,
                    caption: dao.initialPreviewConfig[0].caption,
                  })
                } else {
                  picList2.push({
                    key: self.fileList2[i].key,
                    caption: self.fileList2[i].caption,
                  })
                }
              }
            }
            let keys2 = [], caption2 = [];
            if (picList2.length > 0) {
              for (let i = 0; i < picList2.length; i++) {
                keys2.push(picList2[i].key)
                caption2.push(picList2[i].caption)
              }
            }
            p.enclosureCaptions = caption2.join(',');
            p.enclosureKeys = keys2.join(',');

            console.log(124, p);
            this.$util.post('api/submitAssessReport', p).then((res) => {
              console.log(res);
              self.back();
            });
          }
        });
      },
      back() {
        this.$router.back();
      },

      uploadCallback2({file, fileList}) {
        this.fileList2 = fileList;
      },
      // handleRemove2(file) {
      //   file.status = '1';
      //   let name = '';
      //   console.log(123,file);
      //   if (file.response) {
      //     name = file.response.initialPreviewConfig[0].key
      //   } else {
      //     name = file.name
      //   }
      //   console.log(111,name);
      //   // this.$util.post("api/deleteFile?saveName=" + name, {})
      // },

      handleRemove2(file) {
        file.status = '1';
        let name = '';
        console.log(123,file);
        if (file.response) {
          name = file.response.content.initialPreviewConfig[0].key
        } else {
          name = file.name
        }
        this.$util.post("api/deleteFile?saveName=" + name, {})
      },
    }
  }
</script>

<style scoped>

</style>
