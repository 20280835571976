<template>
    <div style="margin: 20px;">
        <div style="background: #fff; padding: 30px;">
            <a-row>
                <a-col :span="8">
                    <div class="flex-row-center size-14">
                        <div class="color-000-85">结构物名称：</div>
                        <div class="color-000-65">{{data.STRUCTURE_NAME}}</div>
                    </div>
                </a-col>
                <a-col :span="8">
                    <div class="flex-row-center size-14">
                        <div class="color-000-85">健康度：</div>
                        <div class="color-000-65">{{healthList[data.HEALTH - 1]}}</div>
                    </div>
                </a-col>
                <a-col :span="8">
                    <div class="flex-row-center size-14">
                        <div class="color-000-85">评估时间：</div>
                        <div class="color-000-65">{{getDate(data.EVALUATE_DATES)}}</div>
                    </div>
                </a-col>
            </a-row>
            <a-row style="margin-top: 30px;">
                <a-col :span="8">
                    <div class="flex-row-center size-14">
                        <div class="color-000-85">本月超限一级报警总次数：</div>
                        <div class="color-000-65">{{data.ALARM_LEVEL_1}}</div>
                    </div>
                </a-col>
                <a-col :span="8">
                    <div class="flex-row-center size-14">
                        <div class="color-000-85">本月超限二级报警总次数：</div>
                        <div class="color-000-65">{{data.ALARM_LEVEL_2}}</div>
                    </div>
                </a-col>
                <a-col :span="8">
                    <div class="flex-row-center size-14">
                        <div class="color-000-85">本月超限三级报警总次数：</div>
                        <div class="color-000-65">{{data.ALARM_LEVEL_3}}</div>
                    </div>
                </a-col>
            </a-row>

            <div class="flex-row size-14" style="line-height: 24px; margin-top: 30px;">
                <div class="color-000-85" style="min-width: 70px;">备注：</div>
                <div class="color-000-65">{{data.REMARK}}</div>
            </div>

        </div>

    </div>
</template>

<script>
    import moment from 'moment';
  export default {
    data() {
      return {
        data: {},
        healthList: ['基本完好', '轻度异常', '中等异常', '严重异常']
      }
    },

    computed: {
      getDate() {
        return function (date) {
          return moment(date).format('YYYY-MM-DD')
        }
      }
    },

    mounted() {
      this.getInfo();
    },

    methods: {
      getInfo() {
        let self = this;
        if (this.$route.query.healthId) {
          this.$util.showLoading(self);
          this.healthId = this.$route.query.healthId;
          this.$util.showLoading(this);
          this.$util.get('api/healthInfo/' + this.healthId, {}, true).then((res) => {
            this.$util.hideLoading();
            console.log(111, res);
            self.data = res.content;
          })
        }
      }
    }
  }
</script>

<style scoped>

</style>
