<template>
  <div class="body">
    <canvas ref="canvas" style="position: absolute;left: 0;top: 0;height: 100%;width: 100%;background-color: white"></canvas>
    <div class="login_box">
      <section>
        <a-carousel autoplay>
          <div v-for="(item, index) in picList" :key="index">
            <div class="flex-column color-fff size-30" style="position: absolute;bottom: 60px;right: 30px;align-items: flex-end">
              <span>{{isManage?'结构物在线监测系统管理后台':'结构物在线监测系统'}}</span>
            </div>
<!--            <img class="shadowTop" :src="getImgUrl('shadowTop')">-->
<!--            <img class="shadow" :src="getImgUrl('shadow')">-->
            <img class="slidePic"  src="./image/login/login_bg.png"/>
          </div>
        </a-carousel>
      </section>
      <aside>
        <div class="login_div">
          <div class="flex-row-center">
            <img  :src="getImgUrl('logo')" style="width: 80px;height: 50px;margin-left: 40px" >
            <div style="margin-left: 20px">
              <h1 class="bold" style="line-height: 25px">树库智能</h1>
              <span class="size-10 color-999">TREE <span style="margin-left: 20px">BANK</span></span>
            </div>
          </div>
          <div style="margin-top: 20px">
            <a-form id="components-form-demo-normal-login" :form="form" class="login-form" @submit="handleSubmit" >
              <a-form-item>
                <a-input v-decorator="[ 'user', { rules: [{ required: true, message: '请输入用户名!' }] } ]"  placeholder="账户" >
                  <a-icon slot="prefix" type="user" style="color: rgba(0,0,0,.25)" />
                </a-input>
              </a-form-item>
              <a-form-item>
                <a-input v-decorator="[ 'password', { rules: [{ required: true, message: '请输入密码!' }] } ]" type="password" placeholder="密码" >
                  <a-icon slot="prefix" type="lock" style="color: rgba(0,0,0,.25)" />
                </a-input>
              </a-form-item>
              <a-form-item>
                <a-button type="primary" html-type="submit" class="login-form-button">登录</a-button>
                <a-checkbox v-decorator="[ 'remember', {valuePropName: 'checked',initialValue: true,} ]" >
                  记住密码
                </a-checkbox>
              </a-form-item>
            </a-form>
          </div>
        </div>
      </aside>
    </div>
  </div>
</template>
<script>
import dot from './js/dot';
export default {
  name: "login",
  data() {
    let picList = [
      {src:'icon_login_bg'},
    ];
    return {
      isManage:false,
      picList:picList,
      formInline: {
        user: '',
        password: '',
        remember:true
      },
    }
  },
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: 'normal_login' });
  },
  mounted() {
    dot.init(this.$refs.canvas);
    console.log('登录:',this.$router)
    if(this.$router.history&&this.$router.history.current.fullPath.indexOf('manage')!=-1){
      this.isManage = true;
    }
    localStorage.clear();

    this.form.setFieldsValue({
      user: 'dundang',
      password: 'Qwe@12345'
    })
  },
  destroyed() {
  },
  methods: {
    getImgUrl(icon)
    {
      return require("./image/login/" + icon+'.png');
    },
    handleSubmit(e) {
      e.preventDefault();
      let self = this;
      this.form.validateFields((err, values) => {
        if (!err) {
          this.formInline = values;
          if(this.isManage){
            this.$util.post('admin/login', {
              "accountName": this.formInline.user,
              "password": this.formInline.password,
              type:1
            }).then((res) => {
              if(res&&res.content){
                this.$util.setToken(res.content.SESSION_ID)
                self.$util.get('api/system', {}).then((systemInfo) => {
                  localStorage.setItem('systemInfo', JSON.stringify(systemInfo.content));
                  localStorage.setItem('adminUserInfo',JSON.stringify(res.content) );
                  self.$util.eventBus.$emit(self.$util.notifyKey.loginManage)
                });
              }
            });
          }else{
            this.$util.post('api/login', {
              "accountName": this.formInline.user,
              "password": this.formInline.password,
              type:2
            }).then((res) => {
              if(res&&res.content){
                this.$util.setToken(res.content.SESSION_ID)
                console.log(888,this.$util.getToken())
                self.$util.get('api/system', {}).then((systemInfo) => {
                  localStorage.setItem('systemInfo', JSON.stringify(systemInfo.content));
                  localStorage.setItem('userInfo',JSON.stringify(res.content) );
                  self.$util.eventBus.$emit(self.$util.notifyKey.login)
                });
              }
            });
          }

        }
      });
    },
  }
}
</script>

<style scoped>

#components-form-demo-normal-login .login-form {max-width: 300px;}
#components-form-demo-normal-login .login-form-forgot {float: right;}
#components-form-demo-normal-login .login-form-button {width: 100%;}
#components-form-demo-normal-login .login-form .a-form  .ant-input{height: 40px!important;}

.body{display: flex;align-items: center;justify-content: center;width: 100%; margin: 0 auto;background: rgba(249,249,249,1);height: 100vh;overflow: hidden;}
.login_box{width: 1200px;border-radius:10px;position: relative;overflow: hidden;height: 800px;display: flex;background: rgba(255,255,255,1);box-shadow: 0px 46px 68px 0px rgba(216, 218, 222, 0.57);}
.login_box section{width: 700px;background:#ffffff;height:100%;float: left;}
.login_box section>img{width:100%;height: 100%;}
.ant-carousel >>> .slick-slide {text-align: center;height: 800px;background: #364d79;overflow: hidden;width: 100%;position: relative;}
.ant-carousel >>> .slick-slide ul{position: absolute;bottom: 90px;right: 60px;color: #FFFFFF;font-size: 36px;font-weight: normal;z-index: 1001;}
.ant-carousel >>> .slick-slide ul li{text-align: right; }
.ant-carousel >>> .slick-slide ul .namePic{font-size: 20px;margin-top: 10px;}
.ant-carousel >>> .slick-slide .shadow{position: absolute;bottom: 0;height: 347px;z-index: 1000;width: 700px}
.ant-carousel >>> .slick-slide .shadowTop{position: absolute;top: 0;height: 85px;z-index: 1000;width: 700px}
.slidePic{width:700px;height:800px;}
aside{float:left;width: 500px;padding-top: 210px;}
.login_div{width: 60%;margin:0 auto;overflow: hidden;}
.login_div>h1{height: 48px;text-align: center;margin-bottom: 50px;}
.login_div>h1  img{height: 48px;margin: 0 auto;}

</style>
