<template>
  <div style="width: 100%;height: 100%">
    <v-chart class="my-chart" :options="chart"/>
  </div>
</template>

<script>
import {graphic} from "echarts/lib/export/api";
export default {
  props: {
    mData: {
      type: Array,
      default: function () {
        return []
      }
    }
  },
  data() {
    return {
      chart: {},
      list:[]
    }
  },
  mounted() {
    if(this.mData)
    this.getChartData(this.mData);
  },
  methods:{
    getChartData(mData) {
      let hours = [],list=[];
      for (let i = 0; i < mData.length; i++) {
        hours.push(mData[i].xValue)
        list.push(mData[i].yValue)
      }
      console.log(55,mData,hours,list)
      let list1 = [(Math.floor(Math.random()*300+600)),(Math.floor(Math.random()*300+600)),(Math.floor(Math.random()*300+600)),
        (Math.floor(Math.random()*300+600)),(Math.floor(Math.random()*300+600)),(Math.floor(Math.random()*300+600))];
      this.chart = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {            // 坐标轴指示器，坐标轴触发有效
            type: 'none'        // 默认为直线，可选为：'line' | 'shadow' | 'none'
          },
          backgroundColor: 'rgba(55,66,97,0.8)',
          borderWidth: 0,
          textStyle: {
            color: '#fff',
          },
          formatter: function (params) {
            let tar = params[0];
            let dd = `<div>
                    <div class="flex-row-center" style="margin-top: 4px;">
                        <div style="width: 10px; height: 10px; background: ` + '#E4C61A' + `; border-radius: 50%"></div>
                        <div class="size-18 color-fff" style="margin-top: -2px; margin-left: 10px;">` + tar.axisValue + `</div>
                    </div>
                    <div class="flex-row-center" style="margin-top: 10px; margin-left: 20px;">
                        <div class="size-16 color-fff-80">数据值</div>
                        <div class="size-20 color-fff margin-left-8">` + tar.data + `</div>
                    </div>
                </div>`;
            return dd;
          }
        },
        grid: {
          left: '2%',
          right: '0%',
          bottom: '0%',
          top: '10%',
          containLabel: true
        },

        xAxis: [{
          type: "category",
          data: hours,
          axisLine: {
            show: false
          },
          axisLabel: {
            color: "#A0CAFF"
          },
          axisTick: {
            show: false
          }
        }],

        yAxis: [{
          type: "value",
          axisLabel: {
            color: "#A0CAFF"
          },
          splitLine: {
            lineStyle: {
              // 使用深浅的间隔色
              color: ['rgba(255,255,255,0.15)']
            }
          }
        }],

        series: [
          {
            barWidth: "25%",
            type: 'bar',
            data: list,
            // color: '#19ACF4'
            color: new graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 0,
                color: 'rgba(25,172,244,1)'
              },
              {
                offset: 1,
                color: 'rgba(25,172,244,0)'
              }
            ])
          },
        ]
      };
    },
  }
}
</script>

<style scoped>
.my-chart {
  width: 100%;
  height: 100%;
}
</style>
