<!--创建于2020-12-28 王双雨-->
<template>
  <a-result status="403" title="403" sub-title="对不起，您没有权限访问该页面">
    <template #extra>
      <a-button type="primary" @click="toHome()">
        返回首页
      </a-button>
    </template>
  </a-result>
</template>

<script>
export default {
  name: "暂无权限",
  methods:{
    toHome(){
      this.$router.replace('/')
    }
  }
}

</script>

<style scoped>

</style>
