<template>
    <div style="width: 100%;height: 100%">
        <v-chart class="my-chart" :options="angelChart"/>
    </div>
</template>

<script>
  import moment from 'moment';
  // const value1 = 10;
  export default {
    props: {
      array_angel: {
        type: Array,
      },
      isSmall:{
        type:Boolean,
        default:false
      },
      isToday:{
        type:Boolean,
        default:false
      }
    },
    data() {
      return {
        angelChart: {},
        source: [],
        value1: 0
      }
    },
    mounted() {
      let tempList = [];
      for (let i = 0; i < this.array_angel.length; i++) {
        let item = this.array_angel[i];
        let minXAxis = item.minXAxis;
        let maxXAxis = item.maxXAxis;
        let minYAxis = item.minYAxis;
        let maxYAxis = item.maxYAxis;
        tempList.push(Math.abs(minXAxis));
        tempList.push(Math.abs(maxXAxis));
        tempList.push(Math.abs(minYAxis));
        tempList.push(Math.abs(maxYAxis));
      }
      let max_Value = Math.max.apply(null, tempList);
      this.value1 = Math.ceil(max_Value);

      for (let i = 0; i < this.array_angel.length; i++) {
        let item = this.array_angel[i];
        let list = [moment(item.monitorTime).format(this.isToday?'HH:00':'MM-DD'), item.minXAxis + this.value1, item.maxXAxis - item.minXAxis, item.minYAxis + this.value1, item.maxYAxis - item.minYAxis];
        this.source.push(list);
      }
      this.getChartData();
    },
    methods: {
      getChartData() {
        let self = this;
        this.angelChart = {

          tooltip: {
            trigger: 'axis',
            axisPointer: {            // 坐标轴指示器，坐标轴触发有效
              type: 'none'        // 默认为直线，可选为：'line' | 'shadow' | 'none'
            },
            backgroundColor: 'rgba(55,66,97,0.8)',
            borderWidth: 0,
            textStyle: {
              color: '#fff',

            },
            formatter: function (params) {
              let tar0 = params[0];
              let tar1 = params[1];
              let tar3 = params[3];
              let arr = tar0.data;
              let dd =
                `<div style="padding: 5px 10px;" class="flex-column">
                          <div class="flex-row" style="margin-top: 4px;">
                              <div style="width: 10px; height: 10px; background: ` + tar1.color + `; border-radius: 50%"></div>
                              <div style="margin-top: -5px; margin-left: 10px;">
                                  <div class="flex-row-center">
                                    <div class="size-16 color-fff-80">x轴最大值</div>
                                     <div class="size-18 color-fff margin-left-8">` + parseFloat((arr[1] + arr[2] - self.value1).toFixed(3)) + `</div>
                                     <div class="size-16 color-fff-80" style="margin-left: 10px;">x轴最小值</div>
                                     <div class="size-18 color-fff margin-left-8">` + parseFloat((arr[1] - self.value1).toFixed(3)) + `</div>
                                  </div>
                              </div>
                          </div>
                          <div class="flex-row" style="margin-top: 15px;">
                              <div style="width: 10px; height: 10px; background: ` + tar3.color + `; border-radius: 50%"></div>
                              <div style="margin-top: -5px; margin-left: 10px;">
                                  <div class="flex-row-center">
                                    <div class="size-16 color-fff-80">y轴最大值</div>
                                     <div class="size-18 color-fff margin-left-8">` + parseFloat((arr[3] + arr[4] - self.value1).toFixed(3) )+ `</div>
                                     <div class="size-16 color-fff-80" style="margin-left: 10px;">y轴最小值</div>
                                     <div class="size-18 color-fff margin-left-8">` + parseFloat((arr[3] - self.value1).toFixed(3)) + `</div>
                                  </div>
                              </div>
                          </div>
                </div>`;

              return dd;
            }
          },

          grid: {
            left: '2%',
            right: '1%',
            bottom: '2%',
            top: '3%',
            containLabel: true
          },
          dataset: {
            source: self.source
          },
          xAxis: [{
            type: "category",
            axisLine: {
              show: false
            },
            axisLabel: {
              color: "#A0CAFF",
              fontSize: self.isSmall?12:12
            },
            axisTick: {
              show: false
            }
          }],
          yAxis: [{
            type: "value",
            axisLabel: {
              color: "#A0CAFF",
              fontSize: 12,
              formatter: function (v) {
                return [parseFloat((v - self.value1).toFixed(1))];
              }
            },
            splitLine: {
              lineStyle: {
                color: ['rgba(255,255,255,0.15)']
              }
            }
          }],
          series: [{
            type: 'bar',
            stack: 'Total',
            barWidth: "10%",
            itemStyle: {
              borderColor: 'transparent',
              color: 'transparent'
            }
          },
            {
              type: 'bar',
              stack: 'Total',
              barWidth: "10%",
              itemStyle: {
                borderColor: 'transparent',
                color: '#0EFEB5'
              }
            },
            {
              type: 'bar',
              stack: 'Total2',
              barWidth: "10%",
              itemStyle: {
                borderColor: 'transparent',
                color: 'transparent'
              }
            },
            {
              type: 'bar',
              stack: 'Total2',
              itemStyle: {
                borderColor: 'transparent',
                color: '#F8C33A'
              }
            }]
        }
      },
    }
  }
</script>

<style scoped>
    .my-chart {
        width: 100%;
        height: 100%;
    }
</style>
