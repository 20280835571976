<template>
  <div style="padding: 20px;">
    <div style="background: #fff; padding: 20px 30px;">
      <p class="color-333 size-18" >传感器管理</p>
      <a-row>
        <a-col :span="7">
          <div class="flex-row-center" style="padding-right: 30px;">
            <div class="size-14 color-000-85">关键词：</div>
            <a-input-search placeholder="名称/编号" class="filter-common" @search="onSearch" v-model="keyword"
                            :allowClear="true"/>
          </div>
        </a-col>
        <a-col :span="7">
          <div class="flex-row-center" style="padding-right: 30px;">
            <div class="size-14 color-000-85">是否在线：</div>
            <a-select placeholder="请选择是否在线" :allowClear="true" v-model="isOneLine" class="filter-common" @change="stateChange">
              <a-select-option :key="0" :value="0">
                在线
              </a-select-option>
              <a-select-option :key="1" :value="1">
                离线
              </a-select-option>
            </a-select>
          </div>
        </a-col>
        <a-col :span="7">
          <div class="flex-row-center" style="padding-right: 30px;">
            <div class="size-14 color-000-85">报警状态：</div>
            <a-select placeholder="请选择报警状态" :allowClear="true" v-model="selectStatus" class="filter-common" @change="statusChange">
              <template v-for="(item,index) in statusList">
                <a-select-option :key="index" :value="index">
                  {{ item }}
                </a-select-option>
              </template>
            </a-select>
          </div>
        </a-col>
        <a-col :span="3">
          <div>
            <a-button type="primary" @click="onSearch()">查询</a-button>
            <a-button style="margin-left: 10px;" @click="reset()">重置</a-button>
          </div>
        </a-col>
      </a-row>
      <div style="margin-top: 20px;">
        <a-button type="primary" icon="plus" @click="add">新建</a-button>
      </div>
    </div>

    <div style="margin-top: 20px;">
      <a-list :grid="mGrid" :data-source="data" :pagination="paginationOpt" v-if="data">
        <a-list-item slot="renderItem" slot-scope="item, index">
          <a-card class="cursor_pointer">
            <template slot="actions" class="ant-card-actions">
              <icon-font type="icon-edit" @click="edit(index)"/>
              <a-popconfirm
                  title="确定要删除吗?"
                  ok-text="确定"
                  cancel-text="取消"
                  @confirm="confirmDelete(index)"
              >
                <icon-font type="icon-delete"/>
              </a-popconfirm>
<!--              <icon-font type="icon-info" @click="goDetail(index)"/>-->
            </template>
            <div class="flex-row-center" style="justify-content: space-between">
              <div class="size-16 color-000-85 lineNum-1" >{{ item.DEVICE_NAME }}</div>
              <div class="size-16 " :style="{'color':getDeviceState(item).color}">{{ getDeviceState(item).name }}
              </div>
            </div>
            <div class="flex-row-center" style="margin-top: 5px">
              <a-tag :color="(item.STATUS&&item.STATUS!==0)?'red':'green'">{{statusList[item.STATUS?item.STATUS:0]}}</a-tag>
              <div class="size-14 color-000-45 lineNum-1">{{ item.DEVICE_NUMBER }}</div>
            </div>
            <div class="size-14 color-000-45" style="margin-top: 5px;">
              <span class="size-14 color-000-85">设备类型：</span>
              <span class="size-14 color-000-65">{{ item.DEVICE_TYPE_NAME }}</span>
            </div>
            <div class="size-14 color-000-45" style="margin-top: 5px;">
              <span class="size-14 color-000-85">安装位置：</span>
              <span class="size-14 color-000-65">{{ item.LOCATION }}</span>
            </div>
          </a-card>
        </a-list-item>
      </a-list>
    </div>
  </div>
</template>

<script>
export default {
  name: "bridgeList2",
  data() {
    let self = this;
    return {
      mGrid: this.$util.mGrid,
      keyword: '',
      paginationOpt: this.$util.getTableParams((current, pageSize) => {
        self.paginationOpt.current = 1;
        self.paginationOpt.defaultPageSize = pageSize;
        self.getList()
      }, (current, size) => {
        self.paginationOpt.current = current
        self.paginationOpt.defaultPageSize = size
        self.getList()
      }),
      data: null,
      isOneLine: undefined,
      statusList: ['正常','故障','损坏','维修','更换'],
      selectStatus:undefined
    }
  },
  mounted() {
    let self = this;
    this.$util.get('api/dataList', {page: 1, pageSize: 100, classType: 'BRIDGE_TYPE'}, true).then((res) => {
      self.types = res.content
    })
    this.getList();

  },
  computed: {
    getDeviceState() {
      return function (item) {
        let color = '', title = '';
        if (item.STATE===0) {
          color = '#52C41A';
          title = '在线';
        } else {
          color = '#BBBBBB';
          title = '离线';
        }
        return {
          name: title, color: color
        }
      }
    },
  },
  methods: {
    getList() {
      let self = this;
      let params = this.paginationOpt;
      if (this.data && this.data.length === 0)
        this.data = null;
      let p = {
        page: params.current,
        pageSize: params.defaultPageSize,
        keyword: this.keyword,
      }

      if(this.isOneLine!==undefined){
        p.state = this.isOneLine;
      }
      if(this.selectStatus!==undefined){
        p.status = this.selectStatus;
      }
      if (this.selectType) {
        p.type = this.selectType;
      }
      this.$util.showLoading(self);
      this.$util.get('api/deviceList', p).then((res) => {
        let bean = res.content;
        self.paginationOpt.total = bean.count;
        self.data = bean.list;
        this.$util.hideLoading();
      })


    },
    onSearch(value) {
      this.paginationOpt.current = 1;
      if (value)
        this.keyword = value;
      this.getList();
    }, reset() {
      this.paginationOpt.current = 1;
      this.keyword = '';
      this.selectStatus = undefined;
      this.isOneLine = undefined;
      this.getList();
    },
    add() {
      this.$router.push('/deviceAdd')
    },
    edit(index) {
      this.$router.push('/deviceAdd?deviceId=' + this.data[index].DEVICE_ID)
      console.log(333, index)
    },
    goDetail(index) {
      this.$router.push('/bridgeInfo?type=' + this.type + '&bridgeId=' + this.data[index].BRIDGE_ID)
    },
    confirmDelete(index) {
      this.$util.showLoading(this);
      this.$util.post('api/deleteDevice/'+this.data[index].DEVICE_ID, {
        deviceId:this.data[index].DEVICE_ID
      }, true).then((res) => {
        this.$util.hideLoading();
        this.$message.success('删除成功');
        this.reset();
      })
    }, stateChange(value) {
      this.isOneLine = value;
      this.paginationOpt.current = 1;
      this.getList();
    },statusChange(value) {
      this.selectStatus = value;
      this.paginationOpt.current = 1;
      this.getList();
    },
  }
}
</script>

<style scoped>

</style>
