<template>
    <div style="margin: 20px;">
        <div style="background: #fff; padding: 30px;">
            <div class="size-16 color-000-85 bold" style="margin-bottom: 20px;">
                基本信息
            </div>
            <a-row>
                <a-col :span="8">
                    <div class="flex-row-center size-14">
                        <div class="color-000-85">桥梁名称：</div>
                        <div class="color-000-65">{{data.STRUCTURE_NAME}}</div>
                    </div>
                </a-col>
                <a-col :span="8">
                    <div class="flex-row-center size-14">
                        <div class="color-000-85">桥梁简称：</div>
                        <div class="color-000-65">{{data.SHORT_NAME}}</div>
                    </div>
                </a-col>
                <a-col :span="8">
                    <div class="flex-row-center size-14">
                        <div class="color-000-85">桥梁类型：</div>
                        <div class="color-000-65">{{data.bridgeInfoMap.BRIDGE_TYPE_NAME}}</div>
                    </div>
                </a-col>
            </a-row>

            <a-row style="margin-top: 30px;">
                <a-col :span="8">
                    <div class="flex-row-center size-14">
                        <div class="color-000-85">桥梁全长(m)：</div>
                        <div class="color-000-65">{{data.bridgeInfoMap.BRIDGE_LENGTH}}</div>
                    </div>
                </a-col>
                <a-col :span="8">
                    <div class="flex-row-center size-14">
                        <div class="color-000-85">桥梁全宽(m)：</div>
                        <div class="color-000-65">{{data.bridgeInfoMap.BRIDGE_WIDTH}}</div>
                    </div>
                </a-col>
                <a-col :span="8">
                    <div class="flex-row-center size-14">
                        <div class="color-000-85">桥面净宽(m)：</div>
                        <div class="color-000-65">{{data.bridgeInfoMap.NET_WIDTH}}</div>
                    </div>
                </a-col>
            </a-row>

          <a-row style="margin-top: 30px;">
            <a-col :span="8">
              <div class="flex-row-center size-14">
                <div class="color-000-85">中心标高(m)：</div>
                <div class="color-000-65">{{data.bridgeInfoMap.CENTER_HEIGHT}}</div>
              </div>
            </a-col>
            <a-col :span="8">
              <div class="flex-row-center size-14">
                <div class="color-000-85">跨径总长(m)：</div>
                <div class="color-000-65">{{data.bridgeInfoMap.TOTAL_LENGTH}}</div>
              </div>
            </a-col>
            <a-col :span="8">
              <div class="flex-row-center size-14">
                <div class="color-000-85">设计载荷(吨)：</div>
                <div class="color-000-65">{{data.bridgeInfoMap.LOAD_WEIGHT}}</div>
              </div>
            </a-col>
          </a-row>

          <a-row style="margin-top: 30px;">
            <a-col :span="8">
              <div class="flex-row-center size-14">
                <div class="color-000-85">起始桩号：</div>
                <div class="color-000-65">{{data.bridgeInfoMap.START_NUMBER}}</div>
              </div>
            </a-col>
            <a-col :span="8">
              <div class="flex-row-center size-14">
                <div class="color-000-85">中心桩号：</div>
                <div class="color-000-65">{{data.PILE_NUMBER}}</div>
              </div>
            </a-col>
            <a-col :span="8">
              <div class="flex-row-center size-14">
                <div class="color-000-85">结束桩号：</div>
                <div class="color-000-65">{{data.bridgeInfoMap.END_NUMBER}}</div>
              </div>
            </a-col>
          </a-row>

          <a-row style="margin-top: 30px;">
            <a-col :span="8">
              <div class="flex-row-center size-14">
                <div class="color-000-85">桥梁上部结构类型：</div>
                <div class="color-000-65">{{data.bridgeInfoMap.STRUCTURE_TYPE_NAME}}</div>
              </div>
            </a-col>
            <a-col :span="8">
              <div class="flex-row-center size-14">
                <div class="color-000-85">桥梁上部结构材料：</div>
                <div class="color-000-65">{{data.bridgeInfoMap.MATERIAL_TYPE_NAME}}</div>
              </div>
            </a-col>
            <a-col :span="8">
              <div class="flex-row-center size-14">
                <div class="color-000-85">桥墩类型：</div>
                <div class="color-000-65">{{data.bridgeInfoMap.PIER_TYPE_NAME}}</div>
              </div>
            </a-col>
          </a-row>

          <a-row style="margin-top: 30px;">
            <a-col :span="8">
              <div class="flex-row-center size-14">
                <div class="color-000-85">单孔最大跨径：</div>
                <div class="color-000-65">{{data.bridgeInfoMap.MAX_SPAN}}</div>
              </div>
            </a-col>
            <a-col :span="8">
              <div class="flex-row-center size-14">
                <div class="color-000-85">所属路线：</div>
                <div class="color-000-65">{{data.ROUTE_NAME}}</div>
              </div>
            </a-col>
            <a-col :span="8">
              <div class="flex-row-center size-14">
                <div class="color-000-85">设计安全等级：</div>
                <div class="color-000-65">{{data.bridgeInfoMap.SAFE_LEVEL_NAME}}</div>
              </div>
            </a-col>
          </a-row>

          <a-row style="margin-top: 30px;">
            <a-col :span="8">
              <div class="flex-row-center size-14">
                <div class="color-000-85">抗震等级：</div>
                <div class="color-000-65">{{data.bridgeInfoMap.SEISMIC_LEVEL_NAME}}</div>
              </div>
            </a-col>
            <a-col :span="8">
              <div class="flex-row-center size-14">
                <div class="color-000-85">结构形式：</div>
                <div class="color-000-65">{{data.bridgeInfoMap.STRUCTURAL_NAME}}</div>
              </div>
            </a-col>
            <a-col :span="8">
              <div class="flex-row-center size-14">
                <div class="color-000-85">通车日期：</div>
                <div class="color-000-65">{{data.bridgeInfoMap.TRAFFIC_DATES}}</div>
              </div>
            </a-col>
          </a-row>

          <a-row style="margin-top: 30px;">
            <a-col :span="8">
              <div class="flex-row-center size-14">
                <div class="color-000-85">修建年度：</div>
                <div class="color-000-65">{{data.bridgeInfoMap.BUILD_YEAR}}</div>
              </div>
            </a-col>
            <a-col :span="8">
              <div class="flex-row-center size-14">
                <div class="color-000-85">技术状况评定：</div>
                <div class="color-000-65">{{data.bridgeInfoMap.TECH_ASSESS_NAME}}</div>
              </div>
            </a-col>
            <a-col :span="8">
            </a-col>
          </a-row>

          <div class="flex-row size-14" style="line-height: 24px; margin-top: 30px;">
            <div class="color-000-85" style="min-width: 70px;">所在位置：</div>
            <div class="color-000-65">{{data.ADDRESS}}</div>
          </div>
          <div id="container" style="width:100%;height: 300px;margin-top: 15px;" v-if="data.LAT>0&&data.LNG>0"/>

          <div class="flex-row size-14" style="line-height: 24px; margin-top: 30px;">
            <div class="color-000-85" style="min-width: 70px;">桥梁简介：</div>
            <div class="color-000-65">{{data.DESCRIPTION}}</div>
          </div>

          <div style="background: #fff; margin-top: 30px;" v-if="data.STRUCTURE_IMAGE_LIST&&data.STRUCTURE_IMAGE_LIST.length>0">
            <div class="color-000-85 size-14" style="min-width: 70px;">图片：</div>
            <viewer :images="data.STRUCTURE_IMAGE_LIST" style="margin-top: 10px">
              <img v-for="item in data.STRUCTURE_IMAGE_LIST" :key="item.SAVE_NAME" :src="imgUrl+item.SAVE_NAME"
                   style="width: 300px; height: 180px; object-fit: cover; margin: 0 15px 15px 0;"
                   :onerror="errorImg">
            </viewer>
          </div>

          <div class="flex-row-center">
            <div style="background: #fff; margin-top: 30px;" v-if="data.bridgeInfoMap.IMG">
              <div class="color-000-85 size-14" style="min-width: 70px;">结构图片：</div>
              <viewer :images="[data.bridgeInfoMap.IMG]" style="margin-top: 10px">
                <img v-for="item in [data.bridgeInfoMap.IMG]" :key="item" :src="imgUrl+item"
                     style="width: 300px; height: 180px; object-fit: cover; margin: 0 15px 15px 0;"
                     :onerror="errorImg">
              </viewer>
            </div>

            <div style="background: #fff; margin-top: 30px;" v-if="data.bridgeInfoMap.DESIGN_IMG">
              <div class="color-000-85 size-14" style="min-width: 70px;">设计图片：</div>
              <viewer :images="[data.bridgeInfoMap.DESIGN_IMG]" style="margin-top: 10px">
                <img v-for="item in [data.bridgeInfoMap.DESIGN_IMG]" :key="item" :src="imgUrl+item"
                     style="width: 300px; height: 180px; object-fit: cover; margin: 0 15px 15px 0;"
                     :onerror="errorImg">
              </viewer>
            </div>
          </div>

          <div class="size-16 color-000-85 bold" style="margin-bottom: 20px;margin-top: 50px">
            管养信息
          </div>
          <a-row>
            <a-col :span="8">
              <div class="flex-row-center size-14">
                <div class="color-000-85">监理单位：</div>
                <div class="color-000-65">{{data.bridgeInfoMap.SUPERVISOR}}</div>
              </div>
            </a-col>
            <a-col :span="8">
              <div class="flex-row-center size-14">
                <div class="color-000-85">施工单位：</div>
                <div class="color-000-65">{{data.bridgeInfoMap.BUILD_COMPANY}}</div>
              </div>
            </a-col>
            <a-col :span="8">
              <div class="flex-row-center size-14">
                <div class="color-000-85">设计单位：</div>
                <div class="color-000-65">{{data.bridgeInfoMap.DESIGN_COMPANY}}</div>
              </div>
            </a-col>
          </a-row>

          <a-row style="margin-top: 30px;">
            <a-col :span="8">
              <div class="flex-row-center size-14">
                <div class="color-000-85">监管单位：</div>
                <div class="color-000-65">{{data.bridgeInfoMap.MANAGE_COMPANY}}</div>
              </div>
            </a-col>
            <a-col :span="8">
              <div class="flex-row-center size-14">
                <div class="color-000-85">建设单位：</div>
                <div class="color-000-65">{{data.bridgeInfoMap.CONSTRUCTION_COMPANY}}</div>
              </div>
            </a-col>
            <a-col :span="8">
              <div class="flex-row-center size-14">
                <div class="color-000-85">管养单位：</div>
                <div class="color-000-65">{{data.bridgeInfoMap.MAINTAIN_COMPANY}}</div>
              </div>
            </a-col>
          </a-row>
      </div>
    </div>
</template>

<script>
import AMap from "AMap";
let map = null;
  export default {
    data() {
      return {
        data: {
          bridgeInfoMap:{}
        },
        errorImg: 'this.src="' + require('../../image/icon_default.png') + '"',
        imgUrl:this.$util.getServeImageUrl()
      }
    },
    mounted() {
      this.getInfo();
    },

    methods: {
      initMap() {
        let info = this.data;
        if (info.LAT > 0 && info.LNG > 0) {
          map = new AMap.Map('container', {
            center: [info.LNG, info.LAT],
            resizeEnable: true,
            zoom: 13,
          });


          AMap.plugin(['AMap.ToolBar', 'AMap.Scale', 'AMap.OverView', 'AMap.Geolocation', 'AMap.DistrictSearch', 'AMap.InfoWindow', 'AMap.Polyline'], function () {//异步加载插件
            let marker = new AMap.Marker({
              position: new AMap.LngLat(info.LNG, info.LAT),
              icon: new AMap.Icon({
                image: require('../../image/screen/home_icon_bridge2.png'),
                size: new AMap.Size(40, 48),  //图标所处区域大小
                imageSize: new AMap.Size(40, 48) //图标大小
              }),
              anchor: 'bottom-center',
              offset: new AMap.Pixel(0, 0),
            });
            map.add(marker);
          });
        }
      },
      getInfo() {
        let self = this;
        if (this.$route.query.id) {
          this.$util.showLoading(self);
          this.$util.get('api/structureInfo/' + this.$route.query.id, {isDetail: 1,isImage:1}, true).then((res) => {
            self.data = res.content;
            self.$util.hideLoading();
            setTimeout(() => {
              self.initMap();
            }, 500);
          })
        }
      },
      back() {
        this.$router.back();
      },

      showModal() {
        this.visible = true;
      },
      handleOk(e) {
        if (!this.measure) {
          this.$message.error('请输入处置措施');
          return;
        }
        let p = {
          measure: this.measure,
          eventId: this.eventId
        }
        this.$util.post('api/handleEvent', p).then((res) => {
          console.log(res);
          this.visible = false;
          this.getInfo();
        });
      },
    }
  }
</script>

<style scoped>

</style>
