<template>
  <div class="head_bg">
    <img src="../../image/electric/header_bg.png" width="100%" height="100%">
    <div style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; margin: 0 auto;">
      <div class="color-B4F8FF size-40 flex center cursor_pointer" style="line-height: 86px; " @click="toHome">{{title}}</div>
    </div>

    <img src="../../image/screen/screen_back.png" class="cursor_pointer" @click="click"
         style="width: 104px;height: 34px;position: absolute;right: 20px;top: 12px" v-if="showBack"/>
  </div>
</template>

<script>
export default {
  name: "demo.vue",
  props: {
    showBack:{
      type: Boolean,
      default: true
    },
    title: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
    }
  },
  mounted() {
  },
  methods:{
    click(){
      this.$router.back();
    },
    toHome(){
      this.$router.replace('/home')
    }
  }
}
</script>

<style scoped>
.head_bg {
  width: 100%;
  height: 125px;
  position: relative;
}
</style>
