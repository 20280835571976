<template>
  <div>
    <a-page-header
        style="padding: 0;margin: 15px 0"
        title="用户管理"
        @back="back()"
    />

    <div class="flex-1 flex-column" style="margin: 10px 0;background-color: white">
      <span class="size-18 color-333" style="margin: 20px">{{ id ? '编辑' : '新增' }}用户</span>
      <div class="flex-column" style="width: 100%">
        <a-form :form="form" @submit="handleSubmit" style="width: 100%" labelAlign="left" :label-col="{ span: 6 }">
          <a-row type="flex" justify="space-around">
            <a-col :span="span">
              <a-form-item label="登录账号">
                <a-input placeholder="请输入登录账号"
                         v-decorator="['accountName', { rules: [{ required: true, message: '请输入登录账号!' }] }]"
                         :allowClear="true"/>
              </a-form-item>
            </a-col>
            <a-col :span="span">
              <a-form-item label="密码">
                <a-input-password placeholder="请输入密码"
                                  v-decorator="['password', { rules: [{ required: id==null, message: '请输入密码!' }] }]"
                                  :allowClear="true" :disabled="id!=null"/>
              </a-form-item>
            </a-col>
            <a-col :span="span">
              <a-form-item label="姓名">
                <a-input placeholder="请输入姓名" v-decorator="['name', { rules: [{ required: true, message: '请输入姓名!' }] }]"
                         :allowClear="true"/>
              </a-form-item>
            </a-col>
          </a-row>

          <a-row type="flex" justify="space-around">
            <a-col :span="span">
              <a-form-item label="角色">
                <a-select placeholder="请选择角色" v-decorator="['roleId', { rules: [{ required: true, message: '请选择角色!' }] }]">
                  <template v-for="item in roleList">
                    <a-select-option :key="item.ROLE_ID" :value="item.ROLE_ID">
                      {{ item.ROLE_NAME }}
                    </a-select-option>
                  </template>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="span">
              <a-form-item label="性别">
                <a-radio-group v-decorator="['gender', {initialValue: 1, rules: [{ required: false}]}]" style="width: 100%;">
                  <a-radio :value="1">男</a-radio>
                  <a-radio :value="0">女</a-radio>
                </a-radio-group>
              </a-form-item>
            </a-col>
            <a-col :span="span">
              <a-form-item label="是否启用">
                <a-radio-group v-decorator="['isUse', {initialValue: 1, rules: [{ required: false}]}]" style="width: 100%;">
                  <a-radio :value="1">是</a-radio>
                  <a-radio :value="0">否</a-radio>
                </a-radio-group>
              </a-form-item>
            </a-col>
          </a-row>


          <a-row type="flex" justify="space-around">
            <a-col :span="span">
              <a-form-item label="联系电话">
                <a-input placeholder="请输入联系电话" v-decorator="['telephone', { rules: [{ required: true, message: '请输入联系电话!' }] }]"
                         :allowClear="true"/>
              </a-form-item>
            </a-col>
            <a-col :span="span">
              <a-form-item label="办公地点">
                <a-input placeholder="请输入办公地点" v-decorator="['address', { rules: [{ required: false }] }]"
                         :allowClear="true"/>
              </a-form-item>
            </a-col>
            <a-col :span="span">
              <a-form-item label="部门">
                <a-input placeholder="请输入部门" v-decorator="['department', { rules: [{ required: false }] }]"
                         :allowClear="true"/>
              </a-form-item>
            </a-col>
          </a-row>

          <a-row type="flex" justify="space-around">
            <a-col :span="span">
              <a-form-item label="邮箱">
                <a-input placeholder="请输入邮箱" v-decorator="['email', { rules: [{ required: false }] }]"
                         :allowClear="true"/>
              </a-form-item>
            </a-col>
            <a-col :span="span">
              <a-form-item label="身份证号">
                <a-input placeholder="请输入身份证号" v-decorator="['idCard', { rules: [{ required: false }] }]"
                         :allowClear="true"/>
              </a-form-item>
            </a-col>

            <a-col :span="span">
            </a-col>
          </a-row>


          <a-row type="flex" justify="space-around">
            <a-col :span="23">
              <a-form-item label="备注">
                <a-textarea placeholder="请输入备注" v-decorator="['remark', { rules: [{ required: false }] }]"
                            :allowClear="true" :auto-size="{ minRows: 3}"/>
              </a-form-item>
            </a-col>
          </a-row>

          <div class="flex-row-center" style="height: 50px;justify-content: flex-end;margin: 30px 50px;">
            <a-button type="link" @click="back">
              取消
            </a-button>
            <a-button type="primary" html-type="submit">
              保存
            </a-button>
          </div>
        </a-form>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      span: 7,
      roleList: [],
      form: this.$form.createForm(this, {name: 'account'}),
      id:undefined
    }
  },
  mounted() {
    let self = this;

    if(this.$route.query.id){this.$util.showLoading(self);
      this.id = this.$route.query.id;
      this.$util.get('api/userInfo/'+this.$route.query.id, {
      }, true).then((res) => {
        self.bean = res.content;
        self.$util.hideLoading();
        let dao = res.content;
        self.form.setFieldsValue({
          accountName: dao.ACCOUNT_NAME,
          name: dao.NAME,
          telephone: dao.TELEPHONE,
          gender: dao.GENDER,
          department: dao.DEPARTMENT,
          remark: dao.REMARK,
          address: dao.ADDRESS,
          email: dao.EMAIL,
          idCard: dao.ID_CARD,
          isUse: dao.IS_USE,
          roleId: dao.ROLE_ID,

        })
      })
    }else{
      self.form.setFieldsValue({
        gender: 1,
        isUse: 1,
      })
    }



    let p = {
      page: 1,
      pageSize: 1000,
    }
    this.$util.get('api/roleList', p, true).then((res) => {
      if(res.content){
        self.roleList = res.content.list;
      }

    })

  },

  methods: {
    handleSubmit(e) {
      e.preventDefault();
      let self = this;
      this.form.validateFields((err, values) => {
        if (!err) {
          console.log(33,values)
          let p = {
            action: "ADD",
            accountName: values.accountName,
            name: values.name,
            telephone: values.telephone,
            gender: values.gender,
            department: values.department,
            remark: values.remark,
            address: values.address,
            email: values.email,
            idCard: values.idCard,
            isUse: values.isUse,
            roleId: values.roleId,
            type:2,
          }
          if (this.bean) {
            p.action = 'EDIT';
            p.userId = this.bean.USER_ID;
            p.password= '11';
            p.accountId=this.bean.ACCOUNT_ID;
          }else{
            p.password= values.password;
          }
          this.$util.post('api/submitUser', p).then((res) => {
            console.log(res);
            self.back();
          });
        }
      });
    },
    back() {
      this.$router.back();
    }
  }
}
</script>

<style scoped>

</style>
