<template>
  <div style="padding: 20px;">
    <div style="background: #fff; padding: 20px 30px;">
      <p class="color-333 size-18" >监测点管理</p>
      <a-row>
        <a-col :span="5">
          <div class="flex-row-center" style="padding-right: 30px;">
            <div class="size-14 color-000-85">关键词：</div>
            <a-input-search placeholder="名称/编号" class="filter-common" @search="onSearch" v-model="keyword"
                            :allowClear="true"/>
          </div>
        </a-col>
        <a-col :span="5">
          <div class="flex-row-center" style="padding-right: 30px;">
            <div class="size-14 color-000-85">结构物：</div>
            <a-select placeholder="请选择结构物" :allowClear="true" v-model="selectStructure" class="filter-common" @change="structureChange">
              <template v-for="(item) in structureList">
                <a-select-option :key="item.STRUCTURE_ID" :value="item.STRUCTURE_ID">
                  {{ item.STRUCTURE_NAME }}
                </a-select-option>
              </template>
            </a-select>
          </div>
        </a-col>
        <a-col :span="5">
          <div class="flex-row-center" style="padding-right: 30px;">
            <div class="size-14 color-000-85">监测项：</div>
            <a-select placeholder="请选择监测项" :allowClear="true" v-model="selectMonitor" class="filter-common" @change="monitorChange">
              <template v-for="(item) in monitorItemList">
                <a-select-option :key="item.MONITOR_ITEM_ID" :value="item.MONITOR_ITEM_ID">
                  {{ item.ITEM_NAME }}
                </a-select-option>
              </template>
            </a-select>
          </div>
        </a-col>
        <a-col :span="5">
          <div class="flex-row-center" style="padding-right: 30px;">
            <div class="size-14 color-000-85">报警状态：</div>
            <a-select placeholder="请选择报警状态" :allowClear="true" v-model="selectStatus" class="filter-common" @change="statusChange">
              <template v-for="(item) in statusList">
                <a-select-option :key="item.DATA_ID" :value="item.DATA_ID">
                  {{ item.CLASS_VALUE }}
                </a-select-option>
              </template>
            </a-select>
          </div>
        </a-col>
        <a-col :span="4">
          <div>
            <a-button type="primary" @click="onSearch()">查询</a-button>
            <a-button style="margin-left: 10px;" @click="reset()">重置</a-button>
          </div>
        </a-col>
      </a-row>
      <div style="margin-top: 20px;">
        <a-button type="primary" icon="plus" @click="add">新建</a-button>
      </div>
    </div>

    <div style="margin-top: 20px;">
      <a-list :grid="mGrid" :data-source="data" :pagination="paginationOpt" v-if="data">
        <a-list-item slot="renderItem" slot-scope="item, index">
          <a-card class="cursor_pointer">
            <template slot="actions" class="ant-card-actions">
              <icon-font type="icon-edit" @click="edit(index)"/>
              <a-popconfirm
                  title="确定要删除吗?"
                  ok-text="确定"
                  cancel-text="取消"
                  @confirm="confirmDelete(index)"
              >
                <icon-font type="icon-delete"/>
              </a-popconfirm>
            </template>
            <div class="flex-row-center" style="justify-content: space-between">
              <div class="size-16 color-000-85 lineNum-1" >{{ item.POINT_NAME }}</div>
            </div>
            <div class="flex-row-center" style="margin-top: 5px">
              <a-tag :color="getStatus(item).color">{{getStatus(item).name}}</a-tag>
              <div class="size-14 color-000-45 lineNum-1">{{ item.POINT_NUMBER }}</div>
            </div>
            <div class="size-14 color-000-45" style="margin-top: 5px;">
              <span class="size-14 color-000-65 lineNum-1"><span class="color-000">监测项：</span>{{ item.MONITOR_ITEM_NAME }}</span>
            </div>
            <div class="size-14 color-000-45 flex-row-center" style="margin-top: 5px;">
              <span class="size-14 color-000-65 lineNum-1"><span class="color-000">结构物：</span>{{ item.STRUCTURE_NAME }}</span>
            </div>
            <div class="size-14 color-000-45" style="margin-top: 5px;">
              <span class="size-14 color-000-65 lineNum-1"><span class="color-000">安装部位：</span>{{ item.LOCATION }}</span>
            </div>
          </a-card>
        </a-list-item>
      </a-list>
    </div>
  </div>
</template>

<script>
export default {
  name: "bridgeList2",
  data() {
    let self = this;
    return {
      mGrid: this.$util.mGrid,
      keyword: '',
      paginationOpt: this.$util.getTableParams((current, pageSize) => {
        self.paginationOpt.current = 1;
        self.paginationOpt.defaultPageSize = pageSize;
        self.getList()
      }, (current, size) => {
        self.paginationOpt.current = current
        self.paginationOpt.defaultPageSize = size
        self.getList()
      }),
      data: null,
      statusList: [{DATA_ID:'0',CLASS_VALUE:'正常',COLOR:'green'},{DATA_ID:'1',CLASS_VALUE:'超限一级',COLOR:'yellow'},{DATA_ID:'2',CLASS_VALUE:'超限二级',COLOR:'orange'},{DATA_ID:'3',CLASS_VALUE:'超限三级',COLOR:'red'}],
      selectStatus:undefined,
      structureList:[],
      selectStructure:undefined,
      monitorItemList:[],
      selectMonitor:undefined
    }
  },
  mounted() {
    let self = this;
    this.$util.get('api/structureList', {page: 1, pageSize: 100}).then((res) => {
      self.structureList = res.content.list;
    })

    this.getList();

  },
  computed: {
    getStatus() {
      return function (item) {
        for (let i = 0; i < this.statusList.length; i++) {
          if(item.ALARM_LEVEL === parseInt(this.statusList[i].DATA_ID)){
            return {name: this.statusList[i].CLASS_VALUE, color: this.statusList[i].COLOR}
          }
        }
        return {name: '正常', color: 'green'}
      }
    },
  },
  methods: {
    getList() {
      let self = this;
      let params = this.paginationOpt;
      if (this.data && this.data.length === 0)
        this.data = null;
      let p = {
        page: params.current,
        pageSize: params.defaultPageSize,
        keyword: this.keyword,
      }

      if(this.selectStructure){
        p.structureId = this.selectStructure;
      }
      if(this.selectStatus!==undefined){
        p.alarmLevel = this.selectStatus;
      }
      if (this.selectMonitor) {
        p.monitorItemId = this.selectMonitor;
      }
      this.$util.showLoading(self);
      this.$util.get('api/monitorPointList', p).then((res) => {
        let bean = res.content;
        self.paginationOpt.total = bean.count;
        self.data = bean.list;
        this.$util.hideLoading();
      })


    },
    onSearch(value) {
      this.paginationOpt.current = 1;
      if (value)
        this.keyword = value;
      this.getList();
    }, reset() {
      this.paginationOpt.current = 1;
      this.keyword = '';
      this.selectMonitor = undefined;
      this.selectStructure = undefined;
      this.selectStatus = undefined;
      this.getList();
    },
    add() {
      this.$router.push('/monitorPointAdd')
    },
    edit(index) {
      this.$router.push('/monitorPointAdd?monitorPointId=' + this.data[index].MONITOR_POINT_ID)
      console.log(333, index)
    },
    confirmDelete(index) {
      this.$util.showLoading(this);
      this.$util.post('api/deleteMonitorPoint/'+this.data[index].MONITOR_POINT_ID, {}).then((res) => {
        this.$util.hideLoading();
        this.$message.success('删除成功');
        this.reset();
      })
    }, structureChange(value) {
      this.selectStructure = value;
      this.paginationOpt.current = 1;
      this.getList();

      this.selectMonitor = undefined;
      this.monitorItemList = [];
      this.$util.get('api/monitorItemList', {page: 1, pageSize: 100,structureId:value}).then((res) => {
        this.monitorItemList = res.content
      })
    },monitorChange(value) {
      this.selectMonitor = value;
      this.paginationOpt.current = 1;
      this.getList();
    },statusChange(value) {
      this.selectStatus = value;
      this.paginationOpt.current = 1;
      this.getList();
    },
  }
}
</script>

<style scoped>

</style>
