<template>
  <div style="width: 100%;height: 100%">
    <v-chart class="my-chart" :options="chart"/>
  </div>
</template>

<script>

  import {graphic} from "echarts/lib/export/api";

  export default {
    props: {
      mData: {
        type: Array,
        default: function () {
          return []
        }
      }
    },
    data() {
      return {
        chart: {},
        list: []
      }
    },
    mounted() {
      if (this.mData)
        this.getChartData(this.mData)
    },
    methods: {
      getChartData(mData) {
        let hours = [], list = [];
        for (let i = 0; i < mData.length; i++) {
          hours.push(mData[i].xValue)
          list.push(mData[i].yValue)
        }
        console.log(66, hours, list)

        let unit = '';
        this.chart = {
          tooltip: {
            trigger: 'axis',
            axisPointer: {            // 坐标轴指示器，坐标轴触发有效
              // type: 'none'        // 默认为直线，可选为：'line' | 'shadow' | 'none'
            },
            backgroundColor: 'rgba(55,66,97,0.8)',
            borderWidth: 0,
            textStyle: {
              color: '#fff',
            },

            formatter: function (params) {
              let tar = params[0];
              let dd = `<div>
                    <div class="flex-row-center" style="margin-top: 4px;">
                        <div style="width: 10px; height: 10px; background: ` + '#E4C61A' + `; border-radius: 50%"></div>
                        <div class="size-18 color-fff" style="margin-top: -2px; margin-left: 10px;">` + tar.axisValue + `</div>
                    </div>
                    <div class="flex-row-center" style="margin-top: 10px; margin-left: 20px;">
                        <div class="size-16 color-fff-80">数据值</div>
                        <div class="size-20 color-fff margin-left-8">` + tar.data + `</div>
                    </div>
                </div>`;
              return dd;
            }
          },
          grid: {
            left: '0%',
            right: '0%',
            bottom: '0%',
            top: '15%',
            containLabel: true
          },
          xAxis: [
            {
              type: 'category',
              axisLine: {
                lineStyle: {
                  color: 'rgba(140, 175, 241, 1)',
                }
              },
              axisTick: {
                show: false,
                lineStyle: {}
              },
              axisLabel: {
                show: true,
                textStyle: {
                  color: 'rgba(140, 175, 241, 1)',
                }
              },

              //网格线
              splitLine: {
                show: true,
                lineStyle: {
                  color: 'rgba(255,255,255,0.15)',
                }
              },
              data: hours
            }
          ],

          yAxis: {
            type: 'value',
            axisLabel: {
              show: true,
              formatter: '{value} ' + unit,
              textStyle: {
                color: 'rgba(140, 175, 241, 1)',
              }
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: 'rgba(255,255,255,0.15)',
              }
            },
            axisLine: {show: true},
            axisTick: {show: false},
          },
          dataZoom: [{
            type: "inside"
          }],
          series: [
            {
              type: 'line',
              z: 10,
              itemStyle: {
                normal: {
                  label: {
                    show: true, //开启显示
                    position: 'top', //在上方显示
                    textStyle: { //数值样式
                      color: 'rgba(140, 175, 241, 1)',
                    }
                  }
                },
              },
              lineStyle: {
                color: '#02F0FF',
                width: 1,
                type: 'solid'
              },
              symbol: 'none',
              smooth: true,
              data: list,
              markLine: {
                symbol: 'none',
                itemStyle: {
                  normal: {
                    borderWidth: 1,
                    lineStyle: {
                      type: 'solid',
                      color: '#333 ',
                      width: 2,
                    },
                    label: {
                      formatter: '',
                      textStyle: {
                        fontSize: 16,
                        fontWeight: "bolder",
                      },
                    }
                  },
                },
                data: [{
                  silent: false,             //鼠标悬停事件  true没有，false有
                  lineStyle: {               //警戒线的样式  ，虚实  颜色
                    type: "dashed",
                    color: "#E4C61A",
                    width: 1
                  },
                  label: {
                    position: 'end',
                    // formatter:"及格(-1)"
                  },
                  yAxis: 5         // 警戒线的标注值，可以有多个yAxis,多条警示线   或者采用   {type : 'average', name: '平均值'}，type值有  max  min  average，分为最大，最小，平均值

                },
                  {
                    silent: false,             //鼠标悬停事件  true没有，false有
                    lineStyle: {               //警戒线的样式  ，虚实  颜色
                      type: "dashed",
                      color: "#FF6F20",
                      width: 1

                    },
                    label: {
                      position: 'end',
                      // formatter:"优秀(1)",
                      fontSize: '8'
                    },
                    yAxis: 10       // 警戒线的标注值，可以有多个yAxis,多条警示线   或者采用   {type : 'average', name: '平均值'}，type值有  max  min  average，分为最大，最小，平均值
                  },
                  {
                    silent: false,             //鼠标悬停事件  true没有，false有
                    lineStyle: {               //警戒线的样式  ，虚实  颜色
                      type: "dashed",
                      color: "#FF571A",
                      width: 1

                    },
                    label: {
                      position: 'end',
                      // formatter:"优秀(1)",
                      fontSize: '8'
                    },
                    yAxis: 18        // 警戒线的标注值，可以有多个yAxis,多条警示线   或者采用   {type : 'average', name: '平均值'}，type值有  max  min  average，分为最大，最小，平均值
                  }
                ]
              },
            },
          ]
        };
      },
    }
  }
</script>

<style scoped>
  .my-chart {
    width: 100%;
    height: 100%;
  }
</style>
