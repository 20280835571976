<template>
  <a-layout>
    <a-modal
        :bodyStyle="{'height':550+'px'}"
        v-model="showEditModal"
        :closable="false"
        :footer="null"
        :width="500"
        :destroyOnClose="true"
    >
      <password-edit @passwordClose="passwordClose" :bean="user"/>
    </a-modal>
    <a-layout-header style="padding-left:1.25rem;">
      <div class="flex-row-center" style="height: 100%;">
        <img src="../image/icon_logo1.png" style="width: 25px;height: 25px"/>
        <span class="color-fff size-18"
              style="margin-left:10px;line-height: 18px">{{ project ? project.PROJECT_NAME : '敦当高速在线监测系统' }}</span>
        <div style="flex: 1"></div>
        <a @click="toPowerScreen()" style="margin-right: 20px" title="电化学大屏" v-if="project&&project.PROJECT_TYPE==1">
          <img src="../image/icon_power_screen.png" style="height: 18px;"/>
        </a>

        <a @click="toScreen()" style="margin-right: 20px" title="大屏" v-else>
          <img src="../image/icon_screen.png" style="height: 18px;"/>
        </a>

        <a-dropdown>
          <a class="ant-dropdown-link color-fff size-14 flex-row-center" @click="e => e.preventDefault()">
            <a-icon type="setting" style="margin-right: 10px;font-size: 17px"/>
            {{ user ? user.NAME : '' }}
            <a-icon type="down" style="margin-left: 10px"/>
          </a>
          <a-menu slot="overlay">
            <a-menu-item class="flex-row-center" @click="showEditModal=true">
              <a-icon type="edit"/>
              <a href="javascript:;">修改密码</a>
            </a-menu-item>
            <a-menu-item class="flex-row-center" @click="loginOut()">
              <a-icon type="poweroff"/>
              <a href="javascript:;">退出登录</a>
            </a-menu-item>
          </a-menu>
        </a-dropdown>
      </div>

    </a-layout-header>

    <a-layout>
      <a-layout-sider v-model="collapsed" collapsible>
        <a-menu theme="dark" mode="inline" :open-keys="openKeys" @openChange="onOpenChange" :selectedKeys="parentPath"
                @click="menuClick">
          <template v-for="item in menuList">
            <a-menu-item v-if="!item.CHILD||item.CHILD.length==0" :key="item.key" class="icons-font">
              <icon-font :type="'icon-menu_'+item.ICON"/>
              <span>{{ item.MENU_NAME }}</span>
            </a-menu-item>
            <sub-menu v-else :key="item.key" :menu-info="item"/>
          </template>
        </a-menu>

      </a-layout-sider>
      <a-layout class="flex-column">
        <a-layout-content style="margin: 0 16px;" class="flex-column"
                          :style="{'margin-left':(isNotMargin()?0:16+'px'),'margin-right':(isNotMargin()?0:16+'px')}">
          <router-view style="flex:1" class="flex-column"/>
        </a-layout-content>
        <!--        <a-layout-footer style="text-align: center" class="flex" v-if="!isNotMargin()">-->
        <!--          © All Rights Reserved 全栈智能 | 技术支持：13328163166-->
        <!--        </a-layout-footer>-->
      </a-layout>

    </a-layout>
  </a-layout>
</template>

<script>

import passwordEdit from '../components/password_edit';
import subMenu from '../components/subMenu';

let rootPath = '';
export default {
  name: "main",
  components: {
    'sub-menu': subMenu,
    'password-edit': passwordEdit,
  },
  watch: {
    '$route': 'init'
  },
  data() {
    return {
      showEditModal: false,
      parentPath: [rootPath],
      user: null,
      rootSubmenuKeys: ['1', '2'],
      openKeys: [rootPath],
      collapsed: false,
      menuList: [],
      nasImageUrl: this.$util.NAS_IMAGE_URL,
      project: null,
    }
  },
  mounted() {
    // this.$util.setToken(this.$util.getToken());

    let self = this;
    let userStr = localStorage.getItem('userInfo');
    this.user = JSON.parse(userStr);

    this.$util.get('api/menuList/2', {}).then((res) => {
      let menuList = res.content;
      for (let i = 0; i < menuList.length; i++) {
        if (menuList[i].CHILD && menuList[i].CHILD.length > 0) {
          menuList[i].key = menuList[i].MENU_ID + "";
          for (let j = 0; j < menuList[i].CHILD.length; j++) {
            self.setMenuInfo(menuList[i].CHILD[j]);
          }
        } else {
          self.setMenuInfo(menuList[i]);
        }
      }
      this.menuList = menuList;
      this.init();
      console.log(res)
    });

    this.$util.get('api/projectInfo/' + this.user.PROJECT_ID, {}).then((res) => {
      self.project = res.content;
      console.log(res)
    });
  },
  methods: {
    setMenuInfo(menuInfo) {
      menuInfo.key = rootPath + '/' + menuInfo.LINK;
    },
    isNotMargin() {
      return this.$route.fullPath.indexOf('home') !== -1 || this.$route.fullPath.indexOf('statistics') !== -1 || this.$route.fullPath.indexOf('alarmList') !== -1
    },
    init() {
      if (this.$route.fullPath === rootPath) {
        this.parentPath = [(rootPath + '/home')]
      } else if (this.$route.fullPath.indexOf('tunnel') !== -1) {
        this.parentPath = [(rootPath + '/tunnelList')]
      } else if (this.$route.fullPath.indexOf('bridge') !== -1) {
        this.parentPath = [(rootPath + '/bridgeList')]
      } else if (this.$route.fullPath.indexOf('AssessReport') !== -1) {
        this.parentPath = [(rootPath + '/assessReport')]
      }else if (this.$route.fullPath.indexOf('Health') !== -1) {
        this.parentPath = [(rootPath + '/healthList')]
      }else if (this.$route.fullPath.indexOf('Event') !== -1 || this.$route.fullPath.indexOf('event') !== -1) {
        this.parentPath = [(rootPath + '/eventList')]
      } else if (this.$route.fullPath.indexOf('sideSlope') !== -1) {
        this.parentPath = [(rootPath + '/sideSlopeList')]
      } else if (this.$route.fullPath.indexOf('monitorPoint') !== -1) {
        this.parentPath = [(rootPath + '/monitorPointList')]
      }  else if (this.$route.fullPath.indexOf('role') !== -1) {
        this.parentPath = [(rootPath + '/roleList')]
      } else if (this.$route.fullPath.indexOf('deviceBind') !== -1) {

        switch (parseInt(this.$route.query.type)) {
          case 1://桥梁
            this.parentPath = [(rootPath + '/bridgeList')]
            break;
          case 2://边坡
            this.parentPath = [(rootPath + '/sideSlopeList')]
            break;
          case 3://隧道左洞
          case 4://隧道右洞
            this.parentPath = [(rootPath + '/tunnelList')]
            break;
        }
        console.log(3334, this.$route, this.parentPath)
      } else if (this.$route.fullPath.indexOf('device') !== -1) {
        this.parentPath = [(rootPath + '/deviceList')]

      } else if (this.$route.fullPath.indexOf('user') !== -1) {
        this.parentPath = [(rootPath + '/userList')]
      } else {
        this.parentPath = [this.$route.fullPath]
      }
      let keys = [];
      a:for (let i = 0; i < this.menuList.length; i++) {
        let item = this.menuList[i];
        if (item.CHILD && item.CHILD.length > 0) {
          for (let j = 0; j < item.CHILD.length; j++) {
            let itemItem = item.CHILD[j];
            if (this.parentPath[0] === itemItem.key) {
              keys.push(item.key);//第一层打开的菜单key
              break a;
            }
          }
        }
      }
      this.openKeys = keys;
    },
    onOpenChange(openKeys) {
      const latestOpenKey = openKeys.find(key => this.openKeys.indexOf(key) === -1);
      if (this.rootSubmenuKeys.indexOf(latestOpenKey + "") === -1) {
        this.openKeys = openKeys;
      } else {
        this.openKeys = latestOpenKey ? [latestOpenKey] : [];
      }
    },
    loginOut() {
      this.$util.post('api/logout', {
        type: 2
      }).then((res) => {
        this.$router.replace('/login')
      });

    }, menuClick(e) {
      if (this.$route.fullPath !== e.key) {
        this.$router.replace(e.key)
      }
    }, toScreen() {

      let routeUrl = this.$router.resolve({
        path: "/screenHome",
        query: {}
      });
      window.open(routeUrl.href, '_blank');
    },toPowerScreen() {
      let routeUrl = this.$router.resolve({
        path: "/screenPowerHome",
        query: {}
      });
      window.open(routeUrl.href, '_blank');
    }, passwordClose() {
      this.showEditModal = false;
    },
  }
}
</script>

<style scoped>
.icons-font >>> .anticon {
  font-size: 20px;
  vertical-align: -0.2em;
}
</style>
