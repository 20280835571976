<template>
  <div style="width: 100%;height: 100%;position: relative" class="flex-col" id="canvas-frame" v-if="showPageMain">
    <a-modal
        dialogClass="screen-dialog"
        :bodyStyle="{'height':850+'px'}"
        v-model="showDetail"
        :closable="true"
        :footer="null"
        :width="700"
        :destroyOnClose="true"
        title="设备详情"
    >
      <deviceDetail :pointInfo="selectPoint"/>
    </a-modal>
    <modalBridge @pointClick="pointClick" ref="modalRef" v-if="showThreeModal" :div="modalPop"/>

    <div class="pop" style="width: 250px;position: absolute;left: 100px;top: 20px;display: none" ref="modalPop">
      <div class="pop-body flex-column " style="overflow: scroll;height: 160px;padding: 12px"
           v-if="popBean">
        <span class="color-fff size-20 bold">{{ popBean.POINT_NAME }}</span>
        <span class="color-fff size-16"
              style="margin-top: 2px">报警状态：{{popBean.ALARM_LEVEL==0?'正常':'异常' }}</span>
        <span class="color-fff size-16" style="margin-top: 2px">监测点编号：{{ popBean.POINT_NUMBER }}</span>
        <span class="color-fff size-16" style="margin-top: 2px">安装位置：{{ popBean.LOCATION }}</span>
        <a-button type="primary" class="cursor_pointer" style="margin-top: 3px;align-self: flex-end" @click="showDeviceInfo(popBean)">查看详情</a-button>
        <a-icon type="close" style="color: white;font-size: 20px;position: absolute;right: 10px;top: 10px"
                class="cursor_pointer" @click="hideModal()"/>
      </div>
    </div>

    <div style="position: absolute;left: 30px;top: 35px" class="flex-column drop1" v-if="selectMonitorItem">
      <a-select dropdownClassName="dropdownStyle" v-model="selectMonitorItem" @change="monitorItemChange"
                style="width: 200px">
        <template v-for="item in monitorItemList">
          <a-select-option :key="item.MONITOR_ITEM_ID" :value="item.MONITOR_ITEM_ID">
            {{ item.ITEM_NAME }}
          </a-select-option>
        </template>
      </a-select>
    </div>

    <div class="marker_bottom_box flex-column" style="padding: 15px;">
      <div class="size-14 color-fff">图例</div>
      <div class="flex-column">
        <div v-for="(item, index) in markerBottomList" :key="index" class="flex-row-center" style="margin-top: 10px">
          <img :src="item.img" style="height: 25px;width: 25px"/>
          <div class="size-14 color-fff" style="margin-left: 8px">{{ item.name }}</div>
        </div>
      </div>
    </div>

    <div
        style="position: absolute;right: 30px;top: 30px;bottom: 30px;background-color: rgba(3, 34, 64, 0.7);width: 450px;padding: 20px"
        class="flex-column" v-show="!isFolding">
      <div class="flex-row-center">
        <img src="../image/screen/icon_right_dot1.png" style="width: 20px"/>
        <span class="size-22 color-fff" style="margin-left: 10px">{{ getTypeStr() }}</span>
        <div class="flex-1"></div>
        <div class="flex-row-center cursor_pointer" @click="isFolding = true">
          <div class="color-fff-80 size-18">折叠</div>
          <img src="../image/screen/folding_icon.png" width="20" style="margin-left: 8px;">
        </div>
      </div>
      <div class="flex-row-center" style="margin-top: 2%;">
        <div style="height: 150px;width: 150px;position: relative">
          <div style="position: absolute;left: 0;top: 0;right:0;bottom:0;align-items: center;"
               class="u-flex-col-center  color-fff">
            <span class="size-20 bold">{{ allDeviceNum }}</span>
            <span class="size-14">设备总数</span>
          </div>
          <chart_pie :dataValue="pieData" v-if="pieData" :key="getTime()"/>
        </div>


        <div class="flex-column flex-1" style="margin: 0 30px">
          <div v-for="(item, index) in pieData" :key="index" class="flex-row-center size-18"
               style="color: rgba(255,255,255,0.5);width: 100%;justify-content: space-around">
            <div style="height: 8px;width: 8px;" :style="{'background-color':item.color}"/>
            <span>{{ item.name }}</span>
            <span style="color: rgba(248, 195, 58, 1);font-size: 30px">{{ item.value }}</span>
            <span>{{ item.unit }}</span>
          </div>
        </div>
      </div>
      <div class="flex-row-center" style="margin-top: 20px">
        <img src="../image/screen/icon_title_left.png" style="width: 15px"/>
        <span class="size-22 color-fff" style="margin-left: 10px">设备列表</span>
      </div>
      <div class="flex-1 flex-column" style="overflow:scroll;margin-top: 15px">
        <div v-for="(item, index) in monitorPointList" :key="index"
             class="flex-column size-20 device-bg color-fff cursor_pointer"
             style="justify-content: center;margin-bottom: 15px" @click="toPageDevice(item)">
          <div class="flex-row-center" style="justify-content: space-between">
            <span>{{ item.POINT_NAME }}</span>
            <div style=" height: 30px; border-radius: 15px; border: 1px solid;padding: 0 15px"
                 class="size-16 u-flex-col-center"
                 :style="{'color':getPointAlarmLevel(item.ALARM_LEVEL).color,'border-color':getPointAlarmLevel(item.ALARM_LEVEL).color}">
              {{ getPointAlarmLevel(item.ALARM_LEVEL).name }}
            </div>
          </div>
          <span class="size-18" style="color: rgba(255,255,255,0.6);margin-top: 3px">编号<span
              style="color: rgba(255,255,255,0.8);margin-left: 10px">{{ item.POINT_NUMBER }}</span></span>
        </div>
      </div>
    </div>

    <div
        style="position: absolute;right: 0px;top: 30px;height:125px;background-color: rgba(3, 34, 64, 0.7);width: 50px;padding: 20px 15px 0px; border-radius: 20px 0 0 20px;"
        class="flex-column  cursor_pointer" v-show="isFolding" @click="isFolding = false">
      <div class="flex-column">
        <div class="color-fff-80 size-18">展开</div>
        <img src="../image/screen/folding_icon2.png" width="20" style="margin-top: 10px;">
      </div>
    </div>
  </div>
  <structure_device_map_three :pointBean="selectPoint" :key="time" @toPageMain="toPageMain" v-else/>

</template>

<script>
import chart_pie from './chart_pie';
import modalBridge from './modalBridge';
import structure_device_map_three from './structure_device_map_three';
import deviceDetail from './deviceDetail';

export default {
  components: {
    chart_pie, modalBridge, structure_device_map_three, deviceDetail
  },
  data() {
    return {
      modalPop: null,
      popBean: null,
      selectMonitorItem: undefined,
      monitorItemList: [],
      monitorPointList: null,
      allDeviceNum: '',
      selectDevicePicker: -1,
      markerBottomList: [
        {name: '报警点位', img: require('../image/screen/point_abnormal.png')},
        {name: '正常点位', img: require('../image/screen/point_normal.png')}],
      markerBgWidth: 500,
      pieData: null,
      isFolding: true,  //是否折叠
      markerImg: '',
      time: new Date().getTime(),
      structureId: null,
      showPageMain: true,
      showThreeModal: false,
      showDetail: false,
      selectPoint: null,

    }
  },
  computed: {
    getTypeStr() {
      return function () {
        for (let i = 0; i < this.monitorItemList.length; i++) {
          if (this.monitorItemList[i].MONITOR_ITEM_ID == this.selectMonitorItem) {
            return this.monitorItemList[i].ITEM_NAME;
          }
        }
        return ''
      }
    },
    getPointAlarmLevel() {
      return function (level) {
        let color = '', title = '';
        if (level!=null) {
          switch (level) {
            case 0:
              color = '#62DBED';
              title = '正常';
              break;
            case 1:
              color = 'yellow';
              title = '超限一级';
              break;
            case 2:
              color = 'orange';
              title = '超限二级';
              break;
            case 3:
              color = 'red';
              title = '超限三级';
              break;
          }
        } else {
          color = '#62DBED';
          title = '正常';
        }
        return {
          name: title, color: color
        }
      }

    },
  },
  mounted() {
    let structureItem = JSON.parse(localStorage.getItem('structureItem'));
    this.structureId = structureItem.STRUCTURE_ID;
    this.getData();
    this.modalPop = this.$refs.modalPop
  },
  methods: {
    getTime() {
      return new Date().getTime()
    },
    getData() {
      let self = this;
      this.$util.get('api/monitorItemList', {
        page: 1,
        pageSize: 10000,
        structureId: this.structureId,
      }, true).then((ret) => {
        self.monitorItemList = ret.content;
        if (ret.content && ret.content.length > 0)
          self.selectMonitorItem = ret.content[0].MONITOR_ITEM_ID;
        self.showThreeModal = true;
        self.getMonitorPointList();
      });
    },
    getMonitorPointList() {
      let self = this;
      if (this.selectMonitorItem) {
        this.$util.get('api/deviceList', {
          monitorItemId: this.selectMonitorItem,
          structureId: this.structureId,
          page: 1,
          pageSize: 10000,
        }).then(res => {
          if (res && res.content && res.content.list) {
            let offLineNum = 0, onLineNum = 0;
            for (let i = 0; i < res.content.list.length; i++) {
              let item = res.content.list[i];
              if (item.STATE === 0) {
                onLineNum++;
              } else offLineNum++;
            }

            let pieData = [
              {value: onLineNum, name: '在线设备', color: 'rgba(248, 195, 58, 1)'},
              {value: offLineNum, name: '离线设备', color: 'rgba(119, 148, 190, 1)'},
            ];
            self.allDeviceNum = onLineNum + offLineNum;
            self.pieData = pieData;

          }
        });

        self.$util.get('api/monitorPointList', {
          page: 1,
          pageSize: 10000,
          structureId: this.structureId,
          monitorItemId: this.selectMonitorItem
        }, true).then((res) => {
          let monitorItem = null;
          for (let i = 0; i < this.monitorItemList.length; i++) {
            if (this.selectMonitorItem == this.monitorItemList[i].MONITOR_ITEM_ID) {
              monitorItem = this.monitorItemList[i];
              break;
            }
          }
          let bean = res.content;
          let angle = -1.5;
          switch (monitorItem.MONITOR_ITEM_ID) {
            // case 57://梁桥高墩墩顶位移
              // for (let i = 0; i < bean.list.length; i++) {
              //   let item = bean.list[i];
              //   item['threePosition'] = [2.9, 1, -20 * i];
              // }
              // angle = 0;
              // break;
            case 57://梁桥高墩墩顶位移
              for (let i = 0; i < bean.list.length; i++) {
                let item = bean.list[i];
                let index = Math.floor(i/2);
                console.log(1111,index);
                switch (i%2) {
                  case 0:
                    item['threePosition'] = [0, 4.5, 19.42+index*-20];
                    item['angleX'] = 0;
                    item['angleY'] = 1.5;
                    item['angleZ'] = -1.55;
                    break;
                  case 1:
                    item['threePosition'] = [0, 4.5, 1.45+index*-20];
                    item['angleX'] = 0;
                    item['angleY'] = -1.5;
                    item['angleZ'] = -1.55 ;
                    break;
                }

              }
              angle = -1.5;

              break;
            case 51://主梁横向变形
              for (let i = 0; i < bean.list.length; i++) {
                let item = bean.list[i];
                let index = Math.floor(i/2);
                console.log(1111,index);
                switch (i%2) {
                  case 0:
                    item['threePosition'] = [2, 4.5, 19.42+index*-20];
                    item['angleX'] = 0;
                    item['angleY'] = 1.5;
                    item['angleZ'] = -1.55 ;
                    break;
                  case 1:
                    item['threePosition'] = [-2, 4.5, 19.42+index*-20];
                    item['angleX'] = 0;
                    item['angleY'] = 1.5;
                    item['angleZ'] = -1.55 ;
                    break;
                }
              }
              angle = -1.5;
              break;
            case 52://支座位移
              for (let i = 0; i < bean.list.length; i++) {
                let item = bean.list[i];
                let index = Math.floor(i/2);
                console.log(1111,index);
                switch (i%2) {
                  case 0:
                    item['threePosition'] = [1, 5, 11.5+index*-20];
                    item['angleX'] = 0;
                    item['angleY'] = 0;
                    item['angleZ'] = 3.12 ;
                    break;
                  case 1:
                    item['threePosition'] = [-1, 5, 11.5+index*-20];
                    item['angleX'] = 0;
                    item['angleY'] = 0;
                    item['angleZ'] = 3.12 ;
                    break;
                }
              }
              angle = -1.5;
              break;
            // case 51://主梁横向变形
            //   for (let i = 0; i < bean.list.length; i++) {
            //     let item = bean.list[i];
            //     item['threePosition'] = [2, 6, i % 2 == 0 ? (Math.random() * 5 * i) : -(Math.random() * 8 * i)];
            //   }
            //   angle = 0;
            //   break;
          }

          self.monitorPointList = bean.list;
          console.log(8888888,self.monitorPointList)
          self.$refs.modalRef.loadPoint(self.monitorPointList, angle);
        });
      }
    },

    monitorItemChange(value) {
      this.selectPoint = undefined;
      this.selectMonitorItem = value;
      this.$refs.modalRef.resetPop(null);
      this.getMonitorPointList();
    },
    pointClick(p) {
      if (p) {
        let index = p.index;
        this.popBean = this.monitorPointList[index];
      }
    },
    toPageMain() {
      this.showPageMain = true;
      this.selectPoint = null;
      this.getMonitorPointList();
    },
    toPageDevice(value) {
      this.$refs.modalRef.resetPop(value);
      this.popBean = value;
    },
    showDeviceInfo(value) {
      this.showDetail = true;
      this.selectPoint = value;
    },
    hideModal(){
      this.$refs.modalRef.resetPop(null);
      this.popBean = null;
    }
  }
}
</script>

<style scoped>
.marker_bottom_box {
  background: rgba(3, 39, 85, 0.56);
  backdrop-filter: blur(10px);
  position: absolute;
  bottom: 30px;
  left: 30px;
  border: 1px solid rgba(255, 255, 255, 0.3);
}


.device-bg {
  width: 410px;
  padding: 10px 15px;
  background: rgba(62, 111, 158, 0.3);
  border: 1px solid;
  border-image: linear-gradient(180deg, rgba(255, 255, 255, 0.3), rgba(98, 219, 237, 0.6)) 1 1;
}

>>> .screen-dialog .ant-modal-header {
  background-color: rgba(41, 65, 105, 0.9);
  border-bottom-color: #60ECFF;
}

>>> .screen-dialog .ant-modal-close-icon {
  color: white;
}

>>> .screen-dialog .ant-modal-title {
  color: white;
}

>>> .screen-dialog .ant-modal-content {
  background-color: rgba(41, 65, 105, 0.9);
  background-clip: padding-box;
  border: 0;
  border-radius: 4px;
  pointer-events: auto;
}
</style>
