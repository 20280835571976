<template>
  <div>
    <a-page-header
        style="padding: 0;margin: 15px 0"
        title="项目管理"
        @back="back()"
    />

    <div class="flex-1 flex-column" style="margin: 10px 0;background-color: white">
      <span class="size-18 color-333" style="margin: 20px">{{ id ? '编辑' : '新增' }}项目</span>
      <div class="flex-column" style="width: 100%">
        <a-form :form="form" @submit="handleSubmit" style="width: 100%" labelAlign="left" :label-col="{ span: 6 }">
          <a-row type="flex" justify="space-around">
            <a-col :span="span">
              <a-form-item label="项目名称">
                <a-input placeholder="请输入项目名称"
                         v-decorator="['projectName', { rules: [{ required: true, message: '请输入项目名称!' }] }]"
                         :allowClear="true"/>
              </a-form-item>
            </a-col>
            <a-col :span="span">
              <a-form-item label="省市区镇">
                <a-cascader
                    v-decorator="['adCode', { rules: [{ required: true, message: '请选择省市区镇!' }] }]"
                    :options="areaCode"
                    :fieldNames="{label: 'NAME', value: 'AD_CODE', children: 'children'}"
                    expand-trigger="hover"
                    placeholder="请选择省市区镇"
                    @change="onAreaChange"
                />
              </a-form-item>
            </a-col>

          </a-row>
          <a-row type="flex" justify="space-around">
            <a-col :span="span">
              <a-form-item label="项目登录账号">
                <a-input placeholder="请输入登录账号"
                         v-decorator="['accountName', { rules: [{ required: id==null, message: '请输入登录账号!' }] }]"
                         :allowClear="true" :disabled="id!=null"/>
              </a-form-item>
            </a-col>
            <a-col :span="span">
              <a-form-item label="项目登录密码">
                <a-input-password placeholder="请输入密码"
                                  v-decorator="['password', { rules: [{ required: id==null, message: '请输入密码!' }] }]"
                                  :allowClear="true" :disabled="id!=null"/>
              </a-form-item>
            </a-col>

          </a-row>
          <a-row type="flex" justify="space-around">
            <a-col :span="span">
              <a-form-item label="模块">
                <a-select mode="multiple" placeholder="请选择模块" v-decorator="['menuIds', { rules: [{ required: true, message: '请选择模块!' }] }]">
                  <template v-for="item in moduleList">
                    <a-select-option :key="item.MENU_ID" :value="item.MENU_ID">
                      {{ item.MENU_NAME }}
                    </a-select-option>
                  </template>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="span">
              <a-form-item label="是否启用">
                <a-radio-group v-decorator="['isUse', {initialValue: 1, rules: [{ required: false}]}]"
                               style="width: 100%;">
                  <a-radio :value="1">是</a-radio>
                  <a-radio :value="0">否</a-radio>
                </a-radio-group>
              </a-form-item>
            </a-col>

          </a-row>

          <a-row type="flex" justify="space-around">
            <a-col :span="span">
              <a-form-item label="联系人">
                <a-input placeholder="请输入联系人" v-decorator="['userName', { rules: [{ required: false }] }]"
                         :allowClear="true" :disabled="id!=null"/>
              </a-form-item>
            </a-col>
            <a-col :span="span">
              <a-form-item label="联系电话">
                <a-input placeholder="请输入联系电话" v-decorator="['telephone', { rules: [{ required: false }] }]"
                         :allowClear="true" :disabled="id!=null"/>
              </a-form-item>
            </a-col>
          </a-row>


          <a-row type="flex" justify="space-around">
            <a-col :span="23">
              <a-form-item label="项目描述">
                <a-textarea placeholder="请输入项目描述" v-decorator="['description', { rules: [{ required: false }] }]"
                            :allowClear="true" :auto-size="{ minRows: 6}"/>
              </a-form-item>
            </a-col>
          </a-row>

          <div class="flex-row-center" style="height: 50px;justify-content: flex-end;margin: 30px 50px;">
            <a-button type="link" @click="back">
              取消
            </a-button>
            <a-button type="primary" html-type="submit">
              保存
            </a-button>
          </div>
        </a-form>
      </div>

    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      span: 11,
      roleList: [],
      form: this.$form.createForm(this, {name: 'project'}),
      id: undefined,
      areaCode: window._cityCode,
      moduleList:[]
    }
  },
  mounted() {
    let self = this;
    // this.$util.get('admin/regionList', {}, true).then((res) => {
    //   let areaList = res.content;
    //   for (let i = 0; i < areaList.length; i++) {
    //     if(!areaList[i].PARENT_CODE){
    //       areaList[i].PARENT_CODE = '0';
    //     }
    //   }
    //   let treeStr = this.translateDataToTree(areaList,'0');
    //   console.log(22222,JSON.stringify(treeStr));
    // })

    this.$util.get('admin/projectMenuList', {}).then((res) => {
      self.moduleList = res.content;
      console.log(22222, res);
    })

    if (this.$route.query.id) {
      this.$util.showLoading(self);
      this.id = this.$route.query.id;
      this.$util.get('admin/projectInfo/' + this.$route.query.id, {}, true).then((res) => {
        self.bean = res.content;
        self.$util.hideLoading();
        let dao = res.content;
        let ids = [];
        if(dao.MENU_IDS){
          let idsStr = dao.MENU_IDS.split(',');
          for (let i = 0; i < idsStr.length; i++) {
            ids.push(parseInt(idsStr[i]))
          }
        }
        let code = dao.AD_CODE;
        self.form.setFieldsValue({
          accountName:dao.ACCOUNT_NAME,
          password:dao.PASSWORD,
          projectName: dao.PROJECT_NAME,
          adCode: [code.substring(0, 2) + "0000", code.substring(0, 4) + "00000000", code.substring(0, 6) + "000000", code],
          isUse: dao.IS_USE,
          description: dao.DESCRIPTION,
          menuIds:ids
        })
      })
    } else {
      self.form.setFieldsValue({
        isUse: 1,
      })
    }

  },

  methods: {
    translateDataToTree(data, parentId = '') {
      let tree = [];
      let temp;
      data.forEach((item, index) => {
        if (data[index].PARENT_CODE == parentId) {
          let obj = data[index];
          temp = this.translateDataToTree(data, data[index].AD_CODE);
          if (temp.length > 0) {
            obj.children = temp;
          }
          tree.push(obj);
        }
      })
      return tree;
    },
    onAreaChange(value, selectedOptions) {
      console.log(value, selectedOptions);
    },
    handleSubmit(e) {
      e.preventDefault();
      let self = this;
      this.form.validateFields((err, values) => {
        if (!err) {
          console.log(33, values)
          let p = {
            action: "ADD",
            projectName: values.projectName,
            adCode: values.adCode.length == 3 ? values.adCode[2] : values.adCode[3],
            isUse: values.isUse,
            description: values.description,
            isLauchUse: 0,
            menuIds:values.menuIds.join(',')
          }
          if (this.bean) {
            p.action = 'EDIT';
            p.projectId = this.bean.PROJECT_ID;
          } else {
            p.password = values.password;
            p.telephone = values.telephone;
            p.userName = values.userName;
            p.accountName = values.accountName;
          }
          console.log(p,values.menuIds.join(','))
          this.$util.post('admin/submitProject', p).then((res) => {
            console.log(res);
            self.back();
          });
        }
      });
    },
    back() {
      this.$router.back();
    }
  }
}
</script>

<style scoped>

</style>
