<template>
  <a-layout>
    <a-layout-header style="padding-left:1.25rem;">
      <div class="flex-row-center" style="height: 100%;">
        <img src="../../image/icon_logo1.png" style="width: 25px;height: 25px"/>
        <span class="color-fff size-18" style="margin-left:10px;line-height: 18px">结构物监测系统管理端</span>
        <div style="flex: 1"></div>
        <a-dropdown>
          <a class="ant-dropdown-link color-fff size-14 flex-row-center" @click="e => e.preventDefault()">
            <a-icon type="setting" style="margin-right: 10px;font-size: 17px"/>
            {{ user ? user.ROLE_NAME : '' }}
            <a-icon type="down" style="margin-left: 10px"/>
          </a>
          <a-menu slot="overlay">
            <a-menu-item class="flex-row-center" @click="loginOut()">
              <a-icon type="poweroff"/>
              <a href="javascript:;">退出登录</a>
            </a-menu-item>
          </a-menu>
        </a-dropdown>
      </div>
    </a-layout-header>

    <a-layout>
      <a-layout-sider v-model="collapsed" collapsible>
        <a-menu theme="dark" :open-keys="openKeys" mode="inline" @openChange="onOpenChange" @click="menuClick"
                :selectedKeys="parentPath">
          <template v-for="item in menuList">
            <a-menu-item v-if="!item.CHILD||item.CHILD.length==0" :key="item.key">
              <a-icon type="appstore" />
              <span>{{ item.MENU_NAME }}</span>
            </a-menu-item>
          </template>
        </a-menu>

      </a-layout-sider>
      <a-layout class="flex-column">
        <a-layout-content style="margin: 0 16px;" class="flex-column"
                          :style="{'margin-left':(16+'px'),'margin-right':(16+'px')}">
          <router-view style="flex:1" class="flex-column"/>
        </a-layout-content>
      </a-layout>

    </a-layout>
  </a-layout>
</template>

<script>

let rootPath = '/manage';
export default {
  name: "adminMain",
  data() {
    return {
      parentPath: [rootPath],
      matched: [],//面包屑
      user: null,
      rootSubmenuKeys: ['1', '2'],
      openKeys: [rootPath],
      collapsed: false,
      menuList: [],
      nasImageUrl:this.$util.NAS_IMAGE_URL,
    }
  },
  mounted() {
    let self = this;
    let userStr = localStorage.getItem('adminUserInfo');
    this.user = JSON.parse(userStr);
    this.init();
    this.$util.get('admin/menuList/1', {}).then((res) => {
      let menuList = res.content;
      for (let i = 0; i < menuList.length; i++) {
        if (menuList[i].CHILD && menuList[i].CHILD.length > 0) {
          menuList[i].key = menuList[i].MENU_ID + "";
          for (let j = 0; j < menuList[i].CHILD.length; j++) {
            self.setMenuInfo(menuList[i].CHILD[j]);
          }
        } else {
          self.setMenuInfo(menuList[i]);
        }
      }
      this.menuList = menuList;
      console.log(res)
    });

  },
  methods: {
    setMenuInfo(menuInfo) {
      menuInfo.key = rootPath + '/' + menuInfo.LINK;
    },
    init() {
      if (this.$route && this.$route.matched) {
        if (this.$route.matched.length > 0) {
          if (this.$route.fullPath === rootPath) {
            this.parentPath = [(rootPath + '/projectList')]
          }else if(this.$route.fullPath.indexOf('project') !== -1) {
            this.parentPath = [(rootPath + '/projectList')]
          }else if(this.$route.fullPath.indexOf('data') !== -1) {
            this.parentPath = [(rootPath + '/dataList')]
          }
        }
      }
    },
    onOpenChange(openKeys) {
      const latestOpenKey = openKeys.find(key => this.openKeys.indexOf(key) === -1);
      if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
        this.openKeys = openKeys;
      } else {
        this.openKeys = latestOpenKey ? [latestOpenKey] : [];
      }
    },
    loginOut() {
      this.$util.post('admin/logout', {
        type: 1
      }).then((res) => {
        this.$util.eventBus.$emit(this.$util.notifyKey.loginOutManage)
      });
    }, menuClick(e) {
      if (this.$route.fullPath !== e.key) {
        console.log(e.key);
        this.$router.replace(e.key)
      }
    }
  }
}
</script>

<style scoped>
.icons-font >>> .anticon {
  font-size: 20px;
  vertical-align: -0.2em;
}
</style>
