<template>
    <div style="padding: 20px;">
        <div class="flex-1 flex-column" style="padding-top: 20px; background: #fff;">
            <div class="flex-column" style="width: 100%">
                <a-form :form="form" @submit="handleSubmit" style="width: 100%">

                    <a-row type="flex" justify="space-around">
                        <a-col :span="span">
                            <a-form-item label="结构物名称">
                                <a-select placeholder="请选择结构物" :allowClear="true" @change="structureChange"
                                          v-decorator="['structureId', { rules: [{ required: true, message: '请选择结构物!' }] }]">
                                    <template v-for="item in structureList">
                                        <a-select-option :key="item.STRUCTURE_ID" :value="item.STRUCTURE_ID">
                                            {{ item.STRUCTURE_NAME }}
                                        </a-select-option>
                                    </template>
                                </a-select>
                            </a-form-item>
                        </a-col>
                        <a-col :span="span">
                            <a-form-item label="健康度">
                                <a-select placeholder="请选择健康度" :allowClear="true"
                                          v-decorator="['health', { rules: [{ required: true, message: '请选择健康度!' }] }]">
                                    <template v-for="item in healthList">
                                        <a-select-option :key="item.value" :value="item.value">
                                            {{ item.name }}
                                        </a-select-option>
                                    </template>
                                </a-select>
                            </a-form-item>
                        </a-col>

                        <a-col :span="span" class="drop1">
                            <a-form-item label="评估时间">
                                <a-date-picker valueFormat="YYYY-MM-DD" placeholder="请选择评估时间" @change="dateChange"
                                               v-decorator="['evaluateDate', { rules: [{ required: true}]}]"
                                               :allowClear="true" style="width: 100%"/>
                            </a-form-item>
                        </a-col>
                    </a-row>

                    <a-row type="flex" justify="space-around">
                        <a-col :span="span">
                            <a-form-item label="本月超限一级报警总次数">
                                <a-input placeholder="请选择结构物和健康度"
                                         v-model="alarmLevel1"
                                         :allowClear="true" :disabled="true"/>
                            </a-form-item>
                        </a-col>
                        <a-col :span="span">
                            <a-form-item label="本月超限二级报警总次数">
                                <a-input placeholder="请选择结构物和健康度"
                                         v-model="alarmLevel2"
                                         :allowClear="true" :disabled="true"/>
                            </a-form-item>
                        </a-col>
                        <a-col :span="span">
                            <a-form-item label="本月超限三级报警总次数">
                                <a-input placeholder="请选择结构物和健康度"
                                         v-model="alarmLevel3"
                                         :allowClear="true" :disabled="true"/>
                            </a-form-item>
                        </a-col>
                    </a-row>

                    <a-row type="flex" justify="space-around">
                        <a-col :span="23">
                            <a-form-item label="备注">
                                <a-textarea placeholder="请输入备注"
                                            v-decorator="['remark', { rules: [{ required: false}]}]"
                                            :allowClear="true" :auto-size="{ minRows: 8}"/>
                            </a-form-item>
                        </a-col>
                    </a-row>

                    <div class="flex-row-center" style="height: 50px;justify-content: flex-end;margin: 30px 50px;">
                        <a-button type="link" @click="back">
                            取消
                        </a-button>
                        <a-button type="primary" html-type="submit">
                            保存
                        </a-button>
                    </div>
                </a-form>
            </div>
        </div>
    </div>
</template>

<script>
    import moment from 'moment';
  export default {
    data() {
      return {
        span: 7,
        form: this.$form.createForm(this, {name: 'company'}),
        structureList: [],
        structureId: undefined,
        healthList: [
          {
            name: '基本完好',
            value: 1
          },
          {
            name: '轻度异常',
            value: 2
          },
          {
            name: '中等异常',
            value: 3
          },
          {
            name: '严重异常',
            value: 4
          }
        ],
        evaluateDate: '',
        alarmLevel1: undefined,
        alarmLevel2: undefined,
        alarmLevel3: undefined
      }
    },
    mounted() {
      this.getStructureList();
    },

    methods: {
      getInfo() {
        let self = this;
        if (this.$route.query.healthId) {
          this.$util.showLoading(self);
          this.healthId = this.$route.query.healthId;
          let p = {}
          this.$util.showLoading(this);
          this.$util.get('api/healthInfo/' + this.healthId, p, true).then((res) => {
            this.$util.hideLoading();
            console.log(222333, res);
            let dao = res.content;
            self.form.setFieldsValue({
              structureId: dao.STRUCTURE_ID,
              health: dao.HEALTH,
              evaluateDate: dao.EVALUATE_DATES,
              remark: dao.REMARK,
            })
            this.alarmLevel1 = dao.ALARM_LEVEL_1;
            this.alarmLevel2 = dao.ALARM_LEVEL_2;
            this.alarmLevel3 = dao.ALARM_LEVEL_3;
          })
        }
      },

      getStructureList() {
        let self = this;
        let p = {
          page: 1,
          pageSize: 1000,
        }
        this.$util.showLoading(self);
        this.$util.get('api/structureList', p).then((res) => {
          this.$util.hideLoading();
          let bean = res.content;
          self.structureList = bean.list;
          self.getInfo();
        })
      },

      getLevelStatisticsList() {
        let self = this;
        if (this.structureId && this.evaluateDate) {
          let p = {
            structureId: this.structureId,
            startDate: moment(this.evaluateDate).startOf('month').format('YYYY-MM-DD'),
            endDate: moment(this.evaluateDate).endOf('month').format('YYYY-MM-DD'),
            type: 2
          }
          console.log(444,p);
          this.$util.showLoading(self);
          this.$util.get('api/monitorAlarmLevelStatistics', p).then((res) => {
            this.$util.hideLoading();
            let bean = res.content;
            this.alarmLevel1 = bean.YELLOW_ALARM_COUNTS ? bean.YELLOW_ALARM_COUNTS : 0;
            this.alarmLevel2 = bean.ORANGE_ALARM_COUNTS ? bean.ORANGE_ALARM_COUNTS : 0;
            this.alarmLevel3 = bean.RED_ALARM_COUNTS ? bean.RED_ALARM_COUNTS : 0;

          })
        }
      },

      handleSubmit(e) {
        e.preventDefault();
        let self = this;
        this.form.validateFields((err, values) => {
          if (!err) {
            console.log(333, values);
            let p = {
              structureId: values.structureId,
              health: values.health,
              evaluateDate: values.evaluateDate,
              alarmLevel1: this.alarmLevel1,
              alarmLevel2: this.alarmLevel2,
              alarmLevel3: this.alarmLevel3,
              remark: values.remark,
              action: 'ADD'
            }
            if (this.healthId) {
              p.action = 'EDIT';
              p.healthId = this.healthId;
            }
            console.log(123, p);
            this.$util.post('api/submitHealth', p).then((res) => {
              console.log(res);
              self.back();
            });
          }
        });
      },
      back() {
        this.$router.back();
      },
      structureChange(value) {
        console.log(11,value);
        this.structureId = value;
        this.getLevelStatisticsList();
      },

      dateChange(value) {
        console.log(222,value);
        this.evaluateDate = value;
        this.getLevelStatisticsList();
      }
    }
  }
</script>

<style scoped>

</style>
