<!--创建于2021-11-11 王双雨 地图infoWindow弹出组件-->
<template>
  <section class="pop" style="width: 440px;">
    <div class="pop-body flex-row-center cursor_pointer" style="overflow: scroll" @click="clickMarker">
      <img :src="getImageUrl()" style="height: 100px;width: 150px"/>
      <div class="flex-column" style="margin-left: 15px">
        <span class="color-fff size-20 bold">{{bean.STRUCTURE_NAME}}</span>
        <div class="flex-row-center" style="align-items: flex-end;margin-top: 10px">
          <span class="color-fff size-16 flex-1">{{bean.ADDRESS}}</span>
          <a-button type="primary" style="margin-left: 15px">进入详情</a-button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    bean: {
      type: Object,
      default: null
    },
  },
  computed:{
    getImageUrl() {
      return function () {
        let item = this.bean;
        let imgName = '';
        if (item.SAVE_NAME_LIST && item.SAVE_NAME_LIST.length) {
          let imgList = item.SAVE_NAME_LIST.split(',');
          imgName = imgList[0];
        }
        return this.$util.getImageUrlWithName(imgName);
      }
    },
  },
  data() {
    return {
    }
  },
  mounted() {
  },
  methods: {
    closeInfoWindow() {
      this.$emit('closeInfoWindow')
    },
    clickMarker(){
      console.log(1)
      this.$emit('clickMarker')
    }
  }
}
</script>

<style scoped>
</style>
