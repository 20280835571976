<template>
  <div class="flex-column" style="height: 100%;width: 100%">
    <a-modal
        dialogClass="screen-dialog"
        title="特征值统计数据表"
        :bodyStyle="{'height':700+'px'}"
        v-model="showModal"
        :footer="null"
        :width="640"
        :destroyOnClose="true"
    >
      <deviceValue :deviceInfo="deviceDetail" v-if="deviceDetail"/>
    </a-modal>

    <div class="flex-row-center" style="margin-top: 20px">
      <img src="../image/screen/icon_title_left.png" style="width: 15px"/>
      <span class="size-20 color-fff" style="margin-left: 10px">今日实时数据</span>
    </div>
    <div style="height: 270px">
      <chart_column :mData="nowTimeData" :key="keyTime"
                    v-if="getDeviceType()==4||getDeviceType()==7||getDeviceType()==8||getDeviceType()==9||getDeviceType()==10"/>
      <div v-else-if="getDeviceType()==48" style="margin-top: 15px;height: 100%;">
        <chart_durable_hum :array_durable_hum="arrayDurableToday" :key="keyTime" v-if="selectDurablePicker==1"
                           :isToday="true"/>
        <chart_durable_dianya :mData="arrayDurableToday" :key="keyTime" v-else-if="selectDurablePicker==2"
                              :isToday="true"/>
        <chart_durable_cur :array_durable="arrayDurableToday" :key="keyTime" v-else-if="selectDurablePicker==3" :isToday="true"/>
        <chart_durable_openv :array_durable="arrayDurableToday" :key="keyTime" v-else :isToday="true"/>
      </div>
      <chart_angel_today :array_angel="array_angel_today" :key="keyTime" :isSmall="true" :isToday="true"
                         v-else-if="getDeviceType()==5"
                         style="margin-top: 15px"/>
      <chart_gnss :array_gnss="arrayGnssToday" :key="keyTime" v-else :isToday="true" :isSmall="true"
                  style="margin-top: 10px"/>
    </div>
    <div style="flex: 1"></div>
    <div class="flex-row-center">
      <img src="../image/screen/icon_title_left.png" style="width: 15px"/>
      <span class="size-20 color-fff" style="margin-left: 10px">历史监测数据</span>
    </div>
    <div style="height: 270px">
      <chart_line :mData="historyTimeData" :key="keyTime"
                  v-if="getDeviceType()==4||getDeviceType()==7||getDeviceType()==8||getDeviceType()==9||getDeviceType()==10"/>
      <div v-else-if="getDeviceType()==48" style="margin-top: 15px;height: 100%;">
        <chart_durable_hum_history :array_durable_hum="arrayDurableHistory" :key="keyTime" v-if="selectDurablePicker==1"/>
        <chart_durable_dianya_history :mData="arrayDurableHistory" :key="keyTime" v-else-if="selectDurablePicker==2"/>
        <chart_durable_cur_history :array_durable="arrayDurableHistory" :key="keyTime" v-else-if="selectDurablePicker==3"/>
        <chart_durable_openv_history :array_durable="arrayDurableHistory" :key="keyTime" v-else/>
      </div>
      <chart_angel :array_angel="array_angel" :key="keyTime" :isSmall="true" v-else-if="getDeviceType()==5"
                   style="margin-top: 15px"/>
      <chart_gnss_history :array_gnss="arrayGnssHistory" :key="keyTime" :isSmall="true" v-else
                          style="margin-top: 10px"/>
    </div>
    <div style="flex: 1"></div>
<!--    <div class="flex-row-center" style="justify-content: center">
      <div class="flex-row-center btn cursor_pointer" style="padding: 0 30px;justify-content: center" @click="showModal = true">
        <a-icon type="container" style="color: white;font-size: 18px"/>
        <span class="size-18 color-fff" style="margin-left: 10px">特征值统计数据表</span>
      </div>
    </div>
    <div style="height: 10px"></div>-->
  </div>
</template>

<script>
import chart_column from './chart_column';
import chart_line from './chart_line';
import moment from 'moment';
import chart_angel from './chart_angel';
import chart_angel_today from './chart_angel';
import chart_gnss from './chart_gnss';
import chart_gnss_history from './chart_gnss';
import chart_durable_dianya from './chart_line';
import chart_durable_dianya_history from './chart_line';
import chart_durable_hum from './chart_durable_hum';
import chart_durable_hum_history from './chart_durable_hum';
import chart_durable_cur from './chart_durable_cur';
import chart_durable_cur_history from './chart_durable_cur';
import chart_durable_openv from "./chart_durable_openv";
import chart_durable_openv_history from "./chart_durable_openv";
import deviceValue from "./deviceValue";
export default {
  props: {
    pointInfo: {
      type: Object,
    }
  },
  name: "设备状态",
  data() {
    return {
      nowTimeData: null,
      historyTimeData: null,
      array_angel: [],
      array_angel_today: [],
      arrayGnssToday: [],
      arrayGnssHistory: [],

      selectDurablePicker: 1,
      isDurable: false,
      arrayDurableToday: [],
      arrayDurableHistory: [],
      durableTypeList: [
        {name: '环境温湿度', value: 1},
        {name: '混合电位电压V', value: 2},
        {name: '电流监控', value: 3},
        {name: '开路电位电压', value: 4}
      ],

      arrayRangeFinderToday: [],
      arrayRangeFinderHistory: [],

      keyTime: '',
      deviceDetail:null,
      showModal:false
    }
  },
  components: {
    chart_column, chart_line, chart_angel, chart_angel_today, chart_gnss, chart_gnss_history,
    chart_durable_dianya, chart_durable_dianya_history, chart_durable_hum, chart_durable_hum_history,chart_durable_cur,chart_durable_cur_history,chart_durable_openv,chart_durable_openv_history,
    deviceValue
  },
  mounted() {
    if(this.pointInfo){
      this.selectDevicePicker = this.pointInfo.DEVICE_ID;
      this.$util.get('api/deviceInfo/'+this.selectDevicePicker,{}).then((res)=>{
        this.deviceDetail = res.content;
        this.getTwoChartData();
      });
    }

  },
  computed: {
    getDeviceType() {
      return function () {
        if(this.deviceDetail)
        return this.deviceDetail.DEVICE_TYPE
      }
    },
  },
  methods: {
    getTime() {
      this.keyTime = new Date().getTime()
    },
    getTwoChartData() {
      if (this.selectDevicePicker) {
        let item = this.deviceDetail;
        if (item) {
          if (item.DEVICE_TYPE == 4) {//拉线位移计
            this.getTimeWireDistanceStatisticsList();
          } else if (item.DEVICE_TYPE == 5) {//倾角计
            this.getTimeInclinometerStatisticsList();
            this.getTimeInclinometerStatisticsListToday();
          } else if (item.DEVICE_TYPE == 6) {//gnss
            this.getTimeGnssStatisticsList();
            this.getTimeGnssStatisticsListHistory();
            // } else if (item.DEVICE_TYPE == 10) {//激光测距仪
            //   this.getTimeRangeFinderStatisticsList();
            //   this.getTimeRangeFinderStatisticsListHistory();
          } else if (item.DEVICE_TYPE == 48) {//耐久
            this.isDurable = true;
            this.getTimeDurableStatisticsList()
            this.getTimeDurableStatisticsListHistory();
          } else {
            this.getTimeWireDistanceStatisticsList();
          }
        }
      }
    },
    getTimeWireDistanceStatisticsList() {
      let self = this;
      let structureItem = JSON.parse(localStorage.getItem('structureItem'));
      let timeList = [];
      for (let i = -6; i <= 0; i++) {
        timeList.push({
          xValue: moment().add(i, 'hours').format("HH:00"),
          time: moment().add(i, 'hours').format("YYYY-MM-DD HH"),
          yValue: 0
        });
      }
      this.$util.get('api/timeWireDistanceStatisticsList', {
        deviceId: structureItem.TYPE == 1 ? this.selectDevicePicker : 3,
        startTime: moment().add(-6, 'hours').format("YYYY-MM-DD HH:mm:ss"),
        endTime: moment().format("YYYY-MM-DD HH:mm:ss"),
        type: 3
      }, true).then((res) => {
        let resList = res.content;
        for (let i = 0; i < timeList.length; i++) {
          let item = timeList[i];
          for (let j = 0; j < resList.length; j++) {
            let item2 = resList[j];
            if (item.time == item2.monitorTime) {
              item.yValue = resList[j].avgDistance;
              break;
            }
          }
        }
        self.nowTimeData = timeList;
        self.getTime()
      })
      this.getHistoryWireDistanceStatisticsList();

    },
    getHistoryWireDistanceStatisticsList() {
      let self = this;
      let structureItem = JSON.parse(localStorage.getItem('structureItem'));
      let timeList = [];
      for (let i = -6; i <= 0; i++) {
        timeList.push({
          xValue: moment().add(i, 'days').format("MM-DD"),
          time: moment().add(i, 'days').format("YYYY-MM-DD"),
          yValue: 0
        });
      }
      this.$util.get('api/timeWireDistanceStatisticsList', {
        deviceId: structureItem.TYPE == 1 ? this.selectDevicePicker : 3,
        startTime: moment().add(-6, 'days').format("YYYY-MM-DD HH:mm:ss"),
        endTime: moment().format("YYYY-MM-DD HH:mm:ss"),
        type: 1
      }, true).then((res) => {
        let resList = res.content;
        for (let i = 0; i < timeList.length; i++) {
          let item = timeList[i];
          for (let j = 0; j < resList.length; j++) {
            let item2 = resList[j];
            if (item.time == item2.monitorTime) {
              item.yValue = parseFloat(resList[j].avgDistance).toFixed(2);
              break;
            }
          }
        }
        self.historyTimeData = timeList;
        self.getTime();
      })
    },
    //倾角计的历史监测数据
    getTimeInclinometerStatisticsList() {
      let self = this;
      let timeList = [];
      for (let i = -6; i <= 0; i++) {
        let dd = moment().add(i, 'days').format("YYYY-MM-DD");
        let item = {
          avgXAxis: 0,
          avgYAxis: 0,
          counts: 0,
          maxXAxis: 0,
          maxYAxis: 0,
          minXAxis: 0,
          minYAxis: 0,
          monitorTime: dd
        }
        timeList.push(item);
      }
      this.$util.get('api/timeInclinometerStatisticsList', {
        deviceId: this.selectDevicePicker,
        startTime: moment().add(-6, 'days').format("YYYY-MM-DD"),
        endTime: moment().format("YYYY-MM-DD"),
        type: 1
      }, true).then((res) => {
        let list = timeList;
        let resList = res.content;
        for (let i = 0; i < list.length; i++) {
          let item = list[i];
          for (let j = 0; j < resList.length; j++) {
            let item2 = resList[j];
            if (item.monitorTime == item2.monitorTime) {
              timeList[i] = resList[j];
              break;
            }
          }
        }
        self.array_angel = timeList;
        self.getTime();
      })
    }, getTimeInclinometerStatisticsListToday() {
      let self = this;
      let timeList = [];

      for (let i = -6; i <= 0; i++) {
        let dd = moment().add(i, 'hours').format("YYYY-MM-DD HH");
        let item = {
          avgXAxis: 0,
          avgYAxis: 0,
          counts: 0,
          maxXAxis: 0,
          maxYAxis: 0,
          minXAxis: 0,
          minYAxis: 0,
          monitorTime: dd
        }
        timeList.push(item);
      }
      this.$util.get('api/timeInclinometerStatisticsList', {
        deviceId: this.selectDevicePicker,
        startTime: moment().add(-6, 'hours').format("YYYY-MM-DD HH:mm:ss"),
        endTime: moment().format("YYYY-MM-DD HH:mm:ss"),
        type: 3
      }, true).then((res) => {
        let list = timeList;
        let resList = res.content;
        for (let i = 0; i < list.length; i++) {
          let item = list[i];
          for (let j = 0; j < resList.length; j++) {
            let item2 = resList[j];
            if (item.monitorTime == item2.monitorTime) {
              timeList[i] = resList[j];
              break;
            }
          }
        }
        self.array_angel_today = timeList;
        self.getTime();
      })
    }, getTimeGnssStatisticsList() {
      let timeList = [];
      let self = this;
      for (let i = -6; i <= 0; i++) {
        let dd = moment().add(i, 'hours').format("YYYY-MM-DD HH");
        let item = {
          avgTotalDe: 0,
          avgTotalDh: 0,
          avgTotalDn: 0,
          counts: 0,
          maxTotalDe: 0,
          maxTotalDh: 0,
          maxTotalDn: 0,
          minTotalDe: 0,
          minTotalDh: 0,
          minTotalDn: 0,
          monitorTime: dd
        }
        timeList.push(item);
      }
      this.$util.get('api/timeGnssStatisticsList', {
        deviceId: this.selectDevicePicker,
        startTime: moment().add(-6, 'hours').format("YYYY-MM-DD HH:mm:ss"),
        endTime: moment().format("YYYY-MM-DD HH:mm:ss"),
        type: 3
      }, true).then((res) => {
        let list = timeList;
        let resList = res.content;
        for (let i = 0; i < list.length; i++) {
          let item = list[i];
          for (let j = 0; j < resList.length; j++) {
            let item2 = resList[j];
            if (item.monitorTime == item2.monitorTime) {
              timeList[i] = resList[j];
              break;
            }
          }
        }
        self.arrayGnssToday = timeList;
        self.getTime();
      })
    },
    getTimeGnssStatisticsListHistory() {
      let timeList = [];
      let self = this;
      for (let i = -6; i <= 0; i++) {
        let dd = moment().add(i, 'days').format("YYYY-MM-DD");
        let item = {
          avgTotalDe: 0,
          avgTotalDh: 0,
          avgTotalDn: 0,
          counts: 0,
          maxTotalDe: 0,
          maxTotalDh: 0,
          maxTotalDn: 0,
          minTotalDe: 0,
          minTotalDh: 0,
          minTotalDn: 0,
          monitorTime: dd
        }
        timeList.push(item);
      }
      this.$util.get('api/timeGnssStatisticsList', {
        deviceId: this.selectDevicePicker,
        startTime: moment().add(-6, 'days').format("YYYY-MM-DD"),
        endTime: moment().format("YYYY-MM-DD"),
        type: 1
      }, true).then((res) => {
        let list = timeList;
        let resList = res.content;
        for (let i = 0; i < list.length; i++) {
          let item = list[i];
          for (let j = 0; j < resList.length; j++) {
            let item2 = resList[j];
            if (item.monitorTime == item2.monitorTime) {
              timeList[i] = resList[j];
              break;
            }
          }
        }
        self.arrayGnssHistory = timeList;
        self.getTime();
      })
    }, getTimeDurableStatisticsList() {
      let timeList = [];
      let self = this;
      for (let i = -6; i <= 0; i++) {
        timeList.push({
          xValue: moment().add(i, 'hours').format("HH:00"),
          _id: moment().add(i, 'hours').format("YYYY-MM-DD HH"),
          avg_temp: 0,
          avg_hum: 0,
          yValue: 0,
          avg_sac: 0,
          avg_cc: 0,
          avg_openv:0,
        });
      }
      this.$util.get('api/timeDurableStatisticsList', {
        deviceId: this.selectDevicePicker,
        startTime: moment().add(-6, 'hours').format("YYYY-MM-DD HH:mm:ss"),
        endTime: moment().format("YYYY-MM-DD HH:mm:ss"),
        type: 3,
        fields: 'temp,hum,mixv,openv,sac,cc',
      }, true).then((res) => {
        let list = timeList;
        let resList = res.content;
        for (let i = 0; i < list.length; i++) {
          let item = list[i];
          for (let j = 0; j < resList.length; j++) {
            let item2 = resList[j];
            if (item._id == item2._id) {
              item.yValue = resList[j].avg_mixv;
              item.avg_temp = resList[j].avg_temp;
              item.avg_hum = resList[j].avg_hum;
              item.avg_sac = resList[j].avg_sac;
              item.avg_cc = resList[j].avg_cc;
              item.avg_openv = resList[j].avg_openv;

              break;
            }
          }
        }
        self.arrayDurableToday = timeList;
        self.getTime();
      })
    },
    getTimeDurableStatisticsListHistory() {
      let timeList = [];
      let self = this;
      for (let i = -6; i <= 0; i++) {
        let item = {
          xValue: moment().add(i, 'days').format("MM-DD"),
          _id: moment().add(i, 'days').format("YYYY-MM-DD"),
          avg_temp: 0,
          avg_hum: 0,
          yValue: 0,
          avg_sac: 0,
          avg_cc: 0,
          avg_openv:0
        }
        timeList.push(item);
      }
      this.$util.get('api/timeDurableStatisticsList', {
        deviceId: this.selectDevicePicker,
        startTime: moment().add(-6, 'days').format("YYYY-MM-DD HH:mm:ss"),
        endTime: moment().format("YYYY-MM-DD HH:mm:ss"),
        fields: 'temp,hum,mixv,openv,sac,cc',
        type: 1
      }, true).then((res) => {
        let list = timeList;
        let resList = res.content;
        for (let i = 0; i < list.length; i++) {
          let item = list[i];
          for (let j = 0; j < resList.length; j++) {
            let item2 = resList[j];
            if (item._id == item2._id) {
              item.yValue = resList[j].avg_mixv;
              item.avg_temp = resList[j].avg_temp;
              item.avg_hum = resList[j].avg_hum;
              item.avg_sac = resList[j].avg_sac;
              item.avg_cc = resList[j].avg_cc;
              item.avg_openv = resList[j].avg_openv;
              break;
            }
          }
        }
        self.arrayDurableHistory = timeList;
        self.getTime();
      })
    },
    getTimeRangeFinderStatisticsList() {
      let timeList = [];
      let self = this;
      for (let i = -6; i <= 0; i++) {
        let dd = moment().add(i, 'hours').format("YYYY-MM-DD HH");
        let item = {
          minTotalDn: 0,
          monitorTime: dd
        }
        timeList.push(item);
      }
      this.$util.get('api/timeRangeFinderStatisticsList', {
        deviceId: this.selectDevicePicker,
        startTime: moment().add(-6, 'hours').format("YYYY-MM-DD HH:mm:ss"),
        endTime: moment().format("YYYY-MM-DD HH:mm:ss"),
        type: 3
      }, true).then((res) => {
        let list = timeList;
        let resList = res.content;
        for (let i = 0; i < list.length; i++) {
          let item = list[i];
          for (let j = 0; j < resList.length; j++) {
            let item2 = resList[j];
            if (item.monitorTime == item2.monitorTime) {
              timeList[i] = resList[j];
              break;
            }
          }
        }
        self.arrayRangeFinderToday = timeList;
        self.getTime();
      })
    },
    getTimeRangeFinderStatisticsListHistory() {
      let timeList = [];
      let self = this;
      for (let i = -6; i <= 0; i++) {
        let dd = moment().add(i, 'days').format("YYYY-MM-DD");
        let item = {
          minTotalDn: 0,
          monitorTime: dd
        }
        timeList.push(item);
      }
      this.$util.get('api/timeRangeFinderStatisticsList', {
        deviceId: this.selectDevicePicker,
        startTime: moment().add(-6, 'days').format("YYYY-MM-DD"),
        endTime: moment().format("YYYY-MM-DD"),
        type: 1
      }, true).then((res) => {
        let list = timeList;
        let resList = res.content;
        for (let i = 0; i < list.length; i++) {
          let item = list[i];
          for (let j = 0; j < resList.length; j++) {
            let item2 = resList[j];
            if (item.monitorTime == item2.monitorTime) {
              timeList[i] = resList[j];
              break;
            }
          }
        }
        self.arrayRangeFinderHistory = timeList;
        self.getTime();
      })
    }
  },
}

</script>

<style scoped>
.btn{
  height: 40px;
  background: #08346F;
  border-radius: 25px;
  border: 1px solid #62DBED;
}
>>>.screen-dialog .ant-modal-header {
  background-color: rgba(41, 65, 105, 0.9);
  border-bottom-color: #60ECFF;
}
>>>.screen-dialog .ant-modal-close-icon {
  color: white;
}
>>>.screen-dialog .ant-modal-title {
  color: white;
}
>>>.screen-dialog .ant-modal-content {
  background-color: rgba(41, 65, 105, 0.9);
  background-clip: padding-box;
  border: 0;
  border-radius: 4px;
  pointer-events: auto;
}
</style>
