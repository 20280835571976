<template>
  <div>
    <a-page-header
        style="padding: 0;margin: 15px 0"
        title="传感器管理"
        @back="back()"
    />
    <div class="flex-1 flex-column" style="margin: 10px 0;background-color: white">
      <span class="size-18 color-333" style="margin: 20px">{{ (id ? '编辑' : '新增') + '传感器' }}</span>
      <div class="flex-column" style="width: 100%">
        <a-form :form="form" @submit="handleSubmit" style="width: 100%" labelAlign="left" :label-col="{ span: 6 }">
          <a-row type="flex" justify="space-around">
            <a-col :span="span">
              <a-form-item label="设备名称">
                <a-input placeholder="请输入设备名称"
                         v-decorator="['deviceName', { rules: [{ required: true, message: '请输入设备名称!' }] }]"
                         :allowClear="true"/>
              </a-form-item>
            </a-col>
            <a-col :span="span">
              <a-form-item label="设备编号">
                <a-input placeholder="请输入设备编号"
                         v-decorator="['deviceNumber', { rules: [{ required: true, message: '请输入设备编号!' }] }]"
                         :allowClear="true"/>
              </a-form-item>
            </a-col>
            <a-col :span="span">
              <a-form-item label="设备类型">
                <a-select placeholder="请选择设备类型" v-decorator="['deviceType', { rules: [{ required: true, message: '请选择设备类型!' }] }]">
                  <template v-for="item in deviceTypeList">
                    <a-select-option :key="item.DATA_ID" :value="item.DATA_ID">
                      {{ item.CLASS_VALUE }}
                    </a-select-option>
                  </template>
                </a-select>
              </a-form-item>
            </a-col>
          </a-row>

          <a-row type="flex" justify="space-around">
            <a-col :span="span">
              <a-form-item label="设备状态">
                <a-select placeholder="请选择设备状态" v-decorator="['status', { rules: [{ required: false }] }]">
                  <template v-for="(item,index) in statusList">
                    <a-select-option :key="index" :value="index">
                      {{ item }}
                    </a-select-option>
                  </template>
                </a-select>
              </a-form-item>
            </a-col>

            <a-col :span="span">
              <a-form-item label="安装部位">
                <a-input placeholder="请输入安装部位" v-decorator="['location', { rules: [{ required: false}]}]"
                         :allowClear="true"/>
              </a-form-item>
            </a-col>

            <a-col :span="span">
              <a-form-item label="生产厂商">
                <a-input placeholder="请输入生产厂商" v-decorator="['manufactor', { rules: [{ required: false}]}]"
                         :allowClear="true"/>
              </a-form-item>
            </a-col>
          </a-row>

          <a-row type="flex" justify="space-around">
            <a-col :span="span">
              <a-form-item label="单位">
                <a-input placeholder="请输入单位" v-decorator="['unit', { rules: [{ required: false}]}]"
                         :allowClear="true"/>
              </a-form-item>
            </a-col>
            <a-col :span="span">
              <a-form-item label="设备型号">
                <a-input placeholder="请输入设备型号" v-decorator="['model', { rules: [{ required: false}]}]"
                         :allowClear="true"/>
              </a-form-item>
            </a-col>

            <a-col :span="span">
              <a-form-item label="设备描述">
                <a-textarea placeholder="请输入设备描述" v-decorator="['description', { rules: [{ required: false}]}]"
                            :allowClear="true" :auto-size="{ minRows: 3}"/>
              </a-form-item>
            </a-col>

          </a-row>

          <a-row type="flex" justify="space-around">
            <a-col :span="23">
              <span class="size-18 color-000-85 ">设备参数</span>
            </a-col>

          </a-row>

          <a-row type="flex" justify="space-around" style="margin-top: 15px">
            <a-col :span="span">
              <a-form-item label="采样频率">
                <a-input-number style="width: 100%" placeholder="请输入采样频率" v-decorator="['quency', { rules: [{ required: false}]}]"
                         :allowClear="true"/>
              </a-form-item>
            </a-col>
            <a-col :span="span">
              <a-form-item label="分辨力">
                <a-input-number style="width: 100%" placeholder="请输入分辨力" v-decorator="['resolution', { rules: [{ required: false}]}]"
                         :allowClear="true"/>
              </a-form-item>
            </a-col>

            <a-col :span="span">
              <a-form-item label="信号范围">
                <a-input placeholder="请输入信号范围" v-decorator="['signalRange', { rules: [{ required: false}]}]"
                         :allowClear="true"/>
              </a-form-item>
            </a-col>
          </a-row>

          <a-row type="flex" justify="space-around">
            <a-col :span="span">
              <a-form-item label="信号类型">
                <a-input placeholder="请输入信号类型" v-decorator="['signalType', { rules: [{ required: false}]}]"
                         :allowClear="true"/>
              </a-form-item>
            </a-col>
            <a-col :span="span">
            </a-col>

            <a-col :span="span">
            </a-col>
          </a-row>

          <a-row type="flex" justify="space-around">
            <a-col :span="23">
              <div class="flex-row-center">
                <span style="width: 75px">设备图片：</span>
                <a-upload
                    accept=".jpg,.png,.jpeg"
                    style="margin-left: 20px"
                    name="imgFile"
                    list-type="picture-card"
                    :action="actionUrl"
                    :file-list="fileList"
                    @change="uploadCallback"
                    @preview="handlePreview"
                    :multiple="true"
                    :remove="handleRemove"
                >
                  <div v-if="fileList&&fileList.length <12">
                    <a-icon :type="loading ? 'loading' : 'plus'"/>
                    <div>
                      点击上传
                    </div>
                  </div>
                </a-upload>
              </div>

            </a-col>
          </a-row>

          <div class="flex-row-center" style="height: 50px;justify-content: flex-end;margin: 30px 50px;">
            <a-button type="link" @click="back">
              取消
            </a-button>
            <a-button type="primary" html-type="submit">
              保存
            </a-button>
          </div>
        </a-form>
      </div>

    </div>
    <a-modal :visible="previewVisible" :footer="null" @cancel="previewVisible = false">
      <img alt="预览" style="width: 100%" :src="previewImage"/>
    </a-modal>
  </div>
</template>

<script>

export default {
  data() {
    return {
      span: 7,
      form: this.$form.createForm(this, {name: 'device'}),
      id: undefined,
      previewVisible: false,
      previewImage: '',
      actionUrl: '/client/api/uploadFile',
      loading: false,
      fileList: [],
      statusList: ['正常','故障','损坏','维修','更换'],
      deviceTypeList:[],
    }
  },
  mounted() {
    let self = this;

    if (this.$route.query.deviceId) {
      this.$util.showLoading(self);
      this.id = this.$route.query.deviceId;
      this.$util.get('api/deviceInfo/' + this.$route.query.deviceId, {isDetail: 1, isImage: 1}, true).then((res) => {
        self.bean = res.content;
        self.$util.hideLoading();
        let dao = res.content;
        self.form.setFieldsValue({
          accuracy: dao.ACCURACY,
          description: dao.DESCRIPTION,
          deviceName: dao.DEVICE_NAME,
          deviceNumber: dao.DEVICE_NUMBER,
          model: dao.MODEL,
          unit: dao.UNIT,
          location: dao.LOCATION,
          status: dao.STATUS,
          manufactor: dao.MANUFACTOR,

          quency: dao.QUENCY,
          resolution: dao.RESOLUTION,
          signalRange: dao.SIGNAL_RANGE,
          signalType: dao.SIGNAL_TYPE,
        })

        this.$util.get('api/dataList', {
          page: 1,
          pageSize: 100,
          classType: 'DEVICE_TYPE'
        }, true).then((res) => {
          self.deviceTypeList = res.content;
          setTimeout(()=>{
            self.form.setFieldsValue({
              deviceType: dao.DEVICE_TYPE,
            })
          },100)
        })

        if (dao.DEVICE_IMAGE_LIST && dao.DEVICE_IMAGE_LIST.length > 0) {
          let picList = [];
          for (let i = 0; i < dao.DEVICE_IMAGE_LIST.length; i++) {
            let item = dao.DEVICE_IMAGE_LIST[i];
            picList.push(
                {
                  uid: '-' + i,
                  name: item.FILE_NAME,
                  caption: item.FILE_NAME,
                  key: item.SAVE_NAME,
                  status: 'done',
                  url: self.$util.getServeImageUrl() + item.SAVE_NAME,
                }
            )
          }
          self.fileList = picList;
        }

      })
    }else{
      this.$util.get('api/dataList', {
        page: 1,
        pageSize: 100,
        classType: 'DEVICE_TYPE'
      }, true).then((res) => {
        self.deviceTypeList = res.content;
      })
    }

  },

  methods: {
    handleSubmit(e) {
      e.preventDefault();
      let self = this;
      this.form.validateFields((err, values) => {
        if (!err) {
          let p = {
            action: "ADD",
            deviceName: values.deviceName,
            deviceNumber: values.deviceNumber,
            deviceType: values.deviceType,
            model: values.model,
            unit: values.unit,
            location: values.location,
            status: values.status,
            manufactor: values.manufactor,
            description: values.description,

            quency: values.quency,
            resolution: values.resolution,
            signalRange: values.signalRange,
            signalType: values.signalType,

          }

          let picList = [];
          if (self.fileList.length > 0) {
            for (let i = 0; i < self.fileList.length; i++) {
              console.log(i)
              let dao = self.fileList[i].response;
              if (dao && dao.errorCode == 0 && dao.content && dao.content.initialPreviewConfig && dao.content.initialPreviewConfig.length > 0) {
                picList.push({
                  key: dao.content.initialPreviewConfig[0].key,
                  caption: dao.content.initialPreviewConfig[0].caption,
                })
              } else {
                picList.push({
                  key: self.fileList[i].key,
                  caption: self.fileList[i].caption,
                })
              }

            }
          }
          let keys = [], caption = [];
          if (picList.length > 0) {
            for (let i = 0; i < picList.length; i++) {
              keys.push(picList[i].key)
              caption.push(picList[i].caption)
            }
          }
          p.imgCaptions = caption.join(',');
          p.imgKeys = keys.join(',');

          if (this.bean) {
            p.action = 'EDIT';
            p.deviceId = this.bean.DEVICE_ID;
          }
          console.log(4444, p);
          this.$util.post('api/submitDevice', p).then((res) => {
            self.back();
          });
        }
      });
    },
    back() {
      this.$router.back();
    }, modalClose(params) {
      if (params)
        this.locationSelect = params.locationSelect;
      this.showModal = false;
    }, handleRemove(file) {
      file.status = '1';
      let name = '';
      if (file.response) {
        name = file.response.content.initialPreviewConfig[0].key
      } else {
        name = file.name
      }
      this.$util.post("api/deleteFile?saveName=" + name, {})
    }, uploadCallback({file, fileList}) {
      this.fileList = fileList;
    },
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await this.$util.getBase64(file.originFileObj);
      }
      this.previewImage = file.url || file.preview;
      this.previewVisible = true;
    },
  }
}
</script>

<style scoped>

</style>
