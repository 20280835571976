// const _import = require('./config/_import_' + process.env.NODE_ENV)

import pageNoPermission from '../pageNoPermission'
import page404 from '../page404'
import login from '../pageLogin'
import manageLogin from '../pageLogin'
export default [
  // 默认页面
  {
    path: '',
    redirect: '/home',
  },
  // 无权限页面
  {
    path: '/noPermission',
    name: '无权限页面',
    component: pageNoPermission
  },
  // 登陆页
  {
    path: '/login',
    name: '登陆页面',
    component: login
  },
  // 登陆页
  {
    path: '/manageLogin',
    name: '后台管理登陆页面',
    component: manageLogin
  },
  // 404
  {
    path: '*',
    name: '404',
    component: page404
  }
]
