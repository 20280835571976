<template>
    <div style="width: 100%;height: 100%">
        <v-chart class="my-chart" :options="structureLineChart"/>
    </div>
</template>

<script>
  import {graphic} from "echarts/lib/export/api";
  import moment from 'moment';
  export default {
    props: {
      array_gnss: {
        type: Array,
      },
      isSmall:{
        type:Boolean,
        default:false
      },
      isToday:{
        type:Boolean,
        default:false
      }
    },
    data() {
      return {
        structureLineChart: {},
        redList: [],
        eastList: [],
        northList: [],
        heightList: []
      }
    },
    mounted() {
      this.eastList = this.array_gnss.map((item) => {
        return item.avgTotalDe;
      })
      this.northList = this.array_gnss.map((item) => {
        return item.avgTotalDn;
      })
      this.heightList = this.array_gnss.map((item) => {
        return item.avgTotalDh;
      })
      this.getChartData();
    },
    methods: {
      getChartData() {
        let self = this;
        let dates = [];
        for (let i = 0; i <this.array_gnss.length; i++) {
          let dd = moment(this.array_gnss[i].monitorTime).format(this.isToday?'HH:00':'MM-DD');
          dates.push(dd);
        }

        this.structureLineChart = {
          tooltip: {
            trigger: 'axis',
            axisPointer: {            // 坐标轴指示器，坐标轴触发有效
              type: 'none'        // 默认为直线，可选为：'line' | 'shadow' | 'none'
            },
            backgroundColor: 'rgba(55,66,97,0.8)',
            borderWidth: 0,
            textStyle: {
              color: '#fff',

            },
            formatter: function (params) {
              let tar0 = params[0];
              let tar1 = params[1];
              let tar2 = params[2];

              let dd = `<div style="padding: 5px 10px;">

                    <div class="flex-row-center" style="margin-top: 2px;">
                        <div class="size-16 color-fff-80" style="margin-left: 0px;">` + tar0.name + `</div>
                    </div>
                    <div class="flex-row-center" style="margin-top: 10px;">
                        <div style="width: 10px; height: 10px; background: ` + tar0.color + `; border-radius: 50%"></div>
                        <div class="size-16 color-fff-80" style="margin-left: 5px;">投影坐标北</div>
                        <div class="size-18 color-fff margin-left-8">` + parseFloat(tar0.value.toFixed(4)) + `</div>
                    </div>
                    <div class="flex-row-center" style="margin-top: 10px;">
                        <div style="width: 10px; height: 10px; background: ` + tar1.color + `; border-radius: 50%"></div>
                        <div class="size-16 color-fff-80" style="margin-left: 5px;">投影坐标东</div>
                        <div class="size-18 color-fff margin-left-8">` + parseFloat(tar1.value.toFixed(4)) + `</div>
                    </div>
                    <div class="flex-row-center" style="margin-top: 10px;">
                        <div style="width: 10px; height: 10px; background: ` + tar2.color + `; border-radius: 50%"></div>
                        <div class="size-16 color-fff-80" style="margin-left: 5px;">投影坐标高</div>
                        <div class="size-18 color-fff margin-left-8">` + parseFloat(tar2.value.toFixed(4)) + `</div>
                    </div>
                </div>`;
              return dd;
            }
          },
          grid: {
            left: '3%',
            right: '3%',
            bottom: '5%',
            top: '3%',
            containLabel: true
          },
          xAxis: [{
            type: "category",
            data: dates,
            axisLine: {
              show: false
            },
            axisLabel: {
              color: "rgba(0,0,0,0.65)",
              fontSize: 12
            },
            axisTick: {
              show: false
            }
          }],

          yAxis: [{
            type: "value",
            axisLabel: {
              color: "rgba(0,0,0,0.65)",
              // fontSize: self.isSmall?12:18
            },
            splitLine: {
              lineStyle: {
                // 使用深浅的间隔色
                color: ['#D8D8D8'],
                type: 'dashed'
              }
            }
          }],
          series: [
            {
              type: 'line',
              areaStyle: { //渐变色
                normal: {
                  color: graphic.LinearGradient(0, 0, 0, 1,
                    [{offset: 0, color: 'rgba(81,238,175, 0.3)'},
                      {offset: 0.5, color: 'rgba(81,238,175, 0.1)'},
                      {offset: 1, color: 'rgba(81,238,175, 0.01)'}])
                }
              },
              itemStyle: {
                color: '#51EEAF'
              },

              data: self.eastList,
              lineStyle: {
                color: '#51EEAF',
                width: 1,
                type: 'dashed'
              },
              symbol: 'image://https://nas.iquanzhan.com/structure/circle_gnss_1.png',
              symbolSize: 15,
            },
            {
              type: 'line',
              areaStyle: { //渐变色
                normal: {
                  color: graphic.LinearGradient(0, 0, 0, 1,
                    [{offset: 0, color: 'rgba(248,195,58, 0.3)'},
                      {offset: 0.5, color: 'rgba(248,195,58, 26, 0.1)'},
                      {offset: 1, color: 'rgba(248,195,58, 0.01)'}])
                }
              },
              itemStyle: {
                color: '#F8C33A'
              },
              data: self.northList,
              lineStyle: {
                color: '#F8C33A',
                width: 1,
                type: 'dashed'
              },
              symbol: 'image://https://nas.iquanzhan.com/structure/circle_gnss_2.png',
              symbolSize: 15,
            },
            {
              type: 'line',
              areaStyle: { //渐变色
                normal: {
                  color: graphic.LinearGradient(0, 0, 0, 1,
                    [{offset: 0, color: 'rgba(96,224,255, 0.3)'},
                      {offset: 0.5, color: 'rgba(96,224,255, 0.1)'},
                      {offset: 1, color: 'rgba(96,224,255, 0.01)'}])
                }
              },
              itemStyle: {
                color: '#60E0FF'
              },
              data: self.heightList,
              lineStyle: {
                color: '#60E0FF',
                width: 1,
                type: 'dashed'
              },
              symbol: 'image://https://nas.iquanzhan.com/structure/circle_gnss_3.png',
              symbolSize: 15,
            }
          ]
        };
      },
    }
  }
</script>

<style scoped>
    .my-chart {
        width: 100%;
        height: 100%;
    }
</style>
