<template>
  <div class="flex-column"
       style="overflow: hidden;height: 100%;width: 100%;position: relative;background-color: #02153A">
    <a-modal
        dialogClass="screen-dialog"
        :bodyStyle="{'height':850+'px'}"
        v-model="showDetail"
        :closable="true"
        :footer="null"
        :width="700"
        :destroyOnClose="true"
        title="设备详情"
    >
      <deviceDetail :pointInfo="selectPoint"/>
    </a-modal>
    <electric_header title="结构物耐久监测系统"/>
    <div style="margin-left: 40px;margin-right: 540px;flex: 1;margin-bottom: 40px;" class="flex-column">
      <div class="flex-row-center" style="position: relative;">
        <electric_home_header title="结构物模型"/>
      </div>
      <div class="flex-1" id="canvas-frame" style="position: relative">
        <div class="pop" style="width: 250px;position: absolute;left: 100px;top: 20px;display: none" ref="modalPop">
          <div class="pop-body flex-column" style="overflow: scroll;height: 160px;position: relative" v-if="popBean">
            <span class="color-fff size-20 bold">{{ popBean.POINT_NAME }}</span>
            <span class="color-fff size-16"
                  style="margin-top: 5px">报警状态：{{ getPointAlarmLevel(popBean.ALARM_LEVEL).name }}</span>
            <span class="color-fff size-16" style="margin-top: 5px">监测点编号：{{ popBean.POINT_NUMBER }}</span>
            <span class="color-fff size-16" style="margin-top: 5px">安装位置：{{ popBean.LOCATION }}</span>
            <a-icon type="close" style="color: white;font-size: 20px;position: absolute;right: 10px;top: 10px"
                    class="cursor_pointer" @click="showAllInfo()"/>
          </div>
        </div>
        <modalBridge @pointClick="pointClick" ref="modalRef" v-if="showThreeModal" :div="modalPop"/>

        <div class="marker_bottom_box flex-column" style="padding: 15px;">
          <div class="size-14 color-fff">图例</div>
          <div class="flex-column">
            <div v-for="(item, index) in markerBottomList" :key="index" class="flex-row-center"
                 style="margin-top: 10px">
              <img :src="item.img" style="height: 25px;width: 25px"/>
              <div class="size-14 color-fff" style="margin-left: 8px">{{ item.name }}</div>
            </div>
          </div>
        </div>


        <div class="marker_bottom_box_device flex-column" style="padding: 15px 30px;" v-if="deviceInfo">
          <div class="flex-row-center">
            <img src="../../image/electric/electric_decoration_1.png" width="10" height="10">
            <div class="size-18 color-fff" style="margin-left: 8px;">
              绑定设备信息
            </div>
          </div>
          <div class="flex-row-center" style="margin-top: 5px">
            <div class="size-14 color-fff-80" style="width: 100px">设备类型</div>
            <div class="size-18 color-fff">{{deviceInfo.DEVICE_TYPE_NAME}}</div>
          </div>
          <div class="flex-row-center" style="margin-top: 5px">
            <div class="size-14 color-fff-80" style="width: 100px">设备编号</div>
            <div class="size-18 color-fff">{{deviceInfo.DEVICE_NUMBER}}</div>
          </div>
          <div class="flex-row-center" style="margin-top: 5px">
            <div class="size-14 color-fff-80" style="width: 100px">在线状态</div>
            <div class="size-18 color-fff">{{0==deviceInfo.STATE?'在线':'离线' }}</div>
          </div>
          <div class="flex-row-center" style="margin-top: 5px">
            <div class="size-14 color-fff-80" style="width: 100px">设备状态</div>
            <div class="size-18 color-fff">{{deviceInfo.STATUS?statusList[deviceInfo.STATUS]:'正常'}}</div>
          </div>
          <div class="flex-row-center" style="margin-top: 5px">
            <div class="size-14 color-fff-80" style="width: 100px">设备电压</div>
            <div class="size-18 color-fff">{{deviceInfo.BAT_VOL?deviceInfo.BAT_VOL:'--' }}</div>
          </div>
        </div>
      </div>


    </div>
    <div style="width: 460px; overflow: hidden;position: absolute;right: 30px;top: 110px;bottom: 40px"
         class="flex-column" v-if="!popBean">
      <div class="flex-row-center">
        <electric_home_header title="监测点统计"/>
      </div>
      <div style="padding-left: 12px;flex: 1;overflow:hidden;">
        <div style="margin-top: 10px;" class="p-relative">
          <img src="../../image/electric/monitor_point_header_bg.png" style="height: 30px; width: 100%;">
          <div class="absolute-0 flex-row-center" style="padding-left: 15px;">
            <div class="flex-1 size-14 color-fff-80">监测点名称</div>
            <div class="flex-1 size-14 color-fff-80">编号</div>
            <div class="flex-1 size-14 color-fff-80">报警状态</div>
            <div class="flex-1 size-14 color-fff-80">绑定设备状态</div>
          </div>
        </div>
        <div style="margin-top: 6px; overflow:auto;height: 100%" class="flex-column">
          <div v-for="(item, index) in monitorPointList" :key="index" class="cursor_pointer"
               @click="toPageDevice(item)">
            <div class="flex-row-center box-bg" style="padding-left: 15px; margin-bottom: 6px; height: 34px;">
              <div class="flex-1 size-16 color-fff">{{ item.POINT_NAME }}</div>
              <div class="flex-1 size-16 color-fff">{{ item.POINT_NUMBER }}</div>
              <div class="flex-1 size-16" :style="{'color':getPointAlarmLevel(item.ALARM_LEVEL).color}">
                {{ getPointAlarmLevel(item.ALARM_LEVEL).name }}
              </div>
              <div class="flex-1 size-16 color-fff">{{ 0 === item.STATE ? '在线' : '离线' }}</div>
            </div>
          </div>
        </div>
      </div>

      <div style="margin-top: 25px; ">
        <electric_home_header title="监测点报警排名"/>
        <div style="padding-left: 12px;">
          <div style="margin-top: 6px;">
            <div v-for="(item, index) in monitorPointRankList" :key="index" class="flex-row" style="height: 66px; margin-bottom: 6px;" >
              <div style="width: 44px; background: rgba(149, 174, 195, 0.6);" class="flex-row center size-20 color-fff">
                {{ index + 1 }}
              </div>
              <div class="flex-1 box-bg flex-row-center" style="padding-left: 15px;">
                <div class="flex-column flex-1">
                  <div class="size-18 color-fff">{{ item.POINT_NAME }}</div>
                  <div class="size-14 color-fff-60" style="margin-top: 5px;">超限三级{{item.RED_CONTS}}次，超限二级{{item.ORANGE_CONTS}}次，超限一级{{item.YELLOW_CONTS}}次</div>
                </div>
                <div class="size-20 color-FF7373" style="padding-right: 15px;">
                  {{item.ALARM_COUNTS}}次
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div style="margin-top: 25px;">
        <electric_home_header title="监测设备统计"/>
        <div style="padding-left: 12px; margin-top: 10px;" class="box-bg">
          <div class="flex-row">
            <div style="height: 200px; width: 200px; position: relative; margin-top: 0px; ">
              <div style="position: absolute;left: 0;top: 0;right:0;bottom:0;align-items: center"
                   class="u-flex-col-center  color-fff">
                <span class="size-30 color-DEE8FE">{{ allDeviceNum }}</span>
                <span class="size-14 color-DEE8FE">设备总数</span>
              </div>
              <chart_pie :dataValue="pieData" v-if="pieData"/>
            </div>

            <div style="margin-left: -20px; margin-right: 40px;" class="flex-1 flex-column center">
              <div v-for="(item, index) in pieData" :key="index" class="flex-row-center alarm-type"
                   style="margin-bottom: 15px;">
                <div style="width: 6px; height: 6px; border-radius: 3px; margin-left: 10px;"
                     :style="'backgroundColor:' + item.color">
                </div>
                <div class="flex-row-center" style="width: 200px;">
                  <div style="margin-left: 15px;" class="size-14 color-fff">{{ item.name }}</div>
                  <div class="size-18 color-fff ff" style="margin-left: 15px; margin-top: 4px;">{{ item.value }}</div>
                  <div class="size-12 color-fff-80" style="margin-left: 10px;">个</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--右边监测项 和上面的只能显示一个-->
    <div style="width: 460px; overflow: hidden;position: absolute;right: 30px;top: 110px;bottom: 40px"
         class="flex-column" v-else>
      <div>
        <div class="flex-row-center" style="position: relative;">
          <electric_home_header title="监测数据"/>
        </div>

        <div style="padding-left: 12px; margin-top: 10px;">
          <div class="flex-row wrap">
            <div v-for="(item, index) in monitorDataList" :key="index" class="p-relative"
                 :style="'margin-right:' + (index%2 == 0 ? '8px' : '0px') + ';' + 'margin-bottom:' + (index < 2 ? '8px' : '0px') + ';'">
              <img src="../../image/electric/monitor_item_cell_bg.png" width="220" height="97">
              <div class="absolute-0 flex-row-center" style="padding: 0 15px;">
                <img :src="item.img" style="width: 34px; height: 42px; object-fit: contain">
                <div style="margin-left: 15px;">
                  <div class="size-16 color-73ABC2">{{ item.name }}</div>
                  <div class="flex-row-center">
                    <div class="size-28 color-fff ff">{{ item.num }}</div>
                    <div class="size-16 color-fff" style="margin-left: 3px;">{{ item.unit }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div style="margin-top: 20px;">
        <electric_home_header title="耐久数据统计"/>
        <div style="padding-left: 12px; margin-top: 10px;">
          <div class="flex-row-center" style="margin: 10px 0;">
            <div v-for="(item, index) in statisticalTitleList" :key="index"
                 class="flex-1 size-16 color-fff flex-row center"
                 style="height: 34px; border: 1px solid transparent;"
                 :style="'marginRight:' + (index == 1 ? '0px':'10px') + ';'
                 + 'backgroundColor:' + (selectIndex == index ? 'rgba(59, 106, 255, 0.5)':'rgba(255,255,255, 0.1)') + ';'
                 + 'borderColor:' + (selectIndex == index ? 'rgba(173 , 244, 250, 31)':'rgba(255,255,255, 0.1)')"
                 @click="clickStatisticalIndex(index)"
            >
              {{ item }}
            </div>

          </div>

          <div style="margin-top: 10px; padding: 10px 15px;" class="box-bg">
            <div class="flex-row-center">
              <img src="../../image/electric/electric_decoration_1.png" width="10" height="10">
              <div class="size-18 color-fff" style="margin-left: 8px;">
                今日实时数据
              </div>
              <div class="flex-1"></div>
              <div class="size-14 color-A0CAFF">
                单位/{{selectIndex===0?'mv':'mA'}}
              </div>
            </div>
            <div style="height: 200px;">
              <chart_column :mData="chartColumnData" :key="time"/>
            </div>
          </div>

          <div style="margin-top: 10px; padding: 10px 15px;" class="box-bg">
            <div class="flex-row-center">
              <img src="../../image/electric/electric_decoration_1.png" width="10" height="10">
              <div class="size-18 color-fff" style="margin-left: 8px;">
                历史监测数据
              </div>
              <div class="flex-1"></div>
              <div class="size-14 color-A0CAFF">
                单位/{{selectIndex===0?'mv':'mA'}}
              </div>
            </div>
            <div style="height: 200px;">
              <chart_line :mData="chartLineData" :key="time"/>
            </div>
          </div>

          <div style="margin-top: 10px; background: rgba(255, 195, 68, 0.3); height: 36px;" class="flex-row center"
               @click="toAnalysis()">
            <img src="../../image/electric/durable_icon.png" width="14" height="17">
            <div class="size-18 color-fff ff-m" style="margin-left: 10px;">耐久数据分析</div>
          </div>
        </div>

      </div>

    </div>

  </div>
</template>

<script>
import electric_home_header from "./electric_home_header";
import chart_pie from "../../components/chart_pie";
import modalBridge from '../../components/modalBridge';
import deviceDetail from '../../components/deviceDetail';
import chart_column from '../../components/chart_column';
import chart_line from '../../components/chart_line';
import moment from "moment";

export default {
  name: "electric_home",
  components: {
    electric_home_header: electric_home_header,
    chart_pie, modalBridge, deviceDetail, chart_column, chart_line
  },
  data() {
    return {
      statusList:['正常','故障','损坏','维修','更换'],
      deviceInfo:null,
      popBean: null,
      modalPop: null,
      markerBottomList: [
        {name: '报警点位', img: require('../../image/screen/point_abnormal.png')},
        {name: '正常点位', img: require('../../image/screen/point_normal.png')}],
      showDetail: false,
      selectPoint: null,
      monitorPointList: [],
      monitorPointRankList: [],
      showThreeModal: false,
      structureId: null,
      pieData: null,
      allDeviceNum: 0,
      monitorDataList: [
        {
          img: require('../../image/electric/monitor_item_icon_1.png'),
          name: '环境温度',
          unit: '℃',
          num: '24'
        },
        {
          img: require('../../image/electric/monitor_item_icon_2.png'),
          name: '环境湿度',
          unit: '%rh',
          num: '78'
        },
        {
          img: require('../../image/electric/monitor_item_icon_3.png'),
          name: '混合电位电压',
          unit: 'mV',
          num: '-686'
        },
        {
          img: require('../../image/electric/monitor_item_icon_4.png'),
          name: '阴极腐蚀挂片电流',
          unit: 'mA',
          num: '0.00003'
        },
      ],
      statisticalTitleList: ['开路电位电压', '阳极保护电流'],
      selectIndex: 0,
      chartColumnData: [],
      chartLineData: [],
      time: new Date().getTime()
    }
  },
  computed: {
    getPointAlarmLevel() {
      return function (level) {
        let color = '', title = '';
        if (level) {
          switch (level) {
            case 0:
              color = '#62DBED';
              title = '正常';
              break;
            case 1:
              color = 'yellow';
              title = '超限一级';
              break;
            case 2:
              color = 'orange';
              title = '超限二级';
              break;
            case 3:
              color = 'red';
              title = '超限三级';
              break;
          }
        } else {
          color = '#62DBED';
          title = '正常';
        }
        return {
          name: title, color: color
        }
      }

    },
  },

  mounted() {
    let structureItem = JSON.parse(localStorage.getItem('structureItem'));
    this.structureId = structureItem.STRUCTURE_ID;
    if (this.structureId){
      this.getData();
      this.getMonitorPointAlarmRankList();
    }

    this.modalPop = this.$refs.modalPop;
  },

  methods: {
    getMonitorPointAlarmRankList() {
      this.$util.get('api/monitorPointAlarmRankList', {
        structureId: this.structureId,
      }, true).then((res) => {
        if (res.content)
          this.monitorPointRankList = res.content.slice(0, res.content.length > 3 ? 3 : res.content.length);
        else this.monitorPointRankList = [];
        console.log(11111111,this.monitorPointRankList)
      })
    },
    getNewRecord() {
      this.$util.get('api/durableDataList', {
        deviceId: this.popBean.DEVICE_ID,
        monitorPointId:this.popBean.MONITOR_POINT_ID,
        page:1,
        pageSize:1
      }, true).then((res) => {
        if (res.content)
          this.monitorPointRankList = res.content.slice(0, res.content.length > 3 ? 3 : res.content.length);
        else this.monitorPointRankList = [];
        console.log(11111111,this.monitorPointRankList)
      })
    },
    getTimeDurableStatisticsList() {
      let timeList = [];
      for (let i = -6; i <= 0; i++) {
        timeList.push({
          xValue: moment().add(i, 'hours').format("HH:00"),
          _id: moment().add(i, 'hours').format("YYYY-MM-DD HH"),
          yValue: 0,
        });
      }
      this.$util.get('api/timeDurableStatisticsList', {
        deviceId: this.popBean.DEVICE_ID,
        startTime: moment().add(-6, 'hours').format("YYYY-MM-DD HH:mm:ss"),
        endTime: moment().format("YYYY-MM-DD HH:mm:ss"),
        type: 3,
        fields: 'openv,sac,cc',
      }, true).then((res) => {
        let list = timeList;
        let resList = res.content;
        for (let i = 0; i < list.length; i++) {
          let item = list[i];
          for (let j = 0; j < resList.length; j++) {
            let item2 = resList[j];
            if (item._id == item2._id) {
              item.yValue = this.selectIndex === 0 ? resList[j].avg_openv : resList[j].avg_sac;//0是电压，1是电流
              break;
            }
          }
        }
        this.chartColumnData = timeList;
        this.time = new Date().getTime();
      })
    },
    getTimeDurableStatisticsListHistory() {
      let timeList = [];
      for (let i = -6; i <= 0; i++) {
        let item = {
          xValue: moment().add(i, 'days').format("MM-DD"),
          _id: moment().add(i, 'days').format("YYYY-MM-DD"),
          yValue: 0,
        }
        timeList.push(item);
      }
      this.$util.get('api/timeDurableStatisticsList', {
        deviceId: this.popBean.DEVICE_ID,
        startTime: moment().add(-6, 'days').format("YYYY-MM-DD HH:mm:ss"),
        endTime: moment().format("YYYY-MM-DD HH:mm:ss"),
        fields: 'openv,sac,cc',
        type: 1
      }, true).then((res) => {
        let list = timeList;
        let resList = res.content;
        for (let i = 0; i < list.length; i++) {
          let item = list[i];
          for (let j = 0; j < resList.length; j++) {
            let item2 = resList[j];
            if (item._id == item2._id) {
              item.yValue = this.selectIndex === 0 ? resList[j].avg_openv : resList[j].avg_sac;
              break;
            }
          }
        }
        this.chartLineData = timeList;
        this.time = new Date().getTime();
        console.log(3333333, timeList)
      })
    },
    toAnalysis() {
      this.$router.push({path: '/electricDataAnalysis',query:{deviceId: this.popBean.DEVICE_ID}});
    },
    getData() {
      let self = this;
      this.$util.get('api/monitorItemList', {
        page: 1,
        pageSize: 10000,
        structureId: this.structureId,
      }, true).then((ret) => {
        self.monitorItemList = ret.content;
        self.showThreeModal = true;
        self.getMonitorPointList();
      });
    },
    getMonitorPointList() {
      let self = this;
      this.$util.get('api/deviceList', {
        structureId: this.structureId,
        page: 1,
        pageSize: 10000,
      }).then(res => {
        if (res && res.content && res.content.list) {
          let offLineNum = 0, onLineNum = 0;
          for (let i = 0; i < res.content.list.length; i++) {
            let item = res.content.list[i];
            if (item.STATE === 0) {
              onLineNum++;
            } else offLineNum++;
          }

          let pieData = [
            {value: onLineNum, name: '在线设备', color: 'rgba(248, 195, 58, 1)'},
            {value: offLineNum, name: '离线设备', color: 'rgba(119, 148, 190, 1)'},
          ];
          self.allDeviceNum = onLineNum + offLineNum;
          self.pieData = pieData;

        }
      });

      self.$util.get('api/monitorPointList', {
        page: 1,
        pageSize: 10000,
        structureId: this.structureId,
      }, true).then((res) => {
        let bean = res.content;
        // switch (52) {
        //   case 57://梁桥高墩墩顶位移
        //     for (let i = 0; i < bean.list.length; i++) {//bean.list.length
        //       let item = bean.list[i];
        //       item['threePosition'] = [2.9, 1, -20 * i + 0.5];
        //     }
        //     break;
        //   case 52://支座位移
        //     for (let i = 0; i < bean.list.length; i++) {
        //       let item = bean.list[i];
        //       item['threePosition'] = [5, 6.3, i % 2 == 0 ? (Math.random() * 5 * i) : -(Math.random() * 10 * i)];
        //     }
        //     break;
        //   case 51://主梁横向变形
        //     for (let i = 0; i < bean.list.length; i++) {
        //       let item = bean.list[i];
        //       item['threePosition'] = [2, 6, i % 2 == 0 ? (Math.random() * 5 * i) : -(Math.random() * 8 * i)];
        //     }
        //     break;
        // }


        for (let i = 0; i < bean.list.length; i++) {
          let item = bean.list[i];
          let index = Math.floor(i/6);
          switch (i%6) {
            case 0:
              item['threePosition'] = [0, 4.5, 19.42+index*-20];
              item['angleX'] = 0;
              item['angleY'] = 1.5;
              item['angleZ'] = -1.55;
              break;
            case 1:
              item['threePosition'] = [0, 4.5, 1.45+index*-20];
              item['angleX'] = 0;
              item['angleY'] = 1.5;
              item['angleZ'] = 1.55 ;
              break;
            case 2:
              item['threePosition'] = [2, 4.8, 19.42+index*-20];
              item['angleX'] = 0;
              item['angleY'] = 1.5;
              item['angleZ'] = -1.55 ;
              break;
            case 3:
              item['threePosition'] = [-2, 4.8, 19.42+index*-20];
              item['angleX'] = 0;
              item['angleY'] = 1.5;
              item['angleZ'] = -1.55 ;
              break;
            case 4:
              item['threePosition'] = [1, 5, 11.5+index*-20];
              item['angleX'] = 0;
              item['angleY'] = 0;
              item['angleZ'] = 3.12 ;
              break;
            case 5:
              item['threePosition'] = [-1, 5, 11.5+index*-20];
              item['angleX'] = 0;
              item['angleY'] = 0;
              item['angleZ'] = 3.12 ;
              break;
          }
        }

        self.monitorPointList = bean.list.slice(0, bean.list.length);
        self.$refs.modalRef.loadPoint(self.monitorPointList,-1.5);
      });
    },
    back() {
      this.$router.back();
    },
    pointClick(p) {
      if (p) {
        let index = p.index;
        this.popBean = this.monitorPointList[index];
        this.toPageDevice(this.popBean)
      }
    },
    toPageDevice(value) {
      console.log(1111111111,value)
      this.$util.get('api/deviceInfo/'+value.DEVICE_ID,{}).then((res)=>{this.deviceInfo = res.content});

      this.$refs.modalRef.resetPop(value);
      this.popBean = value;
      this.getTimeDurableStatisticsList();
      this.getTimeDurableStatisticsListHistory();
      this.getNewRecord();
      // this.showDetail = true;
      // this.selectPoint = value;
    },
    showAllInfo() {
      this.$refs.modalRef.resetPop(null);
      this.popBean = null;
      this.deviceInfo = null;
    },
    clickStatisticalIndex(index) {
      this.selectIndex = index;
      this.getTimeDurableStatisticsList();
      this.getTimeDurableStatisticsListHistory();
    }
  }
}
</script>

<style scoped>
.content {
  width: 100%;
  height: 100%;
  position: relative
}

.bg {
  background: url(../../image/electric/electric_bg.png) no-repeat;
  background-size: 100% 100%;
}

.alarm-type {
  height: 40px;
  background: linear-gradient(90deg, rgba(233, 248, 252, 0.2) 0%, rgba(207, 238, 248, 0) 100%);
}

>>> .screen-dialog .ant-modal-header {
  background-color: rgba(41, 65, 105, 0.9);
  border-bottom-color: #60ECFF;
}

>>> .screen-dialog .ant-modal-close-icon {
  color: white;
}

>>> .screen-dialog .ant-modal-title {
  color: white;
}

>>> .screen-dialog .ant-modal-content {
  background-color: rgba(41, 65, 105, 0.9);
  background-clip: padding-box;
  border: 0;
  border-radius: 4px;
  pointer-events: auto;
}

.marker_bottom_box {
  background: rgba(3, 39, 85, 0.56);
  backdrop-filter: blur(10px);
  position: absolute;
  bottom: 30px;
  left: 30px;
  border: 1px solid rgba(255, 255, 255, 0.3);
}
.marker_bottom_box_device {
  background: rgba(3, 39, 85, 0.56);
  backdrop-filter: blur(10px);
  position: absolute;
  bottom: 30px;
  right: 30px;
  border: 1px solid rgba(255, 255, 255, 0.3);
}
</style>
