<template>
  <div style="position: relative;height: 100%;width: 100%;">
    <div class="flex-column" style="margin: 20px">
      <div class="flex-row-center"><img src="../image/screen/icon_right_dot1.png" style="width: 20px"/><span
          class="size-22 color-fff" style="margin-left: 10px">监测报警</span></div>

      <div style="border: 1px solid rgba(255,255,255,0.3);margin-top: 30px" class="flex-row-center">
        <div v-for="(item, index) in statisticsList" :key="index" class="flex-col flex-1 color-fff" style="border-right: 1px solid rgba(255,255,255,0.3)">
          <div style="height: 45px;background-color:#3E6F9E " class="u-flex-col-center">{{item.name}}</div>
          <div style="height: 50px;" class="u-flex-col-center">{{item.value}}</div>
        </div>
      </div>

      <div class="flex-1" style="margin-top: 25px">
        <a-table  :columns="columns" :data-source="list" rowKey="MONITOR_ALARM_ID" :bordered="false" :pagination="paginationOpt" style="width: 100%;" :scroll="{ y: 540 }" className="table-color-dust">
          <template slot="level" slot-scope="text">
            <div class="flex-row-center">
              <div style="height: 8px;width: 8px;border-radius: 4px"
                   :style="{'background-color':getPointAlarmLevel(text).color}"/>
              <span :style="{'color':getPointAlarmLevel(text).color}"
                    style="margin-left: 5px">{{ getPointAlarmLevel(text).name }}</span>
            </div>
          </template>
        </a-table>
      </div>
    </div>
  </div>
</template>

<script>
const columns = [
  {
    title: '',
    dataIndex: '',
    width: 40
  },
  {
    title: '告警时间',
    dataIndex: 'ALARM_TIMES',
  }, {
    title: '告警等级',
    dataIndex: 'ALARM_LEVEL', scopedSlots: {customRender: 'level'}
  }, {
    title: '结构物名称',
    dataIndex: 'STRUCTURE_NAME'
  }, {
    title: '设备名称',
    dataIndex: 'DEVICE_NAME'
  },
  {
    title: '告警部位',
    dataIndex: 'LOCATION',
  },
  {
    title: '告警描述',
    dataIndex: 'DESCRIPTION',
    width: 550
  }
];
export default {
  computed: {
    getPointAlarmLevel() {
      return function (level) {
        let color = '', title = '';
        if (level) {
          switch (level) {
            case 0:
              color = '#62DBED';
              title = '正常';
              break;
            case 1:
              color = 'yellow';
              title = '超限一级';
              break;
            case 2:
              color = 'orange';
              title = '超限二级';
              break;
            case 3:
              color = 'red';
              title = '超限三级';
              break;
          }
        } else {
          color = '#62DBED';
          title = '正常';
        }
        return {
          name: title, color: color
        }
      }

    },
  },
  data() {
    let self = this;
    return {
      paginationOpt: this.$util.getTableParams((current, pageSize) => {
        self.paginationOpt.current = 1;
        self.paginationOpt.defaultPageSize = pageSize;
        console.log(11, self.paginationOpt)
        self.getList()  //显示列表的接口名称
      }, (current, size) => {
        console.log(current, size)
        self.paginationOpt.current = current
        self.paginationOpt.defaultPageSize = size
        self.getList()
      }, 12),
      list: null,
      columns: columns,
      statisticsList:[{name:'今日报警次数',value:'0'},
        {name:'本月报警次数',value:'0'},
        {name:'报警总数',value:'0'},
        {name:'超限一级次数',value:'0'},
        {name:'超限二级次数',value:'0'},
        {name:'超限三级次数',value:'0'}]
    }
  },
  mounted() {
    this.getList();
    let structureItem = JSON.parse(localStorage.getItem('structureItem'));
    let structureId = structureItem.STRUCTURE_ID;
    this.$util.get('api/monitorAlarmCountStatistics', {structureId:structureId}).then((res) => {
      let list = [{name:'今日报警次数',value:res.content.ALARM_COUNTS},
        {name:'本月报警次数',value:res.content.MONTH_COUNTS},
        {name:'报警总数',value:res.content.TODAY_COUNTS}];
      this.$util.get('api/monitorAlarmLevelStatistics', {}).then((ret) => {
        list.push({name:'超限一级次数',value:ret.content.YELLOW_ALARM_COUNTS});
        list.push({name:'超限二级次数',value:ret.content.ORANGE_ALARM_COUNTS});
        list.push({name:'超限三级次数',value:ret.content.RED_ALARM_COUNTS});
        this.statisticsList = list;
      })
    })


  },
  methods:{
    getList1(){
      let structureItem = JSON.parse(localStorage.getItem('structureItem'));
      let name = structureItem.STRUCTURE_NAME;
      let list = [];
      for (let i = 1; i < 4; i++) {
        console.log(Math.floor(Math.random()*3))
        list.push({
          ALARM_TIMES: '2021-06-08 12:50:36',
          ALARM_LEVEL:1+ Math.floor(Math.random()*3),
          STRUCTURE_NAME: name,
          DEVICE_NAME:'12t13R',
          LOCATION: (i%5)+'右'+(i%20)
        })
      }
      this.list = list;
    },
    getList() {
      let structureItem = JSON.parse(localStorage.getItem('structureItem'));
      let structureId = structureItem.STRUCTURE_ID;
      let self = this;
      let params = this.paginationOpt;
      let p = {
        page: params.current,
        pageSize: params.defaultPageSize,
        structureId: structureId,
      }
      this.$util.showLoading(self);
      this.$util.get('api/monitorAlarmList', p, true).then((res) => {
        let bean = res.content;
        self.paginationOpt.total = bean.count;
        self.list = bean.list;
        this.$util.hideLoading();
      })
    },
  }
}
</script>

<style scoped>
>>> .ant-table table {
  background: rgba(41, 65, 105, 1) !important;
  color: white !important;
  border-radius: 0px !important;
}

>>> .ant-table-tbody > tr > td {
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}

>>> .ant-table-thead > tr > th {
  color: white;
  height: 50px;
  background: rgba(255, 255, 255, 0.2);
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}

>>> ::-webkit-scrollbar {
  display: none;
}

>>> .ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td {
  background: rgba(72, 110, 173, 1);
}

>>> .ant-pagination.mini .ant-pagination-total-text, .ant-pagination.mini .ant-pagination-simple-pager {
  color: white;
}
>>> .ant-pagination-item a{
  color: white;
}
>>>.ant-pagination-item-active a{
  color: black;
}
>>>.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-ellipsis{
  color: white;
}
>>>.ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-ellipsis{
  color: white;
}
>>> .anticon svg{
  color: white;
}
</style>
