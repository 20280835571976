<template>
  <div style="width: 100%;height: 100%;" class="flex-column">
    <span class="size-16 color-333">标识地图</span>
    <div class="flex-1 flex-column" style="margin: 10px 0">
      <div>请先输入大概地址，然后拖动指针在地图中标记具体位置。</div>
      <a-select show-search
                :default-active-first-option="false"
                :show-arrow="false"
                :filter-option="false"
                :not-found-content="null" style="width: 100%;margin-top: 15px" placeholder="请输入位置"
                @search="search"
                @change="handleChange">
        <a-select-option v-for="item in locations" :key="item.name" :value="item.name">
          {{item.name}}
        </a-select-option>
      </a-select>
      <div id="container" style="width:100%;margin-top: 15px" class="flex-1"></div>
      <a-input placeholder="自动带出详细地址" :readOnly="true"   style="margin-top: 15px" :value="locationSelect?locationSelect.address:''"/>
      <div class="flex-row-center" style="margin-top: 15px">
        <a-input placeholder="自动带出经度"  :readOnly="true" class="flex-1" :value="locationSelect?locationSelect.lng:''"/>
        <a-input placeholder="自动带出纬度" :readOnly="true" style="margin-left: 20px;"  class="flex-1" :value="locationSelect?locationSelect.lat:''"/>
      </div>
    </div>
    <div class="flex-row-center" style="height: 50px;justify-content: flex-end;margin-top: 20px">
      <a-button type="link" @click="mClose">
        关闭
      </a-button>
      <a-button type="primary"  @click="commit">
        保存
      </a-button>
    </div>

  </div>
</template>

<script>
import AMap from "AMap";
import Vue from "vue";
let map = null;
export default {
  name: "select_address",
  data() {
    return {
      locations: [],
      locationSelect:null,
      fetching: false,
    };
  },
  mounted() {
    console.log(111222)
    this.initMap(() => {
    });
  },
  methods: {
    search(value){
      let autoComplete= new AMap.Autocomplete({
        city: '全国'
      });
      let self = this;
      this.fetching = true;
      autoComplete.search(value, function(status, result) {
        console.log(result);
        if(result&&result.tips&&result.tips.length>0){
          self.locations = result.tips;
        }
        self.fetching = false;
      })
    },
    initMap(callback) {
      map = new AMap.Map('container', {
        resizeEnable: true,
        zoom: 12,
      });
      AMap.plugin(['AMap.ToolBar', 'AMap.OverView', 'AMap.Autocomplete', 'AMap.Geocoder'], function () {//异步加载插件
        callback();
      });

    },
    handleChange(value) {
      if(this.marker)
      map.remove(this.marker)
      let self = this;
      for (let i = 0; i < this.locations.length; i++) {
        if(this.locations[i].name==value){
          let l = this.locations[i];
          this.locationSelect = {
            address:l.district+l.address,
            lat:l.location.lat,
            lng:l.location.lng
          };

          this.marker = new AMap.Marker({
            position: [l.location.lng,l.location.lat],
            icon: require('../image/icon_location.png'),
            offset: new AMap.Pixel(-14, -16),
            // 设置是否可以拖拽
            draggable: true,
            cursor: 'move',
            // 设置拖拽效果
            raiseOnDrag: true
          });
          this.marker.on('dragend', (e)=>{
            let lnglat = e.lnglat;
            let geocoder = new AMap.Geocoder({
              city: '全国'
            });
            geocoder.getAddress([lnglat.lng,lnglat.lat], (status, result)=> {
              if (result.info === 'OK') {
                self.locationSelect = {
                  address:result.regeocode.formattedAddress,
                  lat:lnglat.lat,
                  lng:lnglat.lng
                }
              }
            })

          });
          this.marker.setMap(map);
          map.setCenter(new AMap.LngLat(l.location.lng, l.location.lat));
          break;
        }
      }
    },mClose() {
      this.$emit('modalClose')
    },
    commit(){
      if(!this.locationSelect){
        this.$message.error('请选择具体位置');
        return;
      }
      this.$emit('modalClose', {locationSelect: this.locationSelect})
    }
  },
}
</script>

<style scoped>

</style>
