<template>
  <div>
    <a-page-header
        style="padding: 0;margin: 15px 0"
        title="监测点管理"
        @back="back()"
    />
    <a-modal
        :bodyStyle="{'height':800+'px'}"
        v-model="showModal"
        :closable="false"
        :footer="null"
        :width="900"
        :destroyOnClose="true"
    >
      <selectAddress @modalClose="modalClose"/>
    </a-modal>
    <div class="flex-1 flex-column" style="margin: 10px 0;background-color: white">
      <span class="size-18 color-333" style="margin: 20px">{{ id ? '编辑' : '新增' }}监测点</span>
      <div class="flex-column" style="width: 100%">
        <a-form :form="form" @submit="handleSubmit" style="width: 100%" labelAlign="left" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }">
          <a-row type="flex" justify="space-around">
            <a-col :span="span">
              <a-form-item label="监测点名称">
                <a-input placeholder="请输入监测点名称"
                         v-decorator="['pointName', { rules: [{ required: true, message: '请输入监测点名称!' }] }]"
                         :allowClear="true"/>
              </a-form-item>
            </a-col>
            <a-col :span="7">
              <a-form-item label="监测点编号">
                <a-input placeholder="请输入监测点编号" v-decorator="['pointNumber', { rules: [{ required: true, message: '请输入监测点编号!' }] }]"
                         :allowClear="true"/>
              </a-form-item>
            </a-col>
            <a-col :span="span">
              <a-form-item label="安装部位">
                <a-input placeholder="请输入安装部位" v-decorator="['location', { rules: [{ required: false }] }]" :allowClear="true"/>
              </a-form-item>
            </a-col>
          </a-row>

          <a-row type="flex" justify="space-around">
            <a-col :span="span">
              <a-form-item label="监测项">
                <a-select placeholder="请选择监测项" option-filter-prop="children" show-search v-decorator="['monitorItemId', { rules: [{ required: true, message: '请选择监测项!' }] }]">
                  <template v-for="item in monitorItemList">
                    <a-select-option :key="item.MONITOR_ITEM_ID" :value="item.MONITOR_ITEM_ID">
                      {{ item.ITEM_NAME }}
                    </a-select-option>
                  </template>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="span">
              <a-form-item label="监测结构物">
                <a-select placeholder="请选择监测结构物" option-filter-prop="children" show-search
                          v-decorator="['structureId', { rules: [{ required: true, message: '请选择监测结构物!' }] }]" @change="structureChange">
                  <template v-for="item in structureList">
                    <a-select-option :key="item.STRUCTURE_ID" :value="item.STRUCTURE_ID">
                      {{ item.STRUCTURE_NAME }}
                    </a-select-option>
                  </template>
                </a-select>
              </a-form-item>
            </a-col>

            <a-col :span="span" v-if="showType" >
              <a-form-item label="类型">
                <a-select placeholder="请选择类型" v-decorator="['type', { rules: [{ required: showType, message: '请选择类型!' }] }]">
                  <template v-for="item in typeList">
                    <a-select-option :key="item.DATA_ID" :value="item.DATA_ID">
                      {{ item.CLASS_VALUE }}
                    </a-select-option>
                  </template>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="span" v-else >
            </a-col>
          </a-row>

          <a-row type="flex" justify="space-around">
<!--            <a-col :span="span">
              <a-form-item label="设备类型">
                <a-select placeholder="请选择设备类型"
                          v-decorator="['deviceType', { rules: [{ required: true, message: '请选择设备类型!' }] }]" @change="deviceTypeChange">
                  <template v-for="item in deviceTypeList">
                    <a-select-option :key="item.DATA_ID" :value="item.DATA_ID">
                      {{ item.CLASS_VALUE }}
                    </a-select-option>
                  </template>
                </a-select>
              </a-form-item>
            </a-col>-->
            <a-col :span="span">
              <a-form-item label="绑定设备">
                <a-select placeholder="请选择绑定设备" option-filter-prop="children" show-search
                          v-decorator="['deviceId', { rules: [{ required: true, message: '请选择绑定设备!' }] }]">
                  <template v-for="item in deviceList">
                    <a-select-option :key="item.DEVICE_ID" :value="item.DEVICE_ID">
                      {{ item.DEVICE_NAME }}
                    </a-select-option>
                  </template>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="span">
              <a-form-item label="通道编码">
                <a-input placeholder="请输入通道编码" v-decorator="['channelNumber', { rules: [{ required: false }] }]"
                         :allowClear="true"/>
              </a-form-item>
            </a-col>

            <a-col :span="span" v-if="isPower">
              <a-form-item label="钢筋截面积">
                <a-input placeholder="请输入钢筋截面积"
                         v-decorator="['area', { rules: [{ required: isPower, message: '请输入钢筋截面积!'}]}]"
                         :allowClear="true"/>
              </a-form-item>
            </a-col>
            <a-col :span="span" v-else/>
          </a-row>

          <a-row type="flex" justify="space-around" style="margin-bottom: 20px ">
            <a-col :span="11">
              <div class="flex-row-center">
                <span>标识地图：</span>
                <a-button type="primary" icon="environment" style="margin-left: 20px" @click="showModal=true">
                  标识地图
                </a-button>
                <a-input placeholder="经度" :readOnly="false" style="margin-left: 20px" class="flex-1"
                         :value="locationSelect?locationSelect.lng:''"/>
                <a-input placeholder="纬度" :readOnly="false" style="margin-left: 20px" class="flex-1"
                         :value="locationSelect?locationSelect.lat:''"/>
              </div>
            </a-col>
            <a-col :span="11">
              <a-input placeholder="地址" :readOnly="false" :value="locationSelect?locationSelect.address:''"/>
            </a-col>
          </a-row>

          <a-row type="flex" justify="space-around">
            <a-col :span="23">
              <a-form-item label="监测点描述" :wrapper-col="{ span: 24 }">
                <a-textarea placeholder="请输入监测点描述" v-decorator="['description', { rules: [{ required: false}]}]"
                            :allowClear="true" :auto-size="{ minRows: 2}"/>
              </a-form-item>
            </a-col>
          </a-row>

          <div v-if="!isPower">
            <a-row type="flex" justify="space-around">
              <a-col :span="23">
                <span class="size-18 color-000-85 bold" style="margin: 15px 0">报警初始值</span>
              </a-col>
            </a-row>
            <a-row type="flex" justify="space-around">
              <a-col :span="span">
                <a-form-item label="初始值1">
                  <a-input placeholder="请输入初始值1" v-decorator="['originValue1', { rules: [{ required: false }] }]"
                           :allowClear="true"/>
                </a-form-item>
              </a-col>
              <a-col :span="span">
                <a-form-item label="初始值2">
                  <a-input placeholder="请输入初始值2" v-decorator="['originValue2', { rules: [{ required: false }] }]"
                           :allowClear="true"/>
                </a-form-item>
              </a-col>
              <a-col :span="span">
                <a-form-item label="初始值3">
                  <a-input placeholder="请输入初始值3" v-decorator="['originValue3', { rules: [{ required: false }] }]"
                           :allowClear="true"/>
                </a-form-item>
              </a-col>
            </a-row>

            <a-row type="flex" justify="space-around">
              <a-col :span="23">
                <span class="size-18 color-000-85 bold" style="margin: 15px 0">实时数据报警</span>
              </a-col>
            </a-row>
            <a-row type="flex" justify="space-around">
              <a-col :span="span">
                <a-form-item label="超限三级报警值">
                  <a-input placeholder="请输入超限三级报警值" v-decorator="['redValue', { rules: [{ required: false }] }]"
                           :allowClear="true"/>
                </a-form-item>
              </a-col>
              <a-col :span="span">
                <a-form-item label="超限二级报警值">
                  <a-input placeholder="请输入超限二级报警值" v-decorator="['orangeValue', { rules: [{ required: false }] }]"
                           :allowClear="true"/>
                </a-form-item>
              </a-col>
              <a-col :span="span">
                <a-form-item label="超限一级报警值">
                  <a-input placeholder="请输入超限一级报警值" v-decorator="['yellowValue', { rules: [{ required: false }] }]"
                           :allowClear="true"/>
                </a-form-item>
              </a-col>
            </a-row>

            <a-row type="flex" justify="space-around">
              <a-col :span="23">
                <span class="size-18 color-000-85 bold" style="margin: 15px 0">单日变化量报警</span>
              </a-col>
            </a-row>
            <a-row type="flex" justify="space-around">
              <a-col :span="span">
                <a-form-item label="超限三级报警值">
                  <a-input placeholder="请输入超限三级报警值" v-decorator="['dayRedValue', { rules: [{ required: false }] }]"
                           :allowClear="true"/>
                </a-form-item>
              </a-col>
              <a-col :span="span">
                <a-form-item label="超限二级报警值">
                  <a-input placeholder="请输入超限二级报警值" v-decorator="['dayOrangeValue', { rules: [{ required: false }] }]"
                           :allowClear="true"/>
                </a-form-item>
              </a-col>
              <a-col :span="span">
              </a-col>
            </a-row>

            <a-row type="flex" justify="space-around">
              <a-col :span="23">
                <span class="size-18 color-000-85 bold" style="margin: 15px 0">累计变化量报警</span>
              </a-col>
            </a-row>
            <a-row type="flex" justify="space-around">
              <a-col :span="span">
                <a-form-item label="超限三级报警值">
                  <a-input placeholder="请输入超限三级报警值" v-decorator="['totalRedValue', { rules: [{ required: false }] }]"
                           :allowClear="true"/>
                </a-form-item>
              </a-col>
              <a-col :span="span">
                <a-form-item label="超限二级报警值">
                  <a-input placeholder="请输入超限二级报警值" v-decorator="['totalOrangeValue', { rules: [{ required: false }] }]"
                           :allowClear="true"/>
                </a-form-item>
              </a-col>
              <a-col :span="span">
              </a-col>
            </a-row>
          </div>

<!--          <a-row type="flex" justify="space-around">
            <a-col :span="11">
              <a-form-item label="监测点图片" :wrapper-col="{ span: 24 }">
                <a-upload
                    accept=".jpg,.png,.jpeg"
                    name="imgFile"
                    list-type="picture-card"
                    :action="actionUrl"
                    :file-list="fileList"
                    @change="uploadCallback"
                    @preview="handlePreview"
                    :multiple="true"
                    :remove="handleRemove"
                >
                  <div v-if="fileList&&fileList.length <12">
                    <a-icon :type="loading ? 'loading' : 'plus'"/>
                    <div>
                      点击上传
                    </div>
                  </div>
                </a-upload>
              </a-form-item>
            </a-col>
            <a-col :span="11">
            </a-col>
          </a-row>-->

          <div class="flex-row-center" style="height: 50px;justify-content: flex-end;margin: 30px 50px;">
            <a-button type="link" @click="back">
              取消
            </a-button>
            <a-button type="primary" html-type="submit">
              保存
            </a-button>
          </div>
        </a-form>
      </div>

    </div>
    <a-modal :visible="previewVisible" :footer="null" @cancel="previewVisible = false">
      <img alt="预览" style="width: 100%" :src="previewImage"/>
    </a-modal>
  </div>
</template>

<script>
import selectAddress from '../../components/select_address';

export default {
  components: {
    selectAddress
  },
  data() {
    return {
      showModal: false,
      span: 7,
      form: this.$form.createForm(this, {name: 'form'}),
      id: undefined,
      previewVisible: false,
      previewImage: '',
      actionUrl: '/client/api/uploadFile',
      loading: false,
      fileList: [],
      locationSelect: null,
      deviceTypeList:[],
      structureList:[],
      monitorItemList:[],
      deviceList:[],
      showType:false,
      typeList:[{DATA_ID:1,CLASS_VALUE:'左洞'},{DATA_ID:2,CLASS_VALUE:'右洞'}],
      isPower:true
    }
  },
  mounted() {
    let self = this;

    let userStr = localStorage.getItem('userInfo');
    let user = JSON.parse(userStr);
    this.$util.get('api/projectInfo/' + user.PROJECT_ID, {}).then((res) => {
      self.isPower = res.content.PROJECT_TYPE==1;
    });


    this.$util.get('api/dataList', {
      page: 1,
      pageSize: 100,
      classType: 'DEVICE_TYPE'
    }, true).then((res) => {
      self.deviceTypeList = res.content
    })

    this.$util.get('api/structureList', {
      page: 1,
      pageSize: 100,
    }, true).then((res) => {
      self.structureList = res.content.list
    });

    this.$util.get('api/monitorItemList', {
      page: 1,
      pageSize: 100,
    }, true).then((res) => {
      self.monitorItemList = res.content
    });

    setTimeout(()=>{
      if (this.$route.query.monitorPointId) {
        this.$util.showLoading(self);
        this.id = this.$route.query.monitorPointId;

        this.$util.get('api/monitorPointInfo/' + this.$route.query.monitorPointId, {isImage:1}).then((res) => {
          self.bean = res.content;
          self.$util.hideLoading();
          let dao = res.content;
          self.structureChange(dao.STRUCTURE_ID);
          self.form.setFieldsValue({
            pointName: dao.POINT_NAME,
            pointNumber: dao.POINT_NUMBER,
            location: dao.LOCATION,
            structureId: dao.STRUCTURE_ID,
            monitorItemId: dao.MONITOR_ITEM_ID,
            channelNumber: dao.CHANNEL_NUMBER,
            originValue1: dao.ORIGIN_VALUE1,
            originValue2: dao.ORIGIN_VALUE2,
            originValue3: dao.ORIGIN_VALUE3,
            redValue: dao.RED_VALUE,
            orangeValue: dao.ORANGE_VALUE,
            yellowValue: dao.YELLOW_VALUE,
            dayRedValue: dao.DAY_RED_VALUE,
            dayOrangeValue: dao.DAY_ORANGE_VALUE,
            totalRedValue: dao.TOTAL_RED_VALUE,
            totalOrangeValue: dao.TOTAL_ORANGE_VALUE,
            description:dao.DESCRIPTION,
            type:dao.TYPE,
            area:dao.DURABLE_POINT_INFO?dao.DURABLE_POINT_INFO.AREA:'',
          })


          this.$util.get('api/deviceList', {
            page: 1,
            pageSize: 100,
            isBind:0
          }, true).then((res) => {
            let list = res.content.list;
            list.push({
              DEVICE_ID:dao.DEVICE_ID,
              DEVICE_NAME:dao.DEVICE_NAME,
            })
            this.deviceList = list;
            self.form.setFieldsValue({
              deviceId: dao.DEVICE_ID,
            });
          });

          self.locationSelect = {
            lat: dao.LAT,
            lng: dao.LNG,
            address: dao.ADDRESS
          }
          if (dao.DEVICE_IMAGE_LIST && dao.DEVICE_IMAGE_LIST.length > 0) {
            let picList = [];
            for (let i = 0; i < dao.DEVICE_IMAGE_LIST.length; i++) {
              let item = dao.DEVICE_IMAGE_LIST[i];
              picList.push(
                  {
                    uid: '-' + i,
                    name: item.FILE_NAME,
                    caption: item.FILE_NAME,
                    key: item.SAVE_NAME,
                    status: 'done',
                    url: self.$util.getServeImageUrl() + item.SAVE_NAME,
                  }
              )
            }
            self.fileList = picList;
          }

        })
      }else{
        this.$util.get('api/deviceList', {
          page: 1,
          pageSize: 100,
          isBind:0
        }, true).then((res) => {
          this.deviceList = res.content.list
        });
      }
    },100)
  },

  methods: {
    handleSubmit(e) {
      e.preventDefault();
      let self = this;
      this.form.validateFields((err, values) => {
        if (!err) {
          let p = {
            action: "ADD",
            pointName: values.pointName,
            pointNumber: values.pointNumber,
            location: values.location,
            structureId: values.structureId,
            monitorItemId: values.monitorItemId,
            deviceId: values.deviceId,
            channelNumber: values.channelNumber,
            originValue1: values.originValue1,
            originValue2: values.originValue2,
            originValue3: values.originValue3,
            redValue: values.redValue,
            orangeValue: values.orangeValue,
            yellowValue: values.yellowValue,
            dayRedValue: values.dayRedValue,
            dayOrangeValue: values.dayOrangeValue,
            totalRedValue: values.totalRedValue,
            totalOrangeValue: values.totalOrangeValue,
            description:values.description,
            type:values.type,
            area:values.area
          }

          if (self.locationSelect) {
            p.lat = self.locationSelect.lat;
            p.lng = self.locationSelect.lng;
            p.address = self.locationSelect.address;
          }

          let picList = [];
          if (self.fileList.length > 0) {
            for (let i = 0; i < self.fileList.length; i++) {
              console.log(i)
              let dao = self.fileList[i].response;
              if (dao && dao.errorCode == 0 && dao.content && dao.content.initialPreviewConfig && dao.content.initialPreviewConfig.length > 0) {
                picList.push({
                  key: dao.content.initialPreviewConfig[0].key,
                  caption: dao.content.initialPreviewConfig[0].caption,
                })
              } else {
                picList.push({
                  key: self.fileList[i].key,
                  caption: self.fileList[i].caption,
                })
              }

            }
          }
          let keys = [], caption = [];
          if (picList.length > 0) {
            for (let i = 0; i < picList.length; i++) {
              keys.push(picList[i].key)
              caption.push(picList[i].caption)
            }
          }
          p.imgCaptions = caption.join(',');
          p.imgKeys = keys.join(',');

          if (this.bean) {
            p.action = 'EDIT';
            p.monitorPointId = this.bean.MONITOR_POINT_ID;
          }
          console.log(33,p);
          this.$util.post('api/submitMonitorPoint', p).then((res) => {
            self.back();
          });
        }
      });
    },
    back() {
      this.$router.back();
    }, modalClose(params) {
      if (params)
        this.locationSelect = params.locationSelect;
      this.showModal = false;
    }, handleRemove(file) {
      file.status = '1';
      let name = '';
      if (file.response) {
        name = file.response.content.initialPreviewConfig[0].key
      } else {
        name = file.name
      }
      this.$util.post("api/deleteFile?saveName=" + name, {})
    }, uploadCallback({file, fileList}) {
      this.fileList = fileList;
    },
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await this.$util.getBase64(file.originFileObj);
      }
      this.previewImage = file.url || file.preview;
      this.previewVisible = true;
    },deviceTypeChange(value){
      this.$util.get('api/deviceList', {
        page: 1,
        pageSize: 100,
        deviceType:value,
        isBind:0
      }, true).then((res) => {
        this.deviceList = res.content.list
      });
    },structureChange(value){
      let dao = null;
      for (let i = 0; i < this.structureList.length; i++) {
        if(this.structureList[i].STRUCTURE_ID===value){
          dao = this.structureList[i];
          break;
        }
      }
      if(dao){
        this.showType = (dao.TYPE===2);
        if(!this.showType)
          this.form.setFields({type:undefined})
      }

    }
  }
}
</script>

<style scoped>

</style>
