<template>
    <div style="margin: 20px;">
        <div style="background: #fff; padding: 30px;">
            <div class="size-16 color-000-85 bold" style="margin-bottom: 20px;">
                事件信息
            </div>
            <a-row>
                <a-col :span="8">
                    <div class="flex-row-center size-14">
                        <div class="color-000-85">事件名称：</div>
                        <div class="color-000-65">{{data.EVENT_NAME}}</div>
                    </div>
                </a-col>
                <a-col :span="8">
                    <div class="flex-row-center size-14">
                        <div class="color-000-85">事件类型：</div>
                        <div class="color-000-65">{{data.EVENT_TYPE_NAME}}</div>
                    </div>
                </a-col>
                <a-col :span="8">
                    <div class="flex-row-center size-14">
                        <div class="color-000-85">关联结构物：</div>
                        <div class="color-000-65">{{data.STRUCTURE_NAME}}</div>
                    </div>
                </a-col>
            </a-row>
            <a-row style="margin-top: 30px;">
                <a-col :span="8">
                    <div class="flex-row-center size-14">
                        <div class="color-000-85">开始时间：</div>
                        <div class="color-000-65">{{data.START_TIMES}}</div>
                    </div>
                </a-col>
                <a-col :span="8">
                    <div class="flex-row-center size-14">
                        <div class="color-000-85">结束时间：</div>
                        <div class="color-000-65">{{data.END_TIMES}}</div>
                    </div>
                </a-col>
                <a-col :span="8">
                    <div class="flex-row-center size-14">
                        <div class="color-000-85">上报人：</div>
                        <div class="color-000-65">{{data.USER_NAME}}</div>
                    </div>
                </a-col>
            </a-row>

            <a-row style="margin-top: 30px;">
                <a-col :span="8">
                    <div class="flex-row-center size-14">
                        <div class="color-000-85">事件状态：</div>
                        <div class="color-000-65">{{data.STATUS == 0 ? '待处置' : '已处置'}}</div>
                    </div>
                </a-col>
                <a-col :span="8">

                </a-col>
                <a-col :span="8">

                </a-col>
            </a-row>
            <div class="flex-row size-14" style="line-height: 24px; margin-top: 30px;">
                <div class="color-000-85" style="min-width: 70px;">事件描述：</div>
                <div class="color-000-65">{{data.DESCRIPTION}}</div>
            </div>

            <div style="background: #fff; margin-top: 30px;" v-if="images&&images.length>0">
                <div class="color-000-85 size-14" style="min-width: 70px;">图片：</div>
                <viewer :images="images" style="margin-top: 10px">
                    <img v-for="src in images" :key="src" :src="src"
                         style="width: 300px; height: 180px; object-fit: contain; margin: 0 15px 15px 0;"
                         :onerror="errorImg">
                </viewer>
            </div>

            <div class="flex-row-center" style="height: 50px;justify-content: flex-end;margin: 30px 50px;" v-if="data.STATUS == 0">
                <a-button type="link" @click="back">
                    取消
                </a-button>
                <a-button type="primary" @click="showModal">
                    处置
                </a-button>
            </div>
        </div>

        <div style="background: #fff; padding: 30px; margin-top: 30px;" v-if="data.STATUS == 1">
            <div class="size-16 color-000-85 bold" style="margin-bottom: 20px;">
                处置信息
            </div>
            <a-row>
                <a-col :span="8">
                    <div class="flex-row-center size-14">
                        <div class="color-000-85">处置人：</div>
                        <div class="color-000-65">{{data.HANDLER_NAME}}</div>
                    </div>
                </a-col>
                <a-col :span="8">
                    <div class="flex-row-center size-14">
                        <div class="color-000-85">处置时间：</div>
                        <div class="color-000-65">{{data.HANDLE_TIMES}}</div>
                    </div>
                </a-col>
                <a-col :span="8">
                </a-col>
            </a-row>
            <div class="flex-row size-14" style="line-height: 24px; margin-top: 30px;">
                <div class="color-000-85" style="min-width: 70px;">处置措施：</div>
                <div class="color-000-65">{{data.MEASURE}}</div>
            </div>
        </div>

        <a-modal v-model="visible" title="处置措施" @ok="handleOk">
            <a-textarea placeholder="请输入处置措施"
                        v-model="measure"
                        :allowClear="true" :auto-size="{ minRows: 4}"/>
        </a-modal>
    </div>
</template>

<script>
  export default {
    data() {
      return {
        data: {},
        images: [],
        errorImg: 'this.src="' + require('../../image/icon_default.png') + '"',
        visible: false,
        measure: ''
      }
    },
    mounted() {
      this.getInfo();
    },

    methods: {
      getInfo() {
        let self = this;
        if (this.$route.query.eventId) {
          this.$util.showLoading(self);
          this.eventId = this.$route.query.eventId;
          this.$util.showLoading(this);
          this.$util.get('api/eventInfo/' + this.eventId, {}, true).then((res) => {
            this.$util.hideLoading();
            console.log(111, res);
            self.data = res.content;
          })

          self.$util.get('api/fileList', {objectId: this.$route.query.eventId, fileType: 2, type: 3}).then((res) => {
            if (res.content && res.content.initialPreview) {
              this.images = res.content.initialPreview;
            }
          });
        }
      },
      back() {
        this.$router.back();
      },

      showModal() {
        this.visible = true;
      },
      handleOk(e) {
        if (!this.measure) {
          this.$message.error('请输入处置措施');
          return;
        }
        let p = {
          measure: this.measure,
          eventId: this.eventId
        }
        this.$util.post('api/handleEvent', p).then((res) => {
          console.log(res);
          this.visible = false;
          this.getInfo();
        });
      },
    }
  }
</script>

<style scoped>

</style>
