<template>
    <div style="width: 100%;height: 100%;">
        <v-chart class="my-chart" :options="structureLineChart"/>
    </div>
</template>

<script>
  import {graphic} from "echarts/lib/export/api";
  import moment from 'moment';
  export default {
    props: {
      array_durable: {
        type: Array,
      },
      isSmall:{
        type:Boolean,
        default:false
      },
      isToday:{
        type:Boolean,
        default:false
      }
    },
    data() {
      return {
        structureLineChart: {},
        firstList: [],
        secondList: []
      }
    },
    mounted() {
      console.log(5555, this.array_durable);

      this.firstList = this.array_durable.map((item) => {
        return item.avg_sac;
      })
      this.secondList = this.array_durable.map((item) => {
        return item.avg_cc;
      })
      this.getChartData();
    },
    methods: {
      getChartData() {
        let self = this;
        let dates = [];
        for (let i = 0; i <this.array_durable.length; i++) {
          let dd = moment(this.array_durable[i]._id).format(this.isToday?'HH:00':'MM-DD');
          dates.push(dd);
        }

        this.structureLineChart = {
          tooltip: {
            trigger: 'axis',
            axisPointer: {            // 坐标轴指示器，坐标轴触发有效
              // type: 'none'        // 默认为直线，可选为：'line' | 'shadow' | 'none'
            },
            backgroundColor: 'rgba(55,66,97,0.8)',
            borderWidth: 0,
            textStyle: {
              color: '#fff',

            },
            formatter: function (params) {
              console.log(333,params);

              let tar0 = params[0];
              let tar1 = params[1];

              let dd = `<div style="padding: 5px 10px;">
                    <div class="flex-row-center" style="margin-top: 2px;">
                        <div class="size-16 color-fff-80" style="margin-left: 0px;">` + tar0.name + `</div>
                    </div>
                    <div class="flex-row-center" style="margin-top: 10px;">
                        <div style="width: 10px; height: 10px; background: ` + tar0.color + `; border-radius: 50%"></div>
                        <div class="size-16 color-fff-80" style="margin-left: 5px;">阳极保护电流</div>
                        <div class="size-18 color-fff margin-left-8">` + parseFloat(tar0.value.toFixed(4)) + `A` + `</div>
                    </div>
                    <div class="flex-row-center" style="margin-top: 10px;">
                        <div style="width: 10px; height: 10px; background: ` + tar1.color + `; border-radius: 50%"></div>
                        <div class="size-16 color-fff-80" style="margin-left: 5px;">阴极腐蚀挂片电流</div>
                        <div class="size-18 color-fff margin-left-8">` + parseFloat(tar1.value.toFixed(4)) + `A` + `</div>
                    </div>
                </div>`;
              return dd;
            }
          },
          grid: {
            left: '2%',
            right: '1%',
            bottom: '2%',
            top: '5%',
            containLabel: true
          },
          xAxis: [{
            type: "category",
            data: dates,
            axisLine: {
              show: false
            },
            axisLabel: {
              color: "#A0CAFF",
              fontSize: 12
            },
            axisTick: {
              show: false
            }
          }],

          yAxis: [{
            type: "value",
            axisLabel: {
              color: "#A0CAFF",
              // fontSize: self.isSmall?12:18
            },
            splitLine: {
              lineStyle: {
                // 使用深浅的间隔色
                color: ['rgba(255,255,255,0.15)']
              }
            }
          }],
          series: [
            {
              type: 'line',
              areaStyle: { //渐变色
                normal: {
                  color: graphic.LinearGradient(0, 0, 0, 1,
                    [{offset: 0, color: 'rgba(248,195,58, 0.3)'},
                      {offset: 0.5, color: 'rgba(248,195,58, 26, 0.1)'},
                      {offset: 1, color: 'rgba(248,195,58, 0.01)'}])
                }
              },
              itemStyle: {
                color: '#51EEAF'
              },

              data: self.firstList,
              lineStyle: {
                color: '#51EEAF',
                width: 2,
                // type: 'dashed'
              },
              smooth: true,
              // symbol: 'none'
              symbol: 'image://https://nas.iquanzhan.com/structure/circle_gnss_1.png',
              symbolSize: 10,
            },

            {
              type: 'line',
              areaStyle: { //渐变色
                normal: {
                  color: graphic.LinearGradient(0, 0, 0, 1,
                    [{offset: 0, color: 'rgba(96,224,255, 0.3)'},
                      {offset: 0.5, color: 'rgba(96,224,255, 0.1)'},
                      {offset: 1, color: 'rgba(96,224,255, 0.01)'}])
                }
              },
              itemStyle: {
                color: '#F8C33A'
              },
              data: self.secondList,
              lineStyle: {
                color: '#F8C33A',
                width: 2,
                // type: 'dashed'
              },
              smooth: true,
              // symbol: 'none',
              symbol: 'image://https://nas.iquanzhan.com/structure/circle_gnss_2.png',
              symbolSize: 10,
            }
          ]
        };
      },
    }
  }
</script>

<style scoped>
    .my-chart {
        width: 100%;
        height: 100%;
    }
</style>
