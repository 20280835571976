<template>
  <div style="padding: 20px;">
    <div style="background: #fff; padding: 20px 30px;">
      <span class="size-18 color-000-85 bold">桥梁管理</span>
      <a-row style="margin-top: 15px">
        <a-col :span="7">
          <div class="flex-row-center" style="padding-right: 30px;">
            <div class="size-14 color-000-85">关键词：</div>
            <a-input-search placeholder="名称/编号" class="filter-common" @search="onSearch" v-model="keyword"
                            :allowClear="true"/>
          </div>
        </a-col>
        <a-col :span="7">
          <div class="flex-row-center" style="padding-right: 30px;">
            <div class="size-14 color-000-85">桥梁类型：</div>
            <a-select placeholder="请选择桥梁类型" :allowClear="true" class="filter-common" v-model="selectType" @change="typeChange">
              <template v-for="item in typeList">
                <a-select-option :key="item.DATA_ID" :value="item.DATA_ID">
                  {{ item.CLASS_VALUE }}
                </a-select-option>
              </template>
            </a-select>
          </div>
        </a-col>
        <a-col :span="3">
          <div>
            <a-button type="primary" @click="onSearch()">查询</a-button>
            <a-button style="margin-left: 10px;" @click="reset()">重置</a-button>
          </div>
        </a-col>
      </a-row>
      <div style="margin-top: 20px;">
        <a-button type="primary" icon="plus" @click="add">新建</a-button>
      </div>
    </div>

    <div style="margin-top: 20px;">
      <a-list :grid="mGrid" :data-source="data" :pagination="paginationOpt" v-if="data">
        <a-list-item slot="renderItem" slot-scope="item, index">
          <a-card class="cursor_pointer">
            <img :src="item.img" slot="cover" :onerror="errorImg" :style="{'height':getImageHeight()+'px'}"
                 style="object-fit: cover" @click="goDetail(index)"/>
            <template slot="actions" class="ant-card-actions">
              <icon-font type="icon-edit" @click="edit(index)"/>
              <a-popconfirm
                  title="确定要删除吗?"
                  ok-text="确定"
                  cancel-text="取消"
                  @confirm="confirmDelete(index)"
              >
                <icon-font type="icon-delete"/>
              </a-popconfirm>

              <a-popover title="更多操作">
                <template slot="content">
                  <div class="flex-row-center cursor_pointer" @click="bind(index)">
                    <icon-font type="icon-biaoqian"/>
                    <div style="margin-left: 10px;">绑定设备</div>
                  </div>
                  <div class="color-line-bg" style="margin: 10px -15px; height: 1px;"/>
                  <div class="flex-row-center cursor_pointer" @click="bindDesign(index)">
                    <icon-font type="icon-biaoqian"/>
                    <div style="margin-left: 10px;">效果图绑定设备</div>
                  </div>
                  <div class="color-line-bg" style="margin: 10px -15px; height: 1px;"/>
                  <div class="flex-row-center cursor_pointer" @click="goDetail(index)">
                    <icon-font type="icon-info"/>
                    <div style="margin-left: 10px;">查看详情</div>
                  </div>
                </template>
                <icon-font type="icon-more"/>
              </a-popover>
            </template>
            <div class="u-flex-between">
              <div class="size-16 color-000-85 lineNum-1" style="margin-top: -10px;">{{ item.STRUCTURE_NAME }}</div>
              <div class="size-14 color-000-45" style="margin-top: -10px;">{{ item.BRIDGE_TYPE_NAME }}
              </div>
            </div>
            <div class="flex-row-center" style="margin-top: 5px">
              <div class="size-14 color-000-45 lineNum-1">{{ item.STRUCTURE_NUMBER }}</div>
            </div>
            <div class="size-14 color-000-45" style="margin-top: 5px; margin-bottom: -10px;">{{ item.START_NUMBER+"~"+item.END_NUMBER }}
            </div>
          </a-card>
        </a-list-item>
      </a-list>
    </div>
  </div>
</template>

<script>
export default {
  name: "bridgeList2",
  data() {
    let self = this;
    return {
      errorImg: 'this.src="' + require('../../image/icon_default.png') + '"',
      type: 0,
      mGrid: this.$util.mGrid,
      keyword: '',
      paginationOpt: this.$util.getTableParams((current, pageSize) => {
        self.paginationOpt.current = 1;
        self.paginationOpt.defaultPageSize = pageSize;
        self.getList()
      }, (current, size) => {
        self.paginationOpt.current = current
        self.paginationOpt.defaultPageSize = size
        self.getList()
      }),
      data: null,
      typeList: [],
      selectType: undefined,
    }
  },
  mounted() {
    let self = this;
    this.$util.get('api/dataList', { page: 1, pageSize: 100, classType: 'BRIDGE_TYPE' }, true).then((res) => {
      self.typeList = res.content
    })
    this.getList();

  },
  computed: {
    getImageHeight() {
      return function () {
        let screenHeight = document.documentElement.clientHeight;
        let imgHeight = 170;
        if (screenHeight < 1100) {
          imgHeight = 170;
        } else if (screenHeight < 1200) {
          imgHeight = 200;
        } else if (screenHeight < 1300) {
          imgHeight = 235;
        } else {
          imgHeight = 255;
        }
        return imgHeight
      }
    },
  },
  methods: {
    getList() {
      let self = this;
      let params = this.paginationOpt;
      if (this.data && this.data.length === 0)
        this.data = null;
      let p = {
        page: params.current,
        pageSize: params.defaultPageSize,
        keyword: this.keyword,
        isImage: 1
      }

      if (this.selectType) {
        p.bridgeType = this.selectType;
      }
      this.$util.showLoading(self);
      this.$util.get('api/bridgeList', p).then((res) => {
        let bean = res.content;
        self.paginationOpt.total = bean.count;

        for (let i = 0; i < bean.list.length; i++) {
          let item = bean.list[i];
          let imgPath = '';
          if (item.SAVE_NAME_LIST && item.SAVE_NAME_LIST.length > 0) {
            let imgs = item.SAVE_NAME_LIST.split(',');
            imgPath = imgs[0].replace("orgin", "thumb");
          }
          item.img = imgPath ? (self.$util.getServeImageUrl() + imgPath) : '';
          console.log(item.img)
        }

        self.data = bean.list;
        this.$util.hideLoading();
      })


    },
    onSearch(value) {
      this.paginationOpt.current = 1;
      if (value)
        this.keyword = value;
      this.getList();
    }, reset() {
      this.paginationOpt.current = 1;
      this.keyword = '';
      this.selectType = undefined;
      this.getList();
    },
    add() {
      this.$router.push('/bridgeAdd')
    },
    edit(index) {
      this.$router.push({path:'/bridgeAdd',query:{id:this.data[index].STRUCTURE_ID}})
    },bind(index){
      this.$router.push({path:'/deviceBind',query:{id:this.data[index].STRUCTURE_ID,type:1}})
    },bindDesign(index){
      this.$router.push({path:'/deviceBind',query:{id:this.data[index].STRUCTURE_ID,type:1,design:true}})
    },
    goDetail(index) {
      this.$router.push({path:'/bridgeInfo',query:{id:this.data[index].STRUCTURE_ID}})
    },
    confirmDelete(index) {
      this.$util.showLoading(this);
      this.$util.post('api/deleteBridge/' + this.data[index].STRUCTURE_ID, {}).then((res) => {
        this.$message.success('删除成功');
        this.paginationOpt.current = 1;
        this.keyword = '';
        this.getList();
      })
    }, typeChange(value) {
      this.selectType = value;
      this.paginationOpt.current = 1;
      this.getList();
    },
  }
}
</script>

<style scoped>

</style>
