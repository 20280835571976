<!--系统管理-基础数据-->
<template>
  <div style="background-color: white">
    <a-modal
        :bodyStyle="{'height':550+'px'}"
        v-model="showModal"
        :closable="false"
        :footer="null"
        :width="500"
        :destroyOnClose="true"
    >
      <data-add @modalClose="modalClose" :bean="bean" :categoryList="categoryList"/>
    </a-modal>
    <p class="color-333 size-18" style="margin: 15px">基础数据</p>
    <div class="flex-row-center" style="margin: 15px">
<!--      <a-input-search placeholder="名称" style="width: 200px" @search="onSearch" v-model="keyword" :allowClear="true"/>-->

      <a-select style="min-width: 200px" @change="categoryChange" placeholder="请选择数据类型"
                :allowClear="true" v-model="selectCategory">
        <template v-for="item in categoryList">
          <a-select-option :key="item.CLASS_TYPE" :value="item.CLASS_TYPE">
            {{ item.CLASS_NAME }}
          </a-select-option>
        </template>
      </a-select>

      <div class="flex-1"/>
      <a-button type="primary" icon="plus-circle" @click="add()">
        新增
      </a-button>
    </div>
    <a-table :columns="columns" :data-source="data" rowKey="DATA_ID" :bordered="false" :pagination="paginationOpt"
             size="small">
      <template slot="name" slot-scope="text,record,index">
        <a @click="edit(index)">{{ text }}</a>
      </template>
      <template slot="IS_SHOW" slot-scope="text">
        <span>{{ text == 1 ? '是' : '否' }}</span>
      </template>

      <template slot="action" slot-scope="text, record,index">
        <a-dropdown class="flex-row-center" style="justify-content: center">
          <a class="ant-dropdown-link color-333 size-14 flex-row-center" @click="e => e.preventDefault()">
            <a-icon type="unordered-list"/>
            <a-icon type="down" style="margin-left: 10px"/>
          </a>
          <a-menu slot="overlay">
            <a-menu-item class="flex-row-center" @click="edit(index)">
              <a-icon type="edit"/>
              <a href="javascript:;">编辑</a>
            </a-menu-item>
            <a-menu-item class="flex-row-center" @click="onDelete(index)">
              <a-icon type="delete"/>
              <a href="javascript:;">删除</a>
            </a-menu-item>
          </a-menu>
        </a-dropdown>

      </template>
    </a-table>
  </div>
</template>
<script>
const columns = [
  {
    title: '名称',
    dataIndex: 'CLASS_VALUE',
    scopedSlots: {customRender: 'name'},
  },{
    title: '类型',
    dataIndex: 'CN_NAME'
  },
  {
    title: '是否启用',
    dataIndex: 'IS_SHOW',
    scopedSlots: {customRender: 'IS_SHOW'},
  },
  {
    title: '创建时间',
    dataIndex: 'CREATE_TIMES',
  },
  {
    title: '操作',
    key: 'action',
    align: 'center',
    width: 80,
    scopedSlots: {customRender: 'action'},
  },
];


import dataAdd from '../../components/data_add';

export default {
  components: {
    'data-add': dataAdd
  },
  data() {
    let self = this;
    return {
      categoryList: [],
      selectCategory: undefined,
      bean: null,
      showModal: false,
      data: [],
      columns,
      keyword: '',
      paginationOpt: this.$util.getTableParams((current, pageSize) => {
        self.paginationOpt.current = 1;
        self.paginationOpt.defaultPageSize = pageSize;
        console.log(11, self.paginationOpt)
        self.getList()  //显示列表的接口名称
      }, (current, size) => {
        console.log(current, size)
        self.paginationOpt.current = current
        self.paginationOpt.defaultPageSize = size
        self.getList()
      }),
    };
  },
  mounted() {
    let self = this;
    this.$util.get('admin/dataCategoryList', {}).then((res) => {
      self.categoryList = res.content;
    })
    this.getList();
  },
  methods: {
    getList() {
      let self = this;
      let params = this.paginationOpt;
      let p = {
        page: params.current,
        pageSize: params.defaultPageSize,
        keyword: this.keyword,
      }
      if (this.selectCategory)
        p.classType = this.selectCategory;
      this.$util.showLoading(self);
      this.$util.get('admin/dataList', p, true).then((res) => {
        let bean = res.content;
        self.paginationOpt.total = bean.count;
        self.data = bean.list;
        this.$util.hideLoading();
      })
    },
    onDelete(index) {
      this.$confirm({
        title: '确认',
        content: '是否确认删除此记录？',
        okText: '确认',
        cancelText: '取消',
        onOk: () => {
          this.$util.post('admin/deleteData/'+this.data[index].DATA_ID , {})
              .then(res => {
                this.$message.success('删除成功');
                this.paginationOpt.current = 1;
                this.keyword = '';
                this.getList();
              });
        }
      });
    },
    onSearch(value) {
      this.paginationOpt.current = 1;
      this.keyword = value;
      this.getList();
    },
    modalClose(params) {
      if (params && params.refresh) {
        this.paginationOpt.current = 1;
        this.keyword = '';
        this.getList();
      }
      this.showModal = false;
    }, add() {
      this.bean = null;
      this.showModal = true;
    }, edit(index) {
      this.bean = this.data[index];
      this.showModal = true;
    }, categoryChange(value) {
      this.selectCategory = value;
      this.paginationOpt.current = 1;
      this.keyword = '';
      this.getList();
      console.log(3333, value)
    }
  },
};
</script>
