<template>
  <div class="flex-column" style="position: relative">
    <powerHomeMap style="flex: 1" @markerClick="markerClick"/>
    <electric_header title="结构物耐久监测系统" style="position: absolute;top: 0" :showBack="false"/>
    <div style="width: 400px; overflow: hidden;position: absolute;left: 30px;top: 110px;bottom: 40px" class="flex-column">
      <electric_home_header title="结构物列表"/>
      <div class="flex-1 flex-column" style="margin-top: 15px; margin-left: 12px; margin-bottom: 10px; overflow: scroll;">
        <div v-for="(item, index) in structureList" :key="index" class="cell flex-row-center cursor_pointer" @click="goDetail(index)">
          <img :src="getImageUrl(index)" style="width: 140px; height: 95px;">
          <div style="margin-left: 15px;">
            <div class="size-18 color-fff lineNum-1" style="max-width: 195px;">
              {{item.STRUCTURE_NAME}}
            </div>
            <div class="flex-row-center" style="margin-top: 10px;">
              <div class="size-14 color-fff">
                健康度：
              </div>
              <div class="size-14"
                   :style="'color:' + getHealthStatus(item.HEALTH).color">
                {{getHealthStatus(item.HEALTH).text}}
              </div>
            </div>
            <div class="flex-row-center" style="margin-top: 10px;">
              <div class="size-14 color-fff">中心桩号：</div>
              <div class="size-16 color-fff">{{item.PILE_NUMBER}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div style="width: 460px; overflow: hidden;position: absolute;right: 30px;top: 110px;bottom: 40px" class="flex-column">
      <div>
        <electric_home_header title="结构物健康统计"/>
        <div style="padding-left: 12px;">
          <div class="box-bg flex-row-center" style="height: 100px; margin-top: 10px;">
            <div class="flex-1 flex-row center">
              <img src="../../image/electric/electric_icon_1.png" style="width: 62px; height: 62px;">
              <div style="margin-left: 15px;" class="flex-column">
                <div class="flex-row-center">
                  <div class="size-26 color-fff ff">{{structureList.length}}</div>
                  <div class="size-14 color-fff-80" style="margin-left: 8px; margin-top: -4px;">个</div>
                </div>
                <div class="size-14 color-fff" style="margin-top: -5px;">结构物数量</div>
              </div>
            </div>
            <div style="height: 50px; width: 1px; background: rgba(165, 236, 249, 0.5)">
            </div>
            <div class="flex-1 flex-row center">
              <img src="../../image/electric/electric_icon_2.png" style="width: 62px; height: 62px;">
              <div style="margin-left: 15px;" class="flex-column">
                <div class="flex-row-center">
                  <div class="size-26 color-fff ff">{{deviceCounts}}</div>
                  <div class="size-14 color-fff-80" style="margin-left: 8px; margin-top: -4px;">个</div>
                </div>
                <div class="size-14 color-fff" style="margin-top: -5px;">监测点位</div>
              </div>
            </div>
          </div>

          <div class="box-bg flex-row flex-1" style="margin-top: 10px; height: 192px;">
            <div style="margin-left: -25px;" class="p-relative">
              <img src="../../image/electric/chart_3d_bg.png" style="width: 318px; height: 189px;">
              <div class="p-absolute" style="left: 0; bottom: 0; right: 0px; top: -20px;">
                <pie3dChart :arr="healthList" v-if="healthList.length"/>
              </div>
            </div>

            <div style="margin-left: -20px; margin-right: 40px;" class="flex-1 flex-column center">
              <div v-for="(item, index) in healthStatisticalList" :key="index" class="flex-row-center health-statistical"
                   style="margin-bottom: 10px;">
                <div style="width: 6px; height: 6px; border-radius: 3px; margin-left: 10px;"
                     :style="'backgroundColor:' + item.color">
                </div>
                <div style="margin-left: 15px;" class="size-14 color-fff">{{item.name}}</div>
                <div class="size-18 color-fff ff" style="margin-left: 15px; margin-top: 4px;">{{item.num}}</div>
                <div class="size-12 color-fff-80" style="margin-left: 10px;">座</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex-1 flex-column" style="margin-top: 20px ; overflow: hidden;">
        <electric_home_header title="最新报警通知"/>
        <div style="margin-left: 12px; overflow: scroll;" class="flex-1 flex-column">
          <div v-for="(item, index) in alarmList" :key="index">
            <div style="margin-top: 10px;" class="p-relative">
              <img :src="getAlarmImage(item.ALARM_LEVEL).bgImg" style="height: 112px;">
              <div class="absolute-0 flex-row" style="padding: 15px 15px;">
                <img :src="getAlarmImage(item.ALARM_LEVEL).img" style="width: 20px; height: 22px;">
                <div class="flex-column" style="margin-left: 20px; margin-top: -2px;">
                  <div class="size-18 color-fff ff-m">
                    {{item.STRUCTURE_NAME}}
                  </div>
                  <div class="size-16 color-fff lineNum-1" style="margin-top: 5px;">
                    {{item.DESCRIPTION}}
                  </div>
                  <div class="size-14 color-fff-80" style="margin-top: 5px;">
                    {{item.ALARM_TIMES}}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>

</template>

<script>
  import electric_home_header from "./electric_home_header";
  import pie3dChart from "../../components/pie3dChart";
  import powerHomeMap from "./powerHomeMap";
  export default {
    name: "electric_home",
    components: {
      electric_home_header: electric_home_header,powerHomeMap,
      pie3dChart
    },
    data() {
      return {
        structureList: [],
        deviceCounts: 0,
        healthList: [],
        healthStatisticalList: [
          {
            name: '基本完好',
            num: 0,
            color: '#57D2FF'
          },
          {
            name: '轻微异常',
            num: 0,
            color: '#80FF2C'
          },
          {
            name: '中度异常',
            num: 0,
            color: '#FFD355'
          },
          {
            name: '重度异常',
            num: 0,
            color: '#FF9A61'
          }
        ],
        alarmList: []
      }
    },
    computed: {
      getImageUrl() {
        return function (index) {
          let item = this.structureList[index];
          let imgName = '';
          if (item.SAVE_NAME_LIST && item.SAVE_NAME_LIST.length) {
            let imgList = item.SAVE_NAME_LIST.split(',');
            imgName = imgList[0];
          }
          console.log(this.$util.getImageUrlWithName(imgName));
          return this.$util.getImageUrlWithName(imgName);
        }
      },

      getHealthStatus() {
        return function (index) {
          let text = 'Ⅰ级-基本完好';
          let color = '#80FF2C';
          switch (index) {
            case 1:
              text = 'Ⅰ级-基本完好';
              color = '#80FF2C';
              break;
            case 2:
              text = 'Ⅱ级-轻微异常';
              color = '#57D2FF';
              break;
            case 3:
              text = 'Ⅲ级-中等异常';
              color = '#FFD355';
              break;
            case 4:
              text = 'Ⅳ级-严重异常';
              color = '#FF7373';
              break;
          }
          return {
            text,
            color
          }
        }
      },

      getAlarmImage() {
        return function (level) {
          let img = require('../../image/electric/electric_level_1.png');
          let bgImg = require('../../image/electric/electric_level_1_bg.png');
          if (level == 1) {
            img = require('../../image/electric/electric_level_1.png');
            bgImg = require('../../image/electric/electric_level_1_bg.png');
          } else if (level == 2) {
            img = require('../../image/electric/electric_level_2.png');
            bgImg = require('../../image/electric/electric_level_2_bg.png');
          } else if (level == 3) {
            img = require('../../image/electric/electric_level_3.png');
            bgImg = require('../../image/electric/electric_level_3_bg.png');
          }
          return {
            img,
            bgImg
          }
        }
      }
    },

    mounted() {
      this.getStructureList();
      this.getStructureScreenStatistics();
      this.getMonitorAlarmList();
    },

    methods: {
      getStructureList() {
        let p = {
          isScore: 1,
          isImage: 1
        }
        let self = this;
        this.$util.get('api/structureList', p).then((res) => {
          self.structureList = res.content.list;
          // this.healthList = [8,2,1,1];
          self.healthList = [self.structureList.length, 0, 0, 0];

          self.healthStatisticalList = [
            {
              name: '基本完好',
              num: self.structureList.length,
              color: '#57D2FF'
            },
            {
              name: '轻微异常',
              num: 0,
              color: '#80FF2C'
            },
            {
              name: '中度异常',
              num: 0,
              color: '#FFD355'
            },
            {
              name: '重度异常',
              num: 0,
              color: '#FF9A61'
            }
          ]

        });
      },

      getStructureScreenStatistics() {
        this.$util.get('api/structureScreenStatistics', {}).then((res) => {
          this.deviceCounts = res.content.DEVICE_COUNTS;
        });
      },

      getMonitorAlarmList() {
        let p = {
          page: 1,
          pageSize: 5,
        }
        this.$util.get('api/monitorAlarmList', p, true).then((res) => {
          console.log(444,res);
          this.alarmList = res.content.list;
        });
      },

      goDetail(index) {
        let item = this.structureList[index];
        this.goPath(item);
      },
      toDetailMask() {
        let item = this.structureList[this.currentIndex];
        this.goPath(item);
      },
      goPath(item) {
        localStorage.setItem('structureItem', JSON.stringify(item));
        this.$router.push({path: '/electricDetail', query: {structureId: item.STRUCTURE_ID, score: item.SCORE, health: item.HEALTH}})
      },
      markerClick(p){
        this.goPath(p.data);
      }
    }
  }
</script>

<style scoped>
  .content {
    width: 100%;
    height: 100%;
    position: relative
  }

  .bg {
    background: url(../../image/electric/electric_bg.png) no-repeat;
    background-size: 100% 100%;
  }

  .cell {
    min-height: 125px;
    background: rgba(255,255,255,0.05);
    margin-bottom: 20px;
    padding: 20px 0 20px 20px;
  }

  .box-bg {
    background: rgba(255,255,255,0.05);
  }

  .health-statistical{
    height: 25px;
    background: linear-gradient(90deg, rgba(233, 248, 252, 0.2) 0%, rgba(207, 238, 248, 0) 100%);
  }


</style>
