import main from './main'
import bridgeAdd from './bridgeAdd'
import bridgeList from './new2/bridgeList2';
import tunnelList from './new2/tunnelList2';
import sideSlopeList from './new2/sideSlopeList2';
import deviceList from './deviceList';
import deviceList2 from './new2/deviceList2';
import deviceAdd from './deviceAdd';
import tunnelAdd from './tunnelAdd';
import sideSlopeAdd from './sideSlopeAdd';
import accountList from './accountList';
import accountAdd from './accountAdd';
import deviceBind from './deviceBind';
import roleList from './roleList';
import durableList from './durableList';
import durableAdd from './durableAdd';
import projectList from './admin/projectList';
import projectAdd from './admin/projectAdd';
import adminMain from './admin/adminMain';
import dataList from './admin/dataList';
import home from './new2/home2';
import assessReport from "./new2/assessReport";
import addAssessReport from "./new2/addAssessReport";
import healthList from "./new2/healthList";
import addHealth from "./new2/addHealth";
import alarmList from './new2/alarmList';
import monitorPointList from "./new2/monitorPointList";
import eventList from "./new2/eventList";
import addEvent from "./new2/addEvent";
import eventInfo from "./new2/eventInfo";
import healthInfo from "./new2/healthInfo";

import monitorPointAdd from "./new2/monitorPointAdd";
import monitorItemList from "./new2/monitorItemList";
import dataAnalysis from './new2/dataAnalysis';
// import dataAnalysis from './dataAnalysis';
import roleAdd from "./roleAdd";
import bridgeInfo from "./new2/bridgeInfo";
import tunnelInfo from "./new2/tunnelInfo";
import sideSlopeInfo from "./new2/sideSlopeInfo";

//------------------------------------大屏start-----------------------------------------
import home_new from "./screen/home_new2";
import structureDetail from "./screen/structureDetail";
import test from "./screen/test-home";
//------------------------------------大屏end-----------------------------------------

//------------------------------------电化学start-----------------------------------------
import electric_home from "./screen/electric_home";
import electric_detail from "./screen/electric_detail";
import electric_monitor_point from "./screen/electric_monitor_point";
import electric_data_analysis from "./screen/electric_data_analysis";
//------------------------------------电化学end-----------------------------------------


let name = '敦当高速在线监测系统';
let name2 = '结构物在线监测系统管理后台';
export default [
  //首页
  {
    path: '',
    name: name,
    component: main,
    meta: {
      keepAlive: false,
      requiresAuth: true // 要求验证的页面,在此情况下其子页面也会被验证.
    },
    children: [
      {
        path: 'home', name: name,
        component: home,
      }, {
        path: 'bridgeList', name: name,
        component: bridgeList,
      }, {
        path: 'bridgeAdd', name: name,
        component: bridgeAdd,
      },{
        path: 'bridgeInfo', name: name,
        component: bridgeInfo,
      },
      {
        path: 'tunnelList', name: name,
        component: tunnelList
      }, {
        path: "tunnelAdd", name: name,
        component: tunnelAdd
      }, {
        path: "tunnelInfo", name: name,
        component: tunnelInfo
      },{
        path: "sideSlopeList", name: name,
        component: sideSlopeList
      }, {
        path: "sideSlopeAdd", name: name,
        component: sideSlopeAdd
      },{
        path: "sideSlopeInfo", name: name,
        component: sideSlopeInfo
      }, {
        path: "wireDistanceList", name: name,//倾角计
        component: deviceList
      }, {
        path: "inclinometerList", name: name,//倾角计
        component: deviceList
      }, {
        path: "gnssList", name: name,//倾角计
        component: deviceList
      }, {
        path: "goniometerList", name: name,//倾角计
        component: deviceList
      }, {
        path: "osmometerList", name: name,//倾角计
        component: deviceList
      }, {
        path: "hyetometerList", name: name,//倾角计
        component: deviceList
      }, {
        path: "rangefindersList", name: name,//倾角计
        component: deviceList
      }, {
        path: 'deviceList', name: name,
        component: deviceList2,
      }, {
        path: 'deviceAdd', name: name,
        component: deviceAdd,
      }, {
        path: 'deviceBind', name: name,
        component: deviceBind,
      }, {
        path: "userList", name: name,
        component: accountList
      }, {
        path: "userAdd", name: name,
        component: accountAdd
      }, {
        path: "roleList", name: name,
        component: roleList
      }, {
        path: "alarmList", name: name,
        component: alarmList
      }, {
        path: "statistics", name: name,
        component: dataAnalysis
      }, {
        path: "durableList", name: name,
        component: durableList
      }, {
        path: "durableAdd", name: name,
        component: durableAdd
      },
      {
        path: "/assessReport", name: name,
        component: assessReport
      },
      {
        path: "/addAssessReport", name: name,
        component: addAssessReport
      },
      {
        path: "/healthList", name: name,
        component: healthList
      },
      {
        path: "/addHealth", name: name,
        component: addHealth
      },
      {
        path: "/monitorPointList", name: name,
        component: monitorPointList
      },{
        path: "/monitorPointAdd", name: name,
        component: monitorPointAdd
      },{
        path: "/monitorItemList", name: name,
        component: monitorItemList
      },
      {
        path: "/eventList", name: name,
        component: eventList
      },
      {
        path: "/addEvent", name: name,
        component: addEvent
      },{
        path: "/roleAdd", name: name,
        component: roleAdd
      },
      {
        path: "/eventInfo", name: name,
        component: eventInfo
      },
      {
        path: "/healthInfo", name: name,
        component: healthInfo
      },
    ]
  },{
    path: '/manage',
    name: name2,
    component: adminMain,
    meta: {
      keepAlive: false,
      requiresAuth: true, // 要求验证的页面,在此情况下其子页面也会被验证.
      manage:true
    },
    children: [
      {
        path: '',
        redirect: '/manage/projectList',
      },
      {
        path: 'projectList', name: name2,
        component: projectList,
      },
      {
        path: 'projectAdd', name: name2,
        component: projectAdd,
      },
      {
        path: 'dataList', name: name2,
        component: dataList,
      }
    ]
  }, {
    path: "/screenHome", name: name,
    component: home_new
  },{
    path: "/screenPowerHome", name: name,
    component: electric_home
  },{
    path: "/electricDetail", name: name,
    component: electric_detail
  },{
    path: "/electricMonitorPoint", name: name,
    component: electric_monitor_point
  },{
    path: "/electricDataAnalysis", name: name,
    component: electric_data_analysis
  }, {
    path: "/test", name: name,
    component: test
  }, {
    path: "/structureDetail", name: name,
    component: structureDetail
  }

]
