<template>
  <div class="flex-column content" style="position: relative; overflow: hidden;">
    <powerHomeMap style="flex: 1" :structureInfo="structureInfo" v-if="structureInfo"  @markerClick="markerClick"/>
    <electric_header title="结构物耐久监测系统" style="position: absolute;top: 0"/>

    <div style=" width: 400px; overflow: hidden;position: absolute;left: 30px;top: 110px;" class="flex-column" v-if="structureInfo">
      <electric_home_header :title="structureInfo.STRUCTURE_NAME"/>
      <div style="margin-left: 12px; margin-top: 20px;">
        <img :src="getImageUrl()" width="388">
        <div style="margin-top: 15px;" class="p-relative">
          <div style="background: #0F2E58; height: 40px;">
          </div>
          <div class="absolute-0">
            <div class="p-relative flex-1">
              <div style="width: 112px; height: 40px;" class="p-relative">
                <img src="../../image/electric/health_box_bg.png" style="width: 112px; height: 40px;">
                <div class="absolute-0 flex-row center size-20 color-fff">
                  健康度
                </div>
              </div>
              <div class="flex-1 flex-row-center size-22" style="margin-left: 22px;" :style="'color:' + getHealthStatus(structureInfo.HEALTH?structureInfo.HEALTH:1).color">
                {{getHealthStatus(structureInfo.HEALTH?structureInfo.HEALTH:1).text}}
              </div>
            </div>
          </div>
        </div>

        <div style="margin-top: 10px;" class="box-bg">
          <div class="flex-row wrap" style="padding-top: 20px;">
            <div v-for="(item, index) in statisticalList" :key="index" style="width: 50%; margin-bottom: 20px; padding-left: 20px;" class="flex-row-center">
              <img :src="item.img" width="62" height="62">
              <div style="margin-left: 10px;">
                <div class="flex-row-center">
                  <div class="size-24" :style="'color:' + (index < 2 ? '#fff':'#FF7E7E')">{{item.num}}</div>
                  <div class="size-14 color-fff-50" style="margin-left: 5px; margin-top: 3px;">{{item.unit}}</div>
                </div>
                <div class="size-16 color-fff">{{item.name}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>

    <div style="width: 460px; overflow: hidden;position: absolute;right: 30px;top: 110px;bottom: 40px" class="flex-column">
      <electric_home_header title="报警统计"/>
      <div style="padding-left: 12px;">
        <div style="margin-top: 10px;" class="box-bg">
          <div class="flex-row" style="padding: 10px 0 20px;">
            <div v-for="(item, index) in alarmList" :key="index">
              <div class="flex-1 flex-column center">
                <img :src="item.img" height="128">
                <div class="flex-row-center" style="margin-top: -25px;">
                  <div class="size-26 color-fff">{{item.num}}</div>
                  <div class="size-14 color-fff-80" style="margin-left: 5px; margin-top: 3px;">次</div>
                </div>
                <div class="size-14 color-fff">{{item.name}}</div>
              </div>
            </div>
          </div>
        </div>

        <div style="margin-top: 20px;">
          <div style="height: 1px; background: rgba(216, 216, 216, 0.2)"></div>
          <div class="flex-row-center" style="margin: 10px 0;">
            <div v-for="(item, index) in statisticalTitleList" :key="index" class="flex-1 size-16 color-fff flex-row center"
                 style="height: 34px; border: 1px solid transparent;"
                 :style="'marginRight:' + (index == 2 ? '0px':'10px') + ';'
                 + 'backgroundColor:' + (selectIndex == index ? 'rgba(59, 106, 255, 0.5)':'rgba(255,255,255, 0.1)') + ';'
                 + 'borderColor:' + (selectIndex == index ? 'rgba(173 , 244, 250, 31)':'rgba(255,255,255, 0.1)')"
                 @click="clickStatisticalIndex(index)"
            >
              {{item}}
            </div>
          </div>
          <div style="height: 1px; background: rgba(216, 216, 216, 0.2)"></div>

          <div style="margin-top: 10px; padding: 10px 15px;" class="box-bg">
            <div class="flex-row-center">
              <img src="../../image/electric/electric_decoration_1.png" width="10" height="10">
              <div class="size-18 color-fff" style="margin-left: 8px;">
                报警类型统计
              </div>
            </div>
            <div class="flex-row">
              <div style="height: 200px; width: 200px; position: relative; margin-top: 0px; ">
                <div style="position: absolute;left: 0;top: 0;right:0;bottom:0;align-items: center"
                     class="u-flex-col-center  color-fff">
                  <span class="size-30 color-DEE8FE">{{ firstLevelCount + secondLevelCount + thirdLevelCount }}</span>
                  <span class="size-14 color-DEE8FE">报警次数</span>
                </div>
                <chart_pie :dataValue="pieData" v-if="pieData"/>
              </div>

              <div style="margin-right: 40px;" class="flex-1 flex-column center">
                <div v-for="(item, index) in alarmTypeList" :key="index" class="flex-row-center alarm-type"
                     style="margin-bottom: 15px;">
                  <div style="width: 6px; height: 6px; border-radius: 3px; margin-left: 10px;"
                       :style="'backgroundColor:' + item.color">
                  </div>
                  <div class="flex-row-center" style="width: 200px;">
                    <div style="margin-left: 15px;" class="size-14 color-fff">{{item.name}}</div>
                    <div class="size-18 color-fff ff" style="margin-left: 15px; margin-top: 4px;">{{item.num}}</div>
                    <div class="size-12 color-fff-80" style="margin-left: 10px;">次</div>
                  </div>

                </div>
              </div>
            </div>
          </div>

          <div style="margin-top: 10px; padding: 10px 15px;" class="box-bg">
            <div class="flex-row-center">
              <img src="../../image/electric/electric_decoration_1.png" width="10" height="10">
              <div class="size-18 color-fff" style="margin-left: 8px;">
                报警次数统计
              </div>
            </div>
            <div class="flex-row-end" style="margin-bottom: 10px; margin-top: 10px;">
              <div v-for="(item, index) in alarmBottomTitleList" :key="index" class="flex-row-center" style="margin-left: 10px;">
                <img :src="item.img" width="21" height="18">
                <div class="size-16 color-fff-70" style="margin-left: 5px;">{{item.name}}</div>
              </div>
            </div>
            <div style="width: 100%; height: 240px;">
              <chart_line_three :first-list="firstList" :second-list="secondList" :third-list="thirdList" :x-list="timeList" :key="keyTime"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import electric_home_header from "./electric_home_header";
  import chart_pie from "../../components/chart_pie";
  import chart_line_three from "../../components/chart_line_three";

  import powerHomeMap from "./powerHomeMap";
  import moment from "moment";

  export default {
    name: "electric_home",
    components: {
      electric_home_header: electric_home_header,
      chart_pie,
      chart_line_three,
      powerHomeMap
    },
    data() {
      return {
        structureInfo: null,
        statisticalList: [
          {
            img: require('../../image/electric/electric_detail_statistical_1.png'),
            name: '监测项目',
            unit: '项',
            num: 0
          },
          {
            img: require('../../image/electric/electric_detail_statistical_2.png'),
            name: '监测设备',
            unit: '个',
            num: 0
          },
          {
            img: require('../../image/electric/electric_detail_statistical_3.png'),
            name: '报警设备',
            unit: '个',
            num: 0
          },
          {
            img: require('../../image/electric/electric_detail_statistical_4.png'),
            name: '近一周报警',
            unit: '次',
            num: 0
          }
        ],
        alarmList: [
          {
            img: require('../../image/electric/electric_detail_alarm_1.png'),
            name: '今日报警',
            num: 12
          },
          {
            img: require('../../image/electric/electric_detail_alarm_2.png'),
            name: '本月报警',
            num: 23
          },
          {
            img: require('../../image/electric/electric_detail_alarm_3.png'),
            name: '报警总数',
            num: 56
          }
        ],
        statisticalTitleList: ['近七天','近半年','近一年'],
        pieData: [
          {value: 16, name: '一级报警', color: '#FFD355'},
          {value: 6, name: '二级报警', color: '#FF9A61'},
          {value: 6, name: '三级报警', color: '#FF7373'},
        ],
        alarmTypeList: [
          {
            name: '一级报警',
            num: 0,
            color: '#FFD355'
          },
          {
            name: '二级报警',
            num: 0,
            color: '#FF9A61'
          },
          {
            name: '三级报警',
            num: 0,
            color: '#FF7373'
          }
        ],

        alarmBottomTitleList: [
          {
            img: require('../../image/electric/electric_level_icon_1.png'),
            name: '一级报警'
          },
          {
            img: require('../../image/electric/electric_level_icon_2.png'),
            name: '二级报警'
          },
          {
            img: require('../../image/electric/electric_level_icon_3.png'),
            name: '三级报警'
          }
        ],
        alarmStatistics: {},
        selectIndex: 0,

        firstLevelCount: 0,
        secondLevelCount: 0,
        thirdLevelCount: 0,

        firstList: [1,2,3,4,5,6],
        secondList: [2,4,5,1,2,3],
        thirdList: [3,4,5,6,7,1],
        timeList: [],
        keyTime: new Date().getTime()
      }
    },
    computed: {
      getImageUrl() {
        return function () {
          let structureInfo = this.structureInfo;
          let imgName = '';
          if (structureInfo.STRUCTURE_IMAGE_LIST && structureInfo.STRUCTURE_IMAGE_LIST.length) {
            // let imgList = item.STRUCTURE_IMAGE_LIST.split(',');
            let imgList = structureInfo.STRUCTURE_IMAGE_LIST.map((item) => {
              return item.SAVE_NAME;
            })
            imgName = imgList[0];
          }
          console.log(this.$util.getImageUrlWithName(imgName));
          return this.$util.getImageUrlWithName(imgName);
        }
      },
      getHealthStatus() {
        return function (index) {
          let text = '基本完好';
          let color = '#80FF2C';
          switch (index) {
            case 1:
              text = '基本完好';
              color = '#80FF2C';
              break;
            case 2:
              text = '轻微异常';
              color = '#57D2FF';
              break;
            case 3:
              text = '中等异常';
              color = '#FFD355';
              break;
            case 4:
              text = '严重异常';
              color = '#FF7373';
              break;
          }
          return {
            text,
            color
          }
        }
      },
    },

    mounted() {
      this.score = this.$route.query.score;
      this.health = this.$route.query.health;
      let structureId = this.$route.query.structureId;
      if(!structureId){
        let structureItem = JSON.parse(localStorage.getItem('structureItem'));
        structureId = structureItem.STRUCTURE_ID;
      }
      this.$util.get('api/structureInfo/' + structureId, {isImage: 1, isDetail: 1}).then((res) => {
        this.structureInfo = res.content;
      });
      this.getStructureScreenStatistics();
      this.getMonitorAlarmCountStatistics();
      this.getTimeMonitorAlarmLevelStatisticsList();
    },

    methods: {
      getStructureScreenStatistics() {
        let structureId = this.$route.query.structureId;
        let p = {
          structureId: structureId
        }
        this.$util.get('api/structureScreenStatistics', p).then((res) => {
          console.log(66666666666, res);
          let item = res.content;

          this.statisticalList = [
            {
              img: require('../../image/electric/electric_detail_statistical_1.png'),
              name: '监测项目',
              unit: '项',
              num: item.MONITOR_ITEM_COUNTS ? item.MONITOR_ITEM_COUNTS : 0
            },
            {
              img: require('../../image/electric/electric_detail_statistical_2.png'),
              name: '监测设备',
              unit: '个',
              num: item.DEVICE_COUNTS ? item.DEVICE_COUNTS : 0
            },
            {
              img: require('../../image/electric/electric_detail_statistical_3.png'),
              name: '报警设备',
              unit: '个',
              num: item.ALARM_DEVICE_COUNTS ? item.ALARM_DEVICE_COUNTS : 0
            },
            {
              img: require('../../image/electric/electric_detail_statistical_4.png'),
              name: '近一周报警',
              unit: '次',
              num: item.WEEK_ALARM_COUNTS ? item.WEEK_ALARM_COUNTS : 0
            }
          ]
        });
      },

      getMonitorAlarmCountStatistics() {
        let structureId = this.$route.query.structureId;
        this.$util.get('api/monitorAlarmCountStatistics', {structureId:structureId}).then((res) => {
          this.alarmStatistics = res.content;
          console.log(555,this.alarmStatistics);
          this.alarmList = [
            {
              img: require('../../image/electric/electric_detail_alarm_1.png'),
              name: '今日报警',
              num: this.alarmStatistics.TODAY_COUNTS
            },
            {
              img: require('../../image/electric/electric_detail_alarm_2.png'),
              name: '本月报警',
              num: this.alarmStatistics.MONTH_COUNTS
            },
            {
              img: require('../../image/electric/electric_detail_alarm_3.png'),
              name: '报警总数',
              num: this.alarmStatistics.ALARM_COUNTS
            }
          ]
        })
      },

      getTimeMonitorAlarmLevelStatisticsList() {
        let startDate = '';
        let endDate = '';
        let type = 1;
        let selectIndex = this.selectIndex;
        if (selectIndex == 0) {
          startDate = moment().add(-6, 'days').format("YYYY-MM-DD");
          endDate = moment().add(0, 'days').format("YYYY-MM-DD");
          type = 1;
        } else if (selectIndex == 1) {
          startDate = moment().add(-5, 'months').startOf('month').format("YYYY-MM-DD");
          endDate = moment().add(0, 'months').endOf('month').format("YYYY-MM-DD");
          type = 2;
        } else if (selectIndex == 2) {
          startDate = moment().add(-11, 'months').startOf('month').format("YYYY-MM-DD");
          endDate = moment().add(0, 'months').endOf('month').format("YYYY-MM-DD");
          type = 2;
        }

        let timeList = [];
        if (selectIndex == 0) {
          for (let i = -6; i <= 0; i++) {
            let dd = moment().add(i, 'days').format("YYYY-MM-DD");
            let item = {
              MONITOR_ALARM_COUNTS: 0,
              ORANGE_ALARM_COUNTS: 0,
              RED_ALARM_COUNTS: 0,
              STATISTICS_TIME: dd,
              YELLOW_ALARM_COUNTS: 0
            }
            timeList.push(item);
          }
        } else if (selectIndex == 1) {
          for (let i = -5; i <= 0; i++) {
            let dd = moment().add(i, 'months').format("MM月");
            let item = {
              MONITOR_ALARM_COUNTS: 0,
              ORANGE_ALARM_COUNTS: 0,
              RED_ALARM_COUNTS: 0,
              STATISTICS_TIME: dd,
              YELLOW_ALARM_COUNTS: 0
            }
            timeList.push(item);
          }
        } else if (selectIndex == 2) {
          for (let i = -11; i <= 0; i++) {
            let dd = moment().add(i, 'months').format("MM月");
            let item = {
              MONITOR_ALARM_COUNTS: 0,
              ORANGE_ALARM_COUNTS: 0,
              RED_ALARM_COUNTS: 0,
              STATISTICS_TIME: dd,
              YELLOW_ALARM_COUNTS: 0
            }
            timeList.push(item);
          }
        }

        console.log(678, timeList);

        let structureId = this.$route.query.structureId;
        let p = {
          startDate: startDate,
          endDate: endDate,
          structureId: structureId,
          type: type
        };
        this.$util.get('api/timeMonitorAlarmLevelStatisticsList', p).then((res) => {
          let firstLevelCount = 0;
          let secondLevelCount = 0;
          let thirdLevelCount = 0;
          for (let i = 0; i < res.content.length; i++) {
            let item = res.content[i];
            firstLevelCount += item.ORANGE_ALARM_COUNTS;
            secondLevelCount += item.YELLOW_ALARM_COUNTS;
            thirdLevelCount += item.RED_ALARM_COUNTS;
          }
          this.firstLevelCount = firstLevelCount;
          this.secondLevelCount = secondLevelCount;
          this.thirdLevelCount = thirdLevelCount;
          console.log(555,this.firstLevelCount,this.secondLevelCount, this.thirdLevelCount);

          this.alarmTypeList = [
            {
              name: '一级报警',
              num: firstLevelCount,
              color: '#FFD355'
            },
            {
              name: '二级报警',
              num: secondLevelCount,
              color: '#FF9A61'
            },
            {
              name: '三级报警',
              num: thirdLevelCount,
              color: '#FF7373'
            }
          ]


          let list = timeList;
          let resList = res.content;
          for (let i = 0; i < list.length; i++) {
            let item = list[i];
            for (let j = 0; j < resList.length; j++) {
              let item2 = resList[j];
              if (item.STATISTICS_TIME == item2.STATISTICS_TIME) {
                timeList[i] = resList[j];
                break;
              }
            }
          }

          if (this.selectIndex == 0) {
            this.timeList = timeList.map((item) => {
              return moment(item.STATISTICS_TIME).format('MM-DD');
            })
          } else if (this.selectIndex == 1) {
            this.timeList = timeList.map((item) => {
              return item.STATISTICS_TIME;
            })
          } else if (this.selectIndex == 2) {
            this.timeList = timeList.map((item) => {
              return item.STATISTICS_TIME;
            })
          }

          this.firstList = timeList.map((item) => {
            return item.ORANGE_ALARM_COUNTS;
          })
          this.secondList = timeList.map((item) => {
            return item.YELLOW_ALARM_COUNTS;
          })
          this.thirdList = timeList.map((item) => {
            return item.RED_ALARM_COUNTS;
          })
          this.keyTime = new Date().getTime();

        });
      },

      clickStatisticalIndex(index) {
        this.selectIndex = index;
        this.getTimeMonitorAlarmLevelStatisticsList();
      },

      goPoint() {
        this.$router.push({path: '/electricMonitorPoint'});
      },
      markerClick(p){
        this.goPoint();
      }
    }
  }
</script>

<style scoped>
  .content {
    width: 100%;
    height: 100%;
    position: relative
  }

  .bg {
    background: url(../../image/electric/electric_bg.png) no-repeat;
    background-size: 100% 100%;
  }

  .alarm-type{
    height: 25px;
    background: linear-gradient(90deg, rgba(233, 248, 252, 0.2) 0%, rgba(207, 238, 248, 0) 100%);
  }

</style>
