<template>
  <div class="flex-column content bg" style="position: relative">
    <main-header/>
    <div class="flex-row main_box">
      <div class="left_box flex-column">
        <div class="box left_box_1">
          <div class="box_line"></div>
          <div class="flex-row-center" style="height: 56px; padding: 0 20px;">
            <img src="../../image/screen/text_icon.png" width="18">
            <div class="size-20 color-fff bold margin-left-10" style="width: 200px; line-height: 25px;">
              {{ structureInfo.STRUCTURE_NAME }}
            </div>
            <div class="flex-1"></div>
            <div class="flex-column" style="align-items: flex-end">
              <img src="../../image/screen/icon_point.png" height="3" width="17">
              <img src="../../image/screen/icon_letter.png" width="138" style="margin-top: 10px;">
            </div>
          </div>
          <div style="padding: 0 20px;">
            <img :src="getImageUrl()" style="width: 100%;" :style="'height:' + 200 * scaleX + 'px'">
            <div class="flex-row-center" style="margin-top: 15px;">
              <div class="size-20 color-fff">
                健康度：
              </div>
              <div class="size-22 bold" :style="'color:' + getHealthStatus(this.health).color">
                {{ getHealthStatus(this.health).text }}
              </div>
            </div>
            <div class="flex-row" style="margin-top: 10px;">
              <div class="flex-1 flex-column" v-for="(item, index) in leftTopList" :key="index">
                <div class="flex-row-center">
                  <div class="size-24" :style="'color:' + (index < 2 ? 'rgba(255,255,255, 1.0)':'#FF7E7E')">
                    {{ item.value }}
                  </div>
                  <div class="size-14 color-fff-50" style="margin-left: 5px;">
                    {{ item.unit }}
                  </div>
                </div>
                <div class="size-16 color-fff-70">
                  {{ item.name }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="box left_box_2 flex-column" style="height: 100%;">
          <div class="box_line"></div>
          <div class="flex-row-center" style="height: 56px; padding: 0 20px;">
            <img src="../../image/screen/text_icon.png" width="18">
            <div class="size-22 color-fff bold margin-left-10">功能列表</div>
            <div class="flex-1"></div>
            <div class="flex-column" style="align-items: flex-end">
              <img src="../../image/screen/icon_point.png" height="3" width="17">
              <img src="../../image/screen/icon_letter.png" width="138" style="margin-top: 10px;">
            </div>
          </div>
          <div class="thin_line"></div>
          <div class="flex-column" style="flex:1;">
            <div class="flex-row wrap" :style="'padding:' + 20 * scaleX + 'px;'" style="flex: 1">
              <div v-for="(item, index) in functionList" :key="index"
                   :class="index==(functionIndex-1)?'bg-fuc':'bg-fuc-un'" class=" flex-1 u-flex-col-center"
                   @click="clickFunctionIndex(index + 1)"
                   :style="{'margin-left':index%2==0?0:'15px','margin-bottom':index<2?'15px':0}">
                <div style="width: 128px; height: 145px;" class="flex-column center">
                  <img :src="item.img" style="width: 98px;">
                </div>
                <div class="size-20 color-fff">{{ item.title }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="box right_box flex-column">
        <div class="box_line"></div>
        <div class="flex-row-center" style="height: 56px; padding: 0 20px;">
          <img src="../../image/screen/text_icon.png" width="18">
          <div class="size-22 color-fff margin-left-10 cursor_pointer" @click="toHome">首页</div>
          <a-icon type="right" class="margin-left-10 " style="font-size: 16px;color: white"/>
          <div class="size-22 color-fff margin-left-10 cursor_pointer" @click="clickFunctionIndex(1)">
            {{ structureInfo.STRUCTURE_NAME }}
          </div>
          <div v-if="functionIndex>0" class="flex-row-center">
            <a-icon type="right" class="margin-left-10 " style="font-size: 16px;color: white"/>
            <div class="size-22 color-fff margin-left-10">{{ getTitleText() }}</div>
          </div>
          <div class="flex-1"></div>
          <div class="flex-row-center cursor_pointer" @click="toHome">
            <img src="../../image/screen/back_icon.png" width="20">
            <div class="size-22 color-62DBED" style="margin-left: 6px;">
              返回首页
            </div>
          </div>
        </div>
        <div class="thin_line"></div>
        <div class="flex-1" v-if="1!==structureInfo.TYPE">
          <structure_detail_right v-if="functionIndex==1" :item="structureInfo" :score="score" :health="health"
                                  :key="structureInfo.STRUCTURE_ID"/>
          <structure_monitor_map v-else-if="functionIndex==2" :index="2"/>
          <structure_monitor_alarm v-else-if="functionIndex==3"/>
          <structure_data_analysis v-else :structureId="this.$route.query.structureId" :type="this.structureInfo.TYPE"/>
        </div>


        <div class="flex-1" v-else>
          <structure_detail_right v-if="functionIndex==1" :item="structureInfo" :score="score" :health="health"
                                  :key="structureInfo.STRUCTURE_ID"/>
          <structure_monitor_map_three v-else-if="functionIndex==2" :index="3"/>
          <!--                              <structure_device_time v-else-if="functionIndex==2" :index="2"/>-->
          <structure_monitor_alarm v-else-if="functionIndex==3"/>
          <structure_data_analysis v-else :structureId="this.$route.query.structureId" :type="this.structureInfo.TYPE"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import structure_detail_right from "../../components/structure_detail_right";
import structure_monitor_map from "../../components/structure_monitor_map";
import structure_monitor_map_three from "../../components/structure_monitor_map_three";
// import threeModal1 from "../../components/threeModal1";
import structure_monitor_alarm from "../../components/structure_monitor_alarm";
import structure_data_analysis from "../../components/structure_data_analysis";
import header from './header';

export default {
  components: {
    mainHeader: header,
    structure_detail_right: structure_detail_right,
    structure_monitor_map: structure_monitor_map,
    structure_monitor_map_three: structure_monitor_map_three,
    structure_monitor_alarm: structure_monitor_alarm,
    structure_data_analysis: structure_data_analysis,
  },
  data() {
    return {
      leftTopList: [
        {
          name: '监测项目',
          unit: '项',
          value: 0
        },
        {
          name: '监测设备',
          unit: '个',
          value: 0
        },
        {
          name: '报警设备',
          unit: '个',
          value: 0
        },
        {
          name: '近一周报警',
          unit: '次',
          value: 0
        }
      ],
      functionList: [
        {
          img: require('../../image/screen/function_icon_normal_1.png'),
          title: '结构物概况'
        },
        {
          img: require('../../image/screen/function_icon_normal_2.png'),
          title: '设备状态'
        },
        {
          img: require('../../image/screen/function_icon_normal_3.png'),
          title: '监测报警'
        },
        {
          img: require('../../image/screen/function_icon_normal_4.png'),
          title: '数据分析'
        }
      ],
      functionIndex: 1,
      structureInfo: {},
      score: 0,
      health: 0,
      scaleX: document.body.clientWidth / 1920,
      scaleY: document.body.clientHeight / 1080,
      showThreeModal: false,
    }
  },

  computed: {
    getHealthStatus() {
      return function (index) {
        let text = '基本完好';
        let color = '#46FFA7';
        switch (parseInt(index)) {
          case 1:
            text = '基本完好';
            color = '#46FFA7';
            break;
          case 2:
            text = '轻微异常';
            color = '#60E0FF';
            break;
          case 3:
            text = '中等异常';
            color = '#FFE20E';
            break;
          case 4:
            text = '严重异常';
            color = '#FF6761';
            break;
        }
        return {
          text,
          color
        }
      }
    },

    getTitleText() {
      return function () {
        switch (this.functionIndex) {
          case 1:
            return '结构物概况';
          case 2:
            return '设备状态';
          case 3:
            return '监测预警';
          case 4:
            return '数据分析';
        }
      }
    },
    getColorBg() {
      return function (index) {
        if (this.functionIndex == index + 1) {
          return 'rgba(62, 111, 158, 0.3)';
        } else {
          return 'transparent';
        }
      }
    },

    getColorBoder() {
      return function (index) {
        if (this.functionIndex == index + 1) {
          return 'rgb(67,139, 180)';
        } else {
          return 'transparent';
        }
      }
    },

    getImageUrl() {
      return function () {
        let structureInfo = this.structureInfo;
        let imgName = '';
        if (structureInfo.STRUCTURE_IMAGE_LIST && structureInfo.STRUCTURE_IMAGE_LIST.length) {
          // let imgList = item.STRUCTURE_IMAGE_LIST.split(',');
          let imgList = structureInfo.STRUCTURE_IMAGE_LIST.map((item) => {
            return item.SAVE_NAME;
          })
          imgName = imgList[0];
        }
        console.log(this.$util.getImageUrlWithName(imgName));
        return this.$util.getImageUrlWithName(imgName);
      }
    }
  },

  mounted() {
    this.score = this.$route.query.score;
    this.health = this.$route.query.health;
    this.getStructureInfo();
    this.getStructureScreenStatistics();
  },

  methods: {
    getStructureInfo() {
      let structureId = this.$route.query.structureId;
      this.$util.get('api/structureInfo/' + structureId, {isImage: 1, isDetail: 1}).then((res) => {
        console.log(222, res);
        this.structureInfo = res.content;
      });
    },

    getStructureScreenStatistics() {
      let structureId = this.$route.query.structureId;
      let p = {
        structureId: structureId
      }
      this.$util.get('api/structureScreenStatistics', p).then((res) => {
        console.log(66666666666, res);
        let item = res.content;
        this.leftTopList = [
          {
            name: '监测项目',
            unit: '项',
            value: item.MONITOR_ITEM_COUNTS ? item.MONITOR_ITEM_COUNTS : 0
          },
          {
            name: '监测设备',
            unit: '个',
            value: item.DEVICE_COUNTS ? item.DEVICE_COUNTS : 0
          },
          {
            name: '报警设备',
            unit: '个',
            value: item.ALARM_DEVICE_COUNTS ? item.ALARM_DEVICE_COUNTS : 0
          },
          {
            name: '近一周报警',
            unit: '次',
            value: item.WEEK_ALARM_COUNTS ? item.WEEK_ALARM_COUNTS : 0
          }
        ]
      });
    },

    clickFunctionIndex(index) {
      console.log(index);
      this.functionIndex = index;
    },
    toHome() {
      this.$router.back();
    }
  },
}
</script>

<style>
.content {
  width: 100%;
  height: 100%;
  position: relative;
}

.bg-fuc {
  background: url(../../image/screen/function_icon_bg.png) no-repeat;
  background-size: 100% 100%;
}

.bg-fuc-un {
  background: url(../../image/screen/function_icon_bg_un.png) no-repeat;
  background-size: 100% 100%;
  opacity: 0.8;
}

.bg {
  background: url(../../image/screen/icon_bg.png) no-repeat;
  background-size: 100% 100%;
}

.main_box {
  height: calc(100% - 150px);
}

.box {
  background: linear-gradient(180deg, rgba(3, 34, 64, 0.5) 0%, rgba(3, 34, 64, 0.3) 100%);
  border-radius: 1px;
}

.box_line {
  height: 2px;
  width: 100%;
  background: #62DBED;
}

.left_box {
  width: 410px;
  margin-left: 30px;
  margin-top: 30px;
  height: 100%;
}

.left_box_1 {
  /*height: 415px;*/
  flex: 415;
  margin-bottom: 15px;
}

.left_box_2 {
  flex: 510;
  /*height: 510px;*/
}

.thin_line {
  background: rgba(216, 216, 216, 0.3);
  height: 0.5px;
}

.right_box {
  /*width: 1430px;*/
  flex: 1;
  margin-left: 20px;
  margin-top: 30px;
  margin-right: 30px;
  margin-bottom: -30px;
}

.drop .ant-input {
  background: transparent;
  border: 1px solid rgba(255, 255, 255, 0.5);
  color: #ffffff;
}

.drop1 .ant-select-selection {
  background-color: rgba(3, 34, 64, 0.7);
  color: #ffffff;
  height: 45px;
  font-size: 16px;
}

.drop1 .ant-select-arrow {
  color: #ffffff;
}

.drop1 .ant-select-selection-selected-value {
  margin-top: 5px;
}

.drop2 .ant-select-selection {
  /*background-color: #3E6F9E;*/
  background: transparent;
  color: #ffffff;
  height: 60px;
  font-size: 22px;
  padding: 10px;
  /*border: none;*/
  border: 1px solid transparent;
  box-shadow: none;
}

.drop2 .ant-select-arrow {
  color: #ffffff;
}

/*.drop2 .ant-select-arrow {*/
/*  color: #ffffff;*/
/*  width: 10px;*/
/*  height: 10px;*/
/*  border-left: 5px solid white;*/
/*  border-bottom: 5px solid white;*/
/*  border-top: 5px solid transparent;*/
/*  border-right: 5px solid transparent;*/
/*  transform: rotate(-45deg);*/
/*  transition: transform 0.3s;*/
/*}*/
/*.arrowrotate .ant-select-arrow {*/
/*  transform: rotate(135deg);*/
/*  transition: transform 0.3s;*/
/*  transform-origin:40% 90%;*/
/*}*/
/*.drop2 .ant-select-arrow .ant-select-arrow-icon{*/
/*  display: none;*/
/*}*/

.drop2 .ant-select-arrow-icon {
  transform: rotate(-45deg);
}

.drop2 .ant-select-arrow svg {
  width: 10px;
  height: 10px;
  border-left: 5px solid white;
  border-bottom: 5px solid white;
  border-top: 5px solid transparent;
  border-right: 5px solid transparent;
  transform-origin: 25% 70%;
}

.drop2 .ant-select-selection-selected-value {
  margin-top: 5px;
}

.drop2 .ant-input {
  background: red;
  border: 1px solid rgba(255, 255, 255, 0.5);
}

.drop .ant-calendar-picker-icon {
  color: #ffffff;
}

.dropdownStyle .ant-select-dropdown-menu {
  background: rgba(41, 65, 105, 1);
  border: solid 1px #32323a;
}

.dropdownStyle .ant-select-dropdown-menu-item {
  color: #fff !important;
}

.dropdownStyle .ant-select-dropdown-menu-item:hover {
  background: rgba(41, 65, 105, 1);
}

.dropdownStyle .ant-select-dropdown-menu-item-selected {
  background: rgba(41, 65, 105, 1);
}

.dropdownStyle .ant-select-dropdown-menu-item-active {
  background: rgba(41, 65, 105, 1);
}

.dropdownStyle2 .ant-select-dropdown-menu {
  /*background: red;*/
  border: 1px solid rgb(41, 65, 105) !important;
}

.dropdownStyle2 .ant-select-dropdown-menu-item {
  height: 45px;
  font-size: 20px;
  padding: 10px 20px;
}

.dropdownStyle2 .ant-select-dropdown-menu-item-active {
  height: 45px;
  font-size: 20px;
  font-weight: normal;
}


.dropdownStyle3 .ant-select-dropdown-menu {
  background: rgba(41, 65, 105, 1);
  border: solid 1px #32323a;
}

.dropdownStyle3 .ant-select-dropdown-menu-item {
  color: #fff !important;
}

.dropdownStyle3 .ant-select-dropdown-menu-item:hover {
  background: rgba(41, 65, 105, 1);
}

.dropdownStyle3 .ant-select-dropdown-menu-item-selected {
  background: rgba(41, 65, 105, 1);
}

.dropdownStyle3 .ant-select-dropdown-menu-item-active {
  background: rgba(41, 65, 105, 1);
}

.drop4 .ant-select-selection {
  background-color: #fff;
  color: #ffffff;
  height: 45px;
  font-size: 16px;
  border-color: rgba(0, 0, 0, 0.15);
}

.drop4 .ant-select-arrow {
  color: rgba(0, 0, 0, 0.15);
}

.drop4 .ant-select-selection-selected-value {
  margin-top: 5px;
}

::-webkit-scrollbar {
  display: none;
}


</style>
