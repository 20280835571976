<template>
    <div style="padding: 20px;">
        <div style="background: #fff; padding: 20px 30px 20px;">
            <a-row>
                <a-col :span="8">
                    <div class="flex-row-center" style="padding-right: 30px;">
                        <div class="size-14 color-000-85" style="min-width: 60px;">文件名：</div>
<!--                        <a-input placeholder="名称/编码" class="flex-1 color-333"/>-->
                        <a-input-search placeholder="名称" @search="onSearch" v-model="keyword" :allowClear="true"/>
                    </div>
                </a-col>
                <a-col :span="8">
                    <div class="flex-row-center" style="padding-right: 30px;">
                        <div class="size-14 color-000-85">结构物名称：</div>
                        <a-select @change="structureChange" placeholder="请选择" :allowClear="true" v-model="structureId" class="filter-common">
                            <template v-for="item in structureList">
                                <a-select-option :key="item.STRUCTURE_ID" :value="item.STRUCTURE_ID">
                                    {{ item.STRUCTURE_NAME }}
                                </a-select-option>
                            </template>
                        </a-select>
                    </div>
                </a-col>

                <a-col :span="7">
                    <div>
                        <a-button type="primary" @click="onSearch()">查询</a-button>
                        <a-button style="margin-left: 10px;" @click="reset()">重置</a-button>
                    </div>
                </a-col>
            </a-row>

            <div style="margin-top: 40px;">
                <a-button type="dashed" block style="margin-bottom: 20px;" @click="add()">
                    <a-icon type="plus" />
                    添加
                </a-button>
                <a-list :data-source="data" :pagination="paginationOpt">
                    <a-list-item slot="renderItem" slot-scope="item,index">
                        <a-row type="flex" justify="space-between" align="middle" class="flex-1">
                            <a-col style="margin-left: 10px;">
                                <div class="icon_img flex-row center" :style="'background:' + (index%2 == 0 ? '#597EF7' : '#36CFC9')">
                                    <div class="size-20 color-fff" v-if="item.FILE_NAME">
                                        {{getIconText(item.FILE_NAME)}}
                                    </div>
                                </div>
                            </a-col>
                            <a-col :span="5">
                                <div class="flex-column">
                                    <div class="size-14 color-000-65">{{item.REPORT_NAME}}</div>
                                    <div class="size-14 color-000-45">{{item.FILE_LENGTH}}kb</div>
                                </div>
                            </a-col>
                            <a-col :span="4">
                                <div class="flex-column">
                                    <div class="size-14 color-000-45">评估结构物</div>
                                    <div class="size-14 color-000-45">{{item.STRUCTURE_NAME}}</div>
                                </div>
                            </a-col>
                            <a-col :span="4">
                                <div class="flex-column">
                                    <div class="size-14 color-000-45">上传人</div>
                                    <div class="size-14 color-000-45">{{item.USER_NAME}}</div>
                                </div>
                            </a-col>
                            <a-col :span="4">
                                <div class="flex-column">
                                    <div class="size-14 color-000-45">评估时间</div>
                                    <div class="size-14 color-000-45">{{getDate(item.EVALUATE_DATES)}}</div>
                                </div>
                            </a-col>
                            <a-col :span="3" style="margin-right: 20px;">
                                <div class="flex-row-center" style="justify-content: flex-end">
                                    <a @click="fileClick(item)">查看</a>
                                    <a-divider type="vertical"/>
                                    <a-popconfirm
                                            title="确定要删除吗?"
                                            ok-text="确定"
                                            cancel-text="取消"
                                            @confirm="toDelete(item)"
                                    >
                                        <a>删除</a>
                                    </a-popconfirm>
                                </div>
                            </a-col>
                        </a-row>
                    </a-list-item>
                </a-list>
            </div>
        </div>
    </div>
</template>

<script>
  import moment from 'moment';
  export default {
    name: "culvertList",
    data() {
      let self = this;
      return {
        paginationOpt: this.$util.getTableParams((current, pageSize) => {
          self.paginationOpt.current = 1;
          self.paginationOpt.defaultPageSize = pageSize;
          self.getList()
        }, (current, size) => {
          self.paginationOpt.current = current
          self.paginationOpt.defaultPageSize = size
          self.getList()
        }),
        data: [],
        keyword: '',
        structureList: [],
        structureId: undefined
      }
    },

    mounted() {
      this.getStructureList();
      this.getList();
    },

    computed: {
      getIconText() {
        return function (fileName) {
          return this.$util.getFileSuffix(fileName);
        }
      },
      getDate() {
        return function (date) {
            return moment(date).format('YYYY-MM-DD')
        }
      }
    },
    methods: {
      getStructureList() {
        let self = this;
        let p = {
          page: 1,
          pageSize: 1000,
        }
        this.$util.showLoading(self);
        this.$util.get('api/structureList', p).then((res) => {
          this.$util.hideLoading();
          let bean = res.content;
          self.structureList = bean.list;
        })
      },

      getList() {
        let self = this;
        let params = this.paginationOpt;
        // if (this.data && this.data.length === 0)
        //   this.data = null;
        let p = {
          page: params.current,
          pageSize: params.defaultPageSize,
          keyword: this.keyword,
        }
        if (this.structureId) {
          p.structureId = this.structureId;
        }

        this.$util.showLoading(self);
        this.$util.get('api/assessReportList', p).then((res) => {
          let bean = res.content;
          self.paginationOpt.total = bean.count;
          self.data = bean.list;
          console.log(333,self.data);
          this.$util.hideLoading();
        })
      },

      onSearch(value) {
        this.paginationOpt.current = 1;
        if (value)
          this.keyword = value;
        this.getList();
      },
      query() {
        this.getList();
      },
      reset() {
        this.paginationOpt.current = 1;
        this.keyword = '';
        this.structureId = undefined;
        this.getList();
      },
      structureChange(value) {
        this.structureId = value;
        this.paginationOpt.current = 1;
        this.getList();
      },
      fileClick(item) {

        window.open(this.$util.getServeImageUrl({file: true}) + item.SAVE_NAME, '_blank');
      },
      add() {
        this.$router.push('/addAssessReport')
      },
      toDelete(item) {
        this.$util.showLoading(this);
        this.$util.post('api/deleteAssessReport/'+item.REPORT_ID, {}, true).then((res) => {
          this.$util.hideLoading();
          this.$message.success('删除成功');
          this.getList();
        })
      },

    }
  }
</script>

<style scoped>
    .icon_img {
        width: 48px;
        height: 48px;
        border-radius: 4px;
    }
</style>

