<template>
    <div style="padding: 20px;">
        <div style="background: #fff; padding: 20px 30px 20px;">
            <a-row>
                <a-col :span="8">
                    <div class="flex-row-center" style="padding-right: 30px;">
                        <div class="size-14 color-000-85">健康度：</div>
                        <a-select @change="healthChange" placeholder="请选择" :allowClear="true" v-model="health" class="filter-common">
                            <template v-for="item in healthList">
                                <a-select-option :key="item.value" :value="item.value">
                                    {{ item.name }}
                                </a-select-option>
                            </template>
                        </a-select>
                    </div>
                </a-col>
                <a-col :span="8">
                    <div class="flex-row-center" style="padding-right: 30px;">
                        <div class="size-14 color-000-85">结构物：</div>
                        <a-select @change="structureChange" placeholder="请选择" :allowClear="true" v-model="structureId" class="filter-common">
                            <template v-for="item in structureList">
                                <a-select-option :key="item.STRUCTURE_ID" :value="item.STRUCTURE_ID">
                                    {{ item.STRUCTURE_NAME }}
                                </a-select-option>
                            </template>
                        </a-select>
                    </div>
                </a-col>

                <a-col :span="7">
                    <div>
                        <a-button type="primary" @click="onSearch()">查询</a-button>
                        <a-button style="margin-left: 10px;" @click="reset()">重置</a-button>
                    </div>
                </a-col>
            </a-row>

            <div style="margin-top: 20px;">
                <a-button type="primary" icon="plus" @click="add()">新增</a-button>
            </div>
        </div>

        <div style="margin-top: 10px;">
            <a-list :data-source="data" :split="false" :pagination="paginationOpt">
                <a-list-item slot="renderItem" slot-scope="item,index">
                    <div class="flex-row flex-1" style="background: #fff; padding: 15px;">
                        <img :src="getImageUrl(item)" style="width: 200px;height: 140px">
                        <div style="margin-left: 15px;" class="flex-column flex-1">
                            <div class="flex-row-center">
                                <div class="size-16 color-000-85 bold" style="margin-right: 8px;">
                                    {{item.STRUCTURE_NAME}}
                                </div>
                                <div class="size-12" style="padding: 1px 6px; border: 1px solid #fff; border-radius: 4px;"
                                     :style="'color:' + getHealth(item.HEALTH).color + ';' +
                                 'background:' + getHealth(item.HEALTH).bgColor + ';' +
                                 'borderColor:' + getHealth(item.HEALTH).borderColor">
                                    {{getHealth(item.HEALTH).name}}
                                </div>
                                <div class="flex-1">
                                </div>
                                <div>
                                    <icon-font type="icon-edit" @click="toEdit(index)"/>
                                    <a-divider type="vertical"/>
                                    <a-popconfirm
                                            title="确定要删除吗?"
                                            ok-text="确定"
                                            cancel-text="取消"
                                            @confirm="toDelete(index)"
                                    >
                                        <icon-font type="icon-delete" class="cursor_pointer"/>
                                    </a-popconfirm>
                                    <a-divider type="vertical"/>
                                    <icon-font type="icon-info" @click="goDetail(index)"/>
                                </div>
                            </div>
                            <div class="flex-row-center" style="margin-top: 5px;">
                                <div class="size-14 color-000-85">
                                    评估时间：
                                </div>
                                <div class="size-14 color-000-65">
                                    {{getDate(item.EVALUATE_DATES)}}
                                </div>
                            </div>
                            <div class="flex-row" style="margin-top: 15px; margin-bottom: 10px;">
                                <div v-for="(item2, index2) in alarmTitleList" :key="index2" class="flex-row-center" style="margin-right: 80px;">
                                    <div class="flex-column">
                                        <div class="color-000-45 size-14">{{item2}}</div>
                                        <div class="color-000-85 size-24" style="margin-top: 5px;">{{getPercent(item, index2).level}}次</div>
                                    </div>
                                    <div style="margin-left: 20px;">
                                        <a-progress type="circle" :percent="getPercent(item, index2).value" :width="60" :strokeWidth="20"
                                                    :showInfo="false" :strokeColor="getPercent(item, index2).color"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </a-list-item>
            </a-list>
        </div>
    </div>
</template>

<script>
  import moment from 'moment';
  export default {
    name: "culvertList",
    data() {
      let self = this;
      return {
        paginationOpt: this.$util.getTableParams((current, pageSize) => {
          self.paginationOpt.current = 1;
          self.paginationOpt.defaultPageSize = pageSize;
          self.getList()
        }, (current, size) => {
          self.paginationOpt.current = current
          self.paginationOpt.defaultPageSize = size
          self.getList()
        }),
        data: [],
        keyword: '',
        structureList: [],
        structureId: undefined,
        alarmTitleList: ['超限一级报警','超限二级报警','超限三级报警'],
        healthList: [
          {
            name: '基本完好',
            value: 1
          },
          {
            name: '轻度异常',
            value: 2
          },
          {
            name: '中等异常',
            value: 3
          },
          {
            name: '严重异常',
            value: 4
          }
        ],
        health: undefined
      }
    },

    mounted() {
      this.getStructureList();
      this.getList();
    },

    computed: {
      getImageUrl() {
        return function (item) {
          let imgName = '';
          if (item.SAVE_NAME_LIST && item.SAVE_NAME_LIST.length) {
            let imgList = item.SAVE_NAME_LIST.split(',');
            imgName = imgList[0];
          }
          console.log(this.$util.getImageUrlWithName(imgName));
          return this.$util.getImageUrlWithName(imgName);
        }
      },

      getHealth() {
        return function (health) {
            let name = '基本完好';
            let color = '#1890FF';
            let bgColor = '#E6F7FF';
            let borderColor = '#91D5FF';
            if (health == 1) {
              name = '基本完好';
              color = '#1890FF';
              bgColor = '#E6F7FF';
              borderColor = '#91D5FF';
            }
            else if (health == 2) {
              name = '轻微异常';
              color = '#FFAF00';
              bgColor = '#FFFBE6';
              borderColor = '#FFE58F';
            }
            else if (health == 3) {
              name = '中等异常';
              color = '#FA541C';
              bgColor = '#FFF2E8';
              borderColor = '#FFBB96';
            }

            else if (health == 4) {
              name = '严重异常';
              color = '#F5222D';
              bgColor = '#FFF1F0';
              borderColor = '#FFA39E';
            }
            return {
              name,
              color,
              bgColor,
              borderColor
            }
        }
      },

      getDate() {
        return function (date) {
          return moment(date).format('YYYY-MM-DD')
        }
      },

      getPercent() {
        return function(item,index) {
          let level = 0;
          let value = 0;
          let total = item.alarmTotal;
          let color = 'rgba(255, 175, 0, 0.85)';
          if (total > 0) {
            if (index == 0) {
              level = (item.ALARM_LEVEL_1 ? item.ALARM_LEVEL_1 : 0);
              color = 'rgba(255, 175, 0, 0.85)';
              value = (item.ALARM_LEVEL_1 ? item.ALARM_LEVEL_1 : 0) / total * 100;
            }
            else if (index == 1) {
              level = (item.ALARM_LEVEL_2 ? item.ALARM_LEVEL_2 : 0);
              color = 'rgba(250, 84, 28, 0.85)';
              value = (item.ALARM_LEVEL_2 ? item.ALARM_LEVEL_2 : 0) / total * 100;
            }
            else if (index == 2) {
              level = (item.ALARM_LEVEL_3 ? item.ALARM_LEVEL_3 : 0);
              color = 'rgba(245, 34, 45, 0.85)';
              value = (item.ALARM_LEVEL_3 ? item.ALARM_LEVEL_3 : 0) / total * 100;
            }
          }
          return {
            level,
            color,
            value
          };
        }
      },
    },
    methods: {
      getStructureList() {
        let self = this;
        let p = {
          page: 1,
          pageSize: 1000,
        }
        this.$util.showLoading(self);
        this.$util.get('api/structureList', p).then((res) => {
          this.$util.hideLoading();
          let bean = res.content;
          self.structureList = bean.list;
        })
      },

      getList() {
        let self = this;
        let params = this.paginationOpt;
        let p = {
          page: params.current,
          pageSize: params.defaultPageSize,
        }
        if (this.structureId) {
          p.structureId = this.structureId;
        }
        if (this.health) {
          p.health = this.health;
        }

        this.$util.showLoading(self);
        this.$util.get('api/healthList', p).then((res) => {
          let bean = res.content;
          self.paginationOpt.total = bean.count;
          let list = bean.list;
          for (let i = 0; i < list.length; i++) {
            let item = list[i];
            item.alarmTotal = (item.ALARM_LEVEL_1 ? item.ALARM_LEVEL_1 : 0) +
              (item.ALARM_LEVEL_2 ? item.ALARM_LEVEL_2 : 0) + (item.ALARM_LEVEL_3 ? item.ALARM_LEVEL_3 : 0);
          }
          self.data = list;
          console.log(333,self.data);
          this.$util.hideLoading();
        })
      },

      onSearch(value) {
        this.paginationOpt.current = 1;
        if (value)
          this.keyword = value;
        this.getList();
      },
      query() {
        this.getList();
      },
      reset() {
        this.paginationOpt.current = 1;
        this.health = undefined;
        this.structureId = undefined;
        this.getList();
      },
      structureChange(value) {
        this.structureId = value;
        this.paginationOpt.current = 1;
        this.getList();
      },
      healthChange(value) {
        this.health = value;
        this.paginationOpt.current = 1;
        this.getList();
      },
      add() {
        this.$router.push('/addHealth')
      },
      toEdit(index) {

        this.$router.push({path: '/addHealth', query: {healthId: this.data[index].HEALTH_ID}})
      },
      goDetail(index) {
        console.log(123,index);
        this.$router.push('/healthInfo?healthId='+this.data[index].HEALTH_ID)
      },
      toDelete(index) {
        let healthId = this.data[index].HEALTH_ID;
        this.$util.showLoading(this);
        this.$util.post('api/deleteHealth/' + healthId, {}, true).then((res) => {
          this.$util.hideLoading();
          this.$message.success('删除成功');
          this.getList();
        })
      },

    }
  }
</script>

<style scoped>
    .icon_img {
        width: 48px;
        height: 48px;
        border-radius: 4px;
    }
</style>

