<template>
  <div style="height: 100%;width: 100%;position: relative">
    <div id="container" style="width:100%;height: 100%;" class="flex-1"></div>
    <div class="bottom_box" :style="{'left':structureInfo?'50px':'500px'}">
      <div class="size-14 color-fff" style="padding: 10px 0 0 20px;">图例</div>
      <div class="flex-row wrap" style="padding-left: 10px;">
        <div v-for="(item, index) in bottomList" :key="index">
          <div class="flex-row-center" style="width: 120px; margin-top: 10px;">
            <img :src="item.img" width="23">
            <div class="size-14 color-fff" style="margin-left: 10px">{{ item.title }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import AMap from "AMap";
import Vue from "vue";
import structure_marker from "../../components/structure_marker";
import structure_marker2 from "../../components/structure_marker2";
let map = null;
export default {
  name: "电化学首页地图",
  props:{
    structureInfo:Object
  },
  data(){
    return {
      bottomList: [
        {
          img: require('../../image/screen/home_bottom_1.png'),
          title: '敦当高速'
        },
        {
          img: require('../../image/screen/home_bottom_2.png'),
          title: '桥梁'
        },
        {
          img: require('../../image/screen/home_bottom_3.png'),
          title: '起点'
        },
        {
          img: require('../../image/screen/home_bottom_4.png'),
          title: '隧道'
        },
        {
          img: require('../../image/screen/home_bottom_5.png'),
          title: '终点'
        },
        {
          img: require('../../image/screen/home_bottom_6.png'),
          title: '边坡'
        }
      ],
      markers: [],
    }
  },
  mounted() {
    this.initMap(() => {
      let userStr = localStorage.getItem('userInfo');
      let user = JSON.parse(userStr);
      let weight = 4,opacity = 0.6,fillColor = '#1D3D5A',lineColor = '#68A8DE';
      if(user.PROJECT_ID==10){
        this.$util.get('api/projectInfo/' + user.PROJECT_ID, {}, true).then((res) => {
          this.drawRegionalBoundary(res.content.AD_CODE,weight, opacity, fillColor, lineColor, this.structureInfo?false:true);
        })
      }else{
        this.drawRegionalBoundary('620902000000',weight, opacity, fillColor, lineColor, false);
        this.drawRegionalBoundary('620921000000',weight, opacity, fillColor, lineColor, false);
        this.drawRegionalBoundary('620922000000',weight, opacity, fillColor, lineColor, false);
        this.drawRegionalBoundary('620923000000',weight, opacity, fillColor, lineColor, false);
        this.drawRegionalBoundary('620924000000',weight, opacity, fillColor, lineColor, false);
        this.drawRegionalBoundary('620981000000',weight, opacity, fillColor, lineColor, false);
        this.drawRegionalBoundary('620982000000',weight, opacity, fillColor, lineColor, false);
      }

      this.getStructureList();
      if(this.structureInfo){
        this.openWindow(this.structureInfo, -5, -50);
      }
    });
  },
  methods:{
    setDeviceValue(type, item) {
      let list = [];
      switch (type) {
        case 1://桥
          list = this.listQiaoliang;
          break;
        case 2://隧道
          list = this.listSuidao;
          break;
        case 3://边坡
          list = this.listBianpo;
          break;
      }
      for (let i = 0; i < list.length; i++) {
        let dao = list[i];
        console.log(type, item, dao);
        if (dao.deviceType == item.DEVICE_TYPE) {
          dao.value = item.DEVICE_COUNTS;
          dao.online = item.ONLINE_COUNTS;
          break;
        }
      }
      switch (type) {
        case 1://桥
          this.listQiaoliang = list;
          break;
        case 2://隧道
          this.listSuidao = list;
          break;
        case 3://边坡
          this.listBianpo = list;
          break;
      }

    },

    initMap(callback) {
      let needDetailLocation = this.structureInfo&&this.structureInfo.LAT&&this.structureInfo.LNG&&this.structureInfo.LAT>0;

      let userStr = localStorage.getItem('userInfo');
      let user = JSON.parse(userStr);
      let weight = 4,opacity = 0.6,fillColor = '#1D3D5A',lineColor = '#68A8DE';
      let ll = [96.115093,40.56802];
      if(user.PROJECT_ID==10){
        ll = [123.464675,41.677576]
      }

      map = new AMap.Map('container', {
        center: needDetailLocation?[this.structureInfo.LNG,this.structureInfo.LAT]:ll,
        resizeEnable: true,
        zoom: needDetailLocation?18:8,
        mapStyle: 'amap://styles/c1e60d719f40ead0a98e061e50cdcb2a'//样式URL
      });
      AMap.plugin(['AMap.ToolBar', 'AMap.Scale', 'AMap.OverView', 'AMap.Geolocation', 'AMap.DistrictSearch', 'AMap.InfoWindow', 'AMap.Polyline'], function () {//异步加载插件
        callback();
      });

    },
    getStructureList() {
      let p = {
        isScore: 1,
        isImage: 1
      }
      let self = this;
      this.$util.get('api/structureList', p).then((res) => {
        this.structureList = res.content.list;
        let markers = [];
        if (this.markers.length > 0) {
          map.remove(this.markers);
        }
        for (let i = 0; i < this.structureList.length; i++) {
          let item = this.structureList[i];
          let lng = item.LNG;
          let lat = item.LAT;
          if (null == lng || undefined == lng || null == lat || undefined == lat) {
            continue;
          }
          let icon = require('../../image/screen/home_icon_bridge.png');
          switch (item.TYPE) {
            case 1:
              icon = require('../../image/screen/home_icon_bridge.png');
              break;
            case 2:
              icon = require('../../image/screen/home_icon_tunnel.png');
              break;
            case 3:
              icon = require('../../image/screen/home_icon_slope.png');
              break;
          }
          let marker = new AMap.Marker({
            position: new AMap.LngLat(lng, lat),
            icon: new AMap.Icon({
              image: icon,
              size: new AMap.Size(36, 40),  //图标所处区域大小
              imageSize: new AMap.Size(36, 40) //图标大小
            }),
            anchor: 'bottom-center',
            offset: new AMap.Pixel(0, 0),
          });
          marker.item = item;
          if(!this.structureInfo){
            marker.on('click', function (e) {
              self.openWindow(e.target.item, -5, -50);
              console.log(e.target)
            });
          }

          if (i == 1) {
            map.add(marker);
          } else {
            markers.push(marker);
          }
        }
        self.markers = markers;
        map.add(this.markers);
        // map.setFitView(this.markers);
        self.$util.getDundangLine(map);
      });
    },
    drawRegionalBoundary(adCode,strokeWeight, fillOpacity, fillColor, strokeColor, isFitView) {
      let projectAdCode = adCode;
      let centerMap = this.$util.getMapCenterCodeMap(projectAdCode);

      if (centerMap.centerCode != "") {
        //实例化DistrictSearch
        let opts = {
          subdistrict: 0,   //获取边界不需要返回下级行政区
          extensions: 'all',  //返回行政区边界坐标组等具体信息
          level: centerMap.level  //查询行政级别为 市
        };
        let district = new AMap.DistrictSearch(opts);

        district.search(centerMap.centerCode, function (status, result) {
          let polygons = [];
          let bounds = result.districtList[0].boundaries;
          if (bounds) {
            for (let i = 0, l = bounds.length; i < l; i++) {
              //生成行政区划polygon
              let polygon = new AMap.Polygon({
                strokeWeight: strokeWeight,
                path: bounds[i],
                fillOpacity: fillOpacity,
                fillColor: fillColor,
                strokeColor: strokeColor,
                zIndex: -1
              });
              polygons.push(polygon);
            }
          }
          map.add(polygons);
          if (isFitView) {
            map.setFitView(polygons);  //视口自适应
          }
        });
      }
    },
    openWindow(item, left = 4.5, top = -60) {
      let self = this;
      let lnglat = [item.LNG, item.LAT];
      let Content = Vue.extend({
        template: `
          <div>
            <structure_marker :bean="bean" @closeInfoWindow="closeInfoWindow" @clickMarker="clickMarker" v-if="!structureInfo"/>
            <structure_marker2 :bean="bean" @closeInfoWindow="closeInfoWindow" @clickMarker="clickMarker" v-else/>
          </div>`,
        components: {
          'structure_marker': structure_marker,
          'structure_marker2': structure_marker2
        },
        data() {
          return {
            bean: item,
            structureInfo:self.structureInfo
          }
        }, methods: {
          closeInfoWindow() {
            map.clearInfoWindow();
          },
          clickMarker(){
            let bean = this.bean;
            self.$emit('markerClick',{data:bean});
          }
        }
      });

      let component = new Content().$mount();

      let infoWindow = new AMap.InfoWindow({
        isCustom: true, //使用自定义窗体
        content: component.$el,
        offset: new AMap.Pixel(left, top)
      });

      infoWindow.open(map, lnglat);
    },
  }
}
</script>

<style scoped>
.bottom_box {
  width: 252px;
  height: 155px;
  background: rgba(205, 205, 205, 0.3);
  backdrop-filter: blur(10px);
  position: absolute;
  bottom: 40px;
  left: 530px;
  border: 1px solid rgba(255, 255, 255, 0.8);
}
</style>
