<template>
    <div style="width: 100%;height: 100%;">
        <v-chart class="my-chart" :options="structureLineChart"/>
    </div>
</template>

<script>
  import {graphic} from "echarts/lib/export/api";
  import moment from 'moment';
  export default {
    props: {
      array_durable: {
        type: Array,
      },isToday:{
        type:Boolean,
        default:false
      }
    },
    data() {
      return {
        structureLineChart: {},
        vList: []
      }
    },
    mounted() {

      this.vList = this.array_durable.map((item) => {
        return item.avg_openv;
      })
      this.getChartData();
    },
    methods: {
      getChartData() {
        let self = this;
        let dates = [];
        for (let i = 0; i <this.array_durable.length; i++) {
          let dd = moment(this.array_durable[i]._id).format(this.isToday?'HH:00':'MM-DD');
          dates.push(dd);
        }

        this.structureLineChart = {
          tooltip: {
            trigger: 'axis',
            axisPointer: {            // 坐标轴指示器，坐标轴触发有效
              // type: 'none'        // 默认为直线，可选为：'line' | 'shadow' | 'none'
            },
            backgroundColor: 'rgba(55,66,97,0.8)',
            borderWidth: 0,
            textStyle: {
              color: '#fff',

            },
            formatter: function (params) {
              console.log(333,params);

              let tar0 = params[0];

              let dd = `<div style="padding: 5px 10px;">
                    <div class="flex-row-center" style="margin-top: 2px;">
                        <div class="size-16 color-fff-80" style="margin-left: 0px;">` + tar0.name + `</div>
                    </div>
                    <div class="flex-row-center" style="margin-top: 10px;">
<!--                        <div style="width: 10px; height: 10px; background: ` + `#fff` + `; border-radius: 50%"></div>-->
                        <div class="size-16 color-fff-80" style="margin-left: 0px;">电压</div>
                        <div class="size-18 color-fff margin-left-8">` + parseFloat(tar0.value.toFixed(4)) + ` V` + `</div>
                    </div>
                </div>`;
              return dd;
            }
          },
          grid: {
            left: '2%',
            right: '1%',
            bottom: '2%',
            top: '5%',
            containLabel: true
          },
          xAxis: [{
            type: "category",
            data: dates,
            axisLine: {
              show: false
            },
            axisLabel: {
              color: "#A0CAFF",
              fontSize: 12
            },
            axisTick: {
              show: false
            }
          }],

          yAxis: [{
            type: "value",
            axisLabel: {
              color: "#A0CAFF",
              // fontSize: self.isSmall?12:18
            },
            splitLine: {
              lineStyle: {
                // 使用深浅的间隔色
                color: ['rgba(255,255,255,0.15)']
              }
            }
          }],

          series: [
            {
              type: 'line',
              // z: 10,
              // areaStyle: { //渐变色
              //   normal: {
              //     color: new graphic.LinearGradient(0, 0, 0, 1,
              //       [{offset: 0, color: 'rgba(255,255,255,0.3)'},
              //         {offset: 0.5, color: 'rgba(255,255,255,0.1)'},
              //         {offset: 1, color: 'rgba(255,255,255,0.01)'}])
              //   }
              // },
              itemStyle: {
                normal: {
                  label: {
                    show: true, //开启显示
                    position: 'top', //在上方显示
                    textStyle: { //数值样式
                      color: 'rgba(140, 175, 241, 1)',
                    }
                  }
                },
              },
              lineStyle: {
                color: '#FFF',
                width: 1,
                type: 'dashed'
              },
              symbol: 'image://https://nas.iquanzhan.com/suining/exposure_circle.png',
              symbolSize: 10,
              data: self.vList
            }
          ]
        };
      },
    }
  }
</script>

<style scoped>
    .my-chart {
        width: 100%;
        height: 100%;
    }
</style>
