<template>
  <div :id="'container'+time"/>
</template>
<script>
import {Column} from '@antv/g2plot';

export default {
  props: {
    obj: {
      type: Object,
    }
  },
  data() {
    return {
      plot: null,
      time:Math.random()
    }
  },
  mounted() {
    this.plot = new Column('container'+this.time, {
      data:this.obj.data,
      xField: 'time',
      yField: 'value',
      color:'#33ABFB',
      tooltip: {
        formatter: (datum) => {
          return { name:(this.obj.name?this.obj.name:''), value: datum.value+(this.obj.unit?this.obj.unit:'') };
        },
      },
      xAxis: false,
      yAxis: false,
      columnWidthRatio:0.5,
    });

    this.plot.render();
    this.setTimer();

  },
  methods: {
    setTimer() {
      this.timer = setTimeout(() => {
        this.plot.update({animation: {appear: {duration: 2000}}})
        this.setTimer();
      }, 1000 * 15);
    }
  },
  beforeDestroy() {
    if (this.timer) {
      clearTimeout(this.timer)
    }
  }
}
</script>

<style scoped>

</style>
