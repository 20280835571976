<!--单位管理-->
<template>
  <div style="background-color: white">
    <a-modal
        :bodyStyle="{'height':260+'px'}"
        v-model="showImport"
        :closable="false"
        :footer="null"
        :width="450"
        :destroyOnClose="true"
    >
      <deviceImport @modalClose="modalCloseImport"/>
    </a-modal>
    <p class="color-333 size-18" style="margin: 15px">{{ deviceTypeName }}管理</p>
    <div class="flex-row-center" style="margin: 15px">
      <a-input-search placeholder="设备名称" style="width: 200px" @search="onSearch" v-model="keyword" :allowClear="true"/>
      <div class="flex-1"/>

      <a-button type="primary" icon="import" @click="showImport = true">
        导入
      </a-button>
      <a-button type="primary" icon="plus-circle" @click="toAdd()" style="margin-left: 15px">
        新增
      </a-button>
    </div>
    <a-table :columns="columns" :data-source="data" rowKey="BRIDGE_ID" :bordered="false" :pagination="paginationOpt"
             size="small">
      <template slot="name" slot-scope="text,record,index">
        <a @click="toEdit(index)">{{ text }}</a>
      </template>

      <template slot="LOCATION" slot-scope="text">
        <span>{{text?text:'/'}}</span>
      </template>

      <template slot="ORIGIN_VALUE1" slot-scope="text">
        <span>{{text?text:'/'}}</span>
      </template>

      <template slot="ORIGIN_VALUE2" slot-scope="text">
        <span>{{text?text:'/'}}</span>
      </template>

      <template slot="ORIGIN_VALUE3" slot-scope="text">
        <span>{{text?text:'/'}}</span>
      </template>

      <template slot="action" slot-scope="text, record,index">
        <a-dropdown class="flex-row-center" style="justify-content: center">
          <a class="ant-dropdown-link color-333 size-14 flex-row-center" @click="e => e.preventDefault()">
            <a-icon type="unordered-list"/>
            <a-icon type="down" style="margin-left: 10px"/>
          </a>
          <a-menu slot="overlay">
            <a-menu-item class="flex-row-center" @click="toEdit(index)">
              <a-icon type="edit"/>
              <a href="javascript:;">编辑</a>
            </a-menu-item>
            <a-menu-item class="flex-row-center" @click="onDelete(index)">
              <a-icon type="delete"/>
              <a href="javascript:;">删除</a>
            </a-menu-item>
          </a-menu>
        </a-dropdown>

      </template>
    </a-table>
  </div>
</template>
<script>
import deviceImport from '../components/device_import';

let columns=[];

export default {
  components: {
    deviceImport
  },
  data() {
    let self = this;
    return {
      showImport: false,
      bean: null,
      showModal: false,
      data: [],
      columns:columns,
      keyword: '',
      paginationOpt: this.$util.getTableParams((current, pageSize) => {
        self.paginationOpt.current = 1;
        self.paginationOpt.defaultPageSize = pageSize;
        console.log(11, self.paginationOpt)
        self.getList()  //显示列表的接口名称
      }, (current, size) => {
        console.log(current, size)
        self.paginationOpt.current = current
        self.paginationOpt.defaultPageSize = size
        self.getList()
      }),
      deviceTypeList: [],
      deviceTypeName: '',
    };
  },
  mounted() {
    let self = this;
    this.setAlarmKeys();
    this.$util.get('api/dataList', {
      page: 1,
      pageSize: 100,
      classType: 'DEVICE_TYPE'
    }, true).then((res) => {
      self.deviceTypeList = res.content;
      self.getType();
    })
    this.getList();
  },
  watch: {
    '$route'(to, from) {
      this.setAlarmKeys();
      this.getList();
    }
  },
  methods: {
    setAlarmKeys(){
      this.paginationOpt.current = 1;
      this.columns = [
        {
          title: '设备名称',
          scopedSlots: {customRender: 'name'},
          dataIndex: 'DEVICE_NAME'
        },
        {
          title: '设备编号',
          dataIndex: 'DEVICE_NUMBER',
        },
        {
          title: '测点编号',
          dataIndex: 'MONITOR_NUMBER',
        },
        {
          title: '安装位置',
          scopedSlots: {customRender: 'LOCATION'},
          dataIndex: 'LOCATION',
        },
        {
          title: '创建时间',
          dataIndex: 'CREATE_TIMES',
        },
        {
          title: '操作',
          key: 'action',
          align: 'center',
          width: 80,
          scopedSlots: {customRender: 'action'},
        },
      ];
      switch (this.$route.fullPath.replace('/', '')) {
        case 'wireDistanceList'://拉绳位移计
          if (this.columns.length == 6){
            this.columns.splice(4, 0, {
              title: '距离', scopedSlots: {customRender: 'ORIGIN_VALUE1'},
              dataIndex: 'ORIGIN_VALUE1',
            });
          }

          break;
        case 'inclinometerList'://倾角计
          if (this.columns.length == 6)
            this.columns.splice(4, 0, {
              title: 'X轴', scopedSlots: {customRender: 'ORIGIN_VALUE1'},
              dataIndex: 'ORIGIN_VALUE1',
            }, {
              title: 'Y轴', scopedSlots: {customRender: 'ORIGIN_VALUE2'},
              dataIndex: 'ORIGIN_VALUE2',
            });
          break;
        case 'gnssList'://北斗定位仪
          if (this.columns.length == 6)
            this.columns.splice(4, 0, {
              title: '投影坐标北',
              scopedSlots: {customRender: 'ORIGIN_VALUE1'},
              dataIndex: 'ORIGIN_VALUE1',
            }, {
              title: '投影坐标东', scopedSlots: {customRender: 'ORIGIN_VALUE2'},
              dataIndex: 'ORIGIN_VALUE2',
            }, {
              title: '投影坐标高', scopedSlots: {customRender: 'ORIGIN_VALUE3'},
              dataIndex: 'ORIGIN_VALUE3',
            });
          break;
        case 'goniometerList'://测斜仪
          break;
        case 'osmometerList'://渗压计
          break;
        case 'hyetometerList'://雨量计
          break;
        case 'rangefindersList'://激光测距仪
          break;
      }
    },
    getType() {
      let p = {};
      switch (this.$route.fullPath.replace('/', '')) {
        case 'wireDistanceList'://拉绳位移计
          p.type = 4;
          break;
        case 'inclinometerList'://倾角计
          p.type = 5;
          break;
        case 'gnssList'://北斗定位仪
          p.type = 6;
          break;
        case 'goniometerList'://测斜仪
          p.type = 7;
          break;
        case 'osmometerList'://渗压计
          p.type = 8;
          break;
        case 'hyetometerList'://雨量计
          p.type = 9;
          break;
        case 'rangefindersList'://激光测距仪
          p.type = 10;
          break;
      }

      for (let i = 0; i < this.deviceTypeList.length; i++) {
        if (this.deviceTypeList[i].DATA_ID == p.type) {
          p.deviceTypeName = this.deviceTypeList[i].CLASS_VALUE;
          break;
        }
      }
      this.deviceTypeName = p.deviceTypeName;

      return p;
    },
    getList() {
      let self = this;
      let params = this.paginationOpt;
      let p = {
        page: params.current,
        pageSize: params.defaultPageSize,
        keyword: this.keyword,
      }
      p.deviceType = this.getType().type;
      this.$util.showLoading(self);
      this.$util.get('api/deviceList', p, true).then((res) => {
        let bean = res.content;
        self.paginationOpt.total = bean.count;
        self.data = bean.list;
        this.$util.hideLoading();
      })
    }, modalCloseImport(params) {
      if (params && params.refresh) {
        this.paginationOpt.current = 1;
        this.keyword = '';
        this.getList();
      }
      this.showImport = false;
    },
    onDelete(index) {
      this.$confirm({
        title: '确认',
        content: '是否确认删除此标签记录？',
        okText: '确认',
        cancelText: '取消',
        onOk: () => {
          this.$util.post('api/deleteDevice/' + this.data[index].DEVICE_ID, {})
              .then(res => {
                this.$message.success('删除成功');
                this.paginationOpt.current = 1;
                this.keyword = '';
                this.getList();
              });
        }
      });
    },
    onSearch(value) {
      this.paginationOpt.current = 1;
      this.keyword = value;
      this.getList();
    },
    toAdd() {
      this.$router.push({path: '/deviceAdd', query: {type: this.getType().type}})
    }, toEdit(index) {
      this.$router.push({path: '/deviceAdd', query: {id: this.data[index].DEVICE_ID, type: this.getType().type}})
    }
  },
};
</script>
