<template>
  <div class="flex-row-center">
    <div class="flex-1 color-bg-E4C61A line-height-1">
    </div>
    <div class="small-point color-bg-E4C61A">
    </div>
    <div class="small-point color-bg-19ACF4" style="margin-left: 10px;">
    </div>
    <div class="color-bg-19ACF4 line-height-1" style="flex: 6">
    </div>
  </div>
</template>

<script>
export default {
  name: "line"
}
</script>

<style scoped>
.small-point {
  width: 4px;
  height: 4px;
  border-radius: 2px;
}
.line-height-1 {
  height: 1px;
}
</style>
