<template>
    <div style="padding: 20px;">
        <div style="background: #fff; padding: 20px 30px 20px;">
            <a-row>
                <a-col :span="8">
                    <div class="flex-row-center" style="padding-right: 30px;">
                        <div class="size-14 color-000-85">关键词：</div>
                        <a-input-search placeholder="事件标题" class="filter-common" @search="onSearch" v-model="keyword"
                                        :allowClear="true"/>
                    </div>
                </a-col>

                <a-col :span="8">
                    <div class="flex-row-center" style="padding-right: 30px;">
                        <div class="size-14 color-000-85">结构物：</div>
                        <a-select @change="structureChange" placeholder="请选择" :allowClear="true" v-model="structureId" class="filter-common">
                            <template v-for="item in structureList">
                                <a-select-option :key="item.STRUCTURE_ID" :value="item.STRUCTURE_ID">
                                    {{ item.STRUCTURE_NAME }}
                                </a-select-option>
                            </template>
                        </a-select>
                    </div>
                </a-col>
            </a-row>

            <a-row style="margin-top: 20px;">
                <a-col :span="8">
                    <div class="flex-row-center" style="padding-right: 30px;">
                        <div class="size-14 color-000-85">事件类型：</div>
                        <a-select @change="eventTypeChange" placeholder="请选择" :allowClear="true" v-model="eventType" class="filter-common">
                            <template v-for="item in eventTypeList">
                                <a-select-option :key="item.DATA_ID" :value="item.DATA_ID">
                                    {{ item.CLASS_VALUE }}
                                </a-select-option>
                            </template>
                        </a-select>
                    </div>
                </a-col>

                <a-col :span="8">
                    <div class="flex-row-center" style="padding-right: 30px;">
                        <div class="size-14 color-000-85">事件状态：</div>
                        <a-select @change="statusChange" placeholder="请选择" :allowClear="true" v-model="status" class="filter-common">
                            <template v-for="item in statusList">
                                <a-select-option :key="item.value" :value="item.value">
                                    {{ item.name }}
                                </a-select-option>
                            </template>
                        </a-select>
                    </div>
                </a-col>

                <a-col :span="7">
                    <div>
                        <a-button type="primary" @click="onSearch()">查询</a-button>
                        <a-button style="margin-left: 10px;" @click="reset()">重置</a-button>
                    </div>
                </a-col>
            </a-row>

            <div style="margin-top: 20px;">
                <a-button type="primary" icon="plus" @click="add()">新增</a-button>
            </div>
        </div>

        <div style="margin-top: 10px;">
            <a-list :data-source="data" :split="false" :pagination="paginationOpt">
                <a-list-item slot="renderItem" slot-scope="item,index">
                    <div class="flex-column flex-1" style="background: #fff; padding: 15px;">
                        <div class="flex-row-center" style="margin-bottom: 10px;">
                            <div class="flex-row-center">
                                <div style="width: 6px; height: 6px; border-radius: 50%;" :style="'background:' + getStatus(item.STATUS).color">
                                </div>
                                <div class="size-14 color-000-65" style="margin-left: 8px;">
                                    {{getStatus(item.STATUS).name}}
                                </div>
                                <div class="size-14 color-000-45" style="margin-left: 30px;">
                                    {{item.CREATE_TIMES}}
                                </div>
                            </div>
                            <div class="flex-1">
                            </div>
                            <div>
                                <icon-font type="icon-edit" @click="toEdit(index)"/>
                                <a-divider type="vertical"/>
                                <a-popconfirm
                                        title="确定要删除吗?"
                                        ok-text="确定"
                                        cancel-text="取消"
                                        @confirm="toDelete(index)"
                                >
                                    <icon-font type="icon-delete" class="cursor_pointer"/>
                                </a-popconfirm>
                                <a-divider type="vertical"/>
                                <icon-font type="icon-info" @click="goDetail(index)"/>
                            </div>
                        </div>
                        <div class="flex-row flex-1">
                            <img :src="getImageUrl(item)" style="width: 200px;height: 140px">
                            <div style="margin-left: 15px;" class="flex-column flex-1">
                                <div class="size-16 color-000-85 bold" style="margin-right: 8px;">
                                    {{item.EVENT_NAME}}
                                </div>
                                <div class="flex-row" style="margin-top: 8px;">
                                    <div class="size-12" style="padding: 1px 6px; border: 1px solid #fff; border-radius: 4px;"
                                         :style="'color:' + '#1890FF' + ';' +
                                 'background:' + '#E6F7FF' + ';' +
                                 'borderColor:' + '#91D5FF'">
                                        {{item.EVENT_TYPE_NAME}}
                                    </div>
                                </div>
                                <div class="flex-row-center" style="margin-top: 10px;">
                                    <div class="size-14 color-000-85" style="min-width: 85px;">
                                        关联结构物：
                                    </div>
                                    <div class="size-14 color-000-65">
                                        {{item.STRUCTURE_NAME}}
                                    </div>
                                </div>

                                <div class="flex-row-center" style="margin-top: 10px;">
                                    <div class="size-14 color-000-85" style="min-width: 85px;">
                                        事件描述：
                                    </div>
                                    <div class="size-14 color-000-65 lineNum-1" :style="'max-width:' + (screenWidth - 700) + 'px;'">
                                        <div>
                                            {{item.DESCRIPTION}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </a-list-item>
            </a-list>
        </div>
    </div>
</template>

<script>
  import moment from 'moment';
  export default {
    name: "culvertList",
    data() {
      let self = this;
      return {
        paginationOpt: this.$util.getTableParams((current, pageSize) => {
          self.paginationOpt.current = 1;
          self.paginationOpt.defaultPageSize = pageSize;
          self.getList()
        }, (current, size) => {
          self.paginationOpt.current = current
          self.paginationOpt.defaultPageSize = size
          self.getList()
        }),
        screenWidth: document.body.clientWidth,
        data: [],
        keyword: '',
        structureList: [],
        structureId: undefined,
        eventTypeList: [],
        eventType: undefined,
        statusList: [
          {
            name: '待处置',
            value: 0
          },
          {
            name: '已处置',
            value: 1
          }
        ],
        status: undefined
      }
    },

    mounted() {
      this.getEventTypeList();
      this.getStructureList();
      this.getList();
    },

    computed: {
      getImageUrl() {
        return function (item) {
          let imgName = '';
          if (item.SAVE_NAME_LIST && item.SAVE_NAME_LIST.length) {
            let imgList = item.SAVE_NAME_LIST.split(',');
            imgName = imgList[0];
          }
          console.log(this.$util.getImageUrlWithName(imgName));
          return this.$util.getImageUrlWithName(imgName);
        }
      },

      getStatus() {
        return function (status) {
            let color = '#FAAD14';
            let name = '待处置';
            if (status == 0) {
              color = '#FAAD14';
              name = '待处置';
            } else if (status == 1) {
              color = '#52C41A';
              name = '已处置';
            }
            return {
              color,
              name
            }
        }
      }

    },
    methods: {
      getEventTypeList() {
        this.$util.get('api/dataList', {
          page: 1,
          pageSize: 100,
          classType: 'EVENT_TYPE'
        }, true).then((res) => {
          this.eventTypeList = res.content
        })
      },

      getStructureList() {
        let self = this;
        let p = {
          page: 1,
          pageSize: 1000,
        }
        this.$util.showLoading(self);
        this.$util.get('api/structureList', p).then((res) => {
          this.$util.hideLoading();
          let bean = res.content;
          self.structureList = bean.list;
        })
      },
      getList() {
        let self = this;
        let params = this.paginationOpt;
        let p = {
          page: params.current,
          pageSize: params.defaultPageSize,
          isImage: 1
        }
        if (this.keyword) {
          p.keyword = this.keyword;
        }
        if (this.structureId) {
          p.structureId = this.structureId;
        }
        if (this.eventType) {
          p.eventType = this.eventType;
        }
        if (this.status > -1) {
          p.status = this.status;
        }

        this.$util.showLoading(self);
        this.$util.get('api/eventList', p).then((res) => {
          let bean = res.content;
          self.paginationOpt.total = bean.count;
          self.data = bean.list;
          console.log(333,self.data);
          this.$util.hideLoading();
        })
      },

      onSearch(value) {
        this.paginationOpt.current = 1;
        if (value)
          this.keyword = value;
        this.getList();
      },
      query() {
        this.getList();
      },
      reset() {
        this.paginationOpt.current = 1;
        this.keyword = '';
        this.structureId = undefined;
        this.eventType = undefined;
        this.status = undefined;
        this.getList();
      },
      structureChange(value) {
        this.structureId = value;
        this.paginationOpt.current = 1;
        this.getList();
      },
      eventTypeChange(value) {
        this.eventType = value;
        this.paginationOpt.current = 1;
        this.getList();
      },
      statusChange(value) {
        this.status = value;
        this.paginationOpt.current = 1;
        this.getList();
      },
      add() {
        this.$router.push('/addEvent')
      },
      toEdit(index) {
        this.$router.push({path: '/addEvent', query: {eventId: this.data[index].EVENT_ID}})
      },
      goDetail(index) {
        console.log(123,index);
        this.$router.push('/eventInfo?eventId='+this.data[index].EVENT_ID)
      },
      toDelete(index) {
        this.$util.showLoading(this);
        this.$util.post('api/deleteEvent/' + this.data[index].EVENT_ID, {}, true).then((res) => {
          this.$util.hideLoading();
          this.$message.success('删除成功');
          this.getList();
        })
      },

    }
  }
</script>

<style scoped>
    .icon_img {
        width: 48px;
        height: 48px;
        border-radius: 4px;
    }
</style>

