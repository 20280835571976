<template>
  <div :id="'container'+time"/>
</template>
<script>
import {Pie} from '@antv/g2plot';

export default {
  props: {
    obj: {
      type: Object,
    }
  },
  data() {
    return {
      plot:null,
      time:Math.random()
    }
  },
  mounted() {
    this.plot = new Pie('container'+this.time, {
      appendPadding: 10,
      data:this.obj.data,
      autoFit:false,
      legend: false,
      angleField: 'value',
      colorField: 'type',
      radius: 1,
      innerRadius: 0.8,
      color: this.obj.colors,
      label: null,
      interactions: [{ type: 'element-selected' }, { type: 'element-active' }],
      statistic: {
        title: false,
        content: null,
      },
      tooltip: {
        formatter: (datum) => {
          console.log(333,datum)
          return {name:(this.obj.name?this.obj.name:datum.type),value:datum.value+(this.obj.unit?this.obj.unit:'')};
        },
      },
    });

    this.plot.render();
    this.setTimer();

  },
  methods:{
    setTimer(){
      this.timer = setTimeout(()=>{
        this.plot.update({ animation: { appear: { duration: 2000 } } })
        this.setTimer();
      },1000*15);
    }
  },
  beforeDestroy() {
    if(this.timer){
      clearTimeout(this.timer)
    }
  }
}
</script>

<style scoped>

</style>
