<template>
    <div style="padding: 20px;">
        <div style="background: #fff;">
            <div class="flex-row-center">
                <div class="size-16 color-000-85 bold" style="padding: 15px 30px;">
                    报警统计
                </div>
                <div class="flex-1">

                </div>
                <div class="flex-row-center" style="margin-right: 20px;">
                    <div class="cursor_pointer" style="padding: 4px 12px;"
                         :style="'color:' + (selectedIndex == 0 ? '#1890FF': 'rgba(0,0,0,0.65)')"
                         @click="clickTitleIndex(0)">
                        近七日
                    </div>
                    <div class="cursor_pointer" style="padding: 4px 12px;"
                         :style="'color:' + (selectedIndex == 1 ? '#1890FF': 'rgba(0,0,0,0.65)')"
                         @click="clickTitleIndex(1)">
                        近一年
                    </div>
                </div>
            </div>
            <a-divider style="margin-top: 0px; margin-bottom: 0px;"/>

            <div class="flex-row flex-1">
                <div class="flex-column flex-1">
                    <div class="size-16 color-000-85 bold" style="padding: 20px 30px;">
                        报警类型统计
                    </div>
                    <div class="flex-row" style="margin: 20px 0 40px;">
                        <div v-for="(item, index) in alarmTitleList" :key="index" class="flex-1 center">
                            <div style="position: relative;">
                                <a-progress type="circle" :percent="getPercent(index).value" :width="120"
                                            :strokeWidth="12"
                                            :showInfo="false" :strokeColor="getPercent(index).color"/>
                                <div class="absolute-0 flex-column center">
                                    <div class="color-000-45 size-14">{{item}}</div>
                                    <div class="color-000-85 size-24" style="margin-top: -3px;">
                                        {{getPercent(index).level}}
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div style="flex: 1.5;">
                    <div class="size-16 color-000-85 bold" style="padding: 20px 30px;">
                        历史报警统计
                    </div>
                    <div class="flex-1" style="margin-bottom: 20px;">
                        <v-chart style="width: 95%; height: 240px;" :options="structureLineChart"/>
                    </div>
                </div>
            </div>


        </div>

        <div style="background: #fff; padding: 20px 30px 20px; margin-top: 20px;">
            <a-row>
                <a-col :span="6">
                    <div class="flex-row-center" style="padding-right: 30px;">
                        <div class="size-14 color-000-85">报警等级：</div>
                        <a-select @change="alarmLevelChange" placeholder="请选择" :allowClear="true" v-model="alarmLevel"
                                  class="filter-common">
                            <template v-for="item in alarmLevelList">
                                <a-select-option :key="item.value" :value="item.value">
                                    {{ item.name }}
                                </a-select-option>
                            </template>
                        </a-select>
                    </div>
                </a-col>
                <a-col :span="6">
                    <div class="flex-row-center" style="padding-right: 30px;">
                        <div class="size-14 color-000-85">结构物：</div>
                        <a-select @change="structureChange" placeholder="请选择" :allowClear="true" v-model="structureId"
                                  class="filter-common">
                            <template v-for="item in structureList">
                                <a-select-option :key="item.STRUCTURE_ID" :value="item.STRUCTURE_ID">
                                    {{ item.STRUCTURE_NAME }}
                                </a-select-option>
                            </template>
                        </a-select>
                    </div>
                </a-col>

                <a-col :span="6">
                    <div class="flex-row-center" style="padding-right: 30px;">
                        <div class="size-14 color-000-85">监测点：</div>
                        <a-select @change="monitorPointChange" placeholder="请选择" :allowClear="true"
                                  v-model="monitorPointId" class="filter-common">
                            <template v-for="item in pointList">
                                <a-select-option :key="item.MONITOR_POINT_ID" :value="item.MONITOR_POINT_ID">
                                    {{ item.POINT_NAME }}
                                </a-select-option>
                            </template>
                        </a-select>
                    </div>
                </a-col>

                <a-col :span="5">
                    <div>
                        <a-button type="primary" @click="onSearch()">查询</a-button>
                        <a-button style="margin-left: 10px;" @click="reset()">重置</a-button>
                    </div>
                </a-col>
            </a-row>
        </div>

        <div style="margin-top: 10px;">
            <a-list :data-source="alarmList" :split="false" :pagination="paginationOpt">
                <a-list-item slot="renderItem" slot-scope="item">
                    <div class="flex-row flex-1" style="background: #fff; padding: 15px;">
                        <img :src="getLevel(item.ALARM_LEVEL).img" width="60" height="60">
                        <div style="margin-left: 15px;" class="flex-column flex-1">
                            <div class="flex-row-center">
                                <div class="size-16 color-000-85 bold" style="margin-right: 8px;">
                                    {{item.STRUCTURE_NAME}}发生报警
                                </div>
                            </div>
                            <div class="flex-row-center" style="margin-top: 8px;">
                                <div style="padding: 0px 10px; border-radius: 4px; border: 1px solid #FFE58F;"
                                     :style="'color:' + getLevel(item.ALARM_LEVEL).color + ';' + 'background:' + getLevel(item.ALARM_LEVEL).bgColor + ';'
                                     + 'borderColor:' + getLevel(item.ALARM_LEVEL).borderColor"
                                >
                                    {{getLevel(item.ALARM_LEVEL).levelName}}
                                </div>
                                <div class="size-14 color-000-45" style="margin-left: 8px;">
                                    {{getLevel(item.ALARM_LEVEL).levelContent}}
                                </div>
                            </div>
                            <div style="margin-top: 10px;" class="flex-row">
                                <div class="flex-column" style="min-width: 300px;">
                                    <div class="flex-row-center">
                                        <div class="size-14 color-000-85">
                                            报警点位：
                                        </div>
                                        <div class="size-14 color-000-65">
                                            {{item.POINT_NAME}}
                                        </div>
                                    </div>
                                    <div class="flex-row-center" style="margin-top: 8px;">
                                        <div class="size-14 color-000-85">
                                            报警时间：
                                        </div>
                                        <div class="size-14 color-000-65">
                                            {{item.ALARM_TIMES}}
                                        </div>
                                    </div>
                                </div>

                                <div class="flex-column" style="min-width: 300px;">
                                    <div class="flex-row-center">
                                        <div class="size-14 color-000-85">
                                            报警部位：
                                        </div>
                                        <div class="size-14 color-000-65">
                                            {{item.LOCATION}}
                                        </div>
                                    </div>
                                    <div class="flex-row-center" style="margin-top: 8px;">
                                        <div class="size-14 color-000-85">
                                            监测数据：
                                        </div>
                                        <div class="size-14 color-000-65">
                                            {{item.ALARM_VALUE}}
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </a-list-item>
            </a-list>
        </div>
    </div>
</template>

<script>
  import moment from 'moment';
  import {graphic} from "echarts/lib/export/api";

  export default {
    name: "culvertList",
    data() {
      let self = this;
      return {
        paginationOpt: this.$util.getTableParams((current, pageSize) => {
          self.paginationOpt.current = 1;
          self.paginationOpt.defaultPageSize = pageSize;
          self.getList()
        }, (current, size) => {
          self.paginationOpt.current = current
          self.paginationOpt.defaultPageSize = size
          self.getList()
        }, 3),
        data: [],
        keyword: '',
        structureList: [],
        structureId: undefined,
        alarmTitleList: ['超限一级', '超限二级', '超限三级'],
        alarmLevelList: [
          {
            name: 'Ⅰ级',
            value: 1
          },
          {
            name: 'Ⅱ级',
            value: 2
          },
          {
            name: 'Ⅲ级',
            value: 3
          }
        ],
        alarmLevel: undefined,

        alarmLevel1: 0,
        alarmLevel2: 0,
        alarmLevel3: 0,
        alarmLevelTotal: 0,
        structureLineChart: {},

        alarmList: [],
        pointList: [],
        monitorPointId: undefined,
        selectedIndex: 0, //0 本月 1 近一年
        startDate: moment().add(-6, 'days').format("YYYY-MM-DD"),
        endDate: moment().format('YYYY-MM-DD'),
      }
    },

    mounted() {
      this.getStructureList();
      this.getList();
      this.getMonitorAlarmLevelStatistics();
      this.getAlarmStatistics();
    },

    computed: {
      getLevel() {
        return function (level) {
          let img = require('../../image/alarm_level_yellow_icon.png');
          let levelName = 'Ⅰ级';
          let levelContent = '超限一级报警';
          let color = '#FFAF00';
          let bgColor = '#FFFBE6';
          let borderColor = '#FFE58F';
          if (level == 1) {
            img = require('../../image/alarm_level_yellow_icon.png');
            levelName = 'Ⅰ级';
            levelContent = '超限一级报警';
            color = '#FFAF00';
            bgColor = '#FFFBE6';
            borderColor = '#FFE58F';
          } else if (level == 2) {
            img = require('../../image/alarm_level_orange_icon.png');
            levelName = 'Ⅱ级';
            levelContent = '超限二级报警';
            color = '#FA541C';
            bgColor = '#FFF2E8';
            borderColor = '#FFBB96';
          } else if (level == 3) {
            img = require('../../image/alarm_level_red_icon.png');
            levelName = 'Ⅲ级';
            levelContent = '超限三级报警';
            color = '#F5222D';
            bgColor = '#FFF1F0';
            borderColor = '#FFA39E';
          }
          return {
            img,
            levelName,
            levelContent,
            color,
            bgColor,
            borderColor
          }
        }
      },

      getPercent() {
        return function (index) {
          let level = 0;
          let value = 0;
          let total = this.alarmLevelTotal;
          let color = '#FAD337';
          if (total > 0) {
            if (index == 0) {
              color = '#FAD337';
              level = this.alarmLevel1;
              value = this.alarmLevel1 / total * 100;
            } else if (index == 1) {
              color = '#FA541C';
              level = this.alarmLevel2;
              value = this.alarmLevel2 / total * 100;
            } else if (index == 2) {
              color = '#F04864';
              level = this.alarmLevel3;
              value = this.alarmLevel3 / total * 100;
            }
          }
          return {
            color,
            level,
            value
          };
        }
      },
    },
    methods: {

      getMonitorAlarmLevelStatistics() {
        let p = {
          startDate: this.startDate,
          endDate: this.endDate
        }
        this.$util.get('api/monitorAlarmLevelStatistics', p, true).then((ret) => {
          let dao = ret.content;
          this.alarmLevel1 = dao.YELLOW_ALARM_COUNTS ? dao.YELLOW_ALARM_COUNTS : 0;
          this.alarmLevel2 = dao.ORANGE_ALARM_COUNTS ? dao.ORANGE_ALARM_COUNTS : 0;
          this.alarmLevel3 = dao.RED_ALARM_COUNTS ? dao.RED_ALARM_COUNTS : 0;
          this.alarmLevelTotal = dao.MONITOR_ALARM_COUNTS ? dao.MONITOR_ALARM_COUNTS : 0;
        });
      },

      getAlarmStatistics() {
        let timeList = [];
        let index = (this.selectedIndex == 0 ? -6 : -11);
        for (let i = index; i <= 0; i++) {
          let dd = moment().add(i, 'months').format("MM月");
          let item = {
            MONITOR_ALARM_COUNTS: 0,
            ORANGE_ALARM_COUNTS: 0,
            RED_ALARM_COUNTS: 0,
            STATISTICS_TIME: dd,
            YELLOW_ALARM_COUNTS: 0
          }
          timeList.push(item);
        }

        let p = {
          startDate: this.startDate,
          endDate: this.endDate,
          type: (this.selectedIndex == 0 ? 1 : 2)
        };
        this.$util.get('api/timeMonitorAlarmLevelStatisticsList', p).then((res) => {
          console.log(334, res);

          let list = timeList;
          let resList = res.content;
          for (let i = 0; i < list.length; i++) {
            let item = list[i];
            for (let j = 0; j < resList.length; j++) {
              let item2 = resList[j];
              if (item.STATISTICS_TIME == item2.STATISTICS_TIME) {
                timeList[i] = resList[j];
                break;
              }
            }
          }
          console.log(234, timeList);


          this.alermRedList = timeList.map((item) => {
            return item.RED_ALARM_COUNTS;
          })
          this.alermOrangeList = timeList.map((item) => {
            return item.ORANGE_ALARM_COUNTS;
          })
          this.alermYellowList = timeList.map((item) => {
            return item.YELLOW_ALARM_COUNTS;
          })
          this.getChart();
        });
      },

      getChart() {
        let dates = [];
        let index = (this.selectedIndex == 0 ? -6 : -11);
        for (let i = index; i <= 0; i++) {
          let dd = (this.selectedIndex == 0 ? moment().add(i, 'days').format("DD日") : moment().add(i, 'months').format("YYYY-MM"));
          dates.push(dd);
        }
        this.structureLineChart = {
          tooltip: {
            trigger: 'axis',
            axisPointer: {            // 坐标轴指示器，坐标轴触发有效
              type: 'none'        // 默认为直线，可选为：'line' | 'shadow' | 'none'
            },
            backgroundColor: 'rgba(55,66,97,0.8)',
            borderWidth: 0,
            textStyle: {
              color: '#fff',

            },
            formatter: function (params) {
              console.log(666,params);
              let tar0 = params[0];
              let tar1 = params[1];
              let tar2 = params[2];

              let dd = `<div style="padding: 5px 10px;">

                    <div class="flex-row-center" style="margin-top: 2px;">
                        <div class="size-16 color-fff-80" style="margin-left: 0px;">` + tar0.name + `</div>
                    </div>

                    <div class="flex-row-center" style="margin-top: 5px;">
                        <div style="width: 10px; height: 10px; background: ` + '#FAD337' + `; border-radius: 50%"></div>
                        <div class="size-16 color-fff-80" style="margin-left: 5px;">超限一级</div>
                        <div class="size-18 color-fff margin-left-8">` + parseFloat(tar2.value.toFixed(4)) + '次' +`</div>
                    </div>
                    <div class="flex-row-center" style="margin-top: 5px;">
                        <div style="width: 10px; height: 10px; background: ` + '#FA541C' + `; border-radius: 50%"></div>
                        <div class="size-16 color-fff-80" style="margin-left: 5px;">超限二级</div>
                        <div class="size-18 color-fff margin-left-8">` + parseFloat(tar1.value.toFixed(4)) + '次' +`</div>
                    </div>
                    <div class="flex-row-center" style="margin-top: 5px;">
                        <div style="width: 10px; height: 10px; background: ` + '#F04864' + `; border-radius: 50%"></div>
                        <div class="size-16 color-fff-80" style="margin-left: 5px;">超限三级</div>
                        <div class="size-18 color-fff margin-left-8">` + parseFloat(tar0.value.toFixed(4)) + '次' +`</div>
                    </div>
                </div>`;
              return dd;
            }
          },
          grid: {
            left: '5%',
            right: '1%',
            bottom: '6%',
            top: '5%',
            containLabel: true
          },
          xAxis: [{
            type: "category",
            data: dates,
            axisLine: {
              show: false
            },
            axisLabel: {
              color: "#666",
              fontSize: 12
            },
            axisTick: {
              show: false
            }
          }],

          yAxis: [{
            type: "value",
            minInterval : 1,
            axisLabel: {
              color: "#666",
              fontSize: 14
            },
            splitLine: {
              lineStyle: {
                // 使用深浅的间隔色
                color: ['rgba(0,0,0,0.1)'],
                type: 'dashed'
              }
            }
          }],
          series: [
            {
              type: 'line',
              // areaStyle: { //渐变色
              //   normal: {
              //     color: graphic.LinearGradient(0, 0, 0, 1,
              //       [{offset: 0, color: 'rgba(240, 72, 100, 0.3)'},
              //         {offset: 0.5, color: 'rgba(240, 72, 100, 0.1)'},
              //         {offset: 1, color: 'rgba(240, 72, 100, 0.01)'}])
              //   }
              // },

              data: this.alermRedList,
              lineStyle: {
                color: '#F04864',
                width: 1,
                type: 'solid'
              },
              symbol: 'image://https://nas.iquanzhan.com/structure/circle_point_red.png',
              symbolSize: 8,
            },
            {
              type: 'line',
              // areaStyle: { //渐变色
              //   normal: {
              //     color: graphic.LinearGradient(0, 0, 0, 1,
              //       [{offset: 0, color: 'rgba(250, 84, 28, 0.3)'},
              //         {offset: 0.5, color: 'rgba(250, 84, 28, 0.1)'},
              //         {offset: 1, color: 'rgba(250, 84, 28, 0.01)'}])
              //   }
              // },
              data: this.alermOrangeList,
              lineStyle: {
                color: '#FA541C',
                width: 1,
                type: 'solid'
              },
              symbol: 'image://https://nas.iquanzhan.com/structure/circle_point_orange.png',
              symbolSize: 8,
            },
            {
              type: 'line',
              // areaStyle: { //渐变色
              //   normal: {
              //     color: graphic.LinearGradient(0, 0, 0, 1,
              //       [{offset: 0, color: 'rgba(250, 211, 55, 0.3)'},
              //         {offset: 0.5, color: 'rgba(250, 211, 55, 0.1)'},
              //         {offset: 1, color: 'rgba(250, 211, 55, 0.01)'}])
              //   }
              // },
              data: this.alermYellowList,
              lineStyle: {
                color: '#FAD337',
                width: 1,
                type: 'solid'
              },
              symbol: 'image://https://nas.iquanzhan.com/structure/circle_point_yellow.png',
              symbolSize: 8,
            },

          ]
        };
      },

      getStructureList() {
        let self = this;
        let p = {
          page: 1,
          pageSize: 1000,
        }
        this.$util.showLoading(self);
        this.$util.get('api/structureList', p).then((res) => {
          this.$util.hideLoading();
          let bean = res.content;
          self.structureList = bean.list;
        })
      },

      getList() {
        let self = this;
        let params = this.paginationOpt;
        let p = {
          page: params.current,
          pageSize: params.defaultPageSize,
        }
        if (this.structureId) {
          p.structureId = this.structureId;
        }
        if (this.alarmLevel) {
          p.alarmLevel = this.alarmLevel;
        }
        if (this.monitorPointId) {
          p.monitorPointId = this.monitorPointId
        }
        console.log(1111, p);
        this.$util.showLoading(self);
        this.$util.get('api/monitorAlarmList', p).then((res) => {
          this.$util.hideLoading();
          self.paginationOpt.total = res.content.count;
          this.alarmList = res.content.list;

        })
      },

      onSearch(value) {
        this.paginationOpt.current = 1;
        this.getList();
      },
      query() {
        this.getList();
      },
      reset() {
        this.paginationOpt.current = 1;
        this.alarmLevel = undefined;
        this.structureId = undefined;
        this.monitorPointId = undefined;
        this.getList();
      },
      structureChange(value) {
        this.structureId = value;
        this.paginationOpt.current = 1;
        this.getList();

        this.monitorPointId = undefined;
        // this.monitorItemList = [];
        this.$util.get('api/monitorPointList', {page: 1, pageSize: 100, structureId: value}).then((res) => {
          this.pointList = res.content.list
        })
      },
      monitorPointChange(value) {
        this.monitorPointId = value;
        this.paginationOpt.current = 1;
        this.getList();
      },
      alarmLevelChange(value) {
        this.alarmLevel = value;
        this.paginationOpt.current = 1;
        this.getList();
      },
      clickTitleIndex(index) {
        this.selectedIndex = index;
        if (index == 0) {
          this.startDate = moment().add(-6, 'days').format("YYYY-MM-DD");
          this.endDate = moment().format('YYYY-MM-DD');
        } else if (index == 1) {
          this.startDate = moment().add(-11, 'months').startOf('month').format("YYYY-MM-DD");
          this.endDate = moment().add(0, 'months').endOf('month').format("YYYY-MM-DD");
        }
        this.getMonitorAlarmLevelStatistics();
        this.getAlarmStatistics();
      },
      add() {
        this.$router.push('/addHealth')
      },
      toEdit(index) {

        this.$router.push({path: '/addHealth', query: {healthId: this.data[index].HEALTH_ID}})
      },
      toDelete(index) {
        let healthId = this.data[index].HEALTH_ID;
        this.$util.showLoading(this);
        this.$util.post('api/deleteHealth/' + healthId, {}, true).then((res) => {
          this.$util.hideLoading();
          this.$message.success('删除成功');
          this.getList();
        })
      },

    }
  }
</script>

<style scoped>
    .icon_img {
        width: 48px;
        height: 48px;
        border-radius: 4px;
    }
</style>

