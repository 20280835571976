<template>
<div class="content bg">
  <main-header/>
  <br />
  <a-row :gutter="[25,25]" class="ml-3 mr-3 ">
    <a-col :span="6" >
      <div class="border-top bg-gradient-032240-30">
        <section class="flex u-flex-between pt-3 pb-3 pl-3 pr-3 border-bottom border-color-fff-30">
          <div class="">
            <img src="../../image/screen/text_icon.png" width="18" class="vertical-align-initial">
            <span class="color-FFF size-22 ml-2">结构物列表</span>
          </div>
          <div class="ml-30 mr-30">
<!--            三个小蓝点-->
            <span class="d-block text-align-right" >
              <i class="dot bg-color-62DBED d-inline-block " :class="i!=2?'mr-1':''" v-for="(item,i) in dotlist" v-bind:key="i"></i>
            </span>
            <span class="letter-capitalize color-FFF-50 size-10">structural healthy monitoring system</span>
          </div>
        </section>
        <section class="pt-3 pb-3 pl-3 pr-3">
          <a-row :gutter="[20,20]" v-for="(item,i) in structureList" v-bind:key="i" :class="i==(structureList.length-1)?'':'mb-3'">
            <a-col :span="18" class="flex">
              <img src="../../image/screen/home_bridge.png" width="80" class="vertical-align-bottom">
              <div class="color-FFF d-inline-block ml-3">
                <p class="size-20 bold">{{item.name}}</p>
                <p  class="size-18 pb-1 pt-2">总体得分：<span class="size-22 color-62DBED">{{item.score}}</span></p>
              </div>
            </a-col>
            <a-col :span="6" >
              <a-progress type="circle" :percent="item.percent" :width="80">
                <template #format="jk">
                  <span class="color-fff size-16">{{ jk }}</span>
                </template>
              </a-progress>
            </a-col>
          </a-row>

        </section>
      </div>
    </a-col>
    <a-col :span="18" >
      <div class="border-top bg-color-032240-50">
         <section class="flex u-flex-between pt-3 pb-3 pl-3 pr-3 border-bottom border-color-fff-30">
            <div class="">
              <img src="../../image/screen/text_icon.png" width="18" class="vertical-align-initial">
              <span class="color-FFF size-22 ml-2">结构物分布图</span>
            </div>
            <div class="ml-30 mr-30">
                <span class="d-block text-align-right" >
                  <i class="dot bg-color-62DBED d-inline-block " :class="i!=2?'mr-1':''" v-for="(item,i) in dotlist" v-bind:key="i"></i>
                </span>
              <span class="letter-capitalize color-FFF-50 size-10">structural healthy monitoring system</span>
            </div>
         </section>
         <section class="pt-3 pb-3 pl-3 pr-3 p-relative">
            <img src="../../image/screen/home_map.png" height="840" width="100%"  ref="img">
           <div>
             <!-- 地图的路线 -->
             <img src="../../image/screen/home_road_line.png" width="340" class="p-absolute z-index-1 home_road_line">
             <div>
                <div class="p-absolute z-index-2 cursor_pointer"  @mousedown="dragIcon">
                  <img src="../../image/screen/home_icon_tunnel.png" width="30" v-on:click.stop="showDot()"  >
                  <div class="dot bg-color-62DBED p-absolute dotPosition" v-show="dotShow"></div>
                  <div class="size-14 color-fff p-relative popPosition" v-show="dotShow">
                    <a-icon type="close"  class="p-absolute popClose z-index-3 size-10" @click="closePop()" />
                    <canvas width="330" :height="popHeight" ref= 'myCanvas'></canvas>
                    <p ref="popRef" class="d-block pt-3 pb-3 p-absolute z-index-2" style="top:0;">
                      <a-row :gutter="[15,15]" class="ml-2 mr-2">
                        <a-col :span="17" >
                          <span class="d-inline-block size-20">盐盐锅峡黄河大桥盐锅峡黄河大桥盐锅峡黄河大桥盐锅峡</span>
                        </a-col>
                        <a-col :span="7" >
                          <span class="size-16 color-41FBFD mt-1 d-block">查看详情</span>
                        </a-col>
                      </a-row>
                    </p>
                  </div>
                </div>
             </div>
           </div>

         </section>
      </div>
    </a-col>
  </a-row>
</div>
</template>

<script>
import header from './header';
const list = [
  {
    title: 'Ant Design Title 1',
  },
  {
    title: 'Ant Design Title 2',
  },
  {
    title: 'Ant Design Title 3',
  },
  {
    title: 'Ant Design Title 4',
  },
];
const structureList = [
      {
        name: '敦都大桥',
        score: 95,
        percent: 80
      },
      {
        name: '盐锅峡黄河大桥',
        score: 95,
        percent: 80
      },
      {
        name: '张嘉园大桥',
        score: 95,
        percent: 80
      },
      {
        name: '永靖黄河大桥',
        score: 95,
        percent: 80
      },
      {
        name: '林场隧道',
        score: 95,
        percent: 80
      },
      {
        name: '木架梁隧道',
        score: 95,
        percent: 80
      },
      {
        name: '高嘴隧道',
        score: 95,
        percent: 80
      },
      {
        name: '成桑隧道',
        score: 95,
        percent: 80
      }
    ]
export default {
name: "test-home",
  components: {
    mainHeader: header,
  },
  data(){
    return{
      dotlist:['','',''],
      jk: '健康状况',
      structureList: structureList,
      dotShow:false,
      detailHeight:0,
      popHeight:0,
      boundaryW:0,
      boundaryH:0,
      positionX:0,
      positionY:0
    }
  },
  mounted() {
    // 獲取地圖大小，爲了後面設定拖拽邊界
    this.boundaryW =this.$refs.img.clientWidth;
    this.boundaryH =this.$refs.img.clientHeight;

  },
  updated() {
    this.popHeight = this.$refs.popRef.clientHeight;
    this.myCanvas();
  },
  methods:{
    showDot(){
      this.dotShow = true;
    },
    closePop(){
      this.dotShow = false;
    },
    dragIcon(e){
      let odiv = e.currentTarget;         //获取目标元素
      //算出鼠标相对元素的位置
      let disX = e.clientX - odiv.offsetLeft;
      let disY = e.clientY - odiv.offsetTop;
      document.onmousemove = (e)=>{       //鼠标按下并移动的事件
        //用鼠标的位置减去鼠标相对元素的位置，得到元素的位置
        let left = e.clientX - disX;
        let top = e.clientY - disY;
        // 拖拽邊界
        if(left <= 0){
          left = 0;
        }else if(left>=this.boundaryW){
          left = this.boundaryW;
        }
        if(top <= 0){
          top = 0;
        }else if(top>=this.boundaryH){
          top = this.boundaryH;
        }
        // 讓定位在頁面展示，僅僅爲了測試可刪除
        this.positionX = left;
        this.positionY = top;

        //移动当前元素
        odiv.style.left = left + 'px';
        odiv.style.top = top + 'px';
      };
      document.onmouseup = (e) => {
        document.onmousemove = null;
        document.onmouseup = null;
      };
    },
    myCanvas(){
      let canvas = this.$refs.myCanvas;
      let ctx = canvas.getContext('2d');

      let w =canvas.width-6;
      let h =canvas.height-16;
      ctx.translate(3,3);
      ctx.lineJoin = "round";
      ctx.moveTo(0,0);
      ctx.lineTo(w,0);
      ctx.lineTo(w,h);
      ctx.lineTo(w/2+10,h);
      ctx.lineTo(w/2,h+10);
      ctx.lineTo(w/2-10,h);
      ctx.lineTo(0,h);
      ctx.closePath();
      ctx.lineWidth = 1.5;
      ctx.strokeStyle = '#62DBED';
      ctx.stroke();
      ctx.fillStyle = 'rgba(3, 39, 85, 0.8)';
      ctx.fill();
    }

   }
}
</script>

<style scoped>
.content {
  width: 100%;
  height: 100%;
  position: relative;
}

.bg {
  background: linear-gradient(180deg, #003886 0%, #1A60C7 50%, #012965 100%);;
  background-size: 100% 100%;
  overflow: auto;
}
.border-top{border-top:0.15rem solid #62DBED;}
.ant-list-item-meta-description {
  color: white;
}
.ant-list-item-meta-title > a{
  color: white;
}
.home_road_line{
  left: 481px; top: 133px;
}
.dotPosition{
  left: 12px;top: 30px;width: 6px;height:6px;
}
.popPosition{
  top: -160px;
  left: -150px;
  width: 330px;
}
.popClose{top:10px;right: 10px;}
.cursor_pointer{top:100px;left: 200px;}
>>>.ant-progress-circle-trail {
  stroke: #000!important;
}
</style>