<template>
  <div class="head_bg flex-row-center">
    <div style="width: 4px; height: 40px; background: #20A3EC; margin-right: 6px;">
    </div>
    <img src="../../image/electric/arrow_right.png" style="width: 5px; height: 10px;">
    <div style="padding-left: 15px; margin-left: -3px;" class="header_box flex-row-center size-20 color-fff flex-1 ff-m">
      {{title}}
    </div>
  </div>
</template>

<script>
  export default {
    name: "electric_home_header",
    props: {
      title: {
        type: String,
        default: ''
      },
    },
  }
</script>

<style scoped>
  .head_bg {
    height: 40px;
  }

  .header_box {
    height: 40px;
    background: linear-gradient(90deg, rgba(60, 148, 255, 0.35) 0%, rgba(60, 148, 255, 0) 100%);
  }
</style>
