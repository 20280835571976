<template>
  <div/>
</template>

<script>
import * as THREE from "three";
import {OrbitControls} from 'three/examples/jsm/controls/OrbitControls'
import {GLTFLoader} from 'three/examples/jsm/loaders/GLTFLoader'
import {EffectComposer} from 'three/examples/jsm/postprocessing/EffectComposer'
import {RenderPass} from 'three/examples/jsm/postprocessing/RenderPass'
import {ShaderPass} from 'three/examples/jsm/postprocessing/ShaderPass'
import {FXAAShader} from "three/examples/jsm/shaders/FXAAShader.js"
import {OutlinePass} from 'three/examples/jsm/postprocessing/OutlinePass'

import TWEEN from '@tweenjs/tween.js'
import {Vector2} from "three";

export default {
  props: {
    monitorPoints: {
      type: Array,
    },
    doAction: {
      type: Boolean,
      default: true,
    },
    isDevice: {
      type: Boolean,
      default: false,
    },
    div: Object
  },
  data() {
    let structureItem = JSON.parse(localStorage.getItem('structureItem'));
    console.log(222,structureItem)
    let startPo = {x: 50, y: 50, z: 50};
    switch (structureItem.STRUCTURE_ID) {
      case 9://'红崖子沟特大桥'
      case 46://'客专西街2号高架桥'
        startPo = {x: 50, y: 50, z: 50};
        break;
      case 10://'跨线特大桥'
        startPo = {x: 30, y: 50, z: 70};
        break;
      case 18://'大鄂博图沟特大桥'
        startPo = {x: 60, y: 50, z: 110};
        break;
    }
    return {
      camera: null,
      scene: null,
      light: null,
      renderer: null,
      controls: null,
      width: 0,
      height: 0,
      raycaster: null,
      mixers: [],//点动画
      clock: null,
      isClick: false,
      mouse: null,
      clickGroup: [],
      // positionStart: {x: 0, y: 0, z: 0},
      positionStart: startPo,
      pointPosition: null,
      composer: null,
    }
  },


  mounted() {
    this.threeStart();
    this.isClick = false;
    this.mouse = new THREE.Vector2();
    window.addEventListener('click', this.onMouseClick);
    window.addEventListener('mousemove', this.onMouseMove);
    window.addEventListener("resize", this.onWindowResize)
  },
  methods: {
    resetPop(monitorPointInfo) {
      this.pointPosition = null;
      if (monitorPointInfo) {
        for (let i = 0; i < this.clickGroup.length; i++) {
          let item = this.clickGroup[i];
          if (monitorPointInfo.MONITOR_POINT_ID === item.pId) {
            let parentPo = item.position;
            this.pointPosition = new THREE.Vector3(parentPo.x, parentPo.y, parentPo.z);
            this.divRender();
            break;
          }
        }
      }else{
        this.outlineObj([])
      }
    },


    //点模型
    loadPoint(points, angle) {
      if (this.clickGroup) {
        for (let i = 0; i < this.clickGroup.length; i++) {
          this.scene.remove(this.clickGroup[i])
        }
      }
      let loader = new GLTFLoader();
      let self = this;
      let monitorPoints = points;
      let mixerList = self.mixers;
      let clickGroup_ = [];
      console.log(99,monitorPoints);
      for (let i = 0; i < monitorPoints.length; i++) {
        let modalName = '';
        let item = monitorPoints[i];

        if (item.ALARM_LEVEL == 0) {//正常
          modalName = '/modal/point_normal2.glb';
        } else {//报警
          modalName = '/modal/point_unusual.glb';
        }

        loader.load(modalName, function (gltf) {

          gltf.scene.traverse(function (child) {
            if (child.isMesh) {
              child.material.emissive = child.material.color;
              // child.material.depthWrite = false;
              child.frustumCulled = true;//模型阴影
              child.castShadow = true;//投射阴影
              child.receiveShadow = true;//接收阴影
            }
          });

          self.scene.add(gltf.scene);
          gltf.scene.index = i;
          gltf.scene.pId = item.MONITOR_POINT_ID;
          let scale = 4;
          gltf.scene.scale.set(scale, scale, scale);
          let po = item.threePosition;
          gltf.scene.position.set(po[0], po[1], po[2]);

          if (item.angleX != null) {
            gltf.scene.rotation.x = item.angleX;
          }
          if (item.angleY != null) {
            gltf.scene.rotation.y = item.angleY;
          }
          if (item.angleZ != null) {
            gltf.scene.rotation.z = item.angleZ;
          } else {
            gltf.scene.rotation.z = angle;
          }

          // gltf.scene.material.emissive = gltf.scene.material.color;
          // gltf.scene.transparent = true;

          // let mixer = new THREE.AnimationMixer(gltf.scene);
          // mixer.clipAction(gltf.animations[0]).setDuration(2).play();
          // mixer.clipAction(gltf.animations[1]).setDuration(3).play();
          // mixerList.push(mixer);
          clickGroup_.push(gltf.scene)
        })
      }

      self.mixers = mixerList;
      this.clickGroup = clickGroup_;
    },
    action() {
      //20, 20, 15
      let tweena = this.cameraCon(this.positionStart, 500)//先看一会小图，再变成大图，有个先预览整个场景的效果
      let tweenb = this.cameraCon({
        x: 20, y: 10, z: 35
      }, 2000)
      tweena.chain(tweenb)
      tweena.start();
    },
    cameraCon(p2, time) {
      let p1 = this.positionStart;
      let tween1 = new TWEEN.Tween(p1).to(p2, time).easing(TWEEN.Easing.Quadratic.InOut)
      tween1.onUpdate(() => {
        this.camera.position.set(p1.x, p1.y, p1.z)
        this.camera.lookAt(0, 0, 0)
      })
      return tween1
    },
    onWindowResize() {
      let po = document.getElementById("canvas-frame");
      this.renderer.setSize(po.clientWidth, po.clientHeight);
      this.camera.aspect = po.clientWidth / po.clientHeight;
      this.camera.updateProjectionMatrix();
      this.isClick = false;
    },
    onMouseMove(event) {
      this.isClick = false;
      event.preventDefault();
      let po = document.getElementById("canvas-frame");
      if (po && po.offsetLeft) {
        this.mouse.x = ((event.clientX - po.offsetLeft) / po.clientWidth) * 2 - 1;
        this.mouse.y = -((event.clientY - po.offsetTop) / po.clientHeight) * 2 + 1;


        this.raycaster.setFromCamera(this.mouse, this.camera);
        let intersects = this.raycaster.intersectObjects(this.clickGroup, true);
        if (intersects.length > 0) {
          document.body.style.cursor = 'pointer';
        } else {
          document.body.style.cursor = 'default';
        }
      }

    },
    onMouseClick(event) {
      let po = document.getElementById("canvas-frame");
      // 将鼠标位置归一化为设备坐标。x 和 y 方向的取值范围是 (-1到 +1)
      if (po && po.offsetLeft) {
        this.mouse.x = ((event.clientX - po.offsetLeft) / po.clientWidth) * 2 - 1;
        this.mouse.y = -((event.clientY - po.offsetTop) / po.clientHeight) * 2 + 1;
        this.isClick = true;
      }
    },
    initThree() {
      let po = document.getElementById("canvas-frame");
      this.width = po.clientWidth;
      this.height = po.clientHeight;
      this.renderer = new THREE.WebGLRenderer({
        antialias: true//耗性能，抗锯齿
      });

      this.renderer.setSize(this.width, this.height);
      this.renderer.outputEncoding = THREE.sRGBEncoding;
      this.renderer.shadowMap.enable = true;
      document.getElementById('canvas-frame').appendChild(this.renderer.domElement);

      this.clock = new THREE.Clock();
      this.raycaster = new THREE.Raycaster();

    },
    initCamera() {
      this.camera = new THREE.PerspectiveCamera(45, this.width / this.height, 10, 1000);
      // camera.position.z = 1;
      this.camera.position.set(this.positionStart.x, this.positionStart.y, this.positionStart.x);
      this.camera.lookAt(0, 0, 0);
      this.controls = new OrbitControls(this.camera, this.renderer.domElement);
      this.controls.autoRotate = false;//摄像机转动
      this.controls.autoRotateSpeed = 1;
      this.controls.enablePan = true;

      this.controls.maxPolarAngle = (Math.PI / 1.9) //控制器垂直方向最大旋转角度（理解为逆时针旋转角度）
      this.controls.minDistance = 25; //限制视线最近距离
      this.controls.maxDistance = 100;//限制视线多远距离
      this.controls.enableDamping = true;  //是否开起控制器阻尼系数（理解为对旋转的阻力，系数越小阻力越小）
      this.controls.dampingFactor = 0.6;//设置阻尼系数（如果设置阻尼系数，这涉及到了模型的动态渲染所以在渲染器中需要一直调用.update()。调用update()的前提是需要建立一个时钟 如下）
    },

    initLight() {
      //环境光
      this.light = new THREE.AmbientLight(0x666666);
      //定义光源位置
      this.light.position.set(-1000, 1000, 1000);
      this.scene.add(this.light);


      let directionalLight = new THREE.DirectionalLight(0xcccccc, 1.1)
      directionalLight.position.set(160, 400, 10)
      directionalLight.castShadow = true;
      const d = 1000 //阴影范围
      directionalLight.shadow.camera.left = -d
      directionalLight.shadow.camera.right = d
      directionalLight.shadow.camera.top = d
      directionalLight.shadow.camera.bottom = -d
      directionalLight.shadow.camera.near = 80
      directionalLight.shadow.camera.far = 200
      directionalLight.shadow.mapSize.x = 2048 // 定义阴影贴图的宽度和高度,必须为2的整数此幂
      directionalLight.shadow.mapSize.y = 2048 // 较高的值会以计算时间为代价提供更好的阴影质量
      directionalLight.shadow.bias = -0.0005 //解决条纹阴影的出现
      //添加到场景
      this.scene.add(directionalLight);

      // this.outlineObj([])
    },

    initScene() {
      this.scene = new THREE.Scene();

      //雾化效果
      this.scene.fog = new THREE.Fog(0xcce0ff, 60, 500);

      //天空盒
      this.scene.background = new THREE.CubeTextureLoader()
          .load([require('../image/screen/px.jpg'), require('../image/screen/nx.jpg'), require('../image/screen/py.jpg'), require('../image/screen/ny.jpg'), require('../image/screen/pz.jpg'), require('../image/screen/nz.jpg')]
          );


      //创建平面
      let planeGeo = new THREE.PlaneGeometry(10000, 10000);//平面在x,y的长度(以平面中间处于原点的位置向轴两边扩散)
      let textureLoader = new THREE.TextureLoader();//在此加载贴图

      let structureItem = JSON.parse(localStorage.getItem('structureItem'));
      let bgImg = require('../image/screen/modal_bg.jpg');
      switch (structureItem.STRUCTURE_ID) {
        case 9://'红崖子沟特大桥'
        case 46://'客专西街2号高架桥'
          bgImg = require('../image/screen/modal_bg.jpg');
          break;
        case 10://'跨线特大桥'
          bgImg = require('../image/screen/modal_bg4.jpg');
          break;
        case 18://'大鄂博图沟特大桥'
          bgImg = require('../image/screen/modal_bg2.jpg');
          break;
      }

      textureLoader.meshFloorTexture = textureLoader.load(bgImg);
      textureLoader.meshFloorTexture.wrapS = textureLoader.meshFloorTexture.wrapT = THREE.RepeatWrapping
      textureLoader.meshFloorTexture.repeat.set(200, 200)//plane网格数量
      let planeMat = new THREE.MeshLambertMaterial({
        map: textureLoader.meshFloorTexture,
        // transparent:true
      });

      let plane = new THREE.Mesh(planeGeo, planeMat);
      plane.receiveShadow = true; //平面接受阴影

      //水平面旋转并且设置位置
      plane.rotation.x = -0.5 * Math.PI;
      plane.position.x = 0;
      plane.position.y = 0;
      plane.position.z = 0;
      this.scene.add(plane);

      this.scene.add(this.camera);

      //坐标轴
      // let axes = new THREE.AxisHelper(100);//括号里面是长度
      // this.scene.add(axes);
    },


    //大桥模型
    initModalAndPoint() {
      let loader = new GLTFLoader();
      let self = this;
      loader.load('/modal/hyzgtdq_bridge0627.glb', function (gltf) {
        self.loadBridge(gltf, 0, true);
        for (let i = 0; i < 3; i++) {
          self.loadBridge(gltf, (i + 1) * 120, false);
          self.loadBridge(gltf, -(i + 1) * 120, false);
        }
      })
    },



    initObject() {
      this.initModalAndPoint();
    },
    render() {
      if (this.renderer)
        requestAnimationFrame(this.render);
      else return;
      TWEEN.update();
      if (this.controls)
        this.controls.update();

      // let delta = this.clock.getDelta();
      // for (let i = 0; i < this.mixers.length; i++) { // 重复播放动画
      //   this.mixers[i].update(delta);
      // }
      this.divRender();
      if (this.isClick) {
        // 通过摄像机和鼠标位置更新射线
        this.raycaster.setFromCamera(this.mouse, this.camera);
        // 计算物体和射线的焦点
        let intersects = null;
        intersects = this.raycaster.intersectObjects(this.clickGroup, true);
        let INTERSECTED = null;
        if (intersects.length > 0) {
          if (INTERSECTED != intersects[0].object) {
            INTERSECTED = intersects[0].object;

            this.outlineObj([INTERSECTED.parent]);

            let index = INTERSECTED.parent.index;
            this.$emit('pointClick', {index: index})
            this.isClick = false;

            let parentPo = INTERSECTED.parent.position;
            this.pointPosition = new THREE.Vector3(parentPo.x, parentPo.y, parentPo.z);
            this.divRender();
          }
        } else {
          INTERSECTED = null;
        }
      }
      this.renderer.render(this.scene, this.camera);
      if (this.composer) {
        this.composer.render()
      }
    },

    loadBridge(gltf, z, isFirst) {
      let self = this;
      let dao = gltf.scene.clone();
      gltf.scene.traverse(function (child) {
        if (child.isMesh) {
          child.frustumCulled = true;//模型阴影
          child.castShadow = true;//投射阴影
          child.receiveShadow = true;//接收阴影
        }
      });
      self.scene.add(dao);
      dao.scale.set(0.2, 0.2, 0.2);
      dao.position.set(0, 0, z);
      if (isFirst && this.doAction)
        self.action();
    },
    threeStart() {
      this.initThree();
      this.initCamera();
      this.initScene();
      this.initLight();
      this.initObject();
      this.render();
    },


    clearScene() {
      this.scene.traverse((child) => {
        if (child.material) {
          child.material.dispose();
        }
        if (child.geometry) {
          child.geometry.dispose();
        }
        child = null;
      });
      window.removeEventListener('resize', this.onWindowResize);
      window.removeEventListener('click', this.onMouseClick);
      window.removeEventListener('mousemove', this.onMouseMove);

      this.renderer.renderLists.dispose();
      this.renderer.dispose();
      cancelAnimationFrame(this.render);
      this.renderer.forceContextLoss();
      this.renderer.domElement = null;
      this.renderer.content = null;
      this.renderer = null;
      THREE.Cache.clear();
      TWEEN.removeAll();
      this.isClick = false;
    },
    divRender() {
      if (!this.div) return;
      if (this.pointPosition) {
        //计算三维坐标对应的屏幕坐标
        let windowPosition = this.transPosition(this.pointPosition);
        let left = windowPosition.x;
        let top = windowPosition.y;
        //设置div屏幕位置
        this.div.style.display = "";
        this.div.style.left = left - 135 + 'px';
        this.div.style.top = top - 180 + 'px';
      } else {
        this.div.style.display = "none";
      }

    },
    transPosition(position) {
      let world_vector = new THREE.Vector3(position.x, position.y, position.z);
      let vector = world_vector.project(this.camera);
      let po = document.getElementById("canvas-frame");
      let halfWidth = po.clientWidth / 2,
          halfHeight = po.clientHeight / 2;
      return {
        x: Math.round(vector.x * halfWidth + halfWidth),
        y: Math.round(-vector.y * halfHeight + halfHeight)
      };
    },



    //高亮显示模型（呼吸灯）
    outlineObj(selectedObjects) {

      // 创建一个EffectComposer（效果组合器）对象，然后在该对象上添加后期处理通道。
      let composer = new EffectComposer(this.renderer)

      // 新建一个场景通道  为了覆盖到原理来的场景上
      composer.addPass(new RenderPass(this.scene, this.camera));
      composer.renderTarget1.texture.encoding = THREE.sRGBEncoding;
      composer.renderTarget2.texture.encoding = THREE.sRGBEncoding;

      // 物体边缘发光通道
      let outlinePass = new OutlinePass(new THREE.Vector2(window.innerWidth, window.innerHeight), this.scene, this.camera, selectedObjects)
      console.log(22331,outlinePass)
      outlinePass.selectedObjects = selectedObjects
      outlinePass.maskBufferMaterial = null
      outlinePass.pulsePeriod = 2 //呼吸闪烁速度
      outlinePass.visibleEdgeColor.set(parseInt('0x00ff00')) // 呼吸显示的颜色
      // outlinePass.hiddenEdgeColor.set(parseInt('0xffffff'))// 呼吸消失的颜色
      outlinePass.clear = true

      composer.addPass(outlinePass)

      //自定义的着色器通道 作为参数
      let effectFXAA = new ShaderPass(FXAAShader)
      effectFXAA.uniforms.resolution.value.set(1 / window.innerWidth, 1 / window.innerHeight)
      effectFXAA.renderToScreen = true

      composer.addPass(effectFXAA);
      this.composer = composer;

    },


  },
  beforeDestroy() {
    console.log('清空内存')
    this.clearScene();
  },

}
</script>

<style scoped>
div#canvas-frame {
  width: 100%;
  height: 100%;
}
</style>
