<template>
  <div style="width: 100%;height: 100%;" class="flex-column">
    <span class="size-16 color-333">新增基础数据</span>
    <div class="flex-1" style="margin: 10px 0">
      <a-form :form="form" @submit="handleSubmit" style="width: 100%">
        <a-form-item label="类型名称">
          <a-input placeholder="请输入类型名称" v-decorator="['name', { rules: [{ required: true, message: '请输入类型名称!' }] }]"
                   :allowClear="true"/>
        </a-form-item>
        <a-form-item label="数据类型">
          <a-select @change="categoryChange" placeholder="请选择数据类型" :allowClear="true" v-model="selectCategory">
            <template v-for="item in categoryList">
              <a-select-option :key="item.CLASS_TYPE" :value="item.CLASS_TYPE">
                {{ item.CLASS_NAME }}
              </a-select-option>
            </template>

          </a-select>
        </a-form-item>

        <a-form-item label="排序">
          <a-input placeholder="请输入排序" v-decorator="['sort', { rules: [{ required: true, message: '请输入排序!' }] }]"
                   :allowClear="true"/>
        </a-form-item>

        <a-form-item label="是否显示">
          <a-radio-group v-model="useValue">
            <a-radio :value="1">
              是
            </a-radio>
            <a-radio :value="0">
              否
            </a-radio>
          </a-radio-group>
        </a-form-item>

        <div class="flex-row-center" style="height: 50px;justify-content: flex-end;margin-top: 20px">
          <a-button type="link" @click="mClose">
            关闭
          </a-button>
          <a-button type="primary" html-type="submit">
            保存
          </a-button>
        </div>
      </a-form>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    bean: {
      type: Object,
      default: null
    }, categoryList: {
      type: Array,
      default: null
    },
  },
  data() {
    return {
      selectCategory:undefined,
      useValue: 1,
      form: this.$form.createForm(this, {name: 'data'}),
    }
  },
  mounted() {
    this.form.setFieldsValue({
      sort: "1"
    })
    if (this.bean) {
      this.useValue = this.bean.IS_SHOW;
      this.selectCategory = this.bean.CLASS_TYPE;
      this.form.setFieldsValue({
        name: this.bean.CLASS_VALUE,
        sort:this.bean.SORT
      })
    }
  },

  methods: {
    mClose() {
      this.$emit('modalClose', {refresh: false})
    },
    handleSubmit(e) {
      e.preventDefault();
      let self = this;
      this.form.validateFields((err, values) => {
        if (!err) {
          let cnName = '';
          for (let i = 0; i < self.categoryList.length; i++) {
            if(self.selectCategory==self.categoryList[i].CLASS_TYPE){
              cnName = self.categoryList[i].CLASS_NAME;
              break;
            }
          }
          let p = {
            action: "ADD",
            classValue: values.name,
            classType:self.selectCategory,
            cnName:cnName,
            isShow: self.useValue,
            sort:values.sort
          }
          if (this.bean) {
            p.action = 'EDIT';
            p.dataId = this.bean.DATA_ID;
          }
          this.$util.post('admin/submitData', p).then((res) => {
            console.log(res);
            this.$emit('modalClose', {refresh: true})

          });
        }
      });
    }, categoryChange(value) {
      this.selectCategory = value;
    }
  }
}
</script>

<style scoped>
</style>
