<template>
  <a-config-provider :locale="zh_CN">
    <div id="app" style="height: 100%;background-color: rgb(8,14,20)" class="flex-column">
      <router-view style="height: 100%"></router-view>
    </div>
  </a-config-provider>
</template>
<script>
import zh_CN from "ant-design-vue/lib/locale-provider/zh_CN";

export default {
  name: 'App',

  data() {
    return {
      windowHeight: document.documentElement.clientHeight,
      zh_CN
    }
  },
  mounted() {
    this.$message.config({
      maxCount: 3
    });

    this.$util.eventBus.$on(this.$util.notifyKey.notifyMessage, (data) => {
      this.$message.error(data.name);
    });
    this.$util.eventBus.$on(this.$util.notifyKey.login, () => {
      this.$router.replace('/');
    })
    this.$util.eventBus.$on(this.$util.notifyKey.loginOut, () => {
      this.$router.replace('/login');
    })

    this.$util.eventBus.$on(this.$util.notifyKey.loginManage, () => {
      this.$router.replace('/manage');
    })
    this.$util.eventBus.$on(this.$util.notifyKey.loginOutManage, () => {
      this.$router.replace('/manageLogin');
    })

    console.log('app页面',this.$router)
    // let user = localStorage.getItem('userInfo');
    // if (user) {
    //   this.$util.get('api/system', {}).then((ret) => {
    //     localStorage.setItem('systemInfo', JSON.stringify(ret.content));
    //   });
    // } else {
    //   this.$router.replace('/login');
    // }

    console.log('屏幕高度：', this.windowHeight);
  },
  beforeDestroy() {
    this.$util.eventBus.$off(this.$util.notifyKey.login) //解绑事件
    this.$util.eventBus.$off(this.$util.notifyKey.notifyMessage) //解绑事件
    this.$util.eventBus.$off(this.$util.notifyKey.loginOut) //解绑事件
    this.$util.eventBus.$off(this.$util.notifyKey.loginManage) //解绑事件
    this.$util.eventBus.$off(this.$util.notifyKey.loginOutManage) //解绑事件
  },
  methods: {
  }
}
</script>

<style>
@import "./common/common.css";
@import "./common/map.css";
</style>
