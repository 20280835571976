<template>
  <div style="margin: 20px;">
    <a-page-header
        style="padding: 0;margin: 15px 0"
        title="边坡管理"
        @back="back()"
    />
    <div style="background: #fff; padding: 30px;">
      <div class="size-16 color-000-85 bold" style="margin-bottom: 20px;">
        基本信息
      </div>
      <a-row>
        <a-col :span="8">
          <div class="flex-row-center size-14">
            <div class="color-000-85">边坡名称：</div>
            <div class="color-000-65">{{ data.STRUCTURE_NAME }}</div>
          </div>
        </a-col>
        <a-col :span="8">
          <div class="flex-row-center size-14">
            <div class="color-000-85">边坡简称：</div>
            <div class="color-000-65">{{ data.SHORT_NAME }}</div>
          </div>
        </a-col>
        <a-col :span="8">
          <div class="flex-row-center size-14">
            <div class="color-000-85">边坡类型：</div>
            <div class="color-000-65">{{ data.sideSlopeInfoMap.SLOPE_TYPE_NAME }}</div>
          </div>
        </a-col>
      </a-row>


      <a-row style="margin-top: 30px;">
        <a-col :span="8">
          <div class="flex-row-center size-14">
            <div class="color-000-85">角度：</div>
            <div class="color-000-65">{{ data.sideSlopeInfoMap.ANGLE }}</div>
          </div>
        </a-col>
        <a-col :span="8">
          <div class="flex-row-center size-14">
            <div class="color-000-85">设计安全等级：</div>
            <div class="color-000-65">{{ data.sideSlopeInfoMap.SAFE_LEVEL }}</div>
          </div>
        </a-col>
        <a-col :span="8">
          <div class="flex-row-center size-14">
            <div class="color-000-85">评定时间：</div>
            <div class="color-000-65">{{ data.sideSlopeInfoMap.ASSESS_DATES }}</div>
          </div>
        </a-col>
      </a-row>

      <a-row style="margin-top: 30px;">
        <a-col :span="8">
          <div class="flex-row-center size-14">
            <div class="color-000-85">起始桩号：</div>
            <div class="color-000-65">{{ data.sideSlopeInfoMap.START_NUMBER }}</div>
          </div>
        </a-col>
        <a-col :span="8">
          <div class="flex-row-center size-14">
            <div class="color-000-85">中心桩号：</div>
            <div class="color-000-65">{{ data.PILE_NUMBER }}</div>
          </div>
        </a-col>
        <a-col :span="8">
          <div class="flex-row-center size-14">
            <div class="color-000-85">结束桩号：</div>
            <div class="color-000-65">{{ data.sideSlopeInfoMap.END_NUMBER }}</div>
          </div>
        </a-col>
      </a-row>


      <a-row style="margin-top: 30px;">
        <a-col :span="8">
          <div class="flex-row-center size-14">
            <div class="color-000-85">所属路线：</div>
            <div class="color-000-65">{{ data.sideSlopeInfoMap.START_NUMBER }}</div>
          </div>
        </a-col>
        <a-col :span="8">
        </a-col>
        <a-col :span="8">
        </a-col>
      </a-row>


      <div class="flex-row size-14" style="line-height: 24px; margin-top: 30px;">
        <div class="color-000-85" style="min-width: 70px;">所在位置：</div>
        <div class="color-000-65">{{ data.ADDRESS }}</div>
      </div>
      <div id="container" style="width:100%;height: 300px;margin-top: 15px;" v-if="data.LAT>0&&data.LNG>0"/>

      <div class="flex-row size-14" style="line-height: 24px; margin-top: 30px;">
        <div class="color-000-85" style="min-width: 70px;">边坡简介：</div>
        <div class="color-000-65">{{ data.DESCRIPTION }}</div>
      </div>

      <div style="background: #fff; margin-top: 30px;"
           v-if="data.STRUCTURE_IMAGE_LIST&&data.STRUCTURE_IMAGE_LIST.length>0">
        <div class="color-000-85 size-14" style="min-width: 70px;">图片：</div>
        <viewer :images="data.STRUCTURE_IMAGE_LIST" style="margin-top: 10px">
          <img v-for="item in data.STRUCTURE_IMAGE_LIST" :key="item.SAVE_NAME" :src="imgUrl+item.SAVE_NAME"
               style="width: 300px; height: 180px; object-fit: cover; margin: 0 15px 15px 0;"
               :onerror="errorImg">
        </viewer>
      </div>

      <div class="flex-row-center">
        <div style="background: #fff; margin-top: 30px;" v-if="data.sideSlopeInfoMap.IMG">
          <div class="color-000-85 size-14" style="min-width: 70px;">结构图片：</div>
          <viewer :images="[data.sideSlopeInfoMap.IMG]" style="margin-top: 10px">
            <img v-for="item in [data.sideSlopeInfoMap.IMG]" :key="item" :src="imgUrl+item"
                 style="width: 300px; height: 180px; object-fit: cover; margin: 0 15px 15px 0;"
                 :onerror="errorImg">
          </viewer>
        </div>

        <div style="background: #fff; margin-top: 30px;" v-if="data.sideSlopeInfoMap.DESIGN_IMG">
          <div class="color-000-85 size-14" style="min-width: 70px;">设计图片：</div>
          <viewer :images="[data.sideSlopeInfoMap.DESIGN_IMG]" style="margin-top: 10px">
            <img v-for="item in [data.sideSlopeInfoMap.DESIGN_IMG]" :key="item" :src="imgUrl+item"
                 style="width: 300px; height: 180px; object-fit: cover; margin: 0 15px 15px 0;"
                 :onerror="errorImg">
          </viewer>
        </div>
      </div>
      <div style="height: 100px"></div>
    </div>
  </div>
</template>

<script>
import AMap from "AMap";

let map = null;
export default {
  data() {
    return {
      data: {
        sideSlopeInfoMap: {}
      },
      errorImg: 'this.src="' + require('../../image/icon_default.png') + '"',
      imgUrl: this.$util.getServeImageUrl()
    }
  },
  mounted() {
    this.getInfo();
  },

  methods: {
    initMap() {
      let info = this.data;
      if (info.LAT > 0 && info.LNG > 0) {
        map = new AMap.Map('container', {
          center: [info.LNG, info.LAT],
          resizeEnable: true,
          zoom: 13,
        });


        AMap.plugin(['AMap.ToolBar', 'AMap.Scale', 'AMap.OverView', 'AMap.Geolocation', 'AMap.DistrictSearch', 'AMap.InfoWindow', 'AMap.Polyline'], function () {//异步加载插件
          let marker = new AMap.Marker({
            position: new AMap.LngLat(info.LNG, info.LAT),
            icon: new AMap.Icon({
              image: require('../../image/screen/home_icon_tunnel2.png'),
              size: new AMap.Size(40, 48),  //图标所处区域大小
              imageSize: new AMap.Size(40, 48) //图标大小
            }),
            anchor: 'bottom-center',
            offset: new AMap.Pixel(0, 0),
          });
          map.add(marker);
        });
      }
    },
    getInfo() {
      let self = this;
      if (this.$route.query.id) {
        this.$util.showLoading(self);
        this.$util.get('api/structureInfo/' + this.$route.query.id, {isDetail: 1, isImage: 1}, true).then((res) => {
          self.data = res.content;
          self.$util.hideLoading();
          setTimeout(() => {
            self.initMap();
          }, 500);
        })
      }
    },
    back() {
      this.$router.back();
    },
  }
}
</script>

<style scoped>

</style>
