<template>
  <div style="position: relative; width: 100%;height: 100%" class="flex-row">
    <div style=" width: 100%;overflow: auto;position: relative;" :style="{'height':imgHeight+'px'}">
      <img :src="isDesign?imgBgDesign:imgBg" :width="imgWidthShow"/>

      <div class="u-flex-col-center cursor_pointer" style="position: absolute" :style="{'left':((getXY(deviceInfo).x)+'px'),'top':((getXY(deviceInfo).y)+'px')}" v-if="deviceDetail&&deviceInfo&&deviceInfo.x"  @click="showMarker = true">
        <lottie
            style="z-index: 0;"
            :options="getMarkerImg(deviceDetail)"
            :width="90"
            :height="90"
        />
      </div>
    </div>
    <div class="back-btn flex-row-center cursor_pointer" style="justify-content: center;position: absolute;left: 30px;top: 30px" @click="toPage1()">
      <a-icon type="left" style="color: white;font-size: 18px;margin-left: 3px"/>
      <span class="size-20 color-fff">返回总览</span>
    </div>

    <section class="pop" ref="markerLay" style="position: absolute;width: 300px;z-index: 100" :style="{'left':((getXY(deviceInfo).x-(115))+'px'),'top':((getXY(deviceInfo).y-130)+'px')}" v-if="deviceDetail&&deviceInfo&&deviceInfo.x&&showMarker">
      <div class="pop-body">
        <div class="flex-column size-18 color-fff" style="height: 100%;justify-content: space-between;" >
          <div class="flex-row-center" style="justify-content: space-between">
            <span class="size-20">{{deviceDetail.DEVICE_NAME}}</span>

            <div style="height: 30px; border-radius: 15px; border: 1px solid;margin-right: 10px;padding: 0 10px"
                 class="size-16 u-flex-col-center"
                 :style="{'color':getPointAlarmLevel(deviceDetail.STATE).color,'border-color':getPointAlarmLevel(deviceDetail.STATE).color}">
              {{ getPointAlarmLevel(deviceDetail.STATE).name }}
            </div>
          </div>
          <span style="color:rgba(255,255,255,0.5);" class="size-16">设备编号<span
              style="margin-left: 10px;color:rgba(255,255,255,1);">{{ deviceDetail.DEVICE_NUMBER }}</span></span>
          <span style="color:rgba(255,255,255,0.5);" class="size-16">设备类型<span
              style="margin-left: 10px;color:rgba(255,255,255,1);">{{ deviceDetail.DEVICE_TYPE_NAME }}</span></span>
          <span style="color:rgba(255,255,255,0.5);" class="size-16">绑定状态<span
              style="margin-left: 10px;color:rgba(255,255,255,1);">{{deviceDetail.STATUS?statusList[deviceDetail.STATUS]:'正常'}}</span></span>
        </div>
        <a-icon type="close" style="color: white;font-size: 16px;position: absolute;right: 5px;top: 7px"
                class="cursor_pointer" @click.stop="showMarker=false"/>
      </div>
    </section>

    <div class="marker_bottom_box flex-column" style="padding: 15px;">
      <div class="size-14 color-fff">图例</div>
      <div class="flex-column">
        <div v-for="(item, index) in markerBottomList" :key="index" class="flex-row-center" style="margin-top: 10px">
          <lottie
              :options="item.options"
              :width="40"
              :height="40"
          />
          <div class="size-14 color-fff" style="margin-left: 8px">{{ item.name }}</div>
        </div>
      </div>
    </div>

    <div class="flex-column" style="position: absolute;right: 30px;top: 30px;bottom: 30px;background-color: rgba(3, 34, 64, 0.7);width: 450px;padding: 20px" v-show="!isFolding">
      <div class="flex-row-center">
        <img src="../image/screen/icon_right_dot1.png" style="width: 20px"/>
        <span class="size-22 color-fff" style="margin-left: 10px" v-if="deviceInfo">{{ deviceInfo.DEVICE_NAME }}</span>
        <div class="flex-1"></div>
        <div class="flex-row-center cursor_pointer" @click="isFolding = true">
          <div class="color-fff-80 size-18">折叠</div>
          <img src="../image/screen/folding_icon.png" width="20" style="margin-left: 8px;">
        </div>
      </div>
      <structure_shicheng :key="getTime()" :isFolding="isFolding" :pointInfo="deviceInfo"/>
    </div>


    <!--  折叠的View  -->
    <div
        style="position: absolute;right: 0px;top: 30px;height:125px;background-color: rgba(3, 34, 64, 0.7);width: 50px;padding: 20px 15px 0px; border-radius: 20px 0 0 20px;"
        class="flex-column  cursor_pointer" v-show="isFolding" @click="isFolding = false">
      <div class="flex-column">
        <div class="color-fff-80 size-18">展开</div>
        <img src="../image/screen/folding_icon2.png" width="20" style="margin-top: 10px;">
      </div>
    </div>
  </div>
</template>

<script>
import structure_shicheng from './structure_shicheng';
import icon_wifi_gray from "../image/screen/icon_wifi_gray.json";
import icon_wifi_green from "../image/screen/icon_wifi_green.json";
export default {
  props: {
    pointBean: {
      type: Object,
      default: null,
    },
    isDesign:{
      type: Boolean,
      default: false,
    },
  },
  components: {
    structure_shicheng
  },
  data() {
    return {
      imgBg: '',
      imgBgDesign:'',
      imgWidthShow: 1430 / 1920 * document.documentElement.clientWidth,
      imgHeight: 872 / 1080 * document.documentElement.clientHeight,
      scale: 1,
      deviceInfo: null,
      markerBottomList: [
        {name: '在线设备', options: { animationData: icon_wifi_green, loop: true  }},
        {name: '离线设备', options: { animationData: icon_wifi_gray, loop: true  }}],
      markerBgWidth: 500,
      isFolding: true,  //是否折叠
      markerImg: '',
      deviceDetail:null,
      statusList:['正常','故障','损坏','维修','更换'],
      showMarker:true
    }
  },
  computed: {
    getXY(){
      return function (item) {
        if(this.isDesign){
          return {x:item.xDesign,y:item.yDesign}
        }else{
          return {x:item.x,y:item.y}
        }
      }

    },
    getPointAlarmLevel() {
      return function (state) {
        let color = '', title = '';
        if (state===0) {
          color = '#62DBED';
          title = '在线';
        } else {
          color = '#999999';
          title = '离线';
        }
        return {
          name: title, color: color
        }
      }

    },

    getMarkerImg() {
      return function (item) {
        if (item.STATE === 0) {
          return { animationData: icon_wifi_green, loop: true }
        } else {
          return { animationData: icon_wifi_gray, loop: true }
        }
      }
    },
  },
  mounted() {
    let self = this;
    let structureItem = JSON.parse(localStorage.getItem('structureItem'));
    let structureId = structureItem.STRUCTURE_ID;
    self.deviceInfo = this.pointBean;

    this.$util.get('api/deviceInfo/'+self.deviceInfo.DEVICE_ID,{}).then((res)=>{
      this.deviceDetail = res.content;
    });

    this.$util.get('api/structureInfo/' + structureId, {isDetail: 1, isImage: 1}, true).then((res) => {
      self.structureInfo = res.content;
      let dao = res.content;
      if (dao.bridgeInfoMap) {
        self.imgBg = self.$util.getServeImageUrl() + dao.bridgeInfoMap.IMG;
        self.imgBgDesign = self.$util.getServeImageUrl() + dao.bridgeInfoMap.DESIGN_IMG;
      } else if (dao.sideSlopeInfoMap) {
        self.imgBg = self.$util.getServeImageUrl() + dao.sideSlopeInfoMap.IMG;
        self.imgBgDesign = self.$util.getServeImageUrl() + dao.sideSlopeInfoMap.DESIGN_IMG;
      } else if (dao.tunnelInfoMap) {
        self.imgBg = self.$util.getServeImageUrl() + dao.tunnelInfoMap.LEFT_HOLE;
        self.imgBgDesign = self.$util.getServeImageUrl() + dao.tunnelInfoMap.DESIGN_IMG;
      }

      let img = new Image()
      img.src = self.imgBg;
      img.onload = function () {
      }
    })
  },
  methods: {
    getTime() {
      return new Date().getTime()
    },
    toPage1() {
      this.$emit('toPage1')
    },
  }
}
</script>

<style scoped>
.marker_bottom_box {
  background: rgba(3, 39, 85, 0.56);
  backdrop-filter: blur(10px);
  position: absolute;
  bottom: 30px;
  left: 30px;
  border: 1px solid rgba(255, 255, 255, 0.3);
}

.device-bg {
  width: 410px;
  padding: 10px 15px;
  background: rgba(62, 111, 158, 0.3);
  border: 1px solid;
  border-image: linear-gradient(180deg, rgba(255, 255, 255, 0.3), rgba(98, 219, 237, 0.6)) 1 1;
}

.back-btn{
  width: 150px;
  height: 50px;
  background: #063068;
  border-radius: 25px;
  border: 1px solid #62DBED;
}


</style>
