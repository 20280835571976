<template>
    <div style="width: 100%;height: 100%">
        <v-chart class="my-chart" :options="chart"/>
    </div>
</template>

<script>
  export default {
    props: {
      dataValue: {
        type: Array,
      },
    },
    data() {
      return {
        chart: {},
      }
    },
    mounted() {
      if(this.dataValue&&this.dataValue.length>0){
        let colors = [];
        for (let i = 0; i < this.dataValue.length; i++) {
          colors.push(this.dataValue[i].color);
        }
        this.getChartData(colors);
      }
    },
    methods: {
      getChartData(colors) {
        let self = this;
        this.chart =  {
          tooltip: {
            trigger: 'item'
          },
          color:colors,
          series: [
            {
              type: 'pie',
              radius: ['60%', '70%'],
              avoidLabelOverlap: false,
              itemStyle: {
              },
              label: {
                show: false,
                position: 'center'
              },
              emphasis: {
                label: {
                  show: false,
                }
              },
              labelLine: {
                show: false
              },
              data: self.dataValue
            }
          ]
        }
      },
    }
  }
</script>

<style scoped>
    .my-chart {
        width: 100%;
        height: 100%;
    }
</style>
