<template>
  <div>
    <a-page-header
        style="padding: 0;margin: 15px 0"
        title="隧道管理"
        @back="back()"
    />
    <a-modal
        :bodyStyle="{'height':800+'px'}"
        v-model="showModal"
        :closable="false"
        :footer="null"
        :width="900"
        :destroyOnClose="true"
    >
      <selectAddress @modalClose="modalClose"/>
    </a-modal>
    <div class="flex-1 flex-column" style="margin: 10px 0;background-color: white">
      <span class="size-18 color-333" style="margin: 20px">{{ id ? '编辑' : '新增' }}隧道</span>
      <div class="flex-column" style="width: 100%">
        <a-form :form="form" @submit="handleSubmit" style="width: 100%" labelAlign="left" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }">
          <a-row type="flex" justify="space-around">
            <a-col :span="span">
              <a-form-item label="隧道名称">
                <a-input placeholder="请输入隧道名称"
                         v-decorator="['structureName', { rules: [{ required: true, message: '请输入隧道名称!' }] }]"
                         :allowClear="true"/>
              </a-form-item>
            </a-col>
            <a-col :span="span">
              <a-form-item label="隧道类型">
                <a-select placeholder="请选择隧道类型"
                          v-decorator="['tunnelType', { rules: [{ required: true, message: '请选择隧道类型!' }] }]">
                  <template v-for="item in types">
                    <a-select-option :key="item.DATA_ID" :value="item.DATA_ID">
                      {{ item.CLASS_VALUE }}
                    </a-select-option>
                  </template>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="span">
              <a-form-item label="通车日期">
                <a-date-picker valueFormat="YYYY-MM-DD" placeholder="请选择通车日期" v-decorator="['trafficDate', { rules: [{ required: false}]}]"
                               :allowClear="true" style="width: 100%"/>
              </a-form-item>
            </a-col>
          </a-row>

          <a-row type="flex" justify="space-around">
            <a-col :span="7">
              <a-form-item label="起始桩号">
                <a-input placeholder="请输入起始桩号" v-decorator="['startNumber', { rules: [{ required: true, message: '请输入起始桩号!' }] }]"
                         :allowClear="true"/>
              </a-form-item>
            </a-col>
<!--            <a-col :span="7">-->
<!--              <a-form-item label="中心桩号">-->
<!--                <a-input placeholder="请输入中心桩号" v-decorator="['pileNumber', { rules: [{ required: true, message: '请输入中心桩号!'}]}]"-->
<!--                         :allowClear="true"/>-->
<!--              </a-form-item>-->
<!--            </a-col>-->
            <a-col :span="7">
              <a-form-item label="结束桩号">
                <a-input placeholder="请输入结束桩号" v-decorator="['endNumber', { rules: [{ required: true, message: '请输入结束桩号!'}]}]"
                         :allowClear="true"/>
              </a-form-item>
            </a-col>
            <a-col :span="7">
            </a-col>
          </a-row>

          <a-row type="flex" justify="space-around">
            <a-col :span="span">
              <a-form-item label="隧道长度(m)">
                <a-input placeholder="请输入隧道长度" v-decorator="['tunnelLength', { rules: [{ required: true,message:'请输入隧道长度' }] }]"
                         :allowClear="true"/>
              </a-form-item>
            </a-col>
            <a-col :span="span">
              <a-form-item label="隧道高度(m)">
                <a-input placeholder="请输入隧道高度" v-decorator="['tunnelHeight', { rules: [{ required: true,message:'请输入隧道高度'}]}]"
                         :allowClear="true"/>
              </a-form-item>
            </a-col>
            <a-col :span="span">
              <a-form-item label="隧道宽度(m)">
                <a-input placeholder="请输入隧道宽度" v-decorator="['tunnelWidth', { rules: [{ required: true,message:'请输入隧道宽度'}]}]"
                         :allowClear="true"/>
              </a-form-item>
            </a-col>
          </a-row>

          <a-row type="flex" justify="space-around">
            <a-col :span="span">
              <a-form-item label="排水类型">
                <a-select placeholder="排水类型" v-decorator="['drainageType', { rules: [{ required: false }] }]">
                  <template v-for="item in drainageTypes">
                    <a-select-option :key="item.DATA_ID" :value="item.DATA_ID">
                      {{ item.CLASS_VALUE }}
                    </a-select-option>
                  </template>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="span">
              <a-form-item label="通风方式">
                <a-select placeholder="请选择通风方式" v-decorator="['ventilantionMode', { rules: [{ required: false}] }]">
                  <template v-for="item in ventilantionModes">
                    <a-select-option :key="item.DATA_ID" :value="item.DATA_ID">
                      {{ item.CLASS_VALUE }}
                    </a-select-option>
                  </template>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="span">
              <a-form-item label="车道数">
                <a-input-number placeholder="请输入车道数" style="width: 100%" v-decorator="['lineNums', { rules: [{ required: false}]}]" :allowClear="true"  :min="0" :precision="0"/>
              </a-form-item>
            </a-col>
          </a-row>

          <a-row type="flex" justify="space-around">
            <a-col :span="span">
              <a-form-item label="简称">
                <a-input placeholder="请输入简称，首字母大写" v-decorator="['shortName', { rules: [{ required: false }] }]"
                         :allowClear="true"/>
              </a-form-item>
            </a-col>
            <a-col :span="span">
              <a-form-item label="所属路线">
                <a-input placeholder="请输入所属路线" v-decorator="['routeName', { rules: [{ required: false }] }]"
                         :allowClear="true"/>
              </a-form-item>
            </a-col>
            <a-col :span="span">
            </a-col>
          </a-row>


          <a-row type="flex" justify="space-around" >
            <a-col :span="span">
              <a-form-item label="有无报警">
                <a-radio-group v-decorator="['isAlarm', {initialValue: 1,rules: [{ required: false}]}]" style="width: 100%;">
                  <a-radio :value="1">有</a-radio>
                  <a-radio :value="0">无</a-radio>
                </a-radio-group>
              </a-form-item>
            </a-col>
            <a-col :span="span">
              <a-form-item label="是否双洞">
                <a-radio-group v-decorator="['isDouble', {initialValue: 1, rules: [{ required: false}]}]" style="width: 100%;">
                  <a-radio :value="1">是</a-radio>
                  <a-radio :value="0">否</a-radio>
                </a-radio-group>
              </a-form-item>
            </a-col>
            <a-col :span="span">
              <a-form-item label="有无消防">
                <a-radio-group v-decorator="['isFire', {initialValue: 1, rules: [{ required: false}]}]" style="width: 100%;">
                  <a-radio :value="1">有</a-radio>
                  <a-radio :value="0">无</a-radio>
                </a-radio-group>
              </a-form-item>
            </a-col>
          </a-row>

          <a-row type="flex" justify="space-around">
            <a-col :span="span">
              <a-form-item label="有无照明">
                <a-radio-group v-decorator="['isLight', {initialValue: 1, rules: [{ required: false}]}]" style="width: 100%;">
                  <a-radio :value="1">有</a-radio>
                  <a-radio :value="0">无</a-radio>
                </a-radio-group>
              </a-form-item>
            </a-col>
            <a-col :span="span">
              <a-form-item label="有无监控">
                <a-radio-group v-decorator="['isMonitor', {initialValue: 1, rules: [{ required: false}]}]" style="width: 100%;">
                  <a-radio :value="1">有</a-radio>
                  <a-radio :value="0">无</a-radio>
                </a-radio-group>
              </a-form-item>
            </a-col>
            <a-col :span="span">
            </a-col>
          </a-row>
          <a-row type="flex" justify="space-around" style="margin-bottom: 20px ">
            <a-col :span="11">
              <div class="flex-row-center">
                <span>标识地图：</span>
                <a-button type="primary" icon="environment" style="margin-left: 20px" @click="showModal=true">
                  标识地图
                </a-button>
                <a-input placeholder="经度" :readOnly="false" style="margin-left: 20px" class="flex-1"
                         :value="locationSelect?locationSelect.lng:''"/>
                <a-input placeholder="纬度" :readOnly="false" style="margin-left: 20px" class="flex-1"
                         :value="locationSelect?locationSelect.lat:''"/>
              </div>
            </a-col>
            <a-col :span="11">
              <a-input placeholder="地址" :readOnly="false" :value="locationSelect?locationSelect.address:''"/>
            </a-col>
          </a-row>

          <a-row type="flex" justify="space-around">
            <a-col :span="23">
              <a-form-item label="隧道简介:" :wrapper-col="{ span: 24 }">
                <a-textarea placeholder="请输入隧道简介" v-decorator="['description', { rules: [{ required: false}]}]"
                            :allowClear="true" :auto-size="{ minRows: 2}"/>
              </a-form-item>
            </a-col>
          </a-row>

          <a-row type="flex" justify="space-around">
            <a-col :span="5">
              <a-form-item label="左洞结构图"  :required="true" :wrapper-col="{ span: 24 }">
                <a-upload
                    accept=".jpg,.png,.jpeg"
                    list-type="picture-card"
                    :action="actionUrl"
                    :file-list="fileListLeft"
                    @change="uploadCallbackLeft"
                    @preview="handlePreview"
                >
                  <div v-if="fileListLeft&&fileListLeft.length ==0">
                    <a-icon :type="loadingLeft ? 'loading' : 'plus'"/>
                    <div>
                      点击上传
                    </div>
                  </div>
                </a-upload>
              </a-form-item>
            </a-col>
            <a-col :span="5">
              <a-form-item label="右洞结构图" :required="true" :wrapper-col="{ span: 24 }">
                <a-upload
                    accept=".jpg,.png,.jpeg"
                    list-type="picture-card"
                    :action="actionUrl"
                    :file-list="fileListRight"
                    @change="uploadCallbackRight"
                    @preview="handlePreview"
                >
                  <div v-if="fileListRight&&fileListRight.length ==0">
                    <a-icon :type="loadingRight ? 'loading' : 'plus'"/>
                    <div>
                      点击上传
                    </div>
                  </div>
                </a-upload>
              </a-form-item>
            </a-col>
            <a-col :span="11">
              <a-form-item label="隧道图片" :wrapper-col="{ span: 24 }">
                <a-upload
                    accept=".jpg,.png,.jpeg"
                    name="imgFile"
                    list-type="picture-card"
                    :action="actionUrl"
                    :file-list="fileList"
                    @change="uploadCallback"
                    @preview="handlePreview"
                    :multiple="true"
                    :remove="handleRemove"
                >
                  <div v-if="fileList&&fileList.length <12">
                    <a-icon :type="loading ? 'loading' : 'plus'"/>
                    <div>
                      点击上传
                    </div>
                  </div>
                </a-upload>
              </a-form-item>

            </a-col>
          </a-row>

          <a-row type="flex" justify="space-around">
            <a-col :span="span">
              <a-form-item label="设计效果图" :required="true" :wrapper-col="{ span: 24 }">
                <a-upload
                    accept=".jpg,.png,.jpeg"
                    list-type="picture-card"
                    :action="actionUrl"
                    :file-list="fileListDesign"
                    @change="uploadCallbackDesign"
                    @preview="handlePreview"
                >
                  <div v-if="fileListDesign&&fileListDesign.length ==0">
                    <a-icon :type="loadingDesign ? 'loading' : 'plus'"/>
                    <div>
                      点击上传
                    </div>
                  </div>
                </a-upload>
              </a-form-item>
            </a-col>
            <a-col :span="span">
            </a-col>
            <a-col :span="span">
            </a-col>
          </a-row>

          <div class="flex-row-center" style="height: 50px;justify-content: flex-end;margin: 30px 50px;">
            <a-button type="link" @click="back">
              取消
            </a-button>
            <a-button type="primary" html-type="submit">
              保存
            </a-button>
          </div>
        </a-form>
      </div>

    </div>
    <a-modal :visible="previewVisible" :footer="null" @cancel="previewVisible = false">
      <img alt="预览" style="width: 100%" :src="previewImage"/>
    </a-modal>
  </div>
</template>

<script>
import selectAddress from '../components/select_address';

export default {
  components: {
    selectAddress
  },
  data() {
    return {
      showModal: false,
      span: 7,
      form: this.$form.createForm(this, {name: 'bridge'}),
      id: undefined,
      types: [],
      ventilantionModes: [],
      drainageTypes: [],
      previewVisible: false,
      previewImage: '',
      actionUrl: '/client/api/uploadFile',
      loading: false,
      fileList: [],
      loadingLeft: false,
      fileListLeft: [],
      loadingRight: false,
      fileListRight: [],
      locationSelect: null,
      fileListDesign:[],
      loadingDesign:false,
    }
  },
  mounted() {
    let self = this;

    if (this.$route.query.id) {
      this.$util.showLoading(self);
      this.id = this.$route.query.id;
      this.$util.get('api/structureInfo/' + this.$route.query.id, {isDetail: 1, isImage: 1}, true).then((res) => {
        self.bean = res.content;
        self.$util.hideLoading();
        let dao = res.content;
        self.form.setFieldsValue({
          structureName: dao.STRUCTURE_NAME,
          description: dao.DESCRIPTION,
          // pileNumber: dao.PILE_NUMBER,
          routeName: dao.ROUTE_NAME,
          shortName: dao.SHORT_NAME,
        })
        if (dao.tunnelInfoMap) {
          self.form.setFieldsValue({
            drainageType:  dao.tunnelInfoMap.DRAINAGE_TYPE,
            isAlarm:  dao.tunnelInfoMap.IS_ALARM,
            isDouble:  dao.tunnelInfoMap.IS_DOUBLE,
            isFire:  dao.tunnelInfoMap.IS_FIRE,
            isLight:  dao.tunnelInfoMap.IS_LIGHT,
            isMonitor:  dao.tunnelInfoMap.IS_MONITOR,
            lineNums:  dao.tunnelInfoMap.LINE_NUMS,
            tunnelHeight:  dao.tunnelInfoMap.TUNNEL_HEIGHT,
            tunnelLength:  dao.tunnelInfoMap.TUNNEL_LENGTH,
            tunnelWidth:  dao.tunnelInfoMap.TUNNEL_WIDTH,
            tunnelType:  dao.tunnelInfoMap.TUNNEL_TYPE,
            ventilantionMode:  dao.tunnelInfoMap.VENTILATION_MODE,
            endNumber: dao.tunnelInfoMap.END_NUMBER,
            startNumber: dao.tunnelInfoMap.START_NUMBER,
            trafficDate: dao.tunnelInfoMap.TRAFFIC_DATES,


          })

          let picListLeft = [];
          if (dao.tunnelInfoMap.LEFT_HOLE) {
            picListLeft.push(
                {
                  uid: '-1024',
                  name: dao.tunnelInfoMap.LEFT_HOLE,
                  key: dao.tunnelInfoMap.LEFT_HOLE,
                  status: 'done',
                  url: self.$util.getServeImageUrl() + dao.tunnelInfoMap.LEFT_HOLE,
                }
            )
          }
          self.fileListLeft = picListLeft;

          let picListRight = [];
          if (dao.tunnelInfoMap.RIGHT_HOLE) {
            picListRight.push(
                {
                  uid: '-1025',
                  name: dao.tunnelInfoMap.RIGHT_HOLE,
                  key: dao.tunnelInfoMap.RIGHT_HOLE,
                  status: 'done',
                  url: self.$util.getServeImageUrl() + dao.tunnelInfoMap.RIGHT_HOLE,
                }
            )
          }
          self.fileListRight = picListRight;

          let picListDesign = [];
          if(dao.tunnelInfoMap.DESIGN_IMG){
            picListDesign.push(
                {
                  uid: '-1024',
                  name:dao.tunnelInfoMap.DESIGN_IMG,
                  key: dao.tunnelInfoMap.DESIGN_IMG,
                  status: 'done',
                  url: self.$util.getServeImageUrl() + dao.tunnelInfoMap.DESIGN_IMG,
                }
            )
          }
          self.fileListDesign = picListDesign;
        }
        self.locationSelect = {
          lat: dao.LAT,
          lng: dao.LNG,
          address: dao.ADDRESS
        }
        if (dao.STRUCTURE_IMAGE_LIST && dao.STRUCTURE_IMAGE_LIST.length > 0) {
          let picList = [];
          for (let i = 0; i < dao.STRUCTURE_IMAGE_LIST.length; i++) {
            let item = dao.STRUCTURE_IMAGE_LIST[i];
            picList.push(
                {
                  uid: '-' + i,
                  name: item.FILE_NAME,
                  caption: item.FILE_NAME,
                  key: item.SAVE_NAME,
                  status: 'done',
                  url: self.$util.getServeImageUrl() + item.SAVE_NAME,
                }
            )
          }
          self.fileList = picList;
        }

      })
    }else{
      self.form.setFieldsValue({
        isAlarm: 1,
        isDouble: 1,
        isFire: 1,
        isLight: 1,
        isMonitor: 1,
      })
    }

    this.$util.get('api/dataList', {
      page: 1,
      pageSize: 100,
      classType: 'TUNNEL_TYPE'
    }, true).then((res) => {
      self.types = res.content
    })

    this.$util.get('api/dataList', {
      page: 1,
      pageSize: 100,
      classType: 'TUNNEL_TYPE'
    }, true).then((res) => {
      self.types = res.content
    });

    this.$util.get('api/dataList', {
      page: 1,
      pageSize: 100,
      classType: 'VENTILATION_MODE'
    }, true).then((res) => {
      self.ventilantionModes = res.content
    });

    this.$util.get('api/dataList', {
      page: 1,
      pageSize: 100,
      classType: 'DRAINAGE_TYPE'
    }, true).then((res) => {
      self.drainageTypes = res.content
    });
  },

  methods: {
    handleSubmit(e) {
      e.preventDefault();
      let self = this;
      this.form.validateFields((err, values) => {
        if (!err) {
          console.log(222,values)
          let p = {
            action: "ADD",
            structureName: values.structureName,
            drainageType: values.drainageType,
            description: values.description,
            isAlarm: values.isAlarm,
            isDouble: values.isDouble,
            isFire: values.isFire,
            isLight: values.isLight,
            isMonitor: values.isMonitor,
            lineNums: values.lineNums,
            tunnelHeight: values.tunnelHeight,
            tunnelLength: values.tunnelLength,
            tunnelWidth: values.tunnelWidth,
            tunnelType: values.tunnelType,
            ventilantionMode: values.ventilantionMode,
            endNumber: values.endNumber,
            // pileNumber: values.pileNumber,
            startNumber: values.startNumber,
            trafficDate: values.trafficDate,

            routeName: values.routeName,
            shortName: values.shortName,
          }

          if (self.locationSelect) {
            p.lat = self.locationSelect.lat;
            p.lng = self.locationSelect.lng;
            p.address = self.locationSelect.address;
          }

          let picList = [];
          if (self.fileList.length > 0) {
            for (let i = 0; i < self.fileList.length; i++) {
              console.log(i)
              let dao = self.fileList[i].response;
              if (dao && dao.errorCode == 0 && dao.content && dao.content.initialPreviewConfig && dao.content.initialPreviewConfig.length > 0) {
                picList.push({
                  key: dao.content.initialPreviewConfig[0].key,
                  caption: dao.content.initialPreviewConfig[0].caption,
                })
              } else {
                picList.push({
                  key: self.fileList[i].key,
                  caption: self.fileList[i].caption,
                })
              }

            }
          }
          let keys = [], caption = [];
          if (picList.length > 0) {
            for (let i = 0; i < picList.length; i++) {
              keys.push(picList[i].key)
              caption.push(picList[i].caption)
            }
          }
          p.imgCaptions = caption.join(',');
          p.imgKeys = keys.join(',');


          if (self.fileListLeft.length > 0) {
            let dao = self.fileListLeft[0].response;
            if (dao && dao.errorCode == 0 && dao.content && dao.content.initialPreviewConfig && dao.content.initialPreviewConfig.length > 0) {
              p.leftHole = dao.content.initialPreviewConfig[0].key;
            }else{
              p.leftHole = self.fileListLeft[0].key;
            }
          }else{
            this.$message.error('请上传左洞结构图图');
            return;
          }
          if (self.fileListRight.length > 0) {
            let dao = self.fileListRight[0].response;
            if (dao && dao.errorCode == 0 && dao.content && dao.content.initialPreviewConfig && dao.content.initialPreviewConfig.length > 0) {
              p.rightHole = dao.content.initialPreviewConfig[0].key;
            }else{
              p.rightHole = self.fileListRight[0].key;
            }
          }else{
            this.$message.error('请上传右洞结构图');
            return;
          }

          if (self.fileListDesign.length > 0) {
            let dao = self.fileListDesign[0].response;
            if (dao && dao.errorCode == 0 && dao.content && dao.content.initialPreviewConfig && dao.content.initialPreviewConfig.length > 0) {
              p.designImg = dao.content.initialPreviewConfig[0].key;
            }else{
              p.designImg = self.fileListDesign[0].key;
            }
          }else{
            this.$message.error('请上传设计效果图')
            return;
          }

          if (this.bean) {
            p.action = 'EDIT';
            p.structureId = this.bean.STRUCTURE_ID;
          }
          console.log(33,p);
          this.$util.post('api/submitTunnel', p).then((res) => {
            self.back();
          });
        }
      });
    },
    back() {
      this.$router.back();
    }, modalClose(params) {
      if (params)
        this.locationSelect = params.locationSelect;
      this.showModal = false;
    }, handleRemove(file) {
      file.status = '1';
      let name = '';
      if (file.response) {
        name = file.response.content.initialPreviewConfig[0].key
      } else {
        name = file.name
      }
      this.$util.post("api/deleteFile?saveName=" + name, {})
    }, uploadCallback({file, fileList}) {
      this.fileList = fileList;
    }, uploadCallbackLeft({file, fileList}) {
      this.fileListLeft = fileList;
    }, uploadCallbackRight({file, fileList}) {
      this.fileListRight = fileList;
    },uploadCallbackDesign({file, fileList}) {
      this.fileListDesign = fileList;
    },
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await this.$util.getBase64(file.originFileObj);
      }
      this.previewImage = file.url || file.preview;
      this.previewVisible = true;
    },
  }
}
</script>

<style scoped>

</style>
