<template>
    <div class="head_bg">
        <img src="../../image/screen/header_bg.png" width="100%" height="100%">
        <div style="position: absolute; top: 0; left: 0; bottom: 0; right: 0;">
            <div style="line-height: 80px;" class="flex-row-center">
                <div class="title_1 margin-left-30 cursor_pointer" @click="backManage()">敦当高速</div>
                <div class="title_2 margin-left-8 margin-right-30">在线监测系统</div>
                <img src="../../image/screen/icon_arrow.png" height="20">
                <div class="flex-1"></div>
                <div class="date" style="margin-top: 7px;">{{ currentTime }}</div>
                <div class="week" style="margin: 0 60px 0 20px;">{{ currentWeek }}</div>
            </div>

        </div>
    </div>
</template>

<script>
  export default {
    name: "header1",
    props: {},
    data() {
      return {
        currentTime: this.$util.dateFormat(new Date(), this.$util.dateFormatStr.dateYMDHMS),
        currentWeek: this.$util.getWeek(new Date()),
      }
    },
    mounted() {
      this.timer = setInterval(this.getCurrentDate, 1000);
    },

    beforeDestroy() {
      clearInterval(this.timer);
    },

    computed: {},
    methods: {
      getCurrentDate() {
        this.currentTime = this.$util.dateFormat(new Date(), this.$util.dateFormatStr.dateYMDHMS);
      },
      backManage(){
        this.$router.push({path: '/'})
      }
    }
  }
</script>

<style scoped>
    .head_bg {
        width: 100%;
        height: 80px;
        position: relative;
        z-index: 2;
    }

    .title_1 {
        font-size: 36px;
        font-family: PangMenZhengDao;
        color: #E9EBF7;
        letter-spacing: 1px;
        text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
        background: linear-gradient(0deg, #FFCC4E 0%, #FF9129 100%);
        /*background: linear-gradient(180deg, #FF9129 100%, #FF9129 100%);*/
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-weight: bold;
    }

    .title_2 {
        font-size: 36px;
        font-family: PangMenZhengDao;
        color: #E9EBF7;
        line-height: 41px;
        letter-spacing: 1px;
        text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
        background: linear-gradient(180deg, #FFFFFF 0%, #B1EBFF 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-weight: bold;
    }

    .date {
        font-size: 24px;
        font-family: DINCondensed-Bold, DINCondensed;
        font-weight: bold;
        color: #FFFFFF;
    }

    .week {
        font-size: 20px;
        font-family: PingFangSC-Regular, PingFang SC;
        color: #A0CAFF;
    }
</style>
