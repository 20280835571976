<template>
  <div>
    <a-page-header
        style="padding: 0;margin: 15px 0"
        title="边坡管理"
        @back="back()"
    />
    <div class="flex-1 flex-column" style="margin: 20px;background-color: white;padding: 20px 0">
      <span class="size-18 color-333" style="margin: 20px">{{ id ? '编辑' : '新增' }}角色</span>
      <div class="flex-column" style="width: 100%">
        <a-form :form="form" @submit="handleSubmit" style="width: 100%" labelAlign="left" :label-col="{ span: 5 }"
                :wrapper-col="{ span: 18 }">
          <a-row type="flex" justify="space-around">
            <a-col :span="span">
              <a-form-item label="角色名称">
                <a-input placeholder="请输入角色名称"
                         v-decorator="['roleName', { rules: [{ required: true, message: '请输入登录账号!' }] }]"
                         :allowClear="true"/>
              </a-form-item>
            </a-col>
            <a-col :span="span">
              <a-form-item label="角色描述">
                <a-textarea placeholder="请输入角色描述" v-decorator="['description', { rules: [{ required: false }] }]"
                            :allowClear="true" :auto-size="{ minRows: 3}"/>
              </a-form-item>
            </a-col>
          </a-row>

          <a-row type="flex" justify="space-around" style="margin-top: 20px">
            <a-col :span="23">
              <span class="size-16 color-000-85">权限管理</span>
            </a-col>
          </a-row>
          <a-divider style="margin: 15px 0"/>

          <a-row type="flex" justify="space-around" style="margin-top: 20px">
            <a-col :span="23">
              <a-checkbox :checked="checkAll" @change="onChangeAll()">
                全选
              </a-checkbox>
              <div style="height: 20px"></div>
              <div v-for="(item,index) in roleList" :key="index"
                   style="margin-bottom: 20px;borderBottom: 1px solid #E9E9E9;" class="flex-row-center">
                <a-checkbox :checked="item.checked" @change="onChangeParent(index)"
                            style="background-color: rgba(0,0,0,0.1);padding: 2px 5px">
                  {{ item.SINGLE_ACTION_NAME }}
                </a-checkbox>
                <div class="flex-row-center" style="flex-wrap: wrap;margin-left: 30px">
                  <a-checkbox v-for="(item1,index1) in item.SINGLE_ACTION_LIST" :key="index1" :checked="item1.checked"
                              @change="onChange(index,index1)">
                    {{ item1.ACTION_NAME }}
                  </a-checkbox>
                </div>

              </div>
            </a-col>
          </a-row>

          <div class="flex-row-center" style="height: 50px;justify-content: flex-end;margin: 30px 50px;">
            <a-button type="link" @click="back">
              取消
            </a-button>

            <a-button type="primary" html-type="submit">
              保存
            </a-button>
          </div>
        </a-form>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      span: 11,
      roleList: [],
      form: this.$form.createForm(this, {name: 'account'}),
      id: undefined,
      checkAll: false,
    }
  },
  mounted() {
    let self = this;

    if (this.$route.query.roleId) {
      this.$util.showLoading(self);
      this.$util.get('api/roleInfo/' + this.$route.query.roleId, {}, true).then((res) => {
        self.bean = res.content;
        self.$util.hideLoading();
        let dao = res.content;

        let roles = dao.ACTION_LIST.split(',');
        let rolesList = [];
        self.$util.get('api/privilegeInfo').then((ret) => {
          for (let key in ret.content) {
            rolesList.push(ret.content[key])
          }


          console.log(6,rolesList)

          for (let i = 0; i < rolesList.length; i++) {
            for (let j = 0; j < rolesList[i].SINGLE_ACTION_LIST.length; j++) {
              let hasAction = false;
              for (let k = 0; k < roles.length; k++) {
                if(rolesList[i].SINGLE_ACTION_LIST[j].ACTION==roles[k]){
                  hasAction = true;
                  break;
                }
              }
              rolesList[i].SINGLE_ACTION_LIST[j].checked = hasAction;
            }
          }

          for (let i = 0; i < rolesList.length; i++) {
            let allChecked = true;
            for (let j = 0; j < rolesList[i].SINGLE_ACTION_LIST.length; j++) {
              if(!rolesList[i].SINGLE_ACTION_LIST[j].checked){
                allChecked = false;
                break;
              }
            }
            rolesList[i].checked = allChecked;
          }
          self.roleList = rolesList;
          self.handleChange();
          self.form.setFieldsValue({
            roleName: dao.ROLE_NAME,
            description: dao.DESCRIPTION,
          })

        });

      })
    } else {
      let rolesList = [];
      this.$util.get('api/privilegeInfo').then((res) => {
        for (let key in res.content) {
          rolesList.push(res.content[key])
        }
      });
      for (let i = 0; i < rolesList.length; i++) {
        rolesList[i].checked = false;
        for (let j = 0; j < rolesList[i].SINGLE_ACTION_LIST.length; j++) {
          rolesList[i].SINGLE_ACTION_LIST[j].checked = false;
        }
      }
      this.roleList = rolesList;
    }

  },

  methods: {
    handleSubmit(e) {
      e.preventDefault();
      let self = this;

      let roles = [];
      let rolesList = this.roleList;
      for (let i = 0; i < rolesList.length; i++) {
        for (let j = 0; j < rolesList[i].SINGLE_ACTION_LIST.length; j++) {
          if (rolesList[i].SINGLE_ACTION_LIST[j].checked) {
            roles.push(rolesList[i].SINGLE_ACTION_LIST[j].ACTION);
          }
        }
      }

      this.form.validateFields((err, values) => {
        if (!err) {
          let p = {
            action: "ADD",
            roleName: values.roleName,
            description: values.description,
            actionCode: roles.join(','),
          }
          if (this.bean) {
            p.action = 'EDIT';
            p.roleId = this.bean.ROLE_ID;
          }
          this.$util.post('api/submitRole', p).then((res) => {
            self.back();
          });
        }
      });
    },
    back() {
      this.$router.back();
    },
    onChange(pIndex, index) {
      let list = this.roleList;
      let item = list[pIndex];
      item.SINGLE_ACTION_LIST[index].checked = !item.SINGLE_ACTION_LIST[index].checked;
      let checkNum = 0;
      for (let i = 0; i < item.SINGLE_ACTION_LIST.length; i++) {
        if (item.SINGLE_ACTION_LIST[i].checked) {
          checkNum++;
        }
      }
      item.checked = item.SINGLE_ACTION_LIST.length === checkNum;
      this.roleList = list;
      this.handleChange();
      this.$forceUpdate()
    },
    onChangeParent(index) {
      let list = this.roleList;
      let item = list[index];
      item.checked = !item.checked;
      for (let i = 0; i < item.SINGLE_ACTION_LIST.length; i++) {
        item.SINGLE_ACTION_LIST[i].checked = item.checked;
      }
      this.roleList = list;
      this.handleChange();
      this.$forceUpdate()
    },
    onChangeAll() {
      this.checkAll = !this.checkAll;
      let rolesList = this.roleList;
      for (let i = 0; i < rolesList.length; i++) {
        rolesList[i].checked = this.checkAll;
        for (let j = 0; j < rolesList[i].SINGLE_ACTION_LIST.length; j++) {
          rolesList[i].SINGLE_ACTION_LIST[j].checked = this.checkAll;
        }
      }
      this.roleList = rolesList;
    },
    handleChange() {
      let rolesList = this.roleList;
      let checkAll = true;
      a:for (let i = 0; i < rolesList.length; i++) {
        for (let j = 0; j < rolesList[i].SINGLE_ACTION_LIST.length; j++) {
          if (!rolesList[i].SINGLE_ACTION_LIST[j].checked) {
            checkAll = false;
            break a;
          }
        }
      }
      this.checkAll = checkAll;
    }
  }
}
</script>

<style scoped>

</style>
