<template>
    <div class="flex-column" style="position: relative;height: 100%;width: 100%">
        <a-modal
                dialogClass="screen-dialog"
                title="监测项详情"
                :bodyStyle="{'height':590+'px'}"
                v-model="showModal"
                :footer="null"
                :width="600"
                :destroyOnClose="true"
        >
            <monitorItemDetail :key="getCurrentTime()" :index="currentIndex"/>
        </a-modal>
        <div class="flex-column flex-1" style="padding-top: 10px;padding-left: 20px;">
            <div style="position: relative;">
                <img src="../image/screen/detail_head_bg_2.png" height="40">
                <div class="absolute-0 flex-row-center size-22 color-C5EAFF"
                     style="margin-left: 40px; margin-top: 2px;">
                    统计分析
                </div>
            </div>
            <div class="flex-1 flex-column" style="padding: 5px 0 25px;margin-right: 20px" v-show="type == 1">
                <div class="flex-1 flex-row">
                    <div class="box_top">
                        <div class="flex-column" style="height: 120px; padding: 20px 20px 0;">
                            <div class="flex-row-center">
                                <img src="../image/screen/icon_right_dot1.png" style="width: 20px"/>
                                <span class="size-22 color-fff" style="margin-left: 10px">支座滑移</span>
                                <div class="flex-1"/>
                                <div class="flex-row-center size-16 cursor_pointer" style="color: #5DD3E7"
                                     @click="showDetail(1)">
                                    <a-icon type="info-circle"/>
                                    <span style="margin-left: 5px">详情介绍</span>
                                </div>
                            </div>

                            <div class="flex-row-center" style="margin-top: 10px;">
                                <div class="size-16 color-CBECFF" style="width: 110px; margin-right: -2px;">监测点：</div>
                                <div class="drop" style="margin-left: 0px;" v-if="deviceList.length">
                                    <a-select style="min-width: 200px" @change="deviceChange" placeholder="请选择"
                                              :default-value="0">
                                        <template v-for="(item, index) in deviceList">
                                            <a-select-option :key="item.DEVICE_ID" :value="index">
                                                {{ item.DEVICE_NAME + '(' + item.DEVICE_NUMBER + ')' }}
                                            </a-select-option>
                                        </template>
                                    </a-select>
                                </div>

                                <div class="size-16 color-CBECFF"
                                     style="margin-left: 15px; margin-right: -10px; width: 140px;">起始时间：
                                </div>
                                <div class="drop">
                                    <a-range-picker @change="onChangeDate" :default-value="[startTime,endTime]"
                                                    format="YYYY-MM-DD" dropdownClassName="dateDropdownStyle"
                                                    :allowClear="false"/>
                                </div>
                            </div>
                        </div>
                        <div style="width: 95%; height: 65%;">
                            <chart_displacement :min_array_displacement="min_array_displacement"
                                                :timeList="timeList"
                                                :max_array_displacement="max_array_displacement" :key="deviceKey"/>
                        </div>
                    </div>
                    <div class="box_top" style="margin-left: 15px;">
                        <div class="flex-column" style="height: 120px; padding: 20px 20px 0;">
                            <div class="flex-row-center">
                                <img src="../image/screen/icon_right_dot1.png" style="width: 20px"/>
                                <span class="size-22 color-fff" style="margin-left: 10px">高墩偏位</span>
                                <div class="flex-1"/>
                                <div class="flex-row-center size-16 cursor_pointer" style="color: #5DD3E7"
                                     @click="showDetail(2)">
                                    <a-icon type="info-circle"/>
                                    <span style="margin-left: 5px">详情介绍</span>
                                </div>
                            </div>

                            <div class="flex-row-center" style="margin-top: 10px;">
                                <div class="size-16 color-CBECFF" style="width: 110px; margin-right: -2px;">监测点：</div>
                                <div class="drop" style="margin-left: 0px;" v-if="deviceList2.length">
                                    <a-select style="min-width: 200px" @change="deviceChange2" placeholder="请选择"
                                              :default-value="0">
                                        <template v-for="(item,index) in deviceList2">
                                            <a-select-option :key="item.DEVICE_ID" :value="index">
                                                {{ item.DEVICE_NAME + '(' + item.DEVICE_NUMBER + ')' }}
                                            </a-select-option>
                                        </template>
                                    </a-select>
                                </div>
                                <div class="size-16 color-CBECFF"
                                     style="margin-left: 35px; width: 140px;">起始时间：
                                </div>
                                <div class="drop">
                                    <a-range-picker @change="onChangeDate2" :default-value="[startTime2,endTime2]"
                                                    format="YYYY-MM-DD" dropdownClassName="dateDropdownStyle"
                                                    :allowClear="false"/>
                                </div>
                            </div>
                        </div>

                        <div style="width: 100%; height: 65%;">
                            <chart_angel :array_angel="array_angel" :key="getCurrentTime()"/>
                        </div>
                    </div>
                </div>
                <div class="flex-1" style="margin-top: 5px;">
                    <div class="flex-1 flex-row">
                        <div class="box_top">
                            <div class="flex-column" style="height: 120px; padding: 20px 20px 0;">
                                <div class="flex-row-center">
                                    <img src="../image/screen/icon_right_dot1.png" style="width: 20px"/>
                                    <span class="size-22 color-fff" style="margin-left: 10px">主梁形变</span>
                                    <div class="flex-1"/>
                                    <div class="flex-row-center size-16 cursor_pointer" style="color: #5DD3E7"
                                         @click="showDetail(3)">
                                        <a-icon type="info-circle"/>
                                        <span style="margin-left: 5px">详情介绍</span>
                                    </div>
                                </div>

                                <div class="flex-row-center" style="margin-top: 10px;">
                                    <div class="size-16 color-CBECFF" style="width: 90px; margin-right: -2px;">监测点：
                                    </div>
                                    <div class="drop" style="margin-left: 0px;" v-if="deviceList3.length">
                                        <a-select style="min-width: 200px" @change="deviceChange3" placeholder="请选择"
                                                  :default-value="0">
                                            <template v-for="(item, index) in deviceList3">
                                                <a-select-option :key="item.DEVICE_ID" :value="index">
                                                    {{ item.DEVICE_NAME + '(' + item.DEVICE_NUMBER + ')' }}
                                                </a-select-option>
                                            </template>
                                        </a-select>
                                    </div>
                                    <div class="size-16 color-CBECFF" style="margin-left: 35px; width: 90px;">起始时间：
                                    </div>
                                    <div class="drop">
                                        <a-range-picker @change="onChangeDate3" :default-value="[startTime3,endTime3]"
                                                        format="YYYY-MM-DD" dropdownClassName="dateDropdownStyle"
                                                        :allowClear="false" :key="1"/>
                                    </div>

                                </div>
                            </div>
                            <div style="width: 100%; height: 65%;">
                                <chart_gnss :array_gnss="array_gnss" :key="getCurrentTime()"/>
                            </div>
                        </div>
                        <!--                        <div class="flex-1" style="margin-left: 15px;">-->
                        <!--                        </div>-->
                    </div>
                </div>
            </div>

            <div class="flex-1 flex-column" style="padding: 5px 0 25px;" v-show="type == 2">
                <div class="flex-1 flex-row">
                    <div class="box_top">
                        <div class="flex-column" style="height: 120px; padding: 20px 20px 0;">
                            <div class="flex-row-center">
                                <img src="../image/screen/icon_right_dot1.png" style="width: 20px"/>
                                <span class="size-22 color-fff" style="margin-left: 10px">隧道拱顶沉降</span>
                                <div class="flex-1"/>
                                <div class="flex-row-center size-16 cursor_pointer" style="color: #5DD3E7"
                                     @click="showDetail(4)">
                                    <a-icon type="info-circle"/>
                                    <span style="margin-left: 5px">详情介绍</span>
                                </div>
                            </div>

                            <div class="flex-row-center" style="margin-top: 10px;">
                                <div class="size-16 color-CBECFF">监测点：</div>
                                <div class="drop" style="margin-left: 0px;" v-if="deviceList_distance.length">
                                    <a-select style="min-width: 200px" @change="deviceChange_distance" placeholder="请选择"
                                              :default-value="0">
                                        <template v-for="(item, index) in deviceList_distance">
                                            <a-select-option :key="item.DEVICE_ID" :value="index">
                                                {{ item.DEVICE_NAME + '(' + item.DEVICE_NUMBER + ')' }}
                                            </a-select-option>
                                        </template>
                                    </a-select>
                                </div>
                                <div class="size-16 color-CBECFF" style="margin-left: 25px">起始时间：</div>
                                <div class="drop">
                                    <a-range-picker @change="onChangeDate_distance"
                                                    :default-value="[startTime_distance,endTime_distance]"
                                                    format="YYYY-MM-DD" dropdownClassName="dateDropdownStyle"
                                                    :allowClear="false" :key="100"/>
                                </div>
                            </div>
                        </div>

                        <div style="width: 100%; height: 65%;">
                            <chart_distance :array_distance="array_distance" :key="deviceKey_distance"/>
                        </div>
                    </div>
                </div>

                <div class="flex-1 flex-row">
                    <div class="box_top">
                        <div class="flex-column" style="height: 120px; padding: 20px 20px 0;">
                            <div class="flex-row-center">
                                <img src="../image/screen/icon_right_dot1.png" style="width: 20px"/>
                                <span class="size-22 color-fff" style="margin-left: 10px">隧道周边收敛</span>
                                <div class="flex-1"/>
                                <div class="flex-row-center size-16 cursor_pointer" style="color: #5DD3E7"
                                     @click="showDetail(5)">
                                    <a-icon type="info-circle"/>
                                    <span style="margin-left: 5px">详情介绍</span>
                                </div>
                            </div>

                            <div class="flex-row-center" style="margin-top: 10px;">
                                <div class="size-16 color-CBECFF">监测点：</div>
                                <div class="drop" style="margin-left: 0px;" v-if="deviceList_distance_2.length">
                                    <a-select style="min-width: 200px" @change="deviceChange_distance2"
                                              placeholder="请选择"
                                              :default-value="0">
                                        <template v-for="(item, index) in deviceList_distance_2">
                                            <a-select-option :key="item.DEVICE_ID" :value="index">
                                                {{ item.DEVICE_NAME + '(' + item.DEVICE_NUMBER + ')' }}
                                            </a-select-option>
                                        </template>
                                    </a-select>
                                </div>
                                <div class="size-16 color-CBECFF" style="margin-left: 25px">起始时间：</div>
                                <div class="drop">
                                    <a-range-picker @change="onChangeDate_distance2"
                                                    :default-value="[startTime_distance_2,endTime_distance_2]"
                                                    format="YYYY-MM-DD" dropdownClassName="dateDropdownStyle"
                                                    :allowClear="false" :key="100"/>
                                </div>
                            </div>
                        </div>

                        <div style="width: 100%; height: 65%;">
                            <chart_distance :array_distance="array_distance_2" :key="deviceKey_distance_2"/>
                        </div>
                    </div>
                </div>
            </div>

            <div class="flex-1 flex-column" style="padding: 5px 0 25px;" v-show="type == 3">
                <div class="flex-1 flex-row">
                    <div class="box_top">
                        <div class="flex-column" style="height: 120px; padding: 20px 20px 0;">
                            <div class="flex-row-center">
                                <img src="../image/screen/icon_right_dot1.png" style="width: 20px"/>
                                <span class="size-22 color-fff" style="margin-left: 10px">边坡降雨量监测</span>
                                <div class="flex-1"/>
                                <div class="flex-row-center size-16 cursor_pointer" style="color: #5DD3E7"
                                     @click="showDetail(6)">
                                    <a-icon type="info-circle"/>
                                    <span style="margin-left: 5px">详情介绍</span>
                                </div>
                            </div>

                            <div class="flex-row-center" style="margin-top: 10px;">
                                <div class="size-16 color-CBECFF" style="width: 100px; margin-right: -2px;">监测点：
                                </div>
                                <div class="drop" style="margin-left: 0px;" v-if="deviceList.length">
                                    <a-select style="min-width: 200px" @change="deviceChange" placeholder="请选择"
                                              :default-value="0">
                                        <template v-for="(item, index) in deviceList">
                                            <a-select-option :key="item.DEVICE_ID" :value="index">
                                                {{ item.DEVICE_NAME + '(' + item.DEVICE_NUMBER + ')' }}
                                            </a-select-option>
                                        </template>
                                    </a-select>
                                </div>
                                <div class="size-16 color-CBECFF"
                                     style="margin-left: 15px; margin-right: -10px; width: 140px;">起始时间：
                                </div>
                                <div class="drop">
                                    <a-range-picker @change="onChangeDate" :default-value="[startTime,endTime]"
                                                    format="YYYY-MM-DD" dropdownClassName="dateDropdownStyle"
                                                    :allowClear="false"/>
                                </div>
                            </div>
                        </div>

                        <div style="width: 100%; height: 65%;">
                            <chart_displacement :min_array_displacement="min_array_displacement"
                                                :timeList="timeList"
                                                :max_array_displacement="max_array_displacement" :key="deviceKey"/>
                        </div>
                    </div>
                    <div class="box_top" style="margin-left: 15px;">
                        <div class="flex-column" style="height: 120px; padding: 20px 20px 0;">
                            <div class="flex-row-center">
                                <img src="../image/screen/icon_right_dot1.png" style="width: 20px"/>
                                <span class="size-22 color-fff" style="margin-left: 10px">边坡地下水位监测</span>
                                <div class="flex-1"/>
                                <div class="flex-row-center size-16 cursor_pointer" style="color: #5DD3E7"
                                     @click="showDetail(7)">
                                    <a-icon type="info-circle"/>
                                    <span style="margin-left: 5px">详情介绍</span>
                                </div>
                            </div>

                            <div class="flex-row-center" style="margin-top: 10px;">
                                <div class="size-16 color-CBECFF" style="width: 100px; margin-right: -2px;">监测点：
                                </div>
                                <div class="drop" style="margin-left: 0px;" v-if="deviceList2.length">
                                    <a-select style="min-width: 200px" @change="deviceChange2" placeholder="请选择"
                                              :default-value="0">
                                        <template v-for="(item, index) in deviceList2">
                                            <a-select-option :key="item.DEVICE_ID" :value="index">
                                                {{ item.DEVICE_NAME + '(' + item.DEVICE_NUMBER + ')' }}
                                            </a-select-option>
                                        </template>
                                    </a-select>
                                </div>
                                <div class="size-16 color-CBECFF"
                                     style="margin-left: 15px; margin-right: -10px; width: 140px;">起始时间：
                                </div>
                                <div class="drop">
                                    <a-range-picker @change="onChangeDate2" :default-value="[startTime2,endTime2]"
                                                    format="YYYY-MM-DD" dropdownClassName="dateDropdownStyle"
                                                    :allowClear="false"/>
                                </div>
                            </div>
                        </div>
                        <div style="width: 100%; height: 65%;">
                            <chart_angel :array_angel="array_angel" :key="getCurrentTime()"/>
                        </div>
                    </div>
                </div>
                <div class="flex-1" style="margin-top: 5px;">
                    <div class="flex-1 flex-row">
                        <div class="box_top">
                            <div class="flex-column" style="height: 120px; padding: 20px 20px 0;">
                                <div class="flex-row-center">
                                    <img src="../image/screen/icon_right_dot1.png" style="width: 20px"/>
                                    <span class="size-22 color-fff" style="margin-left: 10px">边坡表面位移</span>
                                    <div class="flex-1"/>
                                    <div class="flex-row-center size-16 cursor_pointer" style="color: #5DD3E7"
                                         @click="showDetail(8)">
                                        <a-icon type="info-circle"/>
                                        <span style="margin-left: 5px">详情介绍</span>
                                    </div>
                                </div>

                                <div class="flex-row-center" style="margin-top: 10px;">
                                    <div class="size-16 color-CBECFF" style="width: 100px; margin-right: -2px;">监测点：
                                    </div>
                                    <div class="drop" style="margin-left: 0px;" v-if="deviceList3.length">
                                        <a-select style="min-width: 200px" @change="deviceChange3" placeholder="请选择"
                                                  :default-value="0">
                                            <template v-for="(item, index) in deviceList3">
                                                <a-select-option :key="item.DEVICE_ID" :value="index">
                                                    {{ item.DEVICE_NAME + '(' + item.DEVICE_NUMBER + ')' }}
                                                </a-select-option>
                                            </template>
                                        </a-select>
                                    </div>
                                    <div class="size-16 color-CBECFF"
                                         style="margin-left: 15px; margin-right: -10px; width: 140px;">起始时间：
                                    </div>
                                    <div class="drop">
                                        <a-range-picker @change="onChangeDate3" :default-value="[startTime3,endTime3]"
                                                        format="YYYY-MM-DD" dropdownClassName="dateDropdownStyle"
                                                        :allowClear="false" :key="99"/>
                                    </div>
                                </div>
                            </div>

                            <div style="width: 100%; height: 65%;">
                                <chart_gnss :array_gnss="array_gnss" :key="getCurrentTime()"/>
                            </div>
                        </div>
                        <div class="box_top" style="margin-left: 15px;">
                            <div class="flex-column" style="height: 120px; padding: 20px 20px 0;">
                                <div class="flex-row-center">
                                    <img src="../image/screen/icon_right_dot1.png" style="width: 20px"/>
                                    <span class="size-22 color-fff" style="margin-left: 10px">边坡深层位移监测</span>
                                    <div class="flex-1"/>
                                    <div class="flex-row-center size-16 cursor_pointer" style="color: #5DD3E7"
                                         @click="showDetail(9)">
                                        <a-icon type="info-circle"/>
                                        <span style="margin-left: 5px">详情介绍</span>
                                    </div>
                                </div>

                                <div class="flex-row-center" style="margin-top: 10px;">
                                    <div class="size-16 color-CBECFF" style="width: 100px; margin-right: -2px;">监测点：
                                    </div>
                                    <div class="drop" style="margin-left: 0px;" v-if="deviceList2.length">
                                        <a-select style="min-width: 200px" @change="deviceChange2" placeholder="请选择"
                                                  :default-value="0">
                                            <template v-for="(item, index) in deviceList2">
                                                <a-select-option :key="item.DEVICE_ID" :value="index">
                                                    {{ item.DEVICE_NAME + '(' + item.DEVICE_NUMBER + ')' }}
                                                </a-select-option>
                                            </template>
                                        </a-select>
                                    </div>
                                    <div class="size-16 color-CBECFF"
                                         style="margin-left: 15px; margin-right: -10px; width: 140px;">起始时间：
                                    </div>
                                    <div class="drop">
                                        <a-range-picker @change="onChangeDate2" :default-value="[startTime2,endTime2]"
                                                        format="YYYY-MM-DD" dropdownClassName="dateDropdownStyle"
                                                        :allowClear="false" :key="98"/>
                                    </div>
                                </div>
                            </div>

                            <div style="width: 100%; height: 65%;">
                                <chart_angel :array_angel="array_angel" :key="getCurrentTime()"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
  import moment from 'moment';
  import chart_displacement from './chart_displacement';
  import chart_angel from './chart_angel';
  import chart_gnss from "./chart_gnss";
  import chart_distance from "./chart_distance";
  // import chart_durable_cur_yang from "./chart_durable_cur_yang";
  // import chart_durable_cur_yin from "./chart_durable_cur_yin";
  // import chart_durable_openv2 from "./chart_durable_openv2";
  import monitorItemDetail from "./monitorItemDetail";

  let formatStr = "YYYY-MM-DD 00:00:00";
  let formatStr2 = "YYYY-MM-DD 23:59:59";
  export default {
    components: {
      chart_displacement,
      chart_angel,
      chart_gnss,
      chart_distance,
      monitorItemDetail
      // chart_durable_cur_yang,
      // chart_durable_cur_yin,
      // chart_durable_openv2,

    },
    props: {
      structureId: {
        type: String
      },
      type: {
        type: String
      }
    },
    computed: {
      getTitle1() {
        return function () {
          let str = '拉线位移';
          if (this.type == 1) {
            str = '拉线位移';
          } else {
            str = '雨量计';
          }
          return str;
        }
      },
      getTitle2() {
        return function () {
          let str = '倾斜角度';
          if (this.type == 1) {
            str = '倾斜角度';
          } else {
            str = '渗压计';
          }
          return str;
        }
      },
      getTitle3() {
        return function () {
          let str = '北斗定位仪';
          if (this.type == 2) {
            str = '激光测距仪';
          } else {
            str = '北斗定位仪';
          }
          return str;
        }
      },

      getDurableTitle() {
        return function () {
          let str = '';
          let selectCurrentPicker = this.selectCurrentPicker;
          let selectPotentialPicker = this.selectPotentialPicker;
          if (this.selectExternalPicker == 1) {
            if (selectCurrentPicker == 1) {
              str = '阳极保护电流（单位：mA）';
            } else if (selectCurrentPicker == 2) {
              str = '阴极腐蚀挂片电流（单位：mA）';
            } else if (selectCurrentPicker == 3) {
              str = '环境温度（单位：℃）';
            } else if (selectCurrentPicker == 4) {
              str = '环境湿度（单位：%）';
            }
          } else if (this.selectExternalPicker == 2) {
            if (selectPotentialPicker == 1) {
              str = '开路电位（单位：mA）';
            } else if (selectPotentialPicker == 2) {
              str = '环境温度（单位：℃）';
            } else if (selectPotentialPicker == 3) {
              str = '环境湿度（单位：%）';
            } else if (selectPotentialPicker == 4) {
              str = '混合电位（单位：mA）';
            }
          }
          return str;
        }
      },

      getCurrentTime() {
        return function () {
          return new Date().getTime();
        }
      },

      getDeviceStatus() {
        return function (item) {
          // let item = this.selectDeviceObj;
          let str = '离线';
          let color = 'rgba(255, 255, 255, 0.5)';
          if (item) {
            if (item.STATE == 0) {//0是在线
              if (item.IS_ALARM == 1) {
                str = '报警';
                color = '#FF571A';
              } else {
                str = '正常';
                color = '#62DBED';
              }
            }
          }
          return {
            title: str,
            color: color
          }
        }
      }

    },
    data() {
      let day = moment(new Date()).format(this.$util.dateFormatStr.dateYMD2);

      return {
        daily: day,
        deviceList: [],
        selectDevice: undefined,
        selectDeviceObj: {},
        min_array_displacement: [],
        max_array_displacement: [],
        deviceKey: 0,

        deviceList2: [],
        selectDevice2: undefined,
        selectDevice2Obj: {},
        array_angel: [],
        deviceKey2: 0,
        startTime: moment().add(-6, 'days').format(formatStr),
        endTime: moment().format(formatStr2),
        timeDiff: 6,//默认显示6天
        timeList: [],

        startTime2: moment().add(-6, 'days').format(formatStr),
        endTime2: moment().format(formatStr2),
        timeDiff2: 6,//默认显示6天


        deviceList3: [],
        selectDevice3: undefined,
        selectDevice3Obj: {},
        array_gnss: [],
        deviceKey3: 0,
        startTime3: moment().add(-6, 'days').format(formatStr),
        endTime3: moment().format(formatStr2),
        timeDiff3: 6,//默认显示6天

        selectExternalPicker: 1,    //电流 1 电位 2
        externalTypeList: [
          {name: '保护电流密度', value: 1},
          {name: '腐蚀电位', value: 2}
        ],

        deviceList_durable_cur: [],
        selectDevice_durable_cur: undefined,
        selectDevice_durable_cur_item: undefined,
        array_durable_cur: [],
        deviceKey_durable_cur: 0,
        startTime_durable_cur: moment().add(-6, 'days').format(formatStr),
        endTime_durable_cur: moment().format(formatStr2),
        timeDiff_durable_cur: 6,//默认显示6天,

        deviceList_distance: [],
        selectDevice_distance: undefined,
        selectDevice_distance_item: undefined,
        array_distance: [],
        deviceKey_distance: 0,
        startTime_distance: moment().add(-6, 'days').format(formatStr),
        endTime_distance: moment().format(formatStr2),
        timeDiff_distance: 6,//默认显示6天,

        //隧道周边收敛
        deviceList_distance_2: [],
        selectDevice_distance_2: undefined,
        selectDevice_distance_item_2: undefined,
        array_distance_2: [],
        deviceKey_distance_2: 0,
        startTime_distance_2: moment().add(-6, 'days').format(formatStr),
        endTime_distance_2: moment().format(formatStr2),
        timeDiff_distance_2: 6,//默认显示6天,

        selectCurrentPicker: 1,
        isCurrent: true,
        currentTypeList: [
          {name: '阳极保护电流', value: 1},
          {name: '阴极腐蚀挂片电流', value: 2},
          {name: '环境温度', value: 3},
          {name: '环境湿度', value: 4}
        ],

        deviceList_durable_potential: [],
        selectDevice_durable_potential: undefined,
        array_durable_potential: [],
        deviceKey_durable_potential: 0,
        startTime_durable_potential: moment().add(-6, 'days').format(formatStr),
        endTime_durable_potential: moment().format(formatStr2),
        timeDiff_durable_potential: 6,//默认显示6天,
        selectPotentialPicker: 1,
        potentialTypeList: [
          {name: '开路电位', value: 1},
          {name: '环境温度', value: 2},
          {name: '环境湿度', value: 3},
          {name: '混合电位', value: 4}
        ],

        selectBridgePicker: 1,
        bridgeTypeList: [
          {name: '支座滑移', value: 1},
          {name: '高墩偏位', value: 2},
          {name: '主梁形变', value: 3}
        ],

        selectTunnelPicker: 1,
        tunnelTypeList: [
          {name: '隧道拱顶沉降', value: 1},
          {name: '隧道周边收敛', value: 2}
        ],

        selectSlopePicker: 1,
        slopeTypeList: [
          {name: '边坡降雨量监测', value: 1},
          {name: '边坡地下水位监测', value: 2},
          {name: '边坡表面位移', value: 3},
          {name: '边坡深层位移监测', value: 4}
        ],
        showModal: false,
        currentIndex: 1
      }
    },
    created() {
      if (this.structureId != 9 && this.structureId != 10 && this.structureId != 17 && this.structureId != 12 && this.structureId != 14) {
        this.structureId = 9;
      }
      this.getData();
    },

    methods: {
      onChangeDate(date, dateString) {
        this.startTime = moment(dateString[0]).format(formatStr);
        this.endTime = moment(dateString[1]).format(formatStr2);
        this.timeDiff = moment(this.endTime).diff(moment(this.startTime), 'day');
        this.getTimeWireDistanceStatisticsList();
      },
      onChangeDate2(date, dateString) {
        this.startTime2 = moment(dateString[0]).format(formatStr);
        this.endTime2 = moment(dateString[1]).format(formatStr2);
        this.timeDiff2 = moment(this.endTime2).diff(moment(this.startTime2), 'day');
        this.getTimeInclinometerStatisticsList();
      },
      onChangeDate3(date, dateString) {
        this.startTime3 = moment(dateString[0]).format(formatStr);
        this.endTime3 = moment(dateString[1]).format(formatStr2);
        this.timeDiff3 = moment(this.endTime3).diff(moment(this.startTime3), 'day');
        this.getTimeGnssStatisticsList();
      },

      onChangeDate_durable_cur(date, dateString) {
        this.startTime_durable_cur = moment(dateString[0]).format(formatStr);
        this.endTime_durable_cur = moment(dateString[1]).format(formatStr2);
        this.timeDiff_durable_cur = moment(this.endTime_durable_cur).diff(moment(this.startTime_durable_cur), 'day');
        this.getTimeDurableStatisticsList(1);
      },

      onChangeDate_durable_potential(date, dateString) {
        this.startTime_durable_potential = moment(dateString[0]).format(formatStr);
        this.endTime_durable_potential = moment(dateString[1]).format(formatStr2);
        this.timeDiff_durable_potential = moment(this.endTime_durable_potential).diff(moment(this.startTime_durable_potential), 'day');
        this.getTimeDurableStatisticsList(1);
      },

      onChangeDate_distance(date, dateString) {
        this.startTime_distance = moment(dateString[0]).format(formatStr);
        this.endTime_distance = moment(dateString[1]).format(formatStr2);
        this.timeDiff_distance = moment(this.endTime_distance).diff(moment(this.startTime_distance), 'day');
        this.getTimeRangeFinderStatisticsList();
      },

      onChangeDate_distance2(date, dateString) {
        this.startTime_distance_2 = moment(dateString[0]).format(formatStr);
        this.endTime_distance_2 = moment(dateString[1]).format(formatStr2);
        this.timeDiff_distance_2 = moment(this.endTime_distance_2).diff(moment(this.startTime_distance_2), 'day');
        this.getTimeRangeFinderStatisticsList2();
      },

      getData() {
        this.$util.get('api/deviceList', {
          page: 1,
          pageSize: 10000,
          isBind: 1,
          structureId: this.structureId,
          deviceType: 4
        }, true).then((res) => {
          let bean = res.content;
          console.log(55555, bean);
          this.deviceList = bean.list;

          if (this.deviceList.length) {
            this.selectDeviceObj = this.deviceList[0];
            this.selectDevice = this.deviceList[0].DEVICE_ID;
            this.getTimeWireDistanceStatisticsList();
          }
        })

        this.$util.get('api/deviceList', {
          page: 1,
          pageSize: 10000,
          isBind: 1,
          structureId: this.structureId,
          deviceType: 5
        }, true).then((res) => {
          let bean = res.content;
          this.deviceList2 = bean.list;

          if (this.deviceList2.length) {
            this.selectDevice2Obj = this.deviceList2[0];
            this.selectDevice2 = this.deviceList2[0].DEVICE_ID;
            this.getTimeInclinometerStatisticsList();
          }
        })

        this.$util.get('api/deviceList', {
          page: 1,
          pageSize: 10000,
          isBind: 1,
          structureId: this.structureId,
          deviceType: 6
        }, true).then((res) => {
          let bean = res.content;
          this.deviceList3 = bean.list;
          if (this.deviceList3.length) {
            this.selectDevice3Obj = this.deviceList3[0];
            this.selectDevice3 = this.deviceList3[0].DEVICE_ID;
            this.getTimeGnssStatisticsList();
          }
        })

        this.$util.get('api/deviceList', {
          page: 1,
          pageSize: 10000,
          isBind: 1,
          structureId: this.structureId,
          deviceType: 48
        }, true).then((res) => {
          let bean = res.content;
          this.deviceList_durable_cur = bean.list;
          this.deviceList_durable_potential = bean.list;

          if (this.deviceList_durable_cur.length) {
            this.selectDevice_durable_cur = this.deviceList_durable_cur[0].DEVICE_ID;
            this.selectDevice_durable_cur_item = this.deviceList_durable_cur[0];
            this.getTimeDurableStatisticsList(1);
          }
          if (this.deviceList_durable_potential.length) {
            this.selectDevice_durable_potential = this.deviceList_durable_potential[0].DEVICE_ID;
          }
        })

        this.$util.get('api/deviceList', {
          page: 1,
          pageSize: 10000,
          isBind: 1,
          structureId: this.structureId,
          deviceType: 10
        }, true).then((res) => {
          let bean = res.content;
          console.log('--------------');
          console.log(bean);
          this.deviceList_distance = bean.list;
          console.log(66667, this.deviceList_distance);
          if (this.deviceList_distance.length) {
            this.selectDevice_distance = this.deviceList_distance[0].DEVICE_ID;
            this.selectDevice_distance_item = this.deviceList_distance[0];
            this.getTimeRangeFinderStatisticsList();
          }
        })

        this.$util.get('api/deviceList', {
          page: 1,
          pageSize: 10000,
          isBind: 1,
          structureId: this.structureId,
          deviceType: 10
        }, true).then((res) => {
          let bean = res.content;
          this.deviceList_distance_2 = bean.list;
          if (this.deviceList_distance_2.length) {
            this.selectDevice_distance_2 = this.deviceList_distance_2[0].DEVICE_ID;
            this.selectDevice_distance_item_2 = this.deviceList_distance_2[0];
            this.getTimeRangeFinderStatisticsList2();
          }
        })
      },

      getTimeWireDistanceStatisticsList() {
        let timeList = [];
        for (let i = -this.timeDiff; i <= 0; i++) {
          let dd = moment(this.endTime).add(i, 'days').format("YYYY-MM-DD");
          let item = {
            avgDistance: 0,
            counts: 0,
            maxDistance: 0,
            minDistance: 0,
            monitorTime: dd
          }
          timeList.push(item);
        }
        this.$util.get('api/timeWireDistanceStatisticsList', {
          deviceId: this.selectDevice,
          startTime: this.startTime,
          endTime: this.endTime,
          type: 1
        }, true).then((res) => {
          let list = timeList;
          let resList = res.content;
          for (let i = 0; i < list.length; i++) {
            let item = list[i];
            for (let j = 0; j < resList.length; j++) {
              let item2 = resList[j];
              if (item.monitorTime == item2.monitorTime) {
                timeList[i] = resList[j];
                break;
              }
            }
          }

          this.timeList = timeList.map((item) => {
            return moment(item.monitorTime).format("MM-DD");
          })
          this.min_array_displacement = timeList.map((item) => {
            return item.minDistance;
          })

          this.max_array_displacement = timeList.map((item) => {
            return item.maxDistance - item.minDistance;
          })

          this.deviceKey = this.selectDevice + this.startTime + this.endTime;
        })
      },

      getTimeInclinometerStatisticsList() {
        let timeList = [];
        for (let i = -this.timeDiff2; i <= 0; i++) {
          let dd = moment(this.endTime2).add(i, 'days').format("YYYY-MM-DD");
          let item = {
            avgXAxis: 0,
            avgYAxis: 0,
            counts: 0,
            maxXAxis: 0,
            maxYAxis: 0,
            minXAxis: 0,
            minYAxis: 0,
            monitorTime: dd
          }
          timeList.push(item);
        }
        this.$util.get('api/timeInclinometerStatisticsList', {
          deviceId: this.selectDevice2,
          startTime: this.startTime2,
          endTime: this.endTime2,
          type: 1
        }, true).then((res) => {
          let list = timeList;
          let resList = res.content;
          for (let i = 0; i < list.length; i++) {
            let item = list[i];
            for (let j = 0; j < resList.length; j++) {
              let item2 = resList[j];
              if (item.monitorTime == item2.monitorTime) {
                timeList[i] = resList[j];
                break;
              }
            }
          }
          this.array_angel = timeList;
          this.deviceKey2 = this.selectDevice2 + this.startTime2 + this.endTime2;
        })
      },

      getTimeGnssStatisticsList() {
        let timeList = [];
        for (let i = -this.timeDiff3; i <= 0; i++) {
          let dd = moment(this.endTime3).add(i, 'days').format("YYYY-MM-DD");
          let item = {
            avgTotalDe: 0,
            avgTotalDh: 0,
            avgTotalDn: 0,
            counts: 0,
            maxTotalDe: 0,
            maxTotalDh: 0,
            maxTotalDn: 0,
            minTotalDe: 0,
            minTotalDh: 0,
            minTotalDn: 0,
            monitorTime: dd
          }
          timeList.push(item);
        }
        this.$util.get('api/timeGnssStatisticsList', {
          deviceId: this.selectDevice3,
          startTime: this.startTime3,
          endTime: this.endTime3,
          type: 1
        }, true).then((res) => {
          let list = timeList;
          let resList = res.content;
          for (let i = 0; i < list.length; i++) {
            let item = list[i];
            for (let j = 0; j < resList.length; j++) {
              let item2 = resList[j];
              if (item.monitorTime == item2.monitorTime) {
                timeList[i] = resList[j];
                break;
              }
            }
          }
          this.array_gnss = timeList;
          this.deviceKey3 = this.selectDevice3 + this.startTime3 + this.endTime3;
        })
      },

      getTimeDurableStatisticsList(type) {
        let timeList = [];
        let timeDiff = 6;
        let startTime;
        let endTime;
        let selectDevice;

        let selectExternalPicker = this.selectExternalPicker;
        //电流 1  电位 2
        if (selectExternalPicker == 1) {
          timeDiff = this.timeDiff_durable_cur;
          startTime = this.startTime_durable_cur;
          endTime = this.endTime_durable_cur;
          selectDevice = this.selectDevice_durable_cur;
        } else if (selectExternalPicker == 2) {
          timeDiff = this.timeDiff_durable_potential;
          startTime = this.startTime_durable_potential;
          endTime = this.endTime_durable_potential;
          selectDevice = this.selectDevice_durable_potential;
        }

        for (let i = -timeDiff; i <= 0; i++) {
          let dd = moment(endTime).add(i, 'days').format("YYYY-MM-DD");
          let item = {
            avg_temp: 0,
            avg_hum: 0,
            avg_mixv: 0,
            avg_sac: 0,
            avg_cc: 0,
            avg_openv: 0,
            _id: dd
          }
          timeList.push(item);
        }
        this.$util.get('api/timeDurableStatisticsList', {
          deviceId: selectDevice,
          startTime: startTime,
          endTime: endTime,
          fields: 'temp,hum,mixv,sac,cc,openv',
          type: 1
        }, true).then((res) => {
          let list = timeList;
          let resList = res.content;
          for (let i = 0; i < list.length; i++) {
            let item = list[i];
            for (let j = 0; j < resList.length; j++) {
              let item2 = resList[j];
              if (item._id == item2._id) {
                timeList[i] = resList[j];
                break;
              }
            }
          }
          if (selectExternalPicker == 1) {
            this.array_durable_cur = timeList;
            this.deviceKey_durable_cur = this.selectDevice_durable_cur + this.startTime_durable_cur + this.endTime_durable_cur + type;
          } else if (selectExternalPicker == 2) {
            this.array_durable_potential = timeList;
            this.deviceKey_durable_potential = new Date().getTime();
          }
        })
      },

      getTimeRangeFinderStatisticsList() {
        let timeList = [];
        for (let i = -this.timeDiff_distance; i <= 0; i++) {
          let dd = moment(this.endTime_distance).add(i, 'days').format("YYYY-MM-DD");
          let item = {
            avgDistance: 0,
            counts: 0,
            maxDistance: 0,
            minDistance: 0,
            monitorTime: dd
          }
          timeList.push(item);
        }
        this.$util.get('api/timeRangeFinderStatisticsList', {
          deviceId: this.selectDevice_distance,
          startTime: this.startTime_distance,
          endTime: this.endTime_distance,
          type: 1
        }, true).then((res) => {
          let list = timeList;
          let resList = res.content;
          console.log('************');
          console.log(resList);
          for (let i = 0; i < list.length; i++) {
            let item = list[i];
            for (let j = 0; j < resList.length; j++) {
              let item2 = resList[j];
              if (item.monitorTime == item2.monitorTime) {
                timeList[i] = resList[j];
                break;
              }
            }
          }
          this.array_distance = timeList;
          console.log(123, this.array_distance);
          this.deviceKey_distance = this.selectDevice_distance + this.startTime_distance + this.endTime_distance;
        })
      },

      getTimeRangeFinderStatisticsList2() {
        let timeList = [];
        for (let i = -this.timeDiff_distance_2; i <= 0; i++) {
          let dd = moment(this.endTime_distance_2).add(i, 'days').format("YYYY-MM-DD");
          let item = {
            avgDistance: 0,
            counts: 0,
            maxDistance: 0,
            minDistance: 0,
            monitorTime: dd
          }
          timeList.push(item);
        }
        this.$util.get('api/timeRangeFinderStatisticsList', {
          deviceId: this.selectDevice_distance_2,
          startTime: this.startTime_distance_2,
          endTime: this.endTime_distance_2,
          type: 1
        }, true).then((res) => {
          let list = timeList;
          let resList = res.content;
          console.log('************');
          console.log(resList);
          for (let i = 0; i < list.length; i++) {
            let item = list[i];
            for (let j = 0; j < resList.length; j++) {
              let item2 = resList[j];
              if (item.monitorTime == item2.monitorTime) {
                timeList[i] = resList[j];
                break;
              }
            }
          }
          this.array_distance_2 = timeList;
          this.deviceKey_distance_2 = this.selectDevice_distance_2 + this.startTime_distance_2 + this.endTime_distance_2;
        })
      },

      deviceChange(value) {
        // let deviceIds = this.deviceList.map(item=>item.DEVICE_ID)
        // let index = deviceIds.indexOf(value);
        // this.selectDeviceObj = this.deviceList[index];
        // console.log(123,this.selectDeviceObj);
        // this.selectDevice = this.deviceList[index].DEVICE_ID;
        this.selectDeviceObj = this.deviceList[value];
        this.selectDevice = this.deviceList[value].DEVICE_ID;
        this.getTimeWireDistanceStatisticsList();
      },

      deviceChange2(value) {
        this.selectDevice2Obj = this.deviceList2[value];
        this.selectDevice2 = this.deviceList2[value].DEVICE_ID;
        this.getTimeInclinometerStatisticsList();
      },

      deviceChange3(value) {
        this.selectDevice3Obj = this.deviceList3[value];
        this.selectDevice3 = this.deviceList3[value].DEVICE_ID;
        this.getTimeGnssStatisticsList();
      },

      deviceChange_durable_hum(value) {
        this.selectDevice_durable_hum = value;
        this.getTimeDurableStatisticsList(1);
      },

      deviceChange_durable_mixv(value) {
        this.selectDevice_durable_mixv = value;
        this.getTimeDurableStatisticsList(2);
      },

      deviceChange_durable_cur(value) {

        this.selectDevice_durable_cur = value;
        this.selectDevice_durable_cur_item = this.deviceList_durable_cur[value - 1];
        this.getTimeDurableStatisticsList(3);
      },

      deviceChange_durable_openv(value) {
        this.selectDevice_durable_openv = value;
        this.getTimeDurableStatisticsList(4);
      },

      deviceChange_durable_potential(value) {
        this.selectDevice_durable_potential = value;
        this.getTimeDurableStatisticsList(4);
      },

      deviceChange_distance(value) {
        this.selectDevice_distance_item = this.deviceList_distance[value];
        console.log(7777, this.selectDevice_distance_item);
        this.selectDevice_distance = this.deviceList_distance[value].DEVICE_ID;
        this.getTimeRangeFinderStatisticsList();
      },

      deviceChange_distance2(value) {
        this.selectDevice_distance_item_2 = this.deviceList_distance_2[value];
        this.selectDevice_distance_2 = this.deviceList_distance_2[value].DEVICE_ID;
        this.getTimeRangeFinderStatisticsList2();
      },

      currentPickerChange(value) {
        this.selectCurrentPicker = value;
        this.getTimeDurableStatisticsList(value);
      },

      potentialPickerChange(value) {
        this.selectPotentialPicker = value;
        this.getTimeDurableStatisticsList(value);
      },

      externalPickerChange(value) {
        this.selectExternalPicker = value;
        this.getTimeDurableStatisticsList(value);
      },

      bridgePickerChange(value) {
        this.selectBridgePicker = value;
      },

      tunnelPickerChange(value) {
        this.selectTunnelPicker = value;
      },

      slopePickerChange(value) {
        this.selectSlopePicker = value;
      },
      showDetail(index) {
        this.currentIndex = index;
        this.showModal = true;
      }
    }
  }
</script>

<style scoped>
    .box_top {
        flex: 1;
        background: rgba(62, 111, 158, 0.3);
        border: 1px solid rgb(67, 139, 180);
        box-sizing: border-box;
        margin-top: 16px;
    }

    .box_bottom {
        width: 1390px;
        height: 350px;
        background: rgba(62, 111, 158, 0.3);
        border: 1px solid rgb(67, 139, 180);
        box-sizing: border-box;
        margin-top: 16px;
    }

    .box_border {
        border: 1px solid rgb(67, 139, 180);
        box-sizing: border-box;
    }

    .box_border_right {
        border-right: 1px solid rgba(216, 216, 216, 0.3);
        box-sizing: border-box;
    }

    .box_border_bottom {
        border-bottom: 1px solid rgba(216, 216, 216, 0.3);
        box-sizing: border-box;
    }

    >>> .ant-calendar-range-picker-separator {
        color: #fff;
    }

    >>> .screen-dialog .ant-modal-header {
        background-color: rgba(41, 65, 105, 0.9);
        border-bottom-color: #60ECFF;
    }

    >>> .screen-dialog .ant-modal-close-icon {
        color: white;
    }

    >>> .screen-dialog .ant-modal-title {
        color: white;
    }

    >>> .screen-dialog .ant-modal-content {
        background-color: rgba(41, 65, 105, 0.9);
        background-clip: padding-box;
        border: 0;
        border-radius: 4px;
        pointer-events: auto;
    }
</style>
