<template>
  <div style="width: 100%;height: 100%;" class="flex-column">
    <span class="size-16 color-333">修改密码</span>
    <div class="flex-1" style="margin: 10px 0">
      <a-form  :form="form" @submit="handleSubmit" style="width: 100%">
        <a-form-item label="登录账户名" style="height: 60px">
          <a-input :disabled="true" :value="bean?bean.ACCOUNT_NAME:''"/>
        </a-form-item>
        <a-form-item label="旧密码">
          <a-input-password placeholder="请输入旧密码" v-decorator="['oldPassword', { rules: [{ required: true, message: '请输入旧密码!' }] }]" :allowClear="true"/>
        </a-form-item>
        <a-form-item label="新密码">
          <a-input-password placeholder="请输入新密码" v-decorator="['newPassword', { rules: [{ required: true, message: '请输入新密码!' }] }]" :allowClear="true"/>
        </a-form-item>

        <a-form-item label="确认密码">
          <a-input-password placeholder="请输入确认密码"  v-decorator="['confirmPassword', { rules: [{ required: true, message: '请输入确认密码!' }] }]" :allowClear="true"/>
        </a-form-item>
        <div class="flex-row-center" style="height: 50px;justify-content: flex-end;margin-top: 20px">
          <a-button type="link" @click="mClose">
            关闭
          </a-button>
          <a-button type="primary"  html-type="submit">
            保存
          </a-button>
        </div>
      </a-form>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    bean: {
      type: Object,
      default: null
    },
  },
  data() {
    return {
      form: this.$form.createForm(this, { name: 'password' }),
    }
  },
  mounted() {
    console.log(11222,this.bean);
  },

  methods: {
    mClose() {
      this.$emit('passwordClose' )
    },
    handleSubmit(e){
      e.preventDefault();
      let self = this;
      this.form.validateFields((err, values) => {
        if (!err) {
          if(values.newPassword!=values.confirmPassword){
            this.$message.error('新密码和确认密码不一致');
            return;
          }
          let p = {
            password: values.newPassword,
            oldPassword:values.oldPassword
          }
          this.$util.post('api/password', p).then((res) => {
            self.$message.success('密码修改成功');
            self.$emit('passwordClose');
            self.$util.eventBus.$emit(self.$util.notifyKey.loginOut)
          });
        }
      });
    },
  }
}
</script>

<style scoped>
</style>
