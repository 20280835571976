<template>
    <div style="padding: 20px;">
        <div class="flex-1 flex-column" style="padding-top: 20px; background: #fff;">
            <div class="flex-column" style="width: 100%">
                <a-form :form="form" @submit="handleSubmit" style="width: 100%" labelAlign="left"
                        :label-col="{ span: 6 }">
                    <a-row type="flex" justify="space-around">
                        <a-col :span="span">
                            <a-form-item label="事件名称">
                                <a-input placeholder="请输入事件名称"
                                         v-decorator="['eventName', { rules: [{ required: true, message: '请输入事件名称!' }] }]"
                                         :allowClear="true"/>
                            </a-form-item>
                        </a-col>

                        <a-col :span="span">
                            <a-form-item label="事件类型">
                                <a-select placeholder="请选择事件类型" :allowClear="true"
                                          v-decorator="['eventType', { rules: [{ required: true, message: '请选择事件类型!' }] }]">
                                    <template v-for="item in typeList">
                                        <a-select-option :key="item.DATA_ID" :value="item.DATA_ID">
                                            {{ item.CLASS_VALUE }}
                                        </a-select-option>
                                    </template>
                                </a-select>
                            </a-form-item>
                        </a-col>
                    </a-row>

                    <a-row type="flex" justify="space-around">
                        <a-col :span="span">
                            <a-form-item label="结构物名称">
                                <a-select placeholder="请选择结构物" :allowClear="true"
                                          v-decorator="['structureId', { rules: [{ required: true, message: '请选择结构物!' }] }]">
                                    <template v-for="item in structureList">
                                        <a-select-option :key="item.STRUCTURE_ID" :value="item.STRUCTURE_ID">
                                            {{ item.STRUCTURE_NAME }}
                                        </a-select-option>
                                    </template>
                                </a-select>
                            </a-form-item>
                        </a-col>
                        <a-col :span="span" class="drop1">
                            <a-form-item label="开始时间">
                                <a-date-picker valueFormat="YYYY-MM-DD HH:mm:ss" placeholder="请选择开始时间"
                                               v-decorator="['startTime', { rules: [{ required: true, message: '请选择开始时间!' }] }]"
                                               :allowClear="true" style="width: 100%" :showTime="{ format: 'HH:mm:ss' }"/>
                            </a-form-item>
                        </a-col>
                    </a-row>

                    <a-row type="flex" justify="space-around">
                        <a-col :span="23">
                            <a-form-item label="事件描述">
                                <a-textarea placeholder="请输入事件描述"
                                            v-decorator="['description', { rules: [{ required: true, message: '请输入事件描述!' }] }]"
                                            :allowClear="true" :auto-size="{ minRows: 8}"/>
                            </a-form-item>
                        </a-col>
                    </a-row>

                    <a-row type="flex" justify="space-around">
                        <a-col :span="23">
                            <a-form-item label="图片" :label-col="{ span: 24 }" :wrapper-col="{ span: 24 }">
                                <a-upload
                                        name="imgFile"
                                        list-type="picture-card"
                                        :action="actionUrl"
                                        :file-list="fileList"
                                        @change="uploadCallback"
                                        @preview="handlePreview"
                                        :multiple="true"
                                        :remove="handleRemove"
                                >
                                    <div v-if="fileList&&fileList.length <12">
                                        <a-icon :type="loading ? 'loading' : 'plus'"/>
                                        <div>
                                            点击上传
                                        </div>
                                    </div>
                                </a-upload>
                            </a-form-item>
                        </a-col>
                    </a-row>

                    <div class="flex-row-center" style="height: 50px;justify-content: flex-end;margin: 30px 50px;">
                        <a-button type="link" @click="back">
                            取消
                        </a-button>
                        <a-button type="primary" html-type="submit">
                            保存
                        </a-button>
                    </div>
                </a-form>
            </div>
        </div>
        <a-modal :visible="previewVisible" :footer="null" @cancel="previewVisible = false">
            <img alt="预览" style="width: 100%" :src="previewImage"/>
        </a-modal>
    </div>
</template>

<script>
  export default {
    data() {
      return {
        span: 11,
        form: this.$form.createForm(this, {name: 'company'}),
        structureList: [],
        typeList: [],

        actionUrl: '/client/api/uploadFile',
        fileList: [],
        previewVisible: false,
        previewImage: '',
      }
    },
    mounted() {
      this.getInfo();
      this.getTypeList();
      this.getStructureList();
    },

    methods: {
      getInfo() {
        let self = this;
        if (this.$route.query.eventId) {
          this.$util.showLoading(self);
          this.eventId = this.$route.query.eventId;
          this.$util.showLoading(this);
          this.$util.get('api/eventInfo/' + this.eventId, {}, true).then((res) => {
            this.$util.hideLoading();
            console.log(111, res);
            let dao = res.content;
            self.form.setFieldsValue({
              eventName: dao.EVENT_NAME,
              eventType: dao.EVENT_TYPE,
              structureId: dao.STRUCTURE_ID,
              startTime: dao.START_TIMES,
              description: dao.DESCRIPTION,
            })
          })

          self.$util.get('api/fileList', {objectId: this.$route.query.eventId, fileType: 2, type: 3}).then((ret) => {
            let imgList = ret.content.initialPreviewConfig;
            if (imgList && imgList.length > 0) {
              let picList = [];
              for (let i = 0; i < imgList.length; i++) {
                let item = imgList[i];
                picList.push(
                  {
                    uid: '-' + i,
                    name: item.caption,
                    caption: item.caption,
                    key: item.key,
                    status: 'done',
                    url: self.$util.getServeImageUrl() + item.key,
                  }
                )
              }
              self.fileList = picList;
            }
          });
        }
      },

      getTypeList() {
        this.$util.get('api/dataList', {
          page: 1,
          pageSize: 100,
          classType: 'EVENT_TYPE'
        }, true).then((res) => {
          this.typeList = res.content
        })
      },

      getStructureList() {
        let self = this;
        let p = {
          page: 1,
          pageSize: 1000,
        }
        this.$util.showLoading(self);
        this.$util.get('api/structureList', p).then((res) => {
          this.$util.hideLoading();
          let bean = res.content;
          self.structureList = bean.list;
        })
      },

      handleSubmit(e) {
        e.preventDefault();
        let self = this;
        this.form.validateFields((err, values) => {
          if (!err) {
            console.log(333, values);
            let p = {
              eventName: values.eventName,
              eventType: values.eventType,
              structureId: values.structureId,
              startTime: values.startTime,
              description: values.description,
              action: 'ADD'
            }
            if (this.eventId) {
              p.action = 'EDIT';
              p.eventId = this.eventId;
            }

            let picList = [];
            if (self.fileList.length > 0) {
              for (let i = 0; i < self.fileList.length; i++) {
                console.log(i)
                let dao = self.fileList[i].response;
                if (dao && dao.errorCode == 0 && dao.content && dao.content.initialPreviewConfig && dao.content.initialPreviewConfig.length > 0) {
                  picList.push({
                    key: dao.content.initialPreviewConfig[0].key,
                    caption: dao.content.initialPreviewConfig[0].caption,
                  })
                } else {
                  picList.push({
                    key: self.fileList[i].key,
                    caption: self.fileList[i].caption,
                  })
                }

              }
            }
            let keys = [], caption = [];
            if (picList.length > 0) {
              for (let i = 0; i < picList.length; i++) {
                keys.push(picList[i].key)
                caption.push(picList[i].caption)
              }
            }
            p.imgCaptions = caption.join(',');
            p.imgKeys = keys.join(',');

            console.log(124, p);
            this.$util.post('api/submitEvent', p).then((res) => {
              console.log(res);
              self.back();
            });
          }
        });
      },
      back() {
        this.$router.back();
      },
      handleRemove(file) {
        file.status = '1';
        let name = '';
        if (file.response) {
          name = file.response.content.initialPreviewConfig[0].key
        } else {
          name = file.name
        }
        this.$util.post("api/deleteFile?saveName=" + name, {})
      },
      uploadCallback({file, fileList}) {
        this.fileList = fileList;
      },
      async handlePreview(file) {
        if (!file.url && !file.preview) {
          file.preview = await this.$util.getBase64(file.originFileObj);
        }
        this.previewImage = file.url || file.preview;
        this.previewVisible = true;
      },
    }
  }
</script>

<style scoped>

</style>
