<template>
  <div class="flex-column" style="position: relative;height: 100%;width: 100%;">
    <div class="flex-row flex-1">

      <div class="flex-1 flex-column" style="margin: 10px 15px 0 20px;">
        <div style="position: relative; width: 100%; height: 40px;">
          <img src="../image/screen/detail_head_bg_1.png" style="width: 100%; height: 40px;">
          <div class="absolute-0 flex-row-center size-22 color-C5EAFF"
               style="margin-left: 40px; margin-top: 2px;">
            总体情况
          </div>
        </div>
        <div class="box_top">
          <div class="flex-row center flex-1" style="height: 100%;">
            <div class="flex-column flex-1">
              <div style="width: 360px; height: 360px; position:relative; margin-top: -10px; margin-left: 0px;">
                <v-chart class="my-chart" :options="dashboardChart"/>
                <div style="position:absolute; left: 0; top: 200px; right: 0px; bottom: 0;"
                     class="flex-column">
                  <!--                                    <div class="flex-row center" style="position: relative; margin-top: 10px;">-->
                  <!--                                        <img src="../image/screen/detail_health_bg.png" height="35">-->
                  <!--                                        <div style="position: absolute; left: 0; top: 0; right: 0; bottom: 0;"-->
                  <!--                                             class="flex-row center">-->
                  <!--                                            <div class="size-16 color-fff">-->
                  <!--                                                总体得分：{{this.score}}-->
                  <!--                                            </div>-->
                  <!--                                            <div class="size-16" style="margin-left: 20px;"-->
                  <!--                                                 :style="'color:' + getHealth().color">-->
                  <!--                                                {{getHealth().text}}-->
                  <!--                                            </div>-->
                  <!--                                        </div>-->
                  <!--                                    </div>-->
                  <div class="flex-row center size-18" style="position: relative; margin-top: 10px;"
                       :style="'color:' + getHealthStatus().color">
                    {{ getHealthStatus().text }}
                  </div>

                  <div class="flex-row wrap" style="margin-top: 10px; margin-left: 20px;">
                    <div v-for="(item, index) in healthList" :key="index">
                      <div class="flex-row-center" style="width: 170px; height: 32px;">
                        <div style="width: 8px; height: 8px; border-radius: 4px; margin-left: 20px;"
                             :style="'background:' + item.color"></div>
                        <div class="size-14 color-fff-80" style="margin-left: 12px;">
                          {{ item.title }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>

            <div class="flex-column flex-1" style="margin: 40px 0 30px 20px;"
                 :style="'margin-left:' + 20 * scaleX + 'px;'">
              <div class="flex-row-center text_height">
                <div class="color-F8C33A size-20" style="width: 105px;">{{ getLabelTitle().title1 }}</div>
                <div class="color-fff size-20">{{ item.STRUCTURE_NAME }}</div>
              </div>
              <div class="flex-row-center text_height">
                <div class="color-F8C33A size-20" style="width: 105px;">{{ getLabelTitle().title2 }}</div>
                <div class="color-fff size-20">{{ getLabelContentLine().content2 }}
                </div>
              </div>
              <div class="flex-row-center text_height">
                <div class="color-F8C33A size-20" style="width: 105px;">{{ getLabelTitle().title3 }}</div>
                <div class="color-fff size-20">
                  {{ getLabelContentLine().content3 }}
                </div>
              </div>
              <div class="flex-row-center text_height">
                <div class="color-F8C33A size-20" style="width: 105px;">{{ getLabelTitle().title4 }}</div>
                <div class="color-fff size-20">{{ getLabelContentLine().content4 }}
                </div>
              </div>
              <div class="flex-row-center text_height">
                <div class="color-F8C33A size-20" style="width: 105px;">{{ getLabelTitle().title5 }}</div>
                <div class="color-fff size-20">{{ getLabelContentLine().content5 }}</div>
              </div>
            </div>
          </div>

        </div>

      </div>

      <div class="flex-1" style="margin: 10px 30px 0 0;">
        <div class="flex-1 flex-column" style="position: relative;">
          <div style="position: relative; width: 100%; height: 40px;">
            <img src="../image/screen/detail_head_bg_1.png" style="width: 100%; height: 40px;">
            <div class="absolute-0 flex-row-center size-22 color-C5EAFF"
                 style="margin-left: 40px; margin-top: 2px;">
              设备在线统计
            </div>
          </div>

          <!--                    <div style="position: absolute;right: 20px;top: 65px;" class="flex-column drop1" v-if="selectMonitorItem">-->
          <!--                        <a-select dropdownClassName="dropdownStyle3" v-model="selectMonitorItem" @change="monitorItemChange"-->
          <!--                                  style="width: 200px">-->
          <!--                            <template v-for="item in monitorItemList">-->
          <!--                                <a-select-option :key="item.MONITOR_ITEM_ID" :value="item.MONITOR_ITEM_ID">-->
          <!--                                    {{ item.ITEM_NAME }}-->
          <!--                                </a-select-option>-->
          <!--                            </template>-->
          <!--                        </a-select>-->
          <!--                    </div>-->

          <div class="box_top flex-column" style="align-items: center;">
            <div style="height: 200px; width: 200px; position: relative; margin-top: 45px;">
              <div style="position: absolute;left: 0;top: 0;right:0;bottom:0;align-items: center"
                   class="u-flex-col-center  color-fff">
                <span class="size-30 color-DEE8FE">{{ allDeviceNum }}</span>
                <span class="size-14 color-DEE8FE">设备总数</span>
              </div>
              <chart_pie :dataValue="pieData" v-if="pieData"/>
            </div>

            <div>
              <div class="flex-row-center">
                <div style="width: 8px; height: 8px; border-radius: 4px;" class="color-bg-2BFFBE"></div>
                <div class="size-16 color-fff-70" style="margin-left: 8px;">在线设备</div>
                <div class="size-20 color-fff" style="margin-left: 25px;">{{ onLineDevice }}</div>
                <div class="size-14 color-fff-50" style="margin-left: 4px;">个</div>
              </div>
              <div class="flex-row-center" style="margin-top: 5px;">
                <div style="width: 8px; height: 8px; border-radius: 4px;" class="color-bg-7794BE"></div>
                <div class="size-16 color-fff-70" style="margin-left: 8px;">离线设备</div>
                <div class="size-20 color-fff" style="margin-left: 25px;">{{ offLineDevice }}</div>
                <div class="size-14 color-fff-50" style="margin-left: 4px;">个</div>
              </div>
            </div>

          </div>
        </div>

        <div class="flex-1 flex-column" style="margin-left: 15px;">
          <div style="position: relative; width: 100%; height: 40px;">
            <img src="../image/screen/detail_head_bg_1.png" style="width: 100%; height: 40px;">
            <div class="absolute-0 flex-row-center size-22 color-C5EAFF"
                 style="margin-left: 40px; margin-top: 2px;">
              设备报警级别统计
            </div>
          </div>

          <div class="box_top flex-column" style="align-items: center;">
            <div style="height: 200px; width: 200px; position: relative; margin-top: 45px;">
              <div style="position: absolute;left: 0;top: 0;right:0;bottom:0;align-items: center"
                   class="u-flex-col-center  color-fff">
                <span class="size-30 color-DEE8FE">{{ allAlarmDevice }}</span>
                <span class="size-14 color-DEE8FE">报警设备</span>
              </div>
              <chart_pie :dataValue="pieData2" v-if="pieData2"/>
            </div>

            <div class="flex-row" style="width: 100%; padding: 5px 20px;">
              <div class="flex-1 flex-column">
                <div class="flex-row-center">
                  <div class="size-20 color-fff" style="margin-left: 12px;">{{ yellowCount }}</div>
                  <div class="size-14 color-fff-50" style="margin-left: 4px;">个</div>
                </div>
                <div class="flex-row-center">
                  <div style="width: 8px; height: 8px; border-radius: 4px;" class="color-bg-FFE20E"></div>
                  <div class="size-16 color-fff-70" style="margin-left: 8px;">超限一级</div>
                </div>
              </div>
              <div class="flex-1 flex-column">
                <div class="flex-row-center">
                  <div class="size-20 color-fff" style="margin-left: 12px;">{{ orangeCount }}</div>
                  <div class="size-14 color-fff-50" style="margin-left: 4px;">个</div>
                </div>
                <div class="flex-row-center">
                  <div style="width: 8px; height: 8px; border-radius: 4px;" class="color-bg-FFA12C"></div>
                  <div class="size-16 color-fff-70" style="margin-left: 8px;">超限二级</div>
                </div>
              </div>
              <div class="flex-1 flex-column">
                <div class="flex-row-center">
                  <div class="size-20 color-fff" style="margin-left: 12px;">{{ redCount }}</div>
                  <div class="size-14 color-fff-50" style="margin-left: 4px;">个</div>
                </div>
                <div class="flex-row-center">
                  <div style="width: 8px; height: 8px; border-radius: 4px;" class="color-bg-FF6761"></div>
                  <div class="size-16 color-fff-70" style="margin-left: 8px;">超限三级</div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>

    <div class="flex-row flex-1" style="margin-top: 10px; flex: 1.5;">

      <div class="flex-column" style="margin: 10px 15px 0 20px; flex: 2;">
        <div style="position: relative; width: 100%; height: 40px;">
          <img src="../image/screen/detail_head_bg_1.png" style="width: 100%; height: 40px;">
          <div class="absolute-0 flex-row-center size-22 color-C5EAFF"
               style="margin-left: 40px; margin-top: 2px;">
            最新报警信息
          </div>
        </div>

        <div class="box_top" style="padding: 30px 20px 0;">
          <div v-for="(item, index) in alarmList" :key="index" class="flex-row" style="margin-bottom: 20px;">

            <img :src="getAlarmImg(item.ALARM_LEVEL)" style="width: 20px; height: 20px;">
            <div class="flex-column" style="margin-left: 10px; margin-top: -5px;">
              <div class="size-18 color-fff lineNum-1">
                {{ item.DESCRIPTION ? item.DESCRIPTION : '' }}
              </div>
              <div class="size-14 color-A0CAFF" style="margin-top: 3px;">
                {{ item.ALARM_TIMES }}
              </div>
            </div>

          </div>
        </div>

      </div>

      <div class="flex-column" style="margin: 10px 30px 0 0; flex: 3;">
        <div style="position: relative; width: 100%; height: 40px;">
          <img src="../image/screen/detail_head_bg_1.png" style="width: 100%; height: 40px;">
          <div class="absolute-0 flex-row-center size-22 color-C5EAFF"
               style="margin-left: 40px; margin-top: 2px;">
            报警次数统计
          </div>
        </div>

        <div class="box_top" style="">
          <div class="flex-1 flex-column" style="margin-top: 0px; height: 100%;">
            <div class="u-flex-between" style="height: 60px; padding: 0 20px;">
              <div class="size-18 color-A0CAFF">单位：次</div>
              <div class="flex-row-center">
                <div class="flex-row-center">
                  <div class="circle_point color-bg-E4C61A"></div>
                  <div class="size-16 color-fff-80 margin-left-8">超限一级</div>
                </div>
                <div class="flex-row-center" style="margin-left: 50px;">
                  <div class="circle_point color-bg-FF6F20"></div>
                  <div class="size-16 color-fff-80 margin-left-8">超限二级</div>
                </div>
                <div class="flex-row-center" style="margin-left: 50px;">
                  <div class="circle_point color-bg-FF571A"></div>
                  <div class="size-16 color-fff-80 margin-left-8">超限三级</div>
                </div>
              </div>
            </div>
            <div class="flex-1" style="">
              <v-chart style="width: 95%; height: 95%;" :options="structureLineChart"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import {graphic} from "echarts/lib/export/api";
import moment from 'moment';
import chart_pie from './chart_pie';

export default {
  props: {
    item: {
      type: Object,
    },
    score: {
      type: Number
    },
    health: {
      type: Number
    },
  },
  components: {
    chart_pie
  },
  data() {
    return {
      showThird: true,
      dashboardChart: {},
      structureLineChart: {},
      thirdChart: {},
      scoreChart: {},
      healthList: [
        {
          color: '#46FFA7',
          title: 'Ⅰ级-基本完好'
        },
        {
          color: '#FFE20E',
          title: 'Ⅲ级-中等异常'
        },
        {
          color: '#60E0FF',
          title: 'Ⅱ级-轻微异常'
        },
        {
          color: '#FF6761',
          title: 'Ⅳ级-严重异常'
        }
      ],
      bridgeInfoMap: {},
      tunnelInfoMap: {},
      sideSlopeInfoMap: {},
      scores: [],
      alermRedList: [],
      alermYellowList: [],
      alermOrangeList: [],
      scaleX: document.body.clientWidth / 1920,
      scaleY: document.body.clientHeight / 1080,

      selectMonitorItem: undefined,
      monitorItemList: [],

      onLineDevice: 0,
      offLineDevice: 0,

      orangeCount: 0,
      yellowCount: 0,
      redCount: 0,

      pieData: null,
      pieData2: null,
      alarmList: []
    }
  },
  computed: {
    getLabelTitle() {
      return function () {
        let type = this.item.TYPE;
        let titleObj;
        switch (type) {
          case 1:
            titleObj = {
              title1: '桥梁名称',
              title2: '桥梁类型',
              title3: '桥梁全长',
              title4: '桥梁全宽',
              title5: '中心桩号'
            }
            break;
          case 2:
            titleObj = {
              title1: '隧道名称',
              title2: '隧道类型',
              title3: '隧道长宽',
              title4: '通风方式',
              title5: '中心桩号'
            }
            break;
          case 3:
            titleObj = {
              title1: '边坡名称',
              title2: '边坡类型',
              title3: '边坡角度',
              title4: '安全等级',
              title5: '中心桩号'
            }
            break;
          default:
            titleObj = {
              title1: '',
              title2: '',
              title3: '',
              title4: '',
              title5: ''
            };
            break;
        }
        return titleObj;
      }
    },

    getLabelContentLine() {
      return function () {
        let type = this.item.TYPE;
        let contentObj;
        switch (type) {
          case 1:
            contentObj = {
              content2: this.bridgeInfoMap && this.bridgeInfoMap.BRIDGE_TYPE_NAME ? this.bridgeInfoMap.BRIDGE_TYPE_NAME : '',
              content3: this.bridgeInfoMap && this.bridgeInfoMap.BRIDGE_LENGTH ? this.bridgeInfoMap.BRIDGE_LENGTH + ' m' : '',
              content4: this.bridgeInfoMap && this.bridgeInfoMap.BRIDGE_WIDTH ? this.bridgeInfoMap.BRIDGE_WIDTH + ' m' : '',
              content5: this.item.PILE_NUMBER ? this.item.PILE_NUMBER : ''
            }

            break;
          case 2:
            contentObj = {
              content2: this.tunnelInfoMap && this.tunnelInfoMap.TUNNEL_TYPE_NAME ? this.tunnelInfoMap.TUNNEL_TYPE_NAME : '',
              content3: '(' + (this.tunnelInfoMap && this.tunnelInfoMap.TUNNEL_LENGTH ? this.tunnelInfoMap.TUNNEL_LENGTH : '') + '*' +
                  (this.tunnelInfoMap && this.tunnelInfoMap.TUNNEL_WIDTH ? this.tunnelInfoMap.TUNNEL_WIDTH : '') + ')m',
              content4: this.tunnelInfoMap && this.tunnelInfoMap.VENTILATION_MODE_NAME ? this.tunnelInfoMap.VENTILATION_MODE_NAME : '',
              content5: this.item.PILE_NUMBER ? this.item.PILE_NUMBER : ''
            }
            break;
          case 3:
            contentObj = {
              content2: this.sideSlopeInfoMap && this.sideSlopeInfoMap.SLOPE_TYPE_NAME ? this.sideSlopeInfoMap.SLOPE_TYPE_NAME : '',
              content3: this.sideSlopeInfoMap && this.sideSlopeInfoMap.ANGLE ? this.sideSlopeInfoMap.ANGLE : '',
              content4: this.sideSlopeInfoMap && this.sideSlopeInfoMap.SAFE_LEVEL_NAME ? this.sideSlopeInfoMap.SAFE_LEVEL_NAME : '',
              content5: this.item.PILE_NUMBER ? this.item.PILE_NUMBER : ''
            }
            break;
        }
        return contentObj;
      }
    },

    getHealthStatus() {
      return function () {
        let text = '基本完好';
        let color = '#46FFA7';
        let score = 10;
        let health = this.health;
        switch (parseInt(health)) {
          case 1:
            text = '基本完好';
            color = '#46FFA7';
            score = 75 + 12;
            break;
          case 2:
            text = '轻微异常';
            color = '#60E0FF';
            score = 50 + 12;
            break;
          case 3:
            text = '中等异常';
            color = '#FFE20E';
            score = 25 + 12;
            break;
          case 4:
            text = '严重异常';
            color = '#FF6761';
            score = 12;
            break;
        }
        return {
          text,
          color,
          score
        }
      }
    },

    getAlarmImg() {
      return function (level) {
        let img;
        switch (level) {
          case 1:
            img = require('../image/screen/alarm_level_1.png');
            break;
          case 2:
            img = require('../image/screen/alarm_level_2.png');
            break;
          case 3:
            img = require('../image/screen/alarm_level_3.png');
            break;
        }
        return img;
      }
    }

  },

  created() {
    this.getData();
    this.getAlermStatistics();
    this.getMonitorItemList();
    this.getDeviceStateStatistics();
    this.getMonitorAlarmLevelStatistics();
    this.getMonitorAlarmList();
  },
  mounted() {
    console.log(888, this.item);
    this.bridgeInfoMap = this.item.bridgeInfoMap;
    this.tunnelInfoMap = this.item.tunnelInfoMap;
    this.sideSlopeInfoMap = this.item.sideSlopeInfoMap;
  },
  methods: {
    getData() {
      this.getFirstChart();
      this.getSecondChart();
    },

    getDeviceStateStatistics() {
      let p = {
        structureId: this.item.STRUCTURE_ID,
      }
      console.log(333444, p);
      this.$util.get('api/deviceStateStatistics', p, true).then((ret) => {
        let dao = ret.content;
        let deviceCount = dao.DEVICE_COUNTS ? dao.DEVICE_COUNTS : 0;
        let onLineDevice = dao.ONLINE_COUNTS ? dao.ONLINE_COUNTS : 0;
        let offLineDevice = deviceCount - onLineDevice;
        let pieData = [
          {value: onLineDevice, name: '在线设备', color: '#2BFFBE'},
          {value: offLineDevice, name: '离线设备', color: '#7794BE'},
        ];
        this.allDeviceNum = deviceCount;
        this.onLineDevice = onLineDevice;
        this.offLineDevice = offLineDevice;
        this.pieData = pieData;
      });
    },

    getMonitorAlarmLevelStatistics() {
      let p = {
        structureId: this.item.STRUCTURE_ID,
      }
      console.log(333444, p);
      this.$util.get('api/monitorAlarmLevelStatistics', p, true).then((ret) => {
        let dao = ret.content;
        this.yellowCount = dao.YELLOW_ALARM_COUNTS ? dao.YELLOW_ALARM_COUNTS : 0;
        this.orangeCount = dao.ORANGE_ALARM_COUNTS ? dao.ORANGE_ALARM_COUNTS : 0;
        this.redCount = dao.RED_ALARM_COUNTS ? dao.RED_ALARM_COUNTS : 0;

        let pieData2 = [
          {value: this.yellowCount, name: '超限一级', color: '#FFE20E'},
          {value: this.orangeCount, name: '超限二级', color: '#FFA12C'},
          {value: this.redCount, name: '超限三级', color: '#FF6761'},
        ];
        this.allAlarmDevice = dao.MONITOR_ALARM_COUNTS ? dao.MONITOR_ALARM_COUNTS : 0;
        this.pieData2 = pieData2;
      });
    },

    getMonitorItemList() {
      let p = {
        page: 1,
        pageSize: 10000,
        structureId: this.item.STRUCTURE_ID,
      }
      console.log(11123, p);
      this.$util.get('api/monitorItemList', p, true).then((ret) => {
        this.monitorItemList = ret.content;
        if (ret.content && ret.content.length > 0) {
          this.selectMonitorItem = ret.content[0].MONITOR_ITEM_ID;
        }
        // this.getMonitorPointList()
      });
    },

    getMonitorAlarmList() {
      let p = {
        page: 1,
        pageSize: 5,
        structureId: this.item.STRUCTURE_ID,
      }
      this.$util.get('api/monitorAlarmList', p, true).then((res) => {
        console.log(444, res);
        this.alarmList = res.content.list;
      });
    },

    getFirstChart() {
      this.dashboardChart = {
        series: [
          {
            type: 'gauge',
            startAngle: 180,
            endAngle: 0,
            axisLine: {
              lineStyle: {
                width: 10,
                color: [
                  [0.25, '#FF6761'],
                  [0.5, '#FFE20E'],
                  [0.75, '#60E0FF'],
                  [1.0, '#46FFA7']
                ]
              }
            },
            pointer: {
              itemStyle: {
                color: 'auto'
              }
            },
            axisTick: {
              distance: -10,
              length: 5,
              lineStyle: {
                color: '#fff',
                width: 1
              }
            },
            splitLine: {
              distance: -10,
              length: 10,
              lineStyle: {
                color: '#fff',
                width: 4
              }
            },
            // axisLabel: {
            //   color: 'auto',
            //   distance: 15,
            //   fontSize: 16
            // },
            axisLabel: {
              formatter: function (value) {
                return '';
              }
            },
            detail: {
              valueAnimation: true,
              // formatter: '{value} km/h',
              color: 'transparent'
            },
            data: [
              {
                value: this.getHealthStatus().score,
              }
            ]
          }
        ]
      };
    },

    getSecondChart() {
      let dates = [];
      for (let i = -5; i <= 0; i++) {
        let dd = moment().add(i, 'months').format("YYYY-MM");
        dates.push(dd);
      }
      this.structureLineChart = {
        grid: {
          left: '5%',
          right: '1%',
          bottom: '2%',
          top: '5%',
          containLabel: true
        },
        xAxis: [{
          type: "category",
          data: dates,
          axisLine: {
            show: false
          },
          axisLabel: {
            color: "#A0CAFF",
            fontSize: 12
          },
          axisTick: {
            show: false
          }
        }],

        yAxis: [{
          type: "value",
          axisLabel: {
            color: "#A0CAFF",
            fontSize: 12
          },
          splitLine: {
            lineStyle: {
              // 使用深浅的间隔色
              color: ['rgba(255,255,255,0.15)']
            }
          }
        }],
        series: [
          {
            type: 'line',
            areaStyle: { //渐变色
              normal: {
                color: graphic.LinearGradient(0, 0, 0, 1,
                    [{offset: 0, color: 'rgba(255, 87, 26, 0.3)'},
                      {offset: 0.5, color: 'rgba(255, 87, 26, 0.1)'},
                      {offset: 1, color: 'rgba(255, 87, 26, 0.01)'}])
              }
            },

            data: this.alermRedList,
            lineStyle: {
              color: '#FF571A',
              width: 1,
              type: 'dashed'
            },
            symbol: 'image://https://nas.iquanzhan.com/structure/circle_red.png',
            symbolSize: 15,
          },
          {
            type: 'line',
            areaStyle: { //渐变色
              normal: {
                color: graphic.LinearGradient(0, 0, 0, 1,
                    [{offset: 0, color: 'rgba(228, 198, 26, 0.3)'},
                      {offset: 0.5, color: 'rgba(228, 198, 26, 0.1)'},
                      {offset: 1, color: 'rgba(228, 198, 26, 0.01)'}])
              }
            },
            data: this.alermYellowList,
            lineStyle: {
              color: '#E4C61A',
              width: 1,
              type: 'dashed'
            },
            symbol: 'image://https://nas.iquanzhan.com/structure/circle_yellow.png',
            symbolSize: 15,
          },
          {
            type: 'line',
            areaStyle: { //渐变色
              normal: {
                color: graphic.LinearGradient(0, 0, 0, 1,
                    [{offset: 0, color: 'rgba(255, 111, 32, 0.3)'},
                      {offset: 0.5, color: 'rgba(255, 111, 32, 0.1)'},
                      {offset: 1, color: 'rgba(255, 111, 32, 0.01)'}])
              }
            },
            data: this.alermOrangeList,
            lineStyle: {
              color: '#FF6F20',
              width: 1,
              type: 'dashed'
            },
            symbol: 'image://https://nas.iquanzhan.com/structure/circle_orange.png',
            symbolSize: 15,
          }
        ]
      };
    },

    getAlermStatistics() {
      let startDate = moment().add(-5, 'months').startOf('month').format("YYYY-MM-DD");
      let endDate = moment().add(0, 'months').endOf('month').format("YYYY-MM-DD");

      let timeList = [];
      for (let i = -5; i <= 0; i++) {
        let dd = moment().add(i, 'months').format("MM月");
        let item = {
          MONITOR_ALARM_COUNTS: 0,
          ORANGE_ALARM_COUNTS: 0,
          RED_ALARM_COUNTS: 0,
          STATISTICS_TIME: dd,
          YELLOW_ALARM_COUNTS: 0
        }
        timeList.push(item);
      }

      let p = {
        startDate: startDate,
        endDate: endDate,
        structureId: this.item.STRUCTURE_ID,
        type: 2
      };
      this.$util.get('api/timeMonitorAlarmLevelStatisticsList', p).then((res) => {
        console.log(334, res);

        let list = timeList;
        let resList = res.content;
        for (let i = 0; i < list.length; i++) {
          let item = list[i];
          for (let j = 0; j < resList.length; j++) {
            let item2 = resList[j];
            if (item.STATISTICS_TIME == item2.STATISTICS_TIME) {
              timeList[i] = resList[j];
              break;
            }
          }
        }
        console.log(234, timeList);

        this.alermRedList = timeList.map((item) => {
          return item.RED_ALARM_COUNTS;
        })
        this.alermYellowList = timeList.map((item) => {
          return item.YELLOW_ALARM_COUNTS;
        })
        this.alermOrangeList = timeList.map((item) => {
          return item.ORANGE_ALARM_COUNTS;
        })
        this.getSecondChart();
      });
    },

    monitorItemChange(value) {
      this.selectMonitorItem = value;
      // this.getMonitorPointList();
    },
  }
}
</script>

<style scoped>
.box_top {
  /*width: 685px;*/
  /*height: 360px;*/
  flex: 1;
  background: rgba(62, 111, 158, 0.3);
  border: 1px solid rgb(67, 139, 180);
  box-sizing: border-box;
  margin-top: 16px;
}

.box_bottom {
  /*width: 1390px;*/
  /*height: 350px;*/
  flex: 1;
  background: rgba(62, 111, 158, 0.3);
  border: 1px solid rgb(67, 139, 180);
  box-sizing: border-box;
  margin-top: 16px;
  margin-bottom: 10px;
}

.text_height {
  height: 50px;
}

.my-chart {
  width: 100%;
  height: 100%;
}

.circle_point {
  width: 8px;
  height: 8px;
  border-radius: 4px;
}
</style>
