<template>
  <div style="width: 100%;height: 100%" class="flex-col">
    <div class="flex-row-center">
      <div class="flex-1"><span class="title">设备名称<span class="value" style="margin-left: 30px">{{ deviceInfo.DEVICE_NAME }}</span></span></div>
      <div class="flex-1"><span class="title">设备编号<span class="value" style="margin-left: 30px">{{ deviceInfo.DEVICE_NUMBER }}</span></span></div>
    </div>
    <div class="flex-row-center" style="margin-top: 20px">
      <div class="flex-1"><span class="title">采集时间<span class="value" style="margin-left: 30px">{{time}}</span></span></div>
      <div class="flex-1"><span class="title">数据状态<span class="value" style="margin-left: 30px">正常</span></span></div>
    </div>

    <div class="flex-row-center" style="background-color: rgba(255,255,255,0.2);margin-top: 20px;height: 50px">
      <div class="flex-1 u-flex-col-center"><span class="title">特征值</span></div>
      <div class="flex-1 u-flex-col-center"><span class="title">监测数据</span></div>
    </div>

    <div v-for="(item, index) in dataList" :key="index" class="flex-row-center" style="height: 60px;border-bottom: 1px solid rgba(255,255,255,0.2);">
      <div class="flex-1 u-flex-col-center" style="border-right:  1px solid rgba(255,255,255,0.2);height: 100%"><span class="title">{{item.name}}</span></div>
      <div class="flex-1 u-flex-col-center"><span class="title">{{item.value}}</span></div>
    </div>

    <div class="flex-row-center" style="margin-top: 20px">
      <div class="flex-1"><span class="title">备注<span class="value" style="margin-left: 30px">{{description}}</span></span></div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "特征值",
  props: {
    deviceInfo: {
      type: Object,
    }
  },
  data(){
    return{
      dataList:[{name:'采样间隔',value:'0.0604167'},
        {name:'最大值',value:'0.0156725'},
        {name:'最小值',value:'0.079861'},
        {name:'平均值',value:'0.016875'},
        {name:'RMS均方根',value:'0.0125645'},
        {name:'方差',value:'0.0608974'}],
      time:'--',
      description:''
    }
  },
  mounted() {
    let item = this.deviceInfo;
    let url = '';
    if (item.DEVICE_TYPE == 4) {//拉线位移计
      url='wireDistanceDataList';
    } else if (item.DEVICE_TYPE == 5) {//倾角计
      url='inclinometerDataList';
    } else if (item.DEVICE_TYPE == 6) {//gnss
      url='gnssDataList';
    } else if (item.DEVICE_TYPE == 10) {//激光测距仪
      url='rangeFinderDataList';
    } else if (item.DEVICE_TYPE == 48) {//耐久
    }
    this.$util.get('api/'+url, {page:1,pageSize:1 }, true).then((res) => {
      if(res.content.list&&res.content.list.length>0){
        let bean = res.content.list[0];
        if(bean.monitorTime){
          this.time = moment(bean.monitorTime.time).format(this.$util.dateFormatStr.dateYMDHMS)
        }
        this.description = bean.description;
      }

    })
  }
}
</script>

<style scoped>
  .title{
    font-size: 16px;
    color: #CBECFF;
  }
  .value{
    font-size: 16px;
    color: #FFFFFF;
  }
</style>
