<template>
  <highcharts :options="chartOptions" :id="id" v-if="chartOptions" style="height: 180px;"></highcharts>
</template>
<script>
import {Chart} from 'highcharts-vue'
import highcharts from 'highcharts'
import highcharts3d from 'highcharts/highcharts-3d'
export default {
  components: {
    highcharts: Chart
  },
  props: {
    arr: []
  },
  data() {
    return {
      id: 'high' + Math.random(),
      chartOptions: null,
    }
  },
  mounted() {
    let self = this;
    highcharts3d(highcharts);
    setTimeout(()=>{
      console.log(22222,self.arr);
      self.chartOptions = {
        credits: {
          enabled: false //不显示LOGO
        },
        colors: ['#57D2FF', '#80FF2C', '#FFD355', '#FF9A61'],
            chart: {
              backgroundColor:'transparent',//改变背景颜色
              type: 'pie',
              options3d: {
                enabled: true,
                alpha: 55
               },
           spacing : [0, 0 ,0, 0]
        },
        tooltip:{
          backgroundColor: 'rgba(20, 37, 59, 0.8)',
          style: {                      // 文字内容相关样式
            color: "white",
            fontSize: "16px",
          }
        },
        title: {
          text: ''
        },
        plotOptions: {
          pie: {
            depth: 20,//饼图的厚度
                innerSize: 95,
                dataLabels: {
                  enabled: false,//是否显示饼图的线形tip
                }
          }
        },
        series: [{
          name: '座数',
          data: [
            ['基本完好', self.arr[0]],
            ['轻微异常', self.arr[1]],
            ['中度异常', self.arr[2]],
            ['重度异常', self.arr[3]]]
        }]
      }
    },300)
  }
}
</script>

<style scoped>

</style>
