<template>
  <div class="flex-column" style="height: 100%;width: 100%">
    <div class="flex-row-center" v-if="deviceDetail" style="margin-bottom: 30px">
      <div class="cursor_pointer u-flex-col-center" :class="currentIndex===1?'btn':'btn-gray'" @click="currentIndex=1">基本信息</div>
      <div class="cursor_pointer u-flex-col-center" :class="currentIndex===2?'btn':'btn-gray'" @click="currentIndex=2">统计信息</div>
      <div class="cursor_pointer u-flex-col-center" :class="currentIndex===3?'btn':'btn-gray'" @click="currentIndex=3">特征值</div>
    </div>
    <div class="flex-column" style="height: 100%;width: 100%;">
      <div v-if="currentIndex===1&&deviceDetail" style=" height: 100%" class="color-fff size-18 flex-column">
        <div class="flex-row-center"><div class="label-width">监测点名称：</div>{{ pointInfo.DEVICE_NAME }}</div>
        <div class="flex-row-center label"><div class="label-width">监测点编号：</div>{{ pointInfo.POINT_NUMBER }}</div>
        <div class="flex-row-center label"><div class="label-width">报警状态：</div>{{ getPointAlarmLevel(pointInfo.ALARM_LEVEL).name }}</div>
        <div class="flex-row-center label"><div class="label-width">安装位置：</div>{{ pointInfo.LOCATION }}</div>
        <div class="flex-row-center label"><div class="label-width">绑定设备：</div>{{ pointInfo.DEVICE_NAME}}</div>
        <div class="flex-row-center label"><div class="label-width">设备编号：</div>{{  deviceDetail.DEVICE_NUMBER }}</div>
        <div class="flex-row-center label"><div class="label-width">设备类型：</div>{{ deviceDetail.DEVICE_TYPE_NAME }}</div>
        <div class="flex-row-center label"><div class="label-width">设备状态：</div>{{ deviceDetail.STATUS?statusList[deviceDetail.STATUS]:'正常' }}</div>
      </div>
      <div v-if="currentIndex===2&&deviceDetail" style=" height: 100%">
        <structure_shicheng  :pointInfo="deviceDetail"/>
      </div>
      <div v-if="currentIndex===3&&deviceDetail" style=" height: 100%">
        <deviceValue :deviceInfo="deviceDetail"/>
      </div>
    </div>

  </div>
</template>

<script>
import structure_shicheng from './structure_shicheng';
import deviceValue from "./deviceValue";
export default {
  name: "deviceDetail",
  components:{
    structure_shicheng,deviceValue
  },
  props:{
    pointInfo:Object
  },
  data(){
    return {
      currentIndex:1,
      deviceDetail:null,
      statusList:['正常','故障','损坏','维修','更换'],
    }
  },
  mounted() {
    if(this.pointInfo){
      let deviceId = this.pointInfo.DEVICE_ID;
      this.$util.get('api/deviceInfo/'+deviceId,{}).then((res)=>{
        this.deviceDetail = res.content;
      });
    }
  },
  computed:{
    getAlarmLevel() {
      return function (state) {
        let color = '', title = '';
        if (state===0) {
          color = '#62DBED';
          title = '在线';
        } else {
          color = '#999999';
          title = '离线';
        }
        return {
          name: title, color: color
        }
      }

    },
    getPointAlarmLevel() {
      return function (level) {
        let color = '', title = '';
        if (level) {
          switch (level) {
            case 0:
              color = '#62DBED';
              title = '正常';
              break;
            case 1:
              color = 'yellow';
              title = '超限一级';
              break;
            case 2:
              color = 'orange';
              title = '超限二级';
              break;
            case 3:
              color = 'red';
              title = '超限三级';
              break;
          }
        } else {
          color = '#62DBED';
          title = '正常';
        }
        return {
          name: title, color: color
        }
      }
    },
  }
}
</script>

<style scoped>
  .btn{
    color: white;
    background-color: #1BA2E3;
    border-radius: 2px;
    height: 30px;
    width: 80px;
    margin-right: 15px;
  }
  .btn-gray{
    color: #999999;
    background-color: #EEEEEE;
    border-radius: 2px;
    height: 30px;
    width: 80px;
    margin-right: 15px;
  }
  .label-width{
    width: 120px;
  }

  .label{
    margin-top: 20px;
  }

</style>
