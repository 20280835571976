<template>
    <div style="width: 100%;height: 100%">
        <v-chart class="my-chart" :options="displacementChart"/>
    </div>
</template>

<script>
  import moment from 'moment';
  export default {
    props: {
      min_array_displacement: {
        type: Array,
      },
      max_array_displacement: {
        type: Array,
      },timeList:{
        type:Array
      }
    },
    data() {
      return {
        displacementChart: {},
      }
    },
    mounted() {
      this.getChartData();
    },
    methods: {
      getChartData() {
        let self = this;

        let noZeroArr = [];
        for (let i = 0; i < this.min_array_displacement.length; i++) {
          let item = this.min_array_displacement[i];
          if (item != 0) {
            noZeroArr.push(item);
          }
        }
        let yMinValue = Math.floor(Math.min.apply(null, noZeroArr));
        console.log(yMinValue);//最小值
        this.displacementChart = {
          tooltip: {
            trigger: 'axis',
            axisPointer: {            // 坐标轴指示器，坐标轴触发有效
              type: 'none'        // 默认为直线，可选为：'line' | 'shadow' | 'none'
            },
            backgroundColor: 'rgba(55,66,97,0.8)',
            borderWidth: 0,
            textStyle: {
              color: '#fff',

            },
            formatter: function (params) {
              let tar;
              let tar0 = params[0];
              if (params[1].value !== '-') {
                tar = params[1];
              } else {
                tar = params[2];
              }

              let dd = `<div style="padding: 5px 10px;">
                    <div class="flex-row-center" style="margin-top: 4px;">
                        <div style="width: 10px; height: 10px; background: ` + tar.color + `; border-radius: 50%"></div>
                        <div class="size-16 color-fff margin-left-5" style="margin-top: -2px;">` + tar.name + `</div>
                    </div>
                    <div class="flex-row-center" style="margin-top: 10px;">
                        <div class="size-16 color-fff-80">最小值</div>
                        <div class="size-18 color-fff margin-left-8">` + parseFloat((tar0.value).toFixed(3)) + `</div>
                    </div>
                    <div class="flex-row-center" style="margin-top: 10px;">
                        <div class="size-16 color-fff-80">最大值</div>
                        <div class="size-18 color-fff margin-left-8">` + parseFloat((tar.value + tar0.value).toFixed(3)) + `</div>
                    </div>
                </div>`;
              return dd;
            }
          },
          grid: {
            left: '5%',
            right: '1%',
            bottom: '2%',
            top: '3%',
            containLabel: true
          },

          xAxis: [{
            type: "category",
            data: self.timeList,
            axisLine: {
              show: false
            },
            axisLabel: {
              color: "#A0CAFF",
              fontSize: 12
            },
            axisTick: {
              show: false
            }
          }],

          yAxis: [{
            type: "value",
            axisLabel: {
              color: "#A0CAFF",
              fontSize: 12
            },
            splitLine: {
              lineStyle: {
                // 使用深浅的间隔色
                color: ['rgba(255,255,255,0.15)']
              }
            },
            min: function (value) {
              return yMinValue;
            }
          }],

          series: [
            {
              type: 'bar',
              stack: '总量',
              barWidth: "10%",
              itemStyle: {
                barBorderColor: 'rgba(0,0,0,0)',
                color: 'rgba(0,0,0,0)'
              },
              emphasis: {
                itemStyle: {
                  barBorderColor: 'rgba(0,0,0,0)',
                  color: 'rgba(0,0,0,0)'
                }
              },
              data: this.min_array_displacement
            },
            {
              type: 'bar',
              stack: '总量',
              data: this.max_array_displacement,
              color: '#0EFEB5'
            }
          ]
        }
      },
    }
  }
</script>

<style scoped>
    .my-chart {
        width: 100%;
        height: 100%;
    }
</style>
