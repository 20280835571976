<template>
  <countTo ref="countTo" :startVal='startVal' :endVal='endVal' :duration='duration' :decimals='decimals'></countTo>
</template>

<script>
import countTo from 'vue-count-to';
export default {
  components: { countTo },
  props: {
    startVal: {
      type: Number,
      default: 0
    },
    endVal: {
      type: Number,
      default: 0
    },duration: {
      type: Number,
      default: 2000
    },
  },
  data(){
    return {
      decimals:0
    }
  },
  name: "count_to",
  mounted() {
    if(String(this.endVal).indexOf('.')!=-1){
      let num = String(this.endVal).length-String(this.endVal).indexOf('.')-1;
      this.decimals = num;
    }else{
      this.decimals = 0;
    }

    this.setTimer();
  },
  methods:{
    setTimer(){
      let self = this;
      this.timer = setTimeout(()=>{
        self.$refs.countTo.start();
        self.setTimer();
      },1000*30);
    }
  },
  beforeDestroy() {
    if(this.timer){
      clearTimeout(this.timer)
    }
  }

}
</script>

<style scoped>

</style>
