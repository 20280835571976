<template>
  <a-sub-menu :key="menuInfo.key" v-bind="$props" v-on="$listeners">
    <span slot="title"  class="icons-font">
          <icon-font :type="'icon-menu_'+menuInfo.ICON"/><span>{{ menuInfo.MENU_NAME }}</span>
        </span>
    <template v-for="item in menuInfo.CHILD">
      <a-menu-item v-if="!item.CHILD||item.CHILD.length==0" :key="item.key">
        <span>{{ item.MENU_NAME }}</span>
      </a-menu-item>
      <sub-menu v-else :key="item.key" :menu-info="item"/>
    </template>
  </a-sub-menu>
</template>

<script>
import {Menu} from 'ant-design-vue';
export default {
  name: 'SubMenu',
  isSubMenu: true,
  props: {
    ...Menu.SubMenu.props,
    menuInfo: {
      type: Object,
      default: () => ({}),
    },
  },
}
</script>

<style scoped>
.icons-font >>> .anticon {
  font-size: 20px;
  vertical-align: -0.2em;
}
</style>
