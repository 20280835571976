<template>
  <div class="flex-column content bg" style="position: relative; overflow: hidden;">
    <electric_header title="结构物耐久监测系统"/>
    <div class="flex-1 flex-column" style="padding: 0px 50px 0px; overflow: hidden;">
      <div class="flex-1 flex-column">
        <div class="flex-row">
          <electric_home_header title="阳极保护电流" class="flex-1"/>
        </div>
        <div style="padding-left: 12px;">
          <div style="margin-top: 10px; padding: 0 15px; height: 390px;" class="box-bg flex-row-center">
            <div class="flex-1 flex-column" style="height: 300px; padding-right: 60px;">
              <div class="flex-row-center" style="margin-top: 0px;">
                <div class="size-16 color-CBECFF" style=" margin-right: 10px;">
                  {{ deviceItem.DEVICE_NAME + '(' + deviceItem.DEVICE_NUMBER + ')' }}
                </div>

                <div class="size-16 color-CBECFF"
                     style="margin-left: 15px; margin-right: -10px; width: 140px;">起始时间：
                </div>
                <div class="drop">
                  <a-range-picker @change="onChangeDate1" :default-value="[startTime1,endTime1]"
                                  format="YYYY-MM-DD" dropdownClassName="dropdownStyle"
                                  :allowClear="false"/>
                </div>
              </div>

              <div class="head-bg flex-row center" style="margin-top: 20px;">
                <div class="size-20 color-fff">当前监测点报警状态：</div>
                <div class="size-20 color-20A3EC ff-m">正常</div>
              </div>
              <chart_line_electric :m-data="mData1" :key="key1"/>
            </div>
          </div>
        </div>
      </div>

      <div class="flex-1 flex-column">
        <div class="flex-row">
          <electric_home_header title="腐蚀电位" class="flex-1"/>
        </div>
        <div style="padding-left: 12px;">
          <div style="margin-top: 10px; padding: 0 15px; height: 390px;" class="box-bg flex-row-center">
            <div class="flex-1 flex-column" style="height: 300px; padding-right: 60px;">
              <div class="flex-row-center" style="margin-top: 10px;">
                <div class="size-16 color-CBECFF" style="margin-right: 10px;">
                  {{ deviceItem.DEVICE_NAME + '(' + deviceItem.DEVICE_NUMBER + ')' }}
                </div>

                <div class="size-16 color-CBECFF"
                     style="margin-left: 15px; margin-right: -10px; width: 140px;">起始时间：
                </div>
                <div class="drop">
                  <a-range-picker @change="onChangeDate2" :default-value="[startTime2,endTime2]"
                                  format="YYYY-MM-DD" dropdownClassName="dropdownStyle"
                                  :allowClear="false"/>
                </div>
              </div>

              <div class="head-bg flex-row center" style="margin-top: 20px;">
                <div class="size-20 color-fff">当前监测点报警状态：</div>
                <div class="size-20 color-20A3EC ff-m">正常</div>
              </div>
              <chart_line_electric :m-data="mData2" :key="key2"/>
            </div>
<!--            <div style="width: 409px; height: 246px; background: #0F2E58;">-->
<!--              <div class="p-relative">-->
<!--                <img src="../../image/electric/electric_analysis_header_bg.png" width="409" height="46">-->
<!--                <div class="absolute-0 flex-row-center">-->
<!--                  <div class="size-16 color-fff flex-1 flex-row center">特性值</div>-->
<!--                  <div class="size-16 color-fff flex-1 flex-row center">监测数据</div>-->
<!--                </div>-->
<!--              </div>-->

<!--              <div class="flex-row-center" style="height: 50px; border-bottom: 1px solid rgba(216, 216, 216, 0.5);">-->
<!--                <div class="size-16 color-fff flex-1 flex-row center">采样间隔</div>-->
<!--                <div style="width: 1px; background: rgba(216, 216, 216, 0.5); height: 100%;">-->
<!--                </div>-->
<!--                <div class="size-16 color-fff flex-1 flex-row center">0.12341</div>-->
<!--              </div>-->
<!--              <div class="flex-row-center" style="height: 50px; border-bottom: 1px solid rgba(216, 216, 216, 0.5);">-->
<!--                <div class="size-16 color-fff flex-1 flex-row center">最大值</div>-->
<!--                <div style="width: 1px; background: rgba(216, 216, 216, 0.5); height: 100%;">-->
<!--                </div>-->
<!--                <div class="size-16 color-fff flex-1 flex-row center">0.12341</div>-->
<!--              </div>-->
<!--              <div class="flex-row-center" style="height: 50px; border-bottom: 1px solid rgba(216, 216, 216, 0.5);">-->
<!--                <div class="size-16 color-fff flex-1 flex-row center">最小值</div>-->
<!--                <div style="width: 1px; background: rgba(216, 216, 216, 0.5); height: 100%;">-->
<!--                </div>-->
<!--                <div class="size-16 color-fff flex-1 flex-row center">0.12341</div>-->
<!--              </div>-->
<!--              <div class="flex-row-center" style="height: 50px;">-->
<!--                <div class="size-16 color-fff flex-1 flex-row center">平均值</div>-->
<!--                <div style="width: 1px; background: rgba(216, 216, 216, 0.5); height: 100%;">-->
<!--                </div>-->
<!--                <div class="size-16 color-fff flex-1 flex-row center">0.12341</div>-->
<!--              </div>-->
<!--            </div>-->
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
  import electric_home_header from "./electric_home_header";
  import chart_line_electric from "../../components/chart_line_electric";
  import moment from "moment";

  let formatStr = "YYYY-MM-DD 00:00:00";
  let formatStr2 = "YYYY-MM-DD 23:59:59";
  export default {
    name: "electric_home",
    components: {
      electric_home_header: electric_home_header,
      chart_line_electric
    },
    data() {
      return {

        deviceItem: {},

        mData1: [],
        startTime1: moment().add(-6, 'days').format(formatStr),
        endTime1: moment().format(formatStr2),
        timeDiff1: 6,//默认显示6天
        key1: new Date().getTime(),

        mData2: [],
        startTime2: moment().add(-6, 'days').format(formatStr),
        endTime2: moment().format(formatStr2),
        timeDiff2: 6,//默认显示6天
        key2: new Date().getTime(),
      }
    },
    computed: {

    },

    mounted() {
      this.getDeviceInfo();
      this.getTimeDurableStatisticsList(1);
      this.getTimeDurableStatisticsList(2);
    },

    methods: {
      onChangeDate1(date, dateString) {
        this.startTime1 = moment(dateString[0]).format(formatStr);
        this.endTime1 = moment(dateString[1]).format(formatStr2);
        this.timeDiff1 = moment(this.endTime1).diff(moment(this.startTime1), 'day');
        this.getTimeDurableStatisticsList(1);
      },

      onChangeDate2(date, dateString) {
        this.startTime2 = moment(dateString[0]).format(formatStr);
        this.endTime2 = moment(dateString[1]).format(formatStr2);
        this.timeDiff2 = moment(this.endTime2).diff(moment(this.startTime2), 'day');
        this.getTimeDurableStatisticsList(2);
      },



      getDeviceInfo() {
        let deviceId = this.$route.query.deviceId;
        this.$util.get('api/deviceInfo/'+deviceId,{}).then((res)=>{
          this.deviceItem = res.content;
        });
      },

      //电流 1  电位 2
      getTimeDurableStatisticsList(type) {
        let timeList = [];
        let timeDiff = 6;
        let startTime;
        let endTime;

        if (type == 1) {
          timeDiff = this.timeDiff1;
          startTime = this.startTime1;
          endTime = this.endTime1;
        } else if (type == 2) {
          timeDiff = this.timeDiff2;
          startTime = this.startTime2;
          endTime = this.endTime2;
        }

        for (let i = -timeDiff; i <= 0; i++) {
          let dd = moment(endTime).add(i, 'days').format("YYYY-MM-DD");
          let item = {
            avg_temp: 0,
            avg_hum: 0,
            avg_mixv: 0,
            avg_sac: 0,
            avg_cc: 0,
            avg_openv: 0,
            _id: dd
          }
          timeList.push(item);
        }
        let deviceId = this.$route.query.deviceId;
        this.$util.get('api/timeDurableStatisticsList', {
          deviceId: deviceId,
          startTime: startTime,
          endTime: endTime,
          fields: 'temp,hum,mixv,sac,cc,openv',
          type: 1
        }, true).then((res) => {
          let list = timeList;
          let resList = res.content;
          for (let i = 0; i < list.length; i++) {
            let item = list[i];
            for (let j = 0; j < resList.length; j++) {
              let item2 = resList[j];
              if (item._id == item2._id) {
                timeList[i] = resList[j];
                break;
              }
            }
          }
          if (type == 1) {
            let mData1 = [];
            for (let i = 0; i < timeList.length; i++) {
              let item = timeList[i];
              let item2 = {
                xValue: moment(item._id).format('MM-DD'),
                yValue: item.avg_sac
              }
              mData1.push(item2);
            }
            this.mData1 = mData1;
            this.key1 = new Date().getTime();
          } else if (type == 2) {
            let mData2 = [];
            for (let i = 0; i < timeList.length; i++) {
              let item = timeList[i];
              let item2 = {
                xValue: moment(item._id).format('MM-DD'),
                yValue: item.avg_openv
              }
              mData2.push(item2);
            }
            this.mData2 = mData2;
            this.key2 = new Date().getTime();
          }
        })
      },
    }
  }
</script>

<style scoped>
  .content {
    width: 100%;
    height: 100%;
    position: relative
  }

  .bg {
    background: url(../../image/electric/electric_bg.png) no-repeat;
    background-size: 100% 100%;
  }

  .head-bg {
    height: 60px;
    background: linear-gradient(90deg, rgba(26, 73, 130, 0) 0%, rgba(0, 40, 78, 0.53) 50%, rgba(26, 72, 129, 0) 100%);
  }

  >>> .ant-calendar-range-picker-separator {
    color: #fff;
  }

</style>
