<template>
    <div class="flex-column content bg" style="position: relative">
        <main-header/>
        <div class="flex-row flex-1">
            <div class="box left_box">
                <div class="box_line"></div>
                <div class="flex-row-center" style="height: 56px; padding: 0 20px;">
                    <img src="../../image/screen/text_icon.png" width="18">
                    <div class="size-22 color-fff bold margin-left-10">结构物列表</div>
                    <div class="flex-1"></div>
                    <div class="flex-column" style="align-items: flex-end">
                        <img src="../../image/screen/icon_point.png" height="3" width="17">
                        <img src="../../image/screen/icon_letter.png" width="138" style="margin-top: 10px;">
                    </div>
                </div>
                <div class="thin_line"></div>
                <div style="margin-top: 20px; overflow: scroll;" :style="'height:' + getListHeight() + 'px'" class="flex-column">
                    <div v-for="(item, index) in structureList" :key="index">
                        <div class="flex-row-center cursor_pointer" style="padding: 6px 20px;" @click="goDetail(index)">
                            <div style="position: relative; margin: auto;">
<!--                                <img :src="getImageUrl(index)" width="370" height="200">-->
                                <img :src="getImageUrl(index)" :style="'width:' + 370 * scaleX + 'px;' + 'height:' + 200 * scaleY + 'px;'">
                                <div style="position: absolute; left: 20px; right: 20px; bottom: 15px;">
                                    <div class="size-20 color-fff bold lineNum-2 text_shadow">{{item.STRUCTURE_NAME}}
                                    </div>
                                    <div class="flex-row-center" style="margin-top: 10px;">
                                        <div class="size-18 color-fff text_shadow">健康状况：
                                            <span class="size-22 bold"
                                                  :style="'color:' + getHealthStatus(item.HEALTH).color">
                                                {{getHealthStatus(item.HEALTH).text}}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="box right_box flex-column">
                <div class="box_line"></div>
                <div class="flex-row-center" style="height: 56px; padding: 0 20px;">
                    <img src="../../image/screen/text_icon.png" width="18">
                    <div class="size-22 color-fff bold margin-left-10">结构物分布图</div>
                    <div class="flex-1"></div>
                    <div class="flex-column" style="align-items: flex-end">
                        <img src="../../image/screen/icon_point.png" height="3" width="17">
                        <img src="../../image/screen/icon_letter.png" width="138" style="margin-top: 10px;">
                    </div>
                </div>
                <div class="thin_line"></div>

                <div style="position: relative;flex: 1;">
                    <div id="container" style="width:100%;height: 100%;z-index: 999" class="flex-1"></div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
  import header from './header';
  import T from 'tMap'; // 天地图引入
  import structure_marker from '../../components/structure_marker_tmap';
  import mapUtil from '../../util/LngLatUtils';
  import Vue from "vue";

  let mapT = null;
  export default {
    components: {
      mainHeader: header,
    },
    data() {
      return {
        markers: [],
        currentIndex: 0,
        structureList: [],
        screenHeight: document.body.clientHeight,
        scaleX: document.body.clientWidth/1920,
        scaleY: document.body.clientHeight/1080,
        bottomList: [
          {
            img: require('../../image/screen/home_bottom_1.png'),
            title: '敦当高速'
          },
          {
            img: require('../../image/screen/home_bottom_22.png'),
            title: '桥梁'
          },
          {
            img: require('../../image/screen/home_bottom_3.png'),
            title: '起点'
          },
          {
            img: require('../../image/screen/home_bottom_24.png'),
            title: '隧道'
          },
          {
            img: require('../../image/screen/home_bottom_5.png'),
            title: '终点'
          },
          {
            img: require('../../image/screen/home_bottom_26.png'),
            title: '边坡'
          }
        ],
      }
    },

    computed: {
      getImageUrl() {
        return function (index) {
          let item = this.structureList[index];
          let imgName = '';
          if (item.SAVE_NAME_LIST && item.SAVE_NAME_LIST.length) {
            let imgList = item.SAVE_NAME_LIST.split(',');
            imgName = imgList[0];
          }
          console.log(this.$util.getImageUrlWithName(imgName));
          return this.$util.getImageUrlWithName(imgName);
        }
      },

      getHealthStatus() {
        return function (index) {
          let text = '基本完好';
          let color = '#46FFA7';
          switch (index) {
            case 1:
              text = '基本完好';
              color = '#46FFA7';
              break;
            case 2:
              text = '轻微异常';
              color = '#60E0FF';
              break;
            case 3:
              text = '中等异常';
              color = '#FFE20E';
              break;
            case 4:
              text = '严重异常';
              color = '#FF6761';
              break;
          }
          return {
            text,
            color
          }
        }
      },

      getListHeight() {
        return function () {
          return this.screenHeight - 240;
        }
      }
    },

    mounted() {
      this.initMap(() => {
        this.getStructureList();
      });
    },

    methods: {
      initMap(callback) {
        mapT = new T.Map("container", {maxZoom: 16});
        //设置显示地图的中心点和级别
        mapT.centerAndZoom(new T.LngLat(94.120561, 39.356116), 12);
        //允许鼠标双击放大地图
        mapT.enableScrollWheelZoom();
        mapT.addControl(new T.Control.MapType([
          {
            title: '卫星混合',
            icon: 'http://api.tianditu.gov.cn/v4.0/image/map/maptype/satellitepoi.png',
            layer: window.TMAP_HYBRID_MAP,
          },
        ]));
        callback();

      },
      getStructureList() {
        let p = {
          isScore: 1,
          isImage: 1
        }
        let self = this;
        this.$util.get('api/structureList', p).then((res) => {
          this.structureList = res.content.list;
          for (let i = 0; i < this.structureList.length; i++) {
            let item = this.structureList[i];
            let ll = mapUtil.gcj02towgs84(parseFloat(item.LNG), parseFloat(item.LAT));
            item.LNG = ll[0];
            item.LAT = ll[1];
            let lng = item.LNG;
            let lat = item.LAT;
            if (null == lng || undefined == lng || null == lat || undefined == lat) {
              continue;
            }
            let icon = require('../../image/screen/home_icon_bridge2.png');
            switch (item.TYPE) {
              case 1:
                icon = require('../../image/screen/home_icon_bridge2.png');
                break;
              case 2:
                icon = require('../../image/screen/home_icon_tunnel2.png');
                break;
              case 3:
                icon = require('../../image/screen/home_icon_slope2.png');
                break;
            }

            //创建图片对象
            let iconT = new T.Icon({
              iconUrl: icon,
              iconSize: new T.Point(36, 40),
              iconAnchor: new T.Point(18, 40)
            });
            //向地图上添加自定义标注
            let marker = new T.Marker(new T.LngLat(lng, lat), {icon: iconT,title:'11111111111111'});
            mapT.addOverLay(marker);
            marker.addEventListener("click", (e) => {
              console.log(item.STRUCTURE_NAME)
              self.openWindow(marker, item);
            });
          }

          self.$util.getDundangLineT(mapT);
        });
      },

      goDetail(index) {
        let item = this.structureList[index];
        this.goPath(item);
      },
      toDetailMask() {
        let item = this.structureList[this.currentIndex];
        this.goPath(item);
      },
      goPath(item) {
        localStorage.setItem('structureItem', JSON.stringify(item));
        this.$router.push({path: '/structureDetail', query: {structureId: item.STRUCTURE_ID, score: item.SCORE, health: item.HEALTH}})
      },
      openWindow(marker, item) {
        let self = this;
        let Content = Vue.extend({
          template: `
              <structure_marker :bean="bean" @closeInfoWindow="closeInfoWindow" @clickMarker="clickMarker"/>`,
          components: {
            'structure_marker': structure_marker
          },
          data() {
            return {
              bean: item
            }
          }, methods: {
            closeInfoWindow() {
              mapT.closeInfoWindow();
            }, clickMarker() {
              self.goPath(item)
            }
          }
        });

        let component = new Content().$mount();
        let infoWin = new T.InfoWindow(component.$el, {
          autoPan: true,//是否开启信息窗口打开时地图自动移动（默认关闭）。
          closeButton: false,
        });
        marker.openInfoWindow(infoWin);
      },
    },


  }
</script>

<style scoped>
    .content {
        width: 100%;
        height: 100%;
        position: relative;
    }

    .bg {
        background: url(../../image/screen/icon_bg.png) no-repeat;
        background-size: 100% 100%;
    }

    .box {
        /*height: 940px;*/
        height: calc(100% - 60px);
        background: linear-gradient(180deg, rgba(3, 34, 64, 0.5) 0%, rgba(3, 34, 64, 0.3) 100%);
        border-radius: 1px;
        /*opacity: 0.5;*/
    }

    .box_line {
        height: 2px;
        width: 100%;
        background: #62DBED;
    }

    .left_box {
        /*width: 410px;*/
        flex: 1;
        margin-left: 30px;
        margin-top: 30px;
    }

    .right_box {
        /*width: 1430px;*/
        flex: 3.49;
        margin-left: 20px;
        margin-top: 30px;
        margin-right: 30px;
    }

    .thin_line {
        background: rgba(216, 216, 216, 0.3);
        height: 0.5px;
    }

    .bottom_box {
        width: 252px;
        height: 180px;
        background: rgba(3, 39, 85, 0.56);
        backdrop-filter: blur(10px);
        position: absolute;
        bottom: 30px;
        left: 30px;
        border: 1px solid rgba(255, 255, 255, 0.3);
    }

    .text_shadow {
        text-shadow: 5px 5px 5px #333;
    }

    >>> .tdt-infowindow-content {
        margin: 0px !important;
        width: auto !important;
    }

    >>> .tdt-infowindow-content-wrapper {
        padding: 0px !important;
    }

    >>> .tdt-infowindow-content-wrapper, .tdt-infowindow-tip {
        background: rgba(90, 133, 187, 0.9) !important;
    }

    >>> .tdt-infowindow-tip {
        background: rgba(90, 133, 187, 0.9) !important;
    }

    /*隐藏右上角*/
    >>> .tdt-iconLayers-layersRow {
        display: none;
    }
</style>
