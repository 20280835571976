<!--单位管理-->
<template>
  <div style="background-color: white">
    <p class="color-333 size-18" style="margin: 15px">项目管理</p>
    <div class="flex-row-center" style="margin: 15px">
      <a-input-search placeholder="项目名称" style="width: 200px" @search="onSearch" v-model="keyword" :allowClear="true"/>
      <div class="flex-1"/>
      <a-button type="primary" icon="plus-circle" @click="toAdd()">
        新增
      </a-button>
    </div>
    <a-table :columns="columns" :data-source="data" rowKey="PROJECT_ID" :bordered="false" :pagination="paginationOpt" size="small">
      <template slot="name" slot-scope="text,record,index">
        <a @click="toEdit(index)">{{ text }}</a>
      </template>
      <template slot="IS_USE" slot-scope="text">
        <span>{{text==1?'启用':'禁用'}}</span>
<!--        <a-switch checked-children="启用" un-checked-children="禁用" :checked="text==1"/>-->
      </template>
      <template slot="action" slot-scope="text, record,index">
        <a-dropdown class="flex-row-center" style="justify-content: center">
          <a class="ant-dropdown-link color-333 size-14 flex-row-center" @click="e => e.preventDefault()">
            <a-icon type="unordered-list"/>
            <a-icon type="down" style="margin-left: 10px"/>
          </a>
          <a-menu slot="overlay">
            <a-menu-item class="flex-row-center" @click="toEdit(index)">
              <a-icon type="edit"/>
              <a href="javascript:;">编辑</a>
            </a-menu-item>
            <a-menu-item class="flex-row-center" @click="onDelete(index)">
              <a-icon type="delete"/>
              <a href="javascript:;">删除</a>
            </a-menu-item>
          </a-menu>
        </a-dropdown>

      </template>
    </a-table>
  </div>
</template>
<script>
const columns = [
  {
    title: '项目名称',
    scopedSlots: {customRender: 'name'},
    dataIndex: 'PROJECT_NAME'
  },{
    title: '项目地址',
    dataIndex: 'CITY_NAME'
  },{
    title: '区域编码',
    dataIndex: 'AD_CODE'
  },{
    title: '创建时间',
    dataIndex: 'CREATE_TIMES'
  },{
    title: '是否启用',
    dataIndex: 'IS_USE',
    scopedSlots: {customRender: 'IS_USE'},
  },
  {
    title: '操作',
    key: 'action',
    align: 'center',
    width: 80,
    scopedSlots: {customRender: 'action'},
  },
];

export default {
  data() {
    let self = this;
    return {
      bean:null,
      showModal: false,
      data: [],
      columns,
      keyword: '',
      paginationOpt: this.$util.getTableParams((current, pageSize) => {
        self.paginationOpt.current = 1;
        self.paginationOpt.defaultPageSize = pageSize;
        self.getList()  //显示列表的接口名称
      }, (current, size) => {
        console.log(current, size)
        self.paginationOpt.current = current
        self.paginationOpt.defaultPageSize = size
        self.getList()
      }),
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    getList() {
      let self = this;
      let params = this.paginationOpt;
      let p = {
        page: params.current,
        pageSize: params.defaultPageSize,
        keyword: this.keyword,
      }
      this.$util.showLoading(self);
      this.$util.get('admin/projectList', p, true).then((res) => {
        let bean = res.content;
        self.paginationOpt.total = bean.count;
        self.data = bean.list;
        this.$util.hideLoading();
      })
    },
    onDelete(index) {
      this.$confirm({
        title: '确认',
        content: '是否确认删除此项目？',
        okText: '确认',
        cancelText: '取消',
        onOk: () => {
          this.$util.post('admin/deleteProject/' + this.data[index].PROJECT_ID, {})
              .then(res => {
                this.$message.success('删除成功');
                this.paginationOpt.current = 1;
                this.keyword = '';
                this.getList();
              });
        }
      });
    },
    onSearch(value) {
      this.paginationOpt.current = 1;
      this.keyword = value;
      this.getList();
    },
    toAdd(){
      this.$router.push('/manage/projectAdd')
    },toEdit(index){
      this.$router.push({path:'/manage/projectAdd',query:{id:this.data[index].PROJECT_ID}})
    }
  },
};
</script>
