<template>
  <div style="width: 100%;height: 100%;" class="flex-column">
    <div class="flex-row-center" style="justify-content: space-between">
      <span class="size-16 color-333">导入设备</span>
      <a-button  icon="download" @click="download" >
        下载模板文件
      </a-button>
    </div>
    <div class="flex-1" style="margin: 10px 0">
      <a-form  :form="form" @submit="handleSubmit" style="width: 100%">
        <a-form-item label="选择导入的Excel文件">
          <a-upload
              :file-list="fileList"
              :multiple="false"
              :before-upload="beforeUpload"
          >
            <a-button style="width: 400px"> <a-icon type="upload" />点击选择需要导入的Excel文件</a-button>
          </a-upload>
        </a-form-item>
        <div class="flex-1"/>
        <div class="flex-row-center" style="height: 50px;justify-content: flex-end;margin-bottom: 30px">
          <a-button type="link" @click="mClose">
            关闭
          </a-button>
          <a-button type="primary"  html-type="submit">
            导入
          </a-button>
        </div>
      </a-form>
    </div>
  </div>
</template>

<script>

export default {
  props: {
  },
  data() {
    return {
      uploading:false,
      fileList:[],
      form: this.$form.createForm(this, { name: 'deviceImport' }),
    }
  },
  mounted() {
  },

  methods: {
    download(){
      window.location.href='https://smsfile.iquanzhan.com/upload/templates/device_import_template.xlsx';
    },
    mClose() {
      this.$emit('modalClose',{refresh:false})
    },
    handleSubmit(e){
      e.preventDefault();
      let self = this;
      let fd = new FormData();
      fd.append('excelFile', this.fileList[0]);
      this.$util.post('api/batchImportDevice',fd,true).then(()=>{
        self.$message.success('导入成功');
        self.$emit('modalClose',{refresh:true});
      }).catch((err)=>{
        self.$message.error('导入失败');
      });
    },
    beforeUpload(file) {
      if (file.name.indexOf('xlsx')>0||file.name.indexOf('xls')>0) {
        this.fileList = [file];
      }else{
        this.$message.error('请上传正确的模板文件!');
      }
      return false;
    },
  }
}
</script>

<style scoped>
</style>
