<!--首页-->
<template>
  <div style="background-color: #F5F5F5;padding: 20px" class="flex-column">
    <div style="height:160px;" class="flex-row">
      <div class="flex-column" style="flex: 1;background-color: white;padding: 20px;border-radius: 5px">
        <div class="flex-1 flex-col size-14 top-bg-1" style="position: relative;">
          <span class="color-000-45">监测结构物</span>
          <div class="size-30 color-000" style="margin-bottom: 15px">
            <count-to :endVal="structureNum"></count-to>
            <div v-if="structureStatistics" class="flex-row-center" style="margin-top: 20px;">
              <div v-for="(item, index) in structureStatistics" :key="index" class="flex-row-center" style="margin-right: 25px">
                <span class="size-14 color-000-65">{{item.type}}：<span class="color-000 size-15">{{item.value}}</span></span>
              </div>
            </div>
          </div>
          <div style="position: absolute;right: 10px;bottom: 10px;">
            <chartPie style="height: 120px;width: 180px;"
                      :obj="{data:structureStatistics,unit:'个',colors:['#1890FF', '#13C2C2','#52C41A']}"  v-if="structureStatistics"/>
          </div>
        </div>
      </div>
      <div class="flex-column" style="flex: 1;background-color: white;padding: 20px;border-radius: 5px;margin-left: 15px">
        <span class="color-000-45">结构物基本完好率</span>
        <div class="size-30 color-000" style="margin-bottom: 15px">
          <count-to :endVal="healthStatistics"></count-to>
          <span>%</span>
        </div>
        <a-progress :percent="healthStatistics" status="active" strokeColor="#13C2C2" :show-info="false"/>
      </div>
      <div class="flex-column" style="flex: 1;background-color: white;padding: 20px;border-radius: 5px;margin-left: 15px">
        <span class="color-000-45">近一个月传感器报警统计</span>
        <div class="size-30 color-000" style="margin-bottom: 15px">
          <count-to :endVal="alarmAllNum"></count-to>
        </div>
        <chartColumnSmall style="height: 80px;width: 100%" :obj="{name:'报警次数',data:monthAlarmList,unit:'次'}" v-if="monthAlarmList"/>
      </div>
    </div>
    <div class="flex-column" style="margin-top: 15px;background-color: white;border-radius: 5px;padding: 20px;overflow: hidden;height: 800px">
      <div class="flex-row-center" style="justify-content: space-between;margin-bottom: 20px">
        <span class="size-18 color-333 bold">结构物列表</span>
        <div class="flex-row-center">
          <div v-for="(item, index) in markerList" :key="index">
            <div class="flex-row-center" style="width: 100px;">
              <img :src="item.img" style="height: 20px">
              <div class="size-14 color-333" style="margin-left: 10px">{{ item.title }}</div>
            </div>
          </div>
        </div>
      </div>

      <div class="flex-row flex-1" style="height: 100%">
        <div style="overflow: scroll;width: 460px; ">
          <div v-for="(item, index) in structureList" :key="index" style="margin-bottom: 20px;border: 1px solid rgba(151, 151, 151, 0.1);border-radius: 5px;padding: 10px">
            <div class="flex-row-center">
              <img :src="getImageUrl(index)" style="width: 160px;height: 120px;border-radius: 3px">
              <div style="margin-left:15px;flex: 1" class="flex-column">
                <span class="size-20 bold">{{ item.STRUCTURE_NAME }}</span>
                <div class="size-18 color-333 flex-row-center" style="margin: 6px 0">
                  <a-tag>{{ item.STRUCTURE_DETAIL_TYPE }}</a-tag>
                  <span class="size-15" :style="'color:' + getHealthStatus(item.HEALTH).color"> {{getHealthStatus(item.HEALTH).text}} </span>
                </div>
                <span class="size-14 color-000">中心桩号：<span class="color-000-65">{{item.PILE_NUMBER}}</span></span>

              </div>
            </div>
          </div>
          <div style="height: 30px"></div>
        </div>
        <div style="position: relative;flex: 1;margin-left: 20px;">
          <div id="container" style="width:100%;height: 100%;" class="flex-1"></div>
        </div>

      </div>
    </div>
    <div style="height: 520px;margin-top: 15px" class="flex-row-center">
      <div class="flex-column" style="flex: 1;background-color: white;border-radius: 5px;height: 100%">
        <div style="margin: 16px 24px ;justify-content: space-between" class="flex-row-center">
          <div class="size-16 bold">监测项统计</div>
          <a-radio-group v-model="selectStructureType" @change="structureTypeChange">
            <a-radio-button :value="0">
              全部
            </a-radio-button>
            <a-radio-button :value="1">
              桥梁
            </a-radio-button>
            <a-radio-button :value="2">
              隧道
            </a-radio-button>
            <a-radio-button :value="3">
              边坡
            </a-radio-button>
          </a-radio-group>
        </div>
        <a-divider style="margin: 0"/>
        <div style="padding: 20px;margin-top: 10px;overflow: scroll">
          <div class="flex-row-center size-14 color-000-45">
            <div class="u-flex-col-center flex-1">
              <span>监测类型共</span>
              <count-to :endVal="monitorItemNum" class="size-24 color-000"></count-to>
            </div>
            <a-divider type="vertical" style="height: 30px"/>
            <div class="u-flex-col-center flex-1">
              <span>监测点位共</span>
              <count-to :endVal="monitorPointNum" class="size-24 color-000"></count-to>
            </div>
            <a-divider type="vertical" style="height: 30px"/>
            <div class="u-flex-col-center flex-1">
              <span>监测设备类型共</span>
              <count-to :endVal="deviceTypeNum" class="size-24 color-000"></count-to>
            </div>
          </div>

          <div class="flex-col" style="margin-top: 30px" v-for="(item, index) in deviceTypeList" :key="index">
            <div class="flex-row-center" style="justify-content: space-between">
              <span class="size-16 color-000">{{item.DEVICE_TYPE_NAME}}</span>
              <span class="size-14 color-000-45">在线率<span class="size-18 color-000-45" style="color: #4DCB73;margin-left: 5px">{{ (item.DEVICE_COUNTS&&item.DEVICE_COUNTS!==0)?Math.floor(item.ONLINE_COUNTS/item.DEVICE_COUNTS*100):0}}%</span></span>
            </div>
            <a-progress :percent="(item.DEVICE_COUNTS&&item.DEVICE_COUNTS!==0)?Math.floor(item.ONLINE_COUNTS/item.DEVICE_COUNTS*100):0" status="active" strokeColor="#4DCB73" :show-info="false"/>
            <div class="flex-row-center" style="justify-content: space-between">
              <span class="size-14 color-000-45">在线设备<span class="size-18 color-000-85" style="margin-left: 5px">{{item.ONLINE_COUNTS}}辆</span></span>
              <span class="size-14 color-000-45">设备总数<span class="size-18 color-000-85" style="margin-left: 5px">{{item.DEVICE_COUNTS}}辆</span></span>
            </div>
          </div>
        </div>
      </div>
      <div class="flex-column" style="flex: 1;background-color: white;border-radius: 5px;margin-left: 15px;height: 100%">
        <div style="margin: 16px 24px ;justify-content: space-between" class="flex-row-center">
          <div class="size-16 bold">最新报警列表</div>
        </div>
        <a-divider style="margin: 0"/>
        <div style="padding: 20px;overflow: scroll">
          <div v-for="(item, index) in alarmList" :key="index" class="flex-row" style="margin-bottom: 25px;">
            <img :src="getAlarmImg(item.ALARM_LEVEL)" style="width: 20px; height: 20px;">
            <div class="flex-column" style="margin-left: 10px; margin-top: -5px;">
              <div class="size-16 color-000-85 lineNum-1">
                {{item.DESCRIPTION ? item.DESCRIPTION: ''}}
              </div>
              <div class="size-14 color-000-45" style="margin-top: 3px;">
                <a-tag color="#f50">{{item.STRUCTURE_NAME}}</a-tag>
                {{item.ALARM_TIMES}}
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import AMap from "AMap";
import structure_marker from '../../components/structure_marker';
import Vue from "vue";
import chartPie from '../../components/chartPie';
import chartColumnSmall from '../../components/chartColumnSmall';
import moment from "moment";
let map = null;
export default {
  components: {chartPie,chartColumnSmall},
  data() {
    return {
      markers: [],
      currentIndex: 0,
      structureList: [],
      structureStatistics: null,
      structureNum:0,
      markerList: [
        {
          img: require('../../image/screen/home_bottom_2.png'),
          title: '桥梁'
        },
        {
          img: require('../../image/screen/home_bottom_4.png'),
          title: '隧道'
        },
        {
          img: require('../../image/screen/home_bottom_6.png'),
          title: '边坡'
        }
      ],
      selectStructureType:0,
      selectDateType:0,
      alarmList:[],
      healthStatistics :0,
      alarmAllNum:0,
      monthAlarmList:null,
      monitorItemNum:0,
      monitorPointNum:0,
      deviceTypeNum:0,
      deviceTypeList:[],
    }
  },

  computed: {
    getAlarmImg() {
      return function (level) {
        let img;
        switch (level) {
          case 1:
            img = require('../../image/screen/alarm_level_1.png');
            break;
          case 2:
            img = require('../../image/screen/alarm_level_2.png');
            break;
          case 3:
            img = require('../../image/screen/alarm_level_3.png');
            break;
        }
        return img;
      }
    },
    getHealthStatus() {
      return function (index) {
        let text = '基本完好';
        let color = '#4DCB73';
        switch (index) {
          case 1:
            text = '基本完好';
            color = '#4DCB73';
            break;
          case 2:
            text = '轻微异常';
            color = '#FAD337';
            break;
          case 3:
            text = '中等异常';
            color = '#FA541C';
            break;
          case 4:
            text = '严重异常';
            color = '#F04864';
            break;
        }
        return {
          text,
          color
        }
      }
    },
    getImageUrl() {
      return function (index) {
        let item = this.structureList[index];
        let imgName = '';
        if (item.SAVE_NAME_LIST && item.SAVE_NAME_LIST.length) {
          let imgList = item.SAVE_NAME_LIST.split(',');
          imgName = imgList[0];
        }
        console.log(this.$util.getImageUrlWithName(imgName));
        return this.$util.getImageUrlWithName(imgName);
      }
    },
  },

  mounted() {
    this.getAlarmList();
    this.initMap(() => {
      this.getStructureList();
    });

    this.getMonitorItemList();

    this.$util.get('api/structureHealthStatistics', {}, true).then((res) => {
      let allNum = 0;
      let greenNum = 0;
      for (let i = 0; i < res.content.length; i++) {
        let item = res.content[i];
        allNum = allNum+item.HEALTH_COUNTS;
        if(!item.HEALTH||item.HEALTH===0){
          greenNum = greenNum+item.HEALTH_COUNTS
        }
      }
      if(allNum===0){
        this.healthStatistics =0;
      }else{
        this.healthStatistics = Math.floor(greenNum/allNum*100)
      }
    });

    let dates = [];
    let dataList = [];
    let alarmAllNum = 0;
    this.monthAlarmList = null;
    for (let i = 60; i >-1; i--) {
      dates.push(moment().subtract(i,'days').format(this.$util.dateFormatStr.dateYMD2));
      dataList.push({time:moment().subtract(i,'days').format(this.$util.dateFormatStr.dateMD),value:0})
    }
    this.$util.get('api/monitorAlarmTimeStatistics', {
      startDate:moment().subtract(1,"months").format(this.$util.dateFormatStr.dateYMD2),
      endDate:moment().format(this.$util.dateFormatStr.dateYMD2),
    }, true).then((res) => {
      for (let i = 0; i < dates.length; i++) {
        for (let j = 0; j <res.content.length; j++) {
          if(res.content[j].ALARM_TIMES==dates[i]){
            dataList[i].value = res.content[j].MONITOR_ALARM_COUNTS;
          }
        }
      }
      for (let j = 0; j <res.content.length; j++) {
        alarmAllNum = alarmAllNum+res.content[j].MONITOR_ALARM_COUNTS;
      }
      this.alarmAllNum = alarmAllNum;
      this.monthAlarmList = dataList;
    });

    this.$util.get('api/monitorItemList', {page:1,pageSize:10000}).then((res) => {
      this.monitorItemNum = res.content.length;
    });

    this.$util.get('api/monitorPointList', {page:1,pageSize:10000}).then((res) => {
      this.monitorPointNum = res.content.count;
    });
    this.$util.get('api/dataList', { page: 1, pageSize: 10000, classType: 'DEVICE_TYPE' }).then((res) => {
      this.deviceTypeNum = res.content.length;
    });
  },

  methods: {
    structureTypeChange(value){
      console.log(333,value)
      this.selectStructureType = value.target.value;
      this.getMonitorItemList()
    },
    getMonitorItemList() {
      let p = {};
      if(this.selectStructureType){
        p.structureType=this.selectStructureType;
      }
      this.$util.get('api/deviceTypeStateStatisticsList', p, true).then((res) => {
        this.deviceTypeList = res.content;
      });
    },
    getAlarmList() {
      let p = {
        page: 1,
        pageSize: 10,
        // startDate:moment().subtract(1,"months").format(this.$util.dateFormatStr.dateYMD2),
        // endDate:moment().format(this.$util.dateFormatStr.dateYMD2),
      }
      this.$util.get('api/monitorAlarmList', p, true).then((res) => {
        this.alarmList = res.content.list;
      });
    },
    initMap(callback) {
      map = new AMap.Map('container', {
        center: [94.120561,39.356116],
        resizeEnable: true,
        // layers: [new AMap.TileLayer.Satellite()],
        zoom: 13,
      });
      AMap.plugin(['AMap.ToolBar', 'AMap.Scale', 'AMap.OverView', 'AMap.Geolocation', 'AMap.DistrictSearch', 'AMap.InfoWindow', 'AMap.Polyline'], function () {//异步加载插件
        callback();
      });

    },
    getStructureList() {
      let p = {
        isDetailType: 1,
        isImage: 1
      }
      let self = this;

      this.$util.get('api/structureList', p).then((res) => {
        this.structureList = res.content.list;
        let markers = [];
        if (this.markers.length > 0) {
          map.remove(this.markers);
        }
        let numAll=0,numBridge=0,numTunnel=0,numSlope=0;
        for (let i = 0; i < this.structureList.length; i++) {
          let item = this.structureList[i];
          let lng = item.LNG;
          let lat = item.LAT;
          if (null == lng || undefined == lng || null == lat || undefined == lat) {
            continue;
          }
          let icon = require('../../image/screen/home_icon_bridge.png');
          switch (item.TYPE) {
            case 1:
              icon = require('../../image/screen/home_icon_bridge.png');
              break;
            case 2:
              icon = require('../../image/screen/home_icon_tunnel.png');
              break;
            case 3:
              icon = require('../../image/screen/home_icon_slope.png');
              break;
          }

          let marker = new AMap.Marker({
            position: new AMap.LngLat(lng, lat),
            icon: new AMap.Icon({
              image: icon,
              size: new AMap.Size(36, 40),  //图标所处区域大小
              imageSize: new AMap.Size(36, 40) //图标大小
            }),
            anchor: 'bottom-center',
            offset: new AMap.Pixel(0, 0),
          });
          marker.item = item;
          marker.on('click', function (e) {
            self.openWindow(e.target.item, -5, -50);
            console.log(e.target)
          });
          if (i == 1) {
            map.add(marker);
          } else {
            markers.push(marker);
          }
        }

        for (let i = 0; i < this.structureList.length; i++) {
          let item = this.structureList[i];
          numAll++;
          switch (item.TYPE) {
            case 1:
              numBridge++;
              break;
            case 2:
              numTunnel++;
              break;
            case 3:
              numSlope++;
              break;
          }
        }

        self.structureStatistics = [
          {type: '桥梁', value: numBridge},
          {type: '隧道', value: numTunnel},
          {type: '边坡', value:numSlope},
        ];
        console.log(333,numAll)
        self.structureNum = numAll;
        self.markers = markers;
        map.add(this.markers);
        // map.setFitView(this.markers);
        self.$util.getDundangLine(map);
      });
    },

    openWindow(item, left = 4.5, top = -60) {
      let self = this;
      let lnglat = [item.LNG, item.LAT];
      let Content = Vue.extend({
        template: `
          <structure_marker :bean="bean" @closeInfoWindow="closeInfoWindow"/>`,
        components: {
          'structure_marker': structure_marker
        },
        data() {
          return {
            bean: item
          }
        }, methods: {
          closeInfoWindow() {
            map.clearInfoWindow();
          }
        }
      });

      let component = new Content().$mount();

      let infoWindow = new AMap.InfoWindow({
        isCustom: true, //使用自定义窗体
        content: component.$el,
        offset: new AMap.Pixel(left, top)
      });

      infoWindow.open(map, lnglat);
    },
  },

}
</script>

<style scoped>
</style>
