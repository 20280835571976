<template>
  <div style="width: 100%;height: 100%" class="flex-col">
    <div style="position: relative; width: 100%;height: 100%" class="flex-row" v-if="showPage1">
      <div style=" width: 100%;overflow: auto;position: relative;" :style="{'height':imgHeight+'px'}"
           @scroll="scrollEvent">
        <img :src="isDesign?imgBgDesign:imgBg" :width="imgWidthShow"/>

        <div v-for="(item, index) in monitorPointList" :key="index" class="u-flex-col-center " style="position: absolute;"
             :style="{'left':(getXY(item).x+'px'),'top':((getXY(item).y)+'px')}">
          <lottie
              style="z-index: 0;"
              :options="getMarkerImg(item)"
              :width="70"
              :height="70"
          />
          <div style="height: 20px;width: 20px; position: absolute;left: 25px;top: 25px;z-index: 1000" @click.stop="markerClick(item)" class="cursor_pointer" ></div>

        </div>

      </div>

      <section class="pop" ref="markerLay" style="position: absolute;width: 300px;z-index: 3000"
               :style="{'left':((getXY(selectPoint).x-(125))+'px'),'top':((getXY(selectPoint).y-125)+'px')}" v-if="selectPoint">
        <div class="pop-body">
          <div class="flex-column size-18 color-fff cursor_pointer" style="height: 100%;justify-content: space-between; " @click.stop="toPage2(selectPoint)">
            <div class="flex-row-center" style="justify-content: space-between">
              <span class="size-20">{{ selectPoint.DEVICE_NAME }}</span>

              <div style="height: 30px; border-radius: 15px; border: 1px solid;margin-right: 10px;padding: 0 10px"
                   class="size-16 u-flex-col-center"
                   :style="{'color':getPointAlarmLevel(selectPoint.ALARM_LEVEL).color,'border-color':getPointAlarmLevel(selectPoint.ALARM_LEVEL).color}">
                {{ getPointAlarmLevel(selectPoint.ALARM_LEVEL).name }}
              </div>
            </div>
            <span style="color:rgba(255,255,255,0.5);" class="size-14">{{ selectPoint.POINT_NUMBER }}</span>
            <span style="color:rgba(255,255,255,0.5);" class="size-16">安装位置<span
                style="margin-left: 10px;color:rgba(255,255,255,1);">{{ selectPoint.LOCATION }}</span></span>
            <span style="color:rgba(255,255,255,0.5);" class="size-16">绑定设备<span
                style="margin-left: 10px;color:rgba(255,255,255,1);">{{ selectPoint.DEVICE_NAME }}</span></span>
          </div>
          <a-icon type="close" style="color: white;font-size: 16px;position: absolute;right: 5px;top: 7px"
                  class="cursor_pointer" @click.stop="markerClose()"/>
        </div>
      </section>


      <div style="position: absolute;left: 30px;top: 35px" class="flex-column drop1" v-if="selectMonitorItem">
        <a-select dropdownClassName="dropdownStyle" v-model="selectMonitorItem" @change="monitorItemChange"
                  style="width: 200px">
          <template v-for="item in monitorItemList">
            <a-select-option :key="item.MONITOR_ITEM_ID" :value="item.MONITOR_ITEM_ID">
              {{ item.ITEM_NAME }}
            </a-select-option>
          </template>
        </a-select>
      </div>

      <div class="marker_bottom_box flex-column" style="padding: 15px;">
        <div class="size-14 color-fff">图例</div>

        <div class="flex-column">
          <div v-for="(item, index) in markerBottomList" :key="index" class="flex-row-center" style="margin-top: 10px">
            <lottie
                :options="item.options"
                :width="30"
                :height="30"
            />
            <div class="size-14 color-fff" style="margin-left: 8px">{{ item.name }}</div>
          </div>
        </div>
      </div>

      <div style="position: absolute;right: 30px;top: 30px;bottom: 30px;background-color: rgba(3, 34, 64, 0.7);width: 450px;padding: 20px" class="flex-column" v-show="!isFolding">
        <div class="flex-row-center">
          <img src="../image/screen/icon_right_dot1.png" style="width: 20px"/>
          <span class="size-22 color-fff" style="margin-left: 10px">{{ getTypeStr() }}</span>
          <div class="flex-1"></div>
          <div class="flex-row-center cursor_pointer" @click="isFolding = true">
            <div class="color-fff-80 size-18">折叠</div>
            <img src="../image/screen/folding_icon.png" width="20" style="margin-left: 8px;">
          </div>
        </div>
        <div class="flex-row-center" style="margin-top: 2%;">
          <div style="height: 150px;width: 150px;position: relative">
            <div style="position: absolute;left: 0;top: 0;right:0;bottom:0;align-items: center;"
                 class="u-flex-col-center  color-fff">
              <span class="size-20 bold">{{ allDeviceNum }}</span>
              <span class="size-14">设备总数</span>
            </div>
            <chart_pie :dataValue="pieData" v-if="pieData" :key="getTime()"/>
          </div>


          <div class="flex-column flex-1" style="margin: 0 30px">
            <div v-for="(item, index) in pieData" :key="index" class="flex-row-center size-18"
                 style="color: rgba(255,255,255,0.5);width: 100%;justify-content: space-around">
              <div style="height: 8px;width: 8px;" :style="{'background-color':item.color}"/>
              <span>{{ item.name }}</span>
              <span style="color: rgba(248, 195, 58, 1);font-size: 30px">{{ item.value }}</span>
              <span>{{ item.unit }}</span>
            </div>
          </div>
        </div>
        <div class="flex-row-center" style="margin-top: 20px">
          <img src="../image/screen/icon_title_left.png" style="width: 15px"/>
          <span class="size-22 color-fff" style="margin-left: 10px">设备列表</span>
        </div>
        <div class="flex-1 flex-column" style="overflow:scroll;margin-top: 15px">
          <div v-for="(item, index) in monitorPointList" :key="index" class="flex-column size-20 device-bg color-fff cursor_pointer"
               style="justify-content: center;margin-bottom: 15px"  @click="toPage2(item)">
            <div class="flex-row-center" style="justify-content: space-between">
              <span>{{ item.POINT_NAME }}</span>
              <div style=" height: 30px; border-radius: 15px; border: 1px solid;padding: 0 15px"
                   class="size-16 u-flex-col-center"
                   :style="{'color':getPointAlarmLevel(item.ALARM_LEVEL).color,'border-color':getPointAlarmLevel(item.ALARM_LEVEL).color}">
                {{ getPointAlarmLevel(item.ALARM_LEVEL).name }}
              </div>
            </div>
            <span class="size-18" style="color: rgba(255,255,255,0.6);margin-top: 3px">编号<span
                style="color: rgba(255,255,255,0.8);margin-left: 10px">{{ item.POINT_NUMBER }}</span></span>
          </div>
        </div>
      </div>

      <!--  折叠的View  -->
      <div
          style="position: absolute;right: 0px;top: 30px;height:125px;background-color: rgba(3, 34, 64, 0.7);width: 50px;padding: 20px 15px 0px; border-radius: 20px 0 0 20px;"
          class="flex-column  cursor_pointer" v-show="isFolding" @click="isFolding = false">
        <!--      <img src="../image/screen/icon_right_dot1.png" style="width: 20px; margin-bottom: 20px;"/>-->
        <!--      <div class="color-fff size-22">{{ getTypeStr() + (index == 1 ? '' : '监测数据') }}</div>-->
        <div class="flex-column">
          <div class="color-fff-80 size-18">展开</div>
          <img src="../image/screen/folding_icon2.png" width="20" style="margin-top: 10px;">
        </div>
      </div>
      <div class="switch-design-bg flex-row-center">
        <div class="switch-design-btn cursor_pointer" @click="clickIsDesign(false)" :style="{'opacity':isDesign?0.7:1}">数字模型</div>
        <div class="switch-design-btn cursor_pointer" style="margin-left: 20px" @click="clickIsDesign(true)" :style="{'opacity':isDesign?1:0.7}">效果图</div>
      </div>
    </div>
    <div v-else>
      <structure_device_map :pointBean="selectPoint" :key="time" @toPage1="toPage1" :isDesign="isDesign"/>
    </div>


  </div>
</template>

<script>
import chart_pie from './chart_pie';
import structure_device_map from './structure_device_map';
import icon_location_alarm from "../image/screen/icon_location_alarm.json";
import icon_location from "../image/screen/icon_location.json";
export default {
  props: {
    index: {
      type: Number,
      default: 1,
    }
  },
  components: {
    chart_pie, structure_device_map
  },
  data() {

    return {
      selectMonitorItem: undefined,
      monitorItemList: [],
      monitorPointList: [],
      monitorPointDesignList: [],
      allDeviceNum: '',
      selectDevicePicker: -1,
      imgBg: '',
      imgBgDesign:'',
      imgWidth: 1430,
      imgWidthShow: 1430 / 1920 * document.documentElement.clientWidth,
      imgHeight: 872 / 1080 * document.documentElement.clientHeight,
      scale: 1,
      structureInfo: null,
      markerBottomList: [
        {name: '报警点位', options: { animationData: icon_location_alarm, loop: true  }},
        {name: '正常点位', options: { animationData: icon_location, loop: true  }}],
      selectPoint: null,
      markerBgWidth: 500,
      pieData: null,
      isFolding: true,  //是否折叠
      markerImg: '',
      showPage1:true,
      time:new Date().getTime(),
      isDesign:false,
    }
  },
  computed: {
    getXY(){
      return function (item) {
        if(this.isDesign){
          return {x:item.xDesign,y:item.yDesign}
        }else{
          return {x:item.x,y:item.y}
        }
      }

    },
    getTypeStr() {
      return function () {
        for (let i = 0; i < this.monitorItemList.length; i++) {
          if (this.monitorItemList[i].MONITOR_ITEM_ID == this.selectMonitorItem) {
            return this.monitorItemList[i].ITEM_NAME;
          }
        }
        return ''
      }
    },
    getPointAlarmLevel() {
      return function (level) {
        let color = '', title = '';
        if (level) {
          switch (level) {
            case 0:
              color = '#62DBED';
              title = '正常';
              break;
            case 1:
              color = 'yellow';
              title = '超限一级';
              break;
            case 2:
              color = 'orange';
              title = '超限二级';
              break;
            case 3:
              color = 'red';
              title = '超限三级';
              break;
          }
        } else {
          color = '#62DBED';
          title = '正常';
        }
        return {
          name: title, color: color
        }
      }
    },

    getMarkerImg() {
      return function (item) {
        if (!item.ALARM_LEVEL || item.ALARM_LEVEL == 0) {
          return { animationData: icon_location, loop: true }
        } else {
          return { animationData: icon_location_alarm, loop: true }
        }
      }
    },
  },
  mounted() {
    this.getData();
  },
  methods: {
    clickIsDesign(value){
      this.selectPoint = null;
      this.isDesign = value;
    },
    getTime() {
      return new Date().getTime()
    },
    getData() {
      let self = this;
      let structureItem = JSON.parse(localStorage.getItem('structureItem'));
      let structureId = structureItem.STRUCTURE_ID;

      this.$util.get('api/structureInfo/' + structureId, {isDetail: 1, isImage: 1}, true).then((res) => {
        self.structureInfo = res.content;

        self.$util.get('api/monitorItemList', {
          page: 1,
          pageSize: 10000,
          structureId: structureId,
        }, true).then((ret) => {
          self.monitorItemList = ret.content;
          if (ret.content && ret.content.length > 0)
            self.selectMonitorItem = ret.content[0].MONITOR_ITEM_ID;
          self.getMonitorPointList()

          self.$util.hideLoading();
        });


        let dao = res.content;
        if (dao.bridgeInfoMap) {
          self.imgBg = self.$util.getServeImageUrl() + dao.bridgeInfoMap.IMG;
          self.imgBgDesign = self.$util.getServeImageUrl() + dao.bridgeInfoMap.DESIGN_IMG;
        } else if (dao.sideSlopeInfoMap) {
          self.imgBg = self.$util.getServeImageUrl() + dao.sideSlopeInfoMap.IMG;
          self.imgBgDesign = self.$util.getServeImageUrl() + dao.sideSlopeInfoMap.DESIGN_IMG;
        } else if (dao.tunnelInfoMap) {
          self.imgBg = self.$util.getServeImageUrl() + dao.tunnelInfoMap.LEFT_HOLE;
          self.imgBgDesign = self.$util.getServeImageUrl() + dao.tunnelInfoMap.DESIGN_IMG;
        }

        let img = new Image()
        img.src = self.imgBg;
        img.onload = function () {
        }
      })

    },
    getMonitorPointList() {
      let self = this;
      if (this.selectMonitorItem) {
        this.$util.get('api/deviceList', {
          monitorItemId: this.selectMonitorItem,
          structureId: this.structureInfo.STRUCTURE_ID,
          page: 1,
          pageSize: 10000,
        }).then(res => {
          if (res && res.content && res.content.list) {
            let offLineNum = 0, onLineNum = 0;
            for (let i = 0; i < res.content.list.length; i++) {
              let item = res.content.list[i];
              if (item.STATE === 0) {
                onLineNum++;
              } else offLineNum++;
            }

            let pieData = [
              {value: onLineNum, name: '在线设备', color: 'rgba(248, 195, 58, 1)'},
              {value: offLineNum, name: '离线设备', color: 'rgba(119, 148, 190, 1)'},
            ];
            self.allDeviceNum = onLineNum + offLineNum;
            self.pieData = pieData;

          }
        });


        console.log(45, this.selectMonitorItem);
        self.$util.get('api/monitorPointList', {
          page: 1,
          pageSize: 10000,
          structureId: this.structureInfo.STRUCTURE_ID,
          monitorItemId: this.selectMonitorItem
        }, true).then((res) => {
          let bean = res.content;
          for (let i = 0; i < bean.list.length; i++) {
            let item = bean.list[i];
            let scale = self.imgWidthShow / self.imgWidth;
            item.x = item.X_COORDINATE * scale;
            item.y = item.Y_COORDINATE * scale;
            item.xDesign = item.DESIGN_X_COORDINATE * scale;
            item.yDesign = item.DESIGN_Y_COORDINATE * scale;
          }
          self.monitorPointList = bean.list;
        });
      }

    },

    monitorItemChange(value) {
      this.selectPoint = undefined;
      this.selectMonitorItem = value;
      this.getMonitorPointList();
    },
    markerClick(value) {
      console.log(3444,value)
      this.selectPoint = value;
    },
    markerClose() {
      this.selectPoint = null;
    },
    scrollEvent() {
      if (this.selectPoint)
        this.selectPoint = undefined;
    },
    toPage2(value){
      this.showPage1 = false;
      this.selectPoint = value;
      this.time = this.getTime();
    },
    toPage1(){
      this.showPage1 = true;
      this.selectPoint = null;
      this.time = this.getTime();
    },
  }
}
</script>

<style scoped>
.marker_bottom_box {
  background: rgba(3, 39, 85, 0.56);
  backdrop-filter: blur(10px);
  position: absolute;
  bottom: 30px;
  left: 30px;
  border: 1px solid rgba(255, 255, 255, 0.3);
}

.switch-design-bg {
  position: absolute;
  bottom: 30px;
  left: 430px;
}
.switch-design-btn {
  width: 120px;
  height: 45px;
  background: linear-gradient(180deg, rgba(7, 71, 116, 0) 0%, #032240 100%);
  color: white;
  align-items: center;
  justify-content: center;
  display: flex;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-bottom-width: 5px;
  border-bottom-color: #62DBED;
  border-bottom-style: solid;
}

.device-bg {
  width: 410px;
  padding: 10px 15px;
  background: rgba(62, 111, 158, 0.3);
  border: 1px solid;
  border-image: linear-gradient(180deg, rgba(255, 255, 255, 0.3), rgba(98, 219, 237, 0.6)) 1 1;
}
</style>
