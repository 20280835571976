<template>
  <div style="padding: 20px;">
    <div style="background: #fff; padding: 20px 30px;">
      <p class="color-333 size-18" >监测项管理</p>
      <a-row>
        <a-col :span="7">
          <div class="flex-row-center" style="padding-right: 30px;">
            <div class="size-14 color-000-85">关键词：</div>
            <a-input-search placeholder="名称/编号" class="filter-common" @search="onSearch" v-model="keyword"
                            :allowClear="true"/>
          </div>
        </a-col>
        <a-col :span="7">
          <div class="flex-row-center" style="padding-right: 30px;">
            <div class="size-14 color-000-85">类型：</div>
            <a-select placeholder="请选择类型" :allowClear="true" v-model="selectType" class="filter-common" @change="typeChange">
              <template v-for="(item) in typeList">
                <a-select-option :key="item.DATA_ID" :value="item.DATA_ID">
                  {{ item.CLASS_VALUE }}
                </a-select-option>
              </template>
            </a-select>
          </div>
        </a-col>
        <a-col :span="7">
          <div class="flex-row-center" style="padding-right: 30px;">
            <div class="size-14 color-000-85">大类：</div>
            <a-select placeholder="请选择大类" :allowClear="true" v-model="selectParent" class="filter-common" @change="parentChange">
              <template v-for="(item) in parentList">
                <a-select-option :key="item.DATA_ID" :value="item.DATA_ID">
                  {{ item.CLASS_VALUE }}
                </a-select-option>
              </template>
            </a-select>
          </div>
        </a-col>
        <a-col :span="3">
          <div>
            <a-button type="primary" @click="onSearch()">查询</a-button>
            <a-button style="margin-left: 10px;" @click="reset()">重置</a-button>
          </div>
        </a-col>
      </a-row>
      <div style="margin-top: 20px;">
        <a-button type="primary" icon="plus" @click="add">新建</a-button>
      </div>
    </div>

    <div class="flex-1 flex-col" style="background-color: white;margin-top: 15px;width: 100%;padding: 15px">
      <a-table :columns="columns" :data-source="data" rowKey="STRUCTURE_ID" :bordered="false" :pagination="true" >
      </a-table>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      mGrid: this.$util.mGrid,
      keyword: '',
      data: [],
      typeList:[],
      selectType:undefined,
      parentList:[],
      selectParent:undefined,
      columns: [
        {
          title: '监测名称',
          scopedSlots: {customRender: 'name'},
          dataIndex: 'ITEM_NAME'
        },{
          title: '简称',
          dataIndex: 'SHORT_NAME'
        },{
          title: '编号',
          dataIndex: 'ITEM_NUMBER'
        },{
          title: '监测目的',
          dataIndex: 'PURPOSE'
        },
      ]
    }
  },
  mounted() {
    let self = this;
    this.$util.get('api/dataList', {page: 1, pageSize: 100, classType:'MONITOR_TYPE'}).then((res) => {
      self.typeList = res.content;
    })
    this.$util.get('api/monitorItemList', {}).then((res) => {
      let parentList=[],parentIdList=[];
      for (let i = 0; i < res.content.length; i++) {
        if(res.content[i].PARENT_ID&&parentIdList.indexOf(res.content[i].PARENT_ID)===-1){
          parentList.push({
            DATA_ID:res.content[i].PARENT_ID,
            CLASS_VALUE:res.content[i].PARENT_NAME
          });
          parentIdList.push(res.content[i].PARENT_ID)
        }
      }
      this.parentList = parentList;
    })
    this.getList();

  },
  computed: {
  },
  methods: {
    getList() {
      let self = this;
      this.data = [];
      let p = {
        keyword: this.keyword,
      }
      if(this.selectType){
        p.type = this.selectType;
      }
      if(this.selectParent){
        p.parentId = this.selectParent;
      }
      this.$util.showLoading(self);
      this.$util.get('api/monitorItemList', p).then((res) => {
        self.data = res.content;
        let parentList=[],parentIdList=[];
        for (let i = 0; i < res.content.length; i++) {
          if(res.content[i].PARENT_ID&&parentIdList.indexOf(res.content[i].PARENT_ID)===-1){
            parentList.push({
              DATA_ID:res.content[i].PARENT_ID,
              CLASS_VALUE:res.content[i].PARENT_NAME
            });
            parentIdList.push(res.content[i].PARENT_ID)
          }
        }
        this.parentList = parentList;
        this.$util.hideLoading();
      })
    },
    onSearch(value) {
      if (value)
        this.keyword = value;
      this.getList();
    }, reset() {
      this.keyword = '';
      this.selectType = undefined;
      this.selectParent = undefined;
      this.getList();
    },
    add() {
      this.$router.push('/monitorPointAdd')
    },
    edit(index) {
      this.$router.push('/monitorPointAdd?monitorPointId=' + this.data[index].MONITOR_POINT_ID)
      console.log(333, index)
    },
    confirmDelete(index) {
      this.$util.showLoading(this);
      this.$util.post('deleteMonitorItem', {
        monitorPointId : this.data[index].MONITOR_POINT_ID
      }, true).then((res) => {
        this.$util.hideLoading();
        this.$message.success('删除成功');
        this.reset();
      })
    }, typeChange(value) {
      this.selectType = value;
      this.getList();
    },parentChange(value) {
      this.selectParent = value;
      this.getList();
    }
  }
}
</script>

<style scoped>

</style>
