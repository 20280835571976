<!--用户管理-->
<template>
  <div style="background-color: white">
    <p class="color-333 size-18" style="margin: 15px">角色管理</p>
    <div class="flex-row-center" style="margin: 15px;justify-content: flex-end">
    </div>
    <a-table :columns="columns" :data-source="data" rowKey="ROLE_ID" :bordered="false" :pagination="paginationOpt"
             size="small">
    </a-table>
  </div>
</template>
<script>
const columns = [
  {
    title: '角色姓名',
    scopedSlots: {customRender: 'name'},
    dataIndex: 'ROLE_NAME'
  },
  {
    title: '角色描述',
    dataIndex: 'DESCRIPTION',
  },{
    title: '创建时间',
    dataIndex: 'CREATE_TIMES',
  },
];


export default {
  data() {
    let self = this;
    return {
      bean:null,
      showModal: false,
      data: [],
      columns,
      keyword: '',
      paginationOpt: this.$util.getTableParams((current, pageSize) => {
        self.paginationOpt.current = 1;
        self.paginationOpt.defaultPageSize = pageSize;
        console.log(11, self.paginationOpt)
        self.getList()  //显示列表的接口名称
      }, (current, size) => {
        console.log(current, size)
        self.paginationOpt.current = current
        self.paginationOpt.defaultPageSize = size
        self.getList()
      }),
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    getList() {
      let self = this;
      let params = this.paginationOpt;
      let p = {
        page: params.current,
        pageSize: params.defaultPageSize,
        keyword: this.keyword,
      }
      this.$util.showLoading(self);
      this.$util.get('api/roleList', p, true).then((res) => {
        let bean = res.content;
        self.paginationOpt.total = bean.count;
        self.data = bean.list;
        this.$util.hideLoading();
      })
    },
    onDelete(index) {
      // this.$confirm({
      //   title: '确认',
      //   content: '是否确认删除此角色记录？',
      //   okText: '确认',
      //   cancelText: '取消',
      //   onOk: () => {
      //     this.$util.post('api/deleteRole', {roleId:this.data[index].ROLE_ID})
      //         .then(res => {
      //           this.$message.success('删除成功');
      //           this.paginationOpt.current = 1;
      //           this.keyword = '';
      //           this.getList();
      //         });
      //   }
      // });
    },
    toAdd(){
      this.$router.push('/roleAdd')
    },toEdit(index){
      this.$router.push({path:'/roleAdd',query:{roleId:this.data[index].ROLE_ID}})
    }
  },
};
</script>
