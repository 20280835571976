<template>
  <div class="flex-column" style="height: 100%;width: 100%">
    <a-page-header
        style="padding: 0;margin: 15px 0"
        :title="'设备绑定（'+(bean?bean.STRUCTURE_NAME:'')+'）'"
        @back="back()"
    />
<!--    <div class="flex-row-center" style="margin-bottom: 20px">
      <a-select style="min-width: 200px" @change="typeChange" placeholder="请选择设备类型" :allowClear="true"
                v-model="selectType">
        <template v-for="item in types">
          <a-select-option :key="item.DATA_ID" :value="item.DATA_ID">
            {{ item.CLASS_VALUE }}
          </a-select-option>
        </template>
      </a-select>

      <a-select show-search option-filter-prop="children" style="margin-left: 20px;min-width: 400px"
                @change="deviceChange" placeholder="请选择监测点" :allowClear="true" v-model="selectDevice">
        <template v-for="item in deviceList">
          <a-select-option :key="item.MONITOR_POINT_ID" :value="item.MONITOR_POINT_ID">
            {{ item.POINT_NAME + "(" + item.POINT_NUMBER + ")" }}
          </a-select-option>
        </template>
      </a-select>
    </div>-->
    <div class="flex" style="position: relative" :style="{'width':imgWidth+'px','height':imgHeight}" v-if="imgHeight>0">
      <img :src="imgBg" :width="imgWidth"/>

      <vue-draggable-resizable :disable-user-select ="false"  :ref="'dragBean'+index" v-for="(item, index) in bindDeviceList" :key="index" :w="29" :h="35" :parent="true" :resizable="false" :x="isDesign?item.DESIGN_X_COORDINATE:item.X_COORDINATE" :y="isDesign?item.DESIGN_Y_COORDINATE:item.Y_COORDINATE">
        <a-popover :title="item.POINT_NAME" trigger="click" >
          <template #content>
            <div class="flex-col">
              <span class="size-16 color-000-45">监测项：<span class="color-000-85">{{item.MONITOR_ITEM_NAME}}</span></span>
              <span class="size-16 color-000-45">监测点：<span class="color-000-85">{{item.POINT_NAME+'('+item.POINT_NUMBER+')'}}</span></span>
            </div>
          </template>
          <img src="../image/screen/icon_location_ok.png" style="height: 35px"/>

<!--          <a-popconfirm @confirm="deleteDevice(index)" placement="topLeft" style="position: absolute;top: -8px;right: -8px"
                        :title='item.MONITOR_ITEM_NAME+"-"+item.POINT_NAME + "(" + item.POINT_NUMBER + ")"'
                        ok-text="删除绑定设备">
            <a-icon slot="icon" type="question-circle-o" style="color: red"/>
            <a-icon type="close-circle" theme="twoTone" two-tone-color="red" class="cursor_pointer"/>
          </a-popconfirm>-->
        </a-popover>
      </vue-draggable-resizable>


      <vue-draggable-resizable @dragstop="showPopConfirm = true" :w="29" :h="35" @dragging="onDrag" :parent="true"
                               :resizable="false"
                               v-if="showBindMarker">
        <img src="../image/icon_location.png"/>
      </vue-draggable-resizable>
    </div>

    <div class="flex-row-center" style="height: 50px;justify-content: flex-end;margin: -30px 50px;">
      <a-button type="link" @click="back">
        返回
      </a-button>
      <a-button type="primary" @click="bind">
        保存
      </a-button>
    </div>
  </div>
</template>

<script>


export default {
  data() {
    return {
      x: 0,
      y: 0,
      imgBg: '',
      imgWidth: 1430,
      imgHeight: 0,
      deviceList: [],
      dragAllPosition: {},
      selectType: undefined,
      selectDevice: undefined,
      types: [],
      bindDeviceList: [],
      showBindMarker: false,
      bean: null,
      showPopConfirm: true,
      visible: false,
      isDesign:false,
    }
  },
  mounted() {
    let self = this;
    this.getDeviceList();
    this.$util.get('api/dataList', {
      page: 1,
      pageSize: 100,
      classType: 'DEVICE_TYPE'
    }, true).then((res) => {
      self.types = res.content
    });


    this.$util.get('api/structureInfo/' + this.$route.query.id, {isDetail: 1, isImage: 1}, true).then((res) => {
      self.bean = res.content;
      self.$util.hideLoading();
      let dao = res.content;
      console.log(333, this.$route.query.type)
      switch (parseInt(this.$route.query.type)) {
        case 1://桥梁
          self.imgBg = self.$util.getServeImageUrl() + dao.bridgeInfoMap.IMG;
          break;
        case 2://边坡
          self.imgBg = self.$util.getServeImageUrl() + dao.sideSlopeInfoMap.IMG;
          break;
        case 3://隧道左洞
          self.imgBg = self.$util.getServeImageUrl() + dao.tunnelInfoMap.LEFT_HOLE;
          break;
        case 4://隧道右洞
          self.imgBg = self.$util.getServeImageUrl() + dao.tunnelInfoMap.RIGHT_HOLE;
          break;
      }
      if(this.$route.query.design){//效果图
        self.isDesign = true;
        switch (parseInt(this.$route.query.type)) {
          case 1://桥梁
            self.imgBg = self.$util.getServeImageUrl() + dao.bridgeInfoMap.DESIGN_IMG;
            break;
          case 2://边坡
            self.imgBg = self.$util.getServeImageUrl() + dao.sideSlopeInfoMap.DESIGN_IMG;
            break;
          case 3://隧道左洞
            self.imgBg = self.$util.getServeImageUrl() + dao.tunnelInfoMap.DESIGN_IMG;
            break;
          case 4://隧道右洞
            self.imgBg = self.$util.getServeImageUrl() + dao.tunnelInfoMap.DESIGN_IMG;
            break;
        }
      }
      console.log(33, self.$util.getServeImageUrl())

      let img = new Image()
      img.src = self.imgBg;
      img.onload = function () {
        self.imgHeight = img.height / (img.width / self.imgWidth);
        console.log('图片原始高度', img.height, self.imgWidth)
        console.log('图片原始宽度', img.width)
      }
    })
  },

  methods: {
    getDeviceList() {
      let self = this;
      let p = {page: 1, pageSize: 10000, isBind: 0};
      if (this.selectType) {
        p.deviceType = this.selectType;
      }
      // this.$util.get('api/monitorPointList', p, true).then((res) => {
      //   let bean = res.content;
      //   self.deviceList = bean.list;
      // })


      this.$util.get('api/monitorPointList', {
        page: 1,
        pageSize: 10000,
        // isBind: 1,
        structureId: this.$route.query.id
      }, true).then((res) => {
        let bean = res.content;
        self.bindDeviceList = bean.list;
        let dragAllPosition = {};
        for (let i = 0; i < bean.list.length; i++) {
          let item = bean.list[i];
          if(this.$route.query.design){//效果图
            dragAllPosition['x' + i] = item.DESIGN_X_COORDINATE;
            dragAllPosition['y' + i] = item.DESIGN_Y_COORDINATE;
          }else{
            dragAllPosition['x' + i] = item.X_COORDINATE;
            dragAllPosition['y' + i] = item.Y_COORDINATE;
          }
        }
      })
    },
    back() {
      this.$router.back();
    },
    onDrag: function (x, y) {
      this.x = x
      this.y = y
      this.showPopConfirm = false;
    }, onDragAll: function (item) {
      console.log(111111111, this.$refs.dragBean0[0].top, this.$refs.dragBean0[0].left)
    }
    , typeChange(value) {
      this.selectDevice = undefined;
      this.showBindMarker = false;
      this.selectType = value;
      this.getDeviceList();
    }, deviceChange(value) {
      this.showBindMarker = true;
      this.selectDevice = value;
    }, bind() {
      let self = this;
      let devicePoints = [];

      for (let i = 0; i < this.bindDeviceList.length; i++) {
        devicePoints.push({
          monitorPointId: this.bindDeviceList[i].MONITOR_POINT_ID,
          xcoordinate: this.$refs['dragBean' + i][0].left,
          ycoordinate: this.$refs['dragBean' + i][0].top,
          zcoordinate: this.$route.query.design?(this.bindDeviceList[i].DESIGN_Z_COORDINATE?this.bindDeviceList[i].DESIGN_Z_COORDINATE:0):(this.bindDeviceList[i].Z_COORDINATE?this.bindDeviceList[i].Z_COORDINATE:0),
        })

      }
      if (this.selectDevice)
        devicePoints.push({
          monitorPointId: this.selectDevice,
          xcoordinate: this.x,
          ycoordinate: this.y,
          zcoordinate: 0,
        })
      let p = {
        structureId: this.$route.query.id,
      };
      if(this.$route.query.design){
        p.designLocationDataList = devicePoints;
      }else{
        p.deviceLocationDataList = devicePoints;
      }

      switch (parseInt(this.$route.query.type)) {
        case 1://桥梁
          p.type = 1;
          break;
        case 2://边坡
          p.type = 1;
          break;
        case 3://隧道左洞
          p.type = 1;
          break;
        case 4://隧道右洞
          p.type = 2;
          break;
      }
      console.log(544444, p)

      this.$util.post('api/updateCoordinate', p).then((res) => {
        self.selectDevice = undefined;
        self.showBindMarker = false;
        self.x = 0;
        self.y = 0;
        self.getDeviceList();
        self.$message.success('绑定成功');
        self.$router.back();
      });
    }, deleteDevice(index) {
      let p = {
        structureId: this.$route.query.id,
        deviceId: this.bindDeviceList[index].DEVICE_ID,
      };
      this.$util.post('api/unbindDevice', p).then((res) => {
        this.$message.success('删除成功');
        this.getDeviceList()
      });
    }, handleVisibleChange(visible) {
      if (!visible) {
        this.visible = false;
        return;
      }
      console.log(this.showPopConfirm);
      if (this.showPopConfirm) {
        this.visible = true;
      }
    },

  }
}
</script>

<style scoped>

</style>
