<template>
  <div>
    <a-page-header
        style="padding: 0;margin: 15px 0"
        title="耐久管理"
        @back="back()"
    />
    <a-modal
        :bodyStyle="{'height':800+'px'}"
        v-model="showModal"
        :closable="false"
        :footer="null"
        :width="900"
        :destroyOnClose="true"
    >
      <selectAddress @modalClose="modalClose"/>
    </a-modal>
    <div class="flex-1 flex-column" style="margin: 10px 0;background-color: white">
      <span class="size-18 color-333" style="margin: 20px">{{ (id ? '编辑耐久设备' : '新增耐久设备') }}</span>
      <div class="flex-column" style="width: 100%">
        <a-form :form="form" @submit="handleSubmit" style="width: 100%" labelAlign="left" :label-col="{ span: 6 }">
          <a-row type="flex" justify="space-around">
            <a-col :span="span">
              <a-form-item label="设备名称">
                <a-input placeholder="请输入设备名称"
                         v-decorator="['deviceName', { rules: [{ required: true, message: '请输入设备名称!' }] }]"
                         :allowClear="true"/>
              </a-form-item>
            </a-col>
            <a-col :span="span">
              <a-form-item label="设备编号">
                <a-input placeholder="请输入设备编号"
                         v-decorator="['deviceNumber', { rules: [{ required: true, message: '请输入设备编号!' }] }]"
                         :allowClear="true"/>
              </a-form-item>
            </a-col>

            <a-col :span="span">
              <a-form-item label="安装部位">
                <a-input placeholder="请输入安装部位" v-decorator="['location', { rules: [{ required: false}]}]"
                         :allowClear="true"/>
              </a-form-item>
            </a-col>
          </a-row>
          <a-row type="flex" justify="space-around">
<!--            <a-col :span="span">-->
<!--              <a-form-item label="测点编号">-->
<!--                <a-input placeholder="请输入测点编号"-->
<!--                         v-decorator="['monitorNumber', { rules: [{ required: true, message: '请输入测点编号!' }] }]"-->
<!--                         :allowClear="true"/>-->
<!--              </a-form-item>-->
<!--            </a-col>-->
<!--            <a-col :span="span">-->
<!--              <a-form-item label="钢筋截面积">-->
<!--                <a-input placeholder="请输入钢筋截面积" v-decorator="['area', { rules: [{ required: true, message: '请输入钢筋截面积!'}]}]"-->
<!--                         :allowClear="true"/>-->
<!--              </a-form-item>-->
<!--            </a-col>-->


            <a-col :span="span">
              <a-form-item label="设备型号">
                <a-input placeholder="请输入设备型号" v-decorator="['model', { rules: [{ required: false}]}]"
                         :allowClear="true"/>
              </a-form-item>
            </a-col>
            <a-col :span="span">
              <a-form-item label="持续时间">
                <a-input placeholder="请输入持续时间" v-decorator="['accelDur', { rules: [{ required: false}]}]"
                         :allowClear="true"/>
              </a-form-item>
            </a-col>
            <a-col :span="span">
              <a-form-item label="量程">

                <a-select placeholder="请选择量程" v-decorator="['accelFull', { rules: [{ required: false }] }]">
                  <template v-for="item in liangchengList">
                    <a-select-option :key="item.value" :value="item.value">
                      {{ item.name }}
                    </a-select-option>
                  </template>
                </a-select>
              </a-form-item>
            </a-col>
          </a-row>

          <a-row type="flex" justify="space-around">
            <a-col :span="span">
              <a-form-item label="异动报警阈值">
                <a-input placeholder="请输入异动报警阈值" v-decorator="['accelSens', { rules: [{ required: false}]}]"
                         :allowClear="true"/>
              </a-form-item>
            </a-col>
            <a-col :span="span">
              <a-form-item label="周期间隔（小时）">
                <a-input placeholder="请输入周期间隔" v-decorator="['pollReport', { rules: [{ required: false}]}]"
                         :allowClear="true"/>
              </a-form-item>
            </a-col>
            <a-col :span="span">
            </a-col>
          </a-row>


<!--          <a-row type="flex" justify="space-around" style="margin: 20px 0">-->
<!--            <a-col :span="11">-->
<!--              <div class="flex-row-center">-->
<!--                <span>标识地图：</span>-->
<!--                <a-button type="primary" icon="environment" style="margin-left: 20px" @click="showModal=true">-->
<!--                  标识地图-->
<!--                </a-button>-->
<!--                <a-input placeholder="经度" :readOnly="false" style="margin-left: 20px" class="flex-1"-->
<!--                         :value="locationSelect?locationSelect.lng:''"/>-->
<!--                <a-input placeholder="纬度" :readOnly="false" style="margin-left: 20px" class="flex-1"-->
<!--                         :value="locationSelect?locationSelect.lat:''"/>-->
<!--              </div>-->
<!--            </a-col>-->
<!--            <a-col :span="11">-->
<!--              <a-input placeholder="地址" :readOnly="false" :value="locationSelect?locationSelect.address:''"/>-->
<!--            </a-col>-->
<!--          </a-row>-->

          <a-row type="flex" justify="space-around">
            <a-col :span="23">
              <a-form-item label="设备描述">
                <a-textarea placeholder="请输入设备描述" v-decorator="['description', { rules: [{ required: false}]}]"
                            :allowClear="true" :auto-size="{ minRows: 2}"/>
              </a-form-item>
            </a-col>
          </a-row>

          <a-row type="flex" justify="space-around">
            <a-col :span="23">
              <div class="flex-row-center">
                <span style="width: 75px">设备图片：</span>
                <a-upload
                    accept=".jpg,.png,.jpeg"
                    style="margin-left: 20px"
                    name="imgFile"
                    list-type="picture-card"
                    :action="actionUrl"
                    :file-list="fileList"
                    @change="uploadCallback"
                    @preview="handlePreview"
                    :multiple="true"
                    :remove="handleRemove"
                >
                  <div v-if="fileList&&fileList.length <12">
                    <a-icon :type="loading ? 'loading' : 'plus'"/>
                    <div>
                      点击上传
                    </div>
                  </div>
                </a-upload>
              </div>

            </a-col>
          </a-row>

          <div class="flex-row-center" style="height: 50px;justify-content: flex-end;margin: 30px 50px;">
            <a-button type="link" @click="back">
              取消
            </a-button>
            <a-button type="primary" html-type="submit">
              保存
            </a-button>
          </div>
        </a-form>
      </div>
    </div>
    <a-modal :visible="previewVisible" :footer="null" @cancel="previewVisible = false">
      <img alt="预览" style="width: 100%" :src="previewImage"/>
    </a-modal>
  </div>
</template>

<script>
import selectAddress from '../components/select_address';

export default {
  components: {
    selectAddress
  },
  data() {
    return {
      showModal: false,
      span: 7,
      form: this.$form.createForm(this, {name: 'device'}),
      id: undefined,
      previewVisible: false,
      previewImage: '',
      actionUrl: '/client/api/uploadFile',
      loading: false,
      fileList: [],
      locationSelect: null,
      originValue1Name: '',
      originValue2Name: '',
      originValue3Name: '',
      liangchengList:[
        {value:0,name:'2g'},{value:1,name:'4g'},{value:2,name:'8g'},{value:3,name:'16g'}
      ]
    }
  },
  mounted() {
    let self = this;

    if (this.$route.query.id) {
      this.$util.showLoading(self);
      this.id = this.$route.query.id;
      this.$util.get('api/deviceInfo/' + this.$route.query.id, {isDetail: 1, isImage: 1}, true).then((res) => {
        self.bean = res.content;
        self.$util.hideLoading();
        let dao = res.content;
        self.form.setFieldsValue({
          description: dao.DESCRIPTION,
          deviceName: dao.DEVICE_NAME,
          deviceNumber: dao.DEVICE_NUMBER,
          model: dao.MODEL,
          monitorNumber: dao.MONITOR_NUMBER,
          location: dao.LOCATION,
        })
        self.locationSelect = {
          lat: dao.LAT,
          lng: dao.LNG,
          address: dao.ADDRESS
        }
        if(dao.DURABLE_INFO){
          self.form.setFieldsValue({
            accelDur: dao.DURABLE_INFO.ACCEL_DUR,
            accelFull: dao.DURABLE_INFO.ACCEL_FULL,
            accelSens: dao.DURABLE_INFO.ACCEL_SENS,
            area: dao.DURABLE_INFO.AREA,
            pollReport: dao.DURABLE_INFO.POLL_REPORT,
          })
        }
        if (dao.DEVICE_IMAGE_LIST && dao.DEVICE_IMAGE_LIST.length > 0) {
          let picList = [];
          for (let i = 0; i < dao.DEVICE_IMAGE_LIST.length; i++) {
            let item = dao.DEVICE_IMAGE_LIST[i];
            picList.push(
                {
                  uid: '-' + i,
                  name: item.FILE_NAME,
                  caption: item.FILE_NAME,
                  key: item.SAVE_NAME,
                  status: 'done',
                  url: self.$util.getServeImageUrl() + item.SAVE_NAME,
                }
            )
          }
          self.fileList = picList;
        }

      })
    }else{
      self.form.setFieldsValue({
        accelFull:0
      })
    }

  },

  methods: {
    handleSubmit(e) {
      e.preventDefault();
      let self = this;
      this.form.validateFields((err, values) => {
        if (!err) {
          let p = {
            action: "ADD",
            description: values.description,
            deviceName: values.deviceName,
            deviceNumber: values.deviceNumber,
            model: values.model,
            monitorNumber: values.monitorNumber,
            accelDur: values.accelDur,
            accelFull: values.accelFull,
            accelSens: values.accelSens,
            area: values.area,
            location: values.location,
            pollReport: values.pollReport,
            deviceType: 48
          }

          if (self.locationSelect) {
            p.lat = self.locationSelect.lat;
            p.lng = self.locationSelect.lng;
            p.address = self.locationSelect.address;
          }

          let picList = [];
          if (self.fileList.length > 0) {
            for (let i = 0; i < self.fileList.length; i++) {
              console.log(i)
              let dao = self.fileList[i].response;
              if (dao && dao.errorCode == 0 && dao.content && dao.content.initialPreviewConfig && dao.content.initialPreviewConfig.length > 0) {
                picList.push({
                  key: dao.content.initialPreviewConfig[0].key,
                  caption: dao.content.initialPreviewConfig[0].caption,
                })
              } else {
                picList.push({
                  key: self.fileList[i].key,
                  caption: self.fileList[i].caption,
                })
              }

            }
          }
          let keys = [], caption = [];
          if (picList.length > 0) {
            for (let i = 0; i < picList.length; i++) {
              keys.push(picList[i].key)
              caption.push(picList[i].caption)
            }
          }
          p.imgCaptions = caption.join(',');
          p.imgKeys = keys.join(',');

          if (this.bean) {
            p.action = 'EDIT';
            p.deviceId = this.bean.DEVICE_ID;
          }
          this.$util.post('api/submitDurable', p).then((res) => {
            self.back();
          });
        }
      });
    },
    back() {
      this.$router.back();
    }, modalClose(params) {
      if (params)
        this.locationSelect = params.locationSelect;
      this.showModal = false;
    }, handleRemove(file) {
      file.status = '1';
      let name = '';
      if (file.response) {
        name = file.response.content.initialPreviewConfig[0].key
      } else {
        name = file.name
      }
      this.$util.post("api/deleteFile?saveName=" + name, {})
    }, uploadCallback({file, fileList}) {
      this.fileList = fileList;
    },
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await this.$util.getBase64(file.originFileObj);
      }
      this.previewImage = file.url || file.preview;
      this.previewVisible = true;
    },
  }
}
</script>

<style scoped>

</style>
