import Vue from 'vue'
import $ from 'jquery';
Vue.prototype.$ = $;
import { Icon } from 'ant-design-vue';
const iconFont = Icon.createFromIconfontCN({
  scriptUrl: '//at.alicdn.com/t/font_2927011_1ohspi18zje.js',
});
Vue.component('icon-font', iconFont)

import VueDraggableResizable from 'vue-draggable-resizable';
import 'vue-draggable-resizable/dist/VueDraggableResizable.css'

Vue.component('vue-draggable-resizable', VueDraggableResizable)

import dataV from '@jiaminghi/data-view'
Vue.use(dataV)

import App from './App.vue'

import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
Vue.use(Antd);

import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
Vue.use(Loading);

import VueViewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'
Vue.use(VueViewer)
VueViewer.setDefaults({
  zIndex: 100,

  toolbar: {
    prev:1,
    next:1
  }
})

import util from '@/util/util.js'
Vue.prototype.$util = util;

import router from './util/permission';

import electricHeader from '../src/view/screen/electric_header';
Vue.component('electric_header',electricHeader)

import line_screen from '../src/components/line'
Vue.component('line_screen',line_screen)

import count_to from '../src/components/count_to'
Vue.component('count-to',count_to)

import echarts from "echarts";
Vue.prototype.$echarts = echarts;
import ECharts from 'vue-echarts';
Vue.component('v-chart',ECharts);

import animated from 'animate.css'
Vue.use(animated)

import lottie from 'vue-lottie'
Vue.component('lottie',lottie)

Vue.config.productionTip = false
new Vue({
  render: h => h(App),
  router: router,
}).$mount('#app')
