<template>
  <div style="width: 100%;height: 100%">
    <v-chart class="my-chart" :options="chart"/>
  </div>
</template>

<script>

import {graphic} from "echarts/lib/export/api";
import moment from "moment";

export default {
  props: {
    firstList: [],
    secondList: [],
    thirdList: [],
    xList: []
  },
  data() {
    return {
      chart: {}
    }
  },
  mounted() {
    this.getChartData();
  },
  methods: {
    getChartData() {
      // let dates = [];
      // for (let i = -5; i <= 0; i++) {
      //   let dd = moment().add(i, 'months').format("YYYY-MM");
      //   dates.push(dd);
      // }
      this.chart = {
        grid: {
          left: '0%',
          right: '1%',
          bottom: '2%',
          top: '3%',
          containLabel: true
        },
        xAxis: [{
          type: "category",
          data: this.xList,
          axisLine: {
            show: false
          },
          axisLabel: {
            color: "#A0CAFF",
            fontSize: 11
          },
          axisTick: {
            show: false
          }
        }],

        yAxis: [{
          type: "value",
          axisLabel: {
            color: "#A0CAFF",
            fontSize: 12
          },
          splitLine: {
            lineStyle: {
              // 使用深浅的间隔色
              color: ['rgba(255,255,255,0.15)']
            }
          }
        }],
        series: [
          {
            type: 'line',
            areaStyle: { //渐变色
              normal: {
                color: graphic.LinearGradient(0, 0, 0, 1,
                  [{offset: 0, color: 'rgba(255, 211, 85, 0.2)'},
                    {offset: 1, color: 'rgba(255, 255, 255, 0)'}])
              }
            },

            data: this.firstList,
            lineStyle: {
              color: '#FFD355',
              width: 1,
              type: 'solid'
            },
            symbol: 'image://https://nas.iquanzhan.com/structure/circle_red.png',
            symbolSize: 15,
          },
          {
            type: 'line',
            areaStyle: { //渐变色
              normal: {
                color: graphic.LinearGradient(0, 0, 0, 1,
                  [{offset: 0, color: 'rgba(255, 154, 97, 0.2)'},
                    {offset: 1, color: 'rgba(255, 255, 255, 0)'}])
              }
            },
            data: this.secondList,
            lineStyle: {
              color: '#FF9A61',
              width: 1,
              type: 'solid'
            },
            symbol: 'image://https://nas.iquanzhan.com/structure/circle_yellow.png',
            symbolSize: 15,
          },
          {
            type: 'line',
            areaStyle: { //渐变色
              normal: {
                color: graphic.LinearGradient(0, 0, 0, 1,
                  [{offset: 0, color: 'rgba(255, 115, 115, 0.2)'},
                    {offset: 1, color: 'rgba(255, 255, 255, 0)'}])
              }
            },
            data: this.thirdList,
            lineStyle: {
              color: '#FF7373',
              width: 1,
              type: 'solid'
            },
            symbol: 'image://https://nas.iquanzhan.com/structure/circle_orange.png',
            symbolSize: 15,
          }
        ]
      };
    },
  }
}
</script>

<style scoped>
.my-chart {
  width: 100%;
  height: 100%;
}
</style>
