<template>
  <div style="width: 100%;height: 100%;overflow: scroll" class="flex-col">
    <span class="value" style="margin-bottom: 30px">{{monitorList[index - 1].name}}</span>

    <div class="flex-row-center">
      <div class="flex-1"><span class="title">监测项ID<span class="value" style="margin-left: 30px">12345938654</span></span></div>
      <div class="flex-1"><span class="title">监测内容编码<span class="value" style="margin-left: 30px">862177043565475</span></span></div>
    </div>
    <div class="flex-row-center" style="margin-top: 20px">
      <div class="flex-1"><span class="title">监测类型<span class="value" style="margin-left: 30px">作用</span></span></div>
      <div class="flex-1"><span class="title">传感器类型<span class="value" style="margin-left: 30px">{{monitorList[index - 1].type}}</span></span></div>
    </div>

    <div class="flex-row" style="margin-top: 20px">
      <div class="title">监测目的</div>
      <div class="value flex-1" style="margin-left: 30px;line-height: 30px;">{{monitorList[index - 1].purpose}}</div>
    </div>
    <div class="flex-row-center" style="margin-top: 20px">
      <div class="flex-1"><span class="title">备注<span class="value" style="margin-left: 30px">无</span></span></div>
    </div>
  </div>
</template>

<script>

export default {
  name: "监测项详情",
  props: {
    monitorItemInfo: {
      type: Object,
    },
    index: {
      type: Number
    }
  },
  data(){
    return{
      monitorList: [
        {
          name: '支座滑移',
          type: '拉线位移计',
          purpose: '桥梁支座是连接桥梁上下部结构的重要部件，位于桥梁和垫石之间，它能将桥梁上部结构承受的荷载和变形可靠地传递给桥梁下部结构，是桥梁的重要传力装置。 坡桥、弯桥等桥梁，在车辆振动荷载和温度影响等综合作用下容易产生支座滑移等病害，影响桥梁的正常使用。\n' +
                  '支座滑移通常采用拉线式位计来监测。拉线式位移计的原理是将机械位移量转换成线性比例的电信号。当被测物体产生滑移时，与其相连接的钢绳会伸长或缩短，钢绳带动传感器传动机构和传感元件同步转动，从而输出一个与绳索移动量成正比例的信号。信号传输到釆集器后通过数据传输链路，再传送给数据中心计算机。\n' +
                  '拉线式位移传感器安装在桥墩靠近支座位置，另一端固定在主梁梁端的竖向支座上。'
        },
        {
          name: '高墩偏位',
          type: '倾斜计',
          purpose: '桥墩作为桥梁的承重结构，其倾斜过大将造成桥面伸缩缝扩大、支座变形、主梁滑移等危害，严重时影响桥梁整体构件的受力形式及使用寿命。桥墩倾斜参数可以反映出桥梁的健康状态，通过了解桥墩的偏位情况，也可以验证设计参数及施工工艺是否合理。' +
                  '对于桥梁墩高大于15米，且桥面纵坡大于等于2.5%的主梁，在伸缩缝桥墩处设置桥墩偏位监测点，来监测桥墩的高墩偏位情况。高墩偏位监测采用固定式测斜仪，它是采用电容式MEMS传感器技术开发的高灵敏度倾角测量仪器，其灵敏度高，可以精确监测到桥墩0.005°的偏位。'
        },
        {
          name: '主梁形变',
          type: '北斗定位仪',
          purpose: '恒载作用下主梁轴线的位置是桥梁整体安全状态的重要标志，活载作用下主梁的挠度既是桥梁整体刚度的重要标志，也能反映作用于桥梁上的荷载情况，通过对桥梁变形的监测可以从整体上把握桥梁的健康状态。' +
                  '主梁形变监测主要监测桥梁主梁的滑移、偏位、和挠度，采用北斗监测系统进行监测。\n' +
                  '利用北斗监测系统的差分定位技术可以监测毫米级的位置移动，基于此高精度的技术方案，可以实现全天候24小时的桥梁实时形变监测。' +
                  '北斗监测系统分为基准站和监测站。基准站安装在稳定的地基上，监测站根据需要安装在主梁跨中位置的外侧护栏上。可同时监测桥梁的横向、纵向以及高度的变化状态。'
        },
        {
          name: '隧道拱顶沉降',
          type: '激光测距仪',
          purpose: '拱顶沉降是隧道开挖后，由于围岩自重和应力调整造成隧道顶板向下移动的现象。拱顶沉降监测是隧道监控量测的必测项目，最能直接反映围岩的工作状态，也是控制围岩稳定状态和保证安全施工的重要指标，\n' +
                  '                                通过拱顶沉降的量测，可以了解断面变化情况，判断拱顶的稳定性，防止塌方事故的发生。' +
                  '拱顶沉降监测采用激光测距仪，它是采用相位式激光测距原理，利用检测发射光和反射光在空间中传播时发生的相位差来检测距离。激光测距仪重量轻、体积小、操作简单速度快而准确。隧道前后洞口各布置一个断面，' +
                  '然后每隔400米布置一个断面，每个断面拱腰安装一台激光测距仪，指向拱顶，测量拱腰到拱顶的距离，然后根据三角函数原理计算拱顶的高度变化。'
        },
        {
          name: '隧道周边收敛',
          type: '激光测距仪',
          purpose: '周边收敛是指隧道两侧壁测点之间连线的相对距离。\n' +
                  '周边收敛监测是隧道监控量测的必测项目，最能直接反映围岩的工作状态，也是控制围岩稳定状态和保证安全施工的重要指标。\n' +
                  '隧道施工完成后，由于隧道的施工，使山体应力改变，隧道周边会发生收敛，但收敛值会在一个合理范围内，否则就会塌方事故或险情。'+
                  '周边收敛监测采用激光测距仪，它是采用相位式激光测距原理，利用检测发射光和反射光在空间中传播时发生的相位差来检测距离。\n' +
                  '激光测距仪重量轻、体积小、操作简单速度快而准确。\n' +
                  '隧道前后洞口各布置一个断面，然后每隔400米布置一个断面，每个断面拱腰安装一台激光测距仪，指向对侧拱腰，测量拱腰到拱腰的距离，依此来监测隧道周边收敛参数。'
        },
        {
          name: '边坡降雨量监测',
          type: '雨量计',
          purpose: '边坡是为保证路基稳定，在路基两侧做成的具有一定坡度的坡面。' +
                  '影响边坡稳定性因素有很多，既有组成边坡的岩土体类型及性质、边坡地质构造、边坡形态、地下水等内部因素，也有气候条件、风化作用、坡体植被、人类工程活动等外部因素。\n' +
                  '边坡失稳，一般来说是有相当长的变形发展期，不是突然发生的。' +
                  '雨量监测主要考虑工程实际，对于水位变化和土壤含水率等有直接的关系。当降雨量过大，边坡失稳的可能性越大。因此有必要对环境降雨量进行监测。\n' +
                  '压电式雨量传感器采用冲击测量原理对单个雨滴重量进行测算，进而计算降雨量。' +
                  '雨滴在降落过程中受到雨滴重量和空气阻力的作用，到达地面时速度为恒定速度，测量冲击即可求出雨滴重量，进而得到持续降雨量。'
        },
        {
          name: '边坡地下水位监测',
          type: '渗压计',
          purpose: '边坡是为保证路基稳定，在路基两侧做成的具有一定坡度的坡面。\n' +
                  '边坡失稳，一般来说是有相当长的变形发展期，不是突然发生的。' +
                  '边坡地下水位监测一般采用振弦式渗压计，振弦式渗压计主要由振弦、高灵敏金属膜片、透水石以及激励与接收线圈等部件构成，其工作原理是水压力作用在薄膜片上并使之产生变形，连接在上面绷紧钢弦的张力也随之发生变化，而钢弦的振动频率和张力有关，表现为钢弦所受的张力，因此仪器受到的渗透压力可以通过测量钢弦的频率得到。\n' +
                  '振弦式渗压计与导轮式固定测斜仪相同，在安装前，需要在监测断面位置打井，并安装测斜管，然后将振弦式渗压计与导轮式固定测斜仪一起敷设到测斜管中。'
        },
        {
          name: '边坡表面位移',
          type: '北斗定位仪',
          purpose: '边坡是为保证路基稳定，在路基两侧做成的具有一定坡度的坡面。通过监测边坡的位移变形，不但可以预测其失稳滑动，根据边坡的动态变化规律还可以及早采取处置加固措施。' +
                  '表面位移监测一般通过北斗监测系统进行监测。\n' +
                  '利用北斗监测系统的差分定位技术可以监测毫米级的位置移动，基于此高精度的技术方案，可以实现全天候24小时的桥梁实时形变监测。\n' +
                  '北斗监测站系统分为基准站和监测站。基准站安装在稳定的地基上，监测站根据需要安装在边坡的监测段断面。可同时监测断面的横向、纵向以及高度的变化状态。'
        },
        {
          name: '边坡深层位移监测',
          type: '测斜仪',
          purpose: '边坡是为保证路基稳定，在路基两侧做成的具有一定坡度的坡面。通过监测边坡的位移变形，不但可以预测其失稳滑动，根据边坡的动态变化规律还可以及早采取处置加固措施。' +
                  '深层位移监测，不但能够测到坡体内部的蠕变，还可以了解边坡尤其是滑动带的位移情况。\n' +
                  '深层位移监测采用导轮式固定测斜仪，它是利用电容式MEMS传感器技术开发的高灵敏度倾角测量仪器，其灵敏度高，可以精确测量每处的水平位移量。\n' +
                  '安装导轮式固定测斜仪之前，需要在监测断面位置打井，并安装测斜管，然后将导轮式固定测斜仪按顺序敷设到测斜管中。'
        }

      ]
    }
  },
  mounted() {
    console.log(11111,this.index);
  }
}
</script>

<style scoped>
  .title{
    font-size: 16px;
    color: #CBECFF;
    font-weight: normal
  }
  .value{
    font-size: 16px;
    color: #FFFFFF;
    font-weight: normal
  }
</style>
