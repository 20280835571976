import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)
// 引入业务逻辑模块
import RouterMain from '../view/router' // 引入业务模块
import RouterCommon from './router' // 引入通用模块
const router = new Router({
  mode: 'history', // history模式需要服务端支持
  routes: [
    ...RouterCommon,
    ...RouterMain,
  ]
})

export default router
