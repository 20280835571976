import { render, staticRenderFns } from "./sideSlopeList2.vue?vue&type=template&id=99c6d750&scoped=true&"
import script from "./sideSlopeList2.vue?vue&type=script&lang=js&"
export * from "./sideSlopeList2.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "99c6d750",
  null
  
)

export default component.exports