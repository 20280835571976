import router from './index'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'

NProgress.configure({
  easing: 'ease', // 动画方式
  speed: 500, // 递增进度条的速度
  showSpinner: true, // 是否显示加载ico
  trickleSpeed: 200, // 自动递增间隔
  minimum: 0.3 // 初始化时的最小百分比
})

router.beforeEach((to, from, next) => {
  // 每次切换页面时，调用进度条
  NProgress.start();

  if(to.fullPath.indexOf('manage')!=-1){
    if (to.matched.some(record => record.meta.requiresAuth)) { // 哪些需要验证
      if (!localStorage.getItem("adminUserInfo")) { // token存在条件
        next('/manageLogin')
      } else {
        next()
      }
    } else {
      next()
    }
  }else{
    if (to.matched.some(record => record.meta.requiresAuth)) { // 哪些需要验证
      if (!localStorage.getItem("userInfo")) { // token存在条件
        next('/login')
      } else {
        next()
      }
    } else {
      next()
    }
  }

})

router.afterEach((to) => {
  // 在即将进入新的页面组件前，关闭掉进度条
  NProgress.done()
  document.title = to.name || ''
})

export default router
