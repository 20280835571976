<!--数据分析-->
<template>
    <div style="background-color: rgb(242,242,242);height: 100%; padding: 30px 30px 0px;" class="flex-1 flex-column">
        <div class="flex-row" style="height: 460px;">
            <div style="flex:1; background: #fff;">
                <div class="flex-row-center" style="padding: 20px 30px 20px 30px;">
                    <div class="size-16 color-111 bold">
                        各监测项报警次数统计
                    </div>
                    <div class="flex-1">
                    </div>
                    <div class="flex-row-center" style="margin-right: 0px;">
                        <div class="cursor_pointer" style="padding: 4px 12px;"
                             :style="'color:' + (selectedIndex1 == 0 ? '#1890FF': 'rgba(0,0,0,0.65)')"
                             @click="clickTitleIndex1(0)">
                            近一个月
                        </div>
                        <div class="cursor_pointer" style="padding: 4px 12px;"
                             :style="'color:' + (selectedIndex1 == 1 ? '#1890FF': 'rgba(0,0,0,0.65)')"
                             @click="clickTitleIndex1(1)">
                            近一年
                        </div>
                    </div>
                </div>
                <a-divider style="margin: 0;"></a-divider>
                <div class="size-14 color-000-85 bold" style="padding: 30px 30px 0px;">
                    报警次数
                </div>
                <div class="flex-row-center" v-if="alarmStatistics.length">
                    <div class="flex-1 flex-row center">
                        <div style="width: 250px;height: 250px;position: relative;margin-top: 15px" class="flex-row center">
                            <chartPie style="position: absolute;left: 0;top: 0;bottom: 0;right: 0"
                                      :obj="{data:alarmStatistics,unit:'个',colors:alarmStatisticsColors}"/>
                            <div class="size-30 color-000 u-flex-col-center">
                                <span class="size-14 color-000-45">报警总数</span>
                                <count-to :endVal="alarmAllNum"></count-to>
                            </div>
                        </div>
                    </div>

                    <div class="flex-1 flex-column">
                        <div v-for="(item,index) in alarmStatistics" :key="index" class="flex-row-center" style="margin-top: 15px;">
                            <div style="height: 8px;width: 8px;border-radius: 4px"
                                 :style="{'background-color':alarmStatisticsColors[index]}"/>
                            <span class="size-14 color-000-65" style="margin-left: 10px">{{ item.type }}</span>
                            <a-divider type="vertical" style="margin-left: 10px"/>
                            <span class="size-14 color-000-45" style="margin-right: 10px;">{{ getRate(item.value, alarmAllNum) }}</span>
                            <count-to :endVal="item.value" style="margin-right: 10px;"></count-to>
                            <span>个</span>
                        </div>
                    </div>
                </div>
                <div v-else style="margin-top: 50px;">
                    <a-empty />
                </div>
            </div>
            <div style="flex:1; background: #fff; margin-left: 20px;">
                <div class="flex-row-center" style="padding: 20px 30px 20px 30px;">
                    <div class="size-16 color-111 bold">
                        监测点报警排名
                    </div>
                    <div class="flex-1">
                    </div>
                    <div class="flex-row-center" style="margin-right: 0px;">
                        <div class="cursor_pointer" style="padding: 4px 12px;"
                             :style="'color:' + (selectedIndex2 == 0 ? '#1890FF': 'rgba(0,0,0,0.65)')"
                             @click="clickTitleIndex2(0)">
                            近一个月
                        </div>
                        <div class="cursor_pointer" style="padding: 4px 12px;"
                             :style="'color:' + (selectedIndex2 == 1 ? '#1890FF': 'rgba(0,0,0,0.65)')"
                             @click="clickTitleIndex2(1)">
                            近一年
                        </div>
                    </div>
                </div>

                <a-divider style="margin: 0;"></a-divider>
                <div style="padding-top: 10px;" v-if="levelList.length">
                    <div v-for="(item, index) in levelList" :key="index">
                        <div class="flex-row-center" style="padding: 10px 30px;">
                            <div class="flex-row center" style="width: 20px; height: 20px; border-radius: 50%;"
                                 :style="'background:' + (index < 3 ? '#1890FF':'#F0F2F5')">
                                <div class="size-12" :style="'color:' + (index < 3 ? '#FFF':'rgba(0,0,0,0.65)')">
                                    {{index + 1}}
                                </div>
                            </div>
                            <div style="width: 180px; margin-left: 20px;" class="size-14 color-000-65">
                                {{item.POINT_NAME}}
                            </div>
                            <div style="width: 320px;" class="size-14 color-000-45">
                                {{item.STRUCTURE_NAME}}
                            </div>
                            <div class="size-14 color-000-65" style="width: 120px;">
                                {{item.MONITOR_ALARM_COUNT}} 次
                            </div>

                            <div>
                                {{ getRate(item.MONITOR_ALARM_COUNT, levelAllNum) }}
                            </div>

                        </div>
                    </div>
                </div>

                <div v-else style="margin-top: 100px;">
                    <a-empty />
                </div>
            </div>
        </div>
        <div style="background: #FFF; width: 100%; margin-top: 20px;;" class="flex-1 flex-column">
            <div class="flex-row-center">
                <div class="size-16 color-111 bold flex-1" style="padding: 20px 0 20px 30px;">
                    主梁形变
                </div>
                <div class="flex-row-center" style="margin-right: 30px;">
                    <div class="size-16 color-000-85" style="width: 90px; margin-right: -2px;">监测点：
                    </div>
                    <div class="" style="margin-left: 0px;" v-if="deviceList3.length">
                        <a-select style="min-width: 200px" @change="deviceChange3" placeholder="请选择"
                                  :default-value="0">
                            <template v-for="(item, index) in deviceList3">
                                <a-select-option :key="item.DEVICE_ID" :value="index">
                                    {{ item.DEVICE_NAME + '(' + item.DEVICE_NUMBER + ')' }}
                                </a-select-option>
                            </template>
                        </a-select>
                    </div>
                    <div class="size-16 color-000-85" style="margin-left: 35px; width: 90px;">起始时间：
                    </div>
                    <div class="">
                        <a-range-picker @change="onChangeDate3" :default-value="[startTime3,endTime3]"
                                        format="YYYY-MM-DD" dropdownClassName="dateDropdownStyle"
                                        :allowClear="false" :key="1"/>
                    </div>

                </div>
            </div>
            <a-divider style="margin: 0;"></a-divider>

            <div class="flex-1 flex-column" style="padding-top: 3%;">
                <div style="width: 100%; height: 80%;">
                    <chart_gnss :array_gnss="array_gnss" :key="getCurrentTime()"/>
                </div>
            </div>
        </div>
        <div style="height: 30px;"/>
    </div>
</template>
<script>
  import chartPie from '../../components/chart_g2_pie';
  import chart_gnss from "../../components/chart_gnss_2";
  import moment from "moment";
  let formatStr = "YYYY-MM-DD 00:00:00";
  let formatStr2 = "YYYY-MM-DD 23:59:59";
  export default {
    components: {
      chartPie: chartPie,
      chart_gnss: chart_gnss
    },
    data() {
      return {
        alarmStatistics: [],
        alarmStatisticsColors: [
          '#3BA0FF', '#36CBCB', '#4DCB73', '#FAD337', '#F2637B','#975FE4','#FAAD14','#BAE7FF','#D3F261','#CF1322'
        ],
        alarmAllNum: 0,

        levelList: [],
        levelAllNum: 0,

        deviceList3: [],
        selectDevice3: undefined,
        selectDevice3Obj: {},
        array_gnss: [],
        deviceKey3: 0,
        startTime3: moment().add(-6, 'days').format(formatStr),
        endTime3: moment().format(formatStr2),
        timeDiff3: 6,//默认显示6天

        selectedIndex1: 0,
        startDate1: moment().add(-30, 'days').format("YYYY-MM-DD"),
        endDate1: moment().format('YYYY-MM-DD'),

        selectedIndex2: 0,
        startDate2: moment().add(-30, 'days').format("YYYY-MM-DD"),
        endDate2: moment().format('YYYY-MM-DD'),
      };
    },
    mounted() {
      this.getMonitorItemStatisticsList();
      this.getMonitorPointAlarmStatisticsList();

      this.$util.get('api/deviceList', {
        page: 1,
        pageSize: 10000,
        isBind: 1,
        // structureId: this.structureId,
        deviceType: 6
      }, true).then((res) => {
        let bean = res.content;
        this.deviceList3 = bean.list;
        if (this.deviceList3.length) {
          this.selectDevice3Obj = this.deviceList3[0];
          this.selectDevice3 = this.deviceList3[0].DEVICE_ID;
          this.getTimeGnssStatisticsList();
        }
      })
    },

    computed: {
      getRate() {
        return function (value, total) {
          let result = 0;
          if (total > 0)
            result = Number(parseFloat(value/total * 100).toFixed(2))
          return result + '%';
        }
      },

      getCurrentTime() {
        return function () {
          return new Date().getTime();
        }
      },
    },

    methods: {
      getMonitorItemStatisticsList() {
        let self = this;
        let p = {
          alarmStartDate: this.startDate1,
          alarmEndDate: this.endDate1
        };
        this.$util.showLoading(self);
        this.$util.get('api/monitorItemStatisticsList', p).then((res) => {
          let data = res.content.slice(0,9);
          let alarmStatistics = [];
          let alarmAllNum = 0;
          for (let i = 0; i < data.length; i++) {
            let item = data[i];
            alarmAllNum += item.MONITOR_ALARM_COUNT;
            alarmStatistics.push(
              {type: item.ITEM_NAME,value: item.MONITOR_ALARM_COUNT}
            )
          }
          this.alarmStatistics = alarmStatistics;
          this.alarmAllNum = alarmAllNum;
          this.$util.hideLoading();
        })
      },

      getMonitorPointAlarmStatisticsList() {
        let self = this;
        let p = {
          alarmStartDate: this.startDate2,
          alarmEndDate: this.endDate2
        };
        this.$util.showLoading(self);
        this.$util.get('api/monitorPointAlarmStatisticsList', p).then((res) => {
          this.$util.hideLoading();
          this.levelList = res.content.slice(0,10);
          let levelAllNum = 0;
          for (let i = 0; i < this.levelList.length; i++) {
            let item = this.levelList[i];
            levelAllNum += item.MONITOR_ALARM_COUNT;
          }
          this.levelAllNum = levelAllNum;
        })
      },

      getTimeGnssStatisticsList() {
        let timeList = [];
        for (let i = -this.timeDiff3; i <= 0; i++) {
          let dd = moment(this.endTime3).add(i, 'days').format("YYYY-MM-DD");
          let item = {
            avgTotalDe: 0,
            avgTotalDh: 0,
            avgTotalDn: 0,
            counts: 0,
            maxTotalDe: 0,
            maxTotalDh: 0,
            maxTotalDn: 0,
            minTotalDe: 0,
            minTotalDh: 0,
            minTotalDn: 0,
            monitorTime: dd
          }
          timeList.push(item);
        }
        this.$util.get('api/timeGnssStatisticsList', {
          deviceId: this.selectDevice3,
          startTime: this.startTime3,
          endTime: this.endTime3,
          type: 1
        }, true).then((res) => {
          let list = timeList;
          let resList = res.content;
          for (let i = 0; i < list.length; i++) {
            let item = list[i];
            for (let j = 0; j < resList.length; j++) {
              let item2 = resList[j];
              if (item.monitorTime == item2.monitorTime) {
                timeList[i] = resList[j];
                break;
              }
            }
          }
          this.array_gnss = timeList;
          this.deviceKey3 = this.selectDevice3 + this.startTime3 + this.endTime3;
        })
      },

      deviceChange3(value) {
        this.selectDevice3Obj = this.deviceList3[value];
        this.selectDevice3 = this.deviceList3[value].DEVICE_ID;
        this.getTimeGnssStatisticsList();
      },

      onChangeDate3(date, dateString) {
        this.startTime3 = moment(dateString[0]).format(formatStr);
        this.endTime3 = moment(dateString[1]).format(formatStr2);
        this.timeDiff3 = moment(this.endTime3).diff(moment(this.startTime3), 'day');
        this.getTimeGnssStatisticsList();
      },

      clickTitleIndex1(index) {
        this.selectedIndex1 = index;
        if (index == 0) {
          this.startDate1 = moment().add(-30, 'days').format("YYYY-MM-DD");
          this.endDate1 = moment().format('YYYY-MM-DD');
        } else if (index == 1) {
          this.startDate1 = moment().add(-364, 'days').format("YYYY-MM-DD");
          this.endDate1 = moment().add(0, 'days').format("YYYY-MM-DD");
        }
        this.getMonitorItemStatisticsList();
      },

      clickTitleIndex2(index) {
        this.selectedIndex2 = index;
        if (index == 0) {
          this.startDate2 = moment().add(-30, 'days').format("YYYY-MM-DD");
          this.endDate2 = moment().format('YYYY-MM-DD');
        } else if (index == 1) {
          this.startDate2 = moment().add(-364, 'days').format("YYYY-MM-DD");
          this.endDate2 = moment().add(0, 'days').format("YYYY-MM-DD");
        }
        this.getMonitorPointAlarmStatisticsList();
      },
    },
  };
</script>

<style scoped>

</style>
