<template>
  <div>
    <a-page-header
        style="padding: 0;margin: 15px 0"
        title="边坡管理"
        @back="back()"
    />
    <a-modal
        :bodyStyle="{'height':800+'px'}"
        v-model="showModal"
        :closable="false"
        :footer="null"
        :width="900"
        :destroyOnClose="true"
    >
      <selectAddress @modalClose="modalClose"/>
    </a-modal>
    <div class="flex-1 flex-column" style="margin: 10px 0;background-color: white">
      <span class="size-18 color-333" style="margin: 20px">{{ id ? '编辑' : '新增' }}边坡</span>
      <div class="flex-column" style="width: 100%">
        <a-form :form="form" @submit="handleSubmit" style="width: 100%" labelAlign="left" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }">
          <a-row type="flex" justify="space-around">
            <a-col :span="span">
              <a-form-item label="边坡名称">
                <a-input placeholder="请输入边坡名称"
                         v-decorator="['structureName', { rules: [{ required: true, message: '请输入边坡名称!' }] }]"
                         :allowClear="true"/>
              </a-form-item>
            </a-col>
            <a-col :span="span">
              <a-form-item label="边坡类型">
                <a-select placeholder="请选择边坡类型"
                          v-decorator="['slopeType', { rules: [{ required: true, message: '请选择边坡类型!' }] }]">
                  <template v-for="item in types">
                    <a-select-option :key="item.DATA_ID" :value="item.DATA_ID">
                      {{ item.CLASS_VALUE }}
                    </a-select-option>
                  </template>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="span">
              <a-form-item label="角度">
                <a-input-number placeholder="请输入角度" :min="0" style="width: 100%" v-decorator="['angle', { rules: [{ required: false, message: '请输入角度!' }] }]" :allowClear="true"
                />
              </a-form-item>
            </a-col>

          </a-row>

          <a-row type="flex" justify="space-around">
            <a-col :span="7">
              <a-form-item label="起始桩号">
                <a-input placeholder="请输入起始桩号" v-decorator="['startNumber', { rules: [{ required: true, message: '请输入起始桩号!' }] }]"
                         :allowClear="true"/>
              </a-form-item>
            </a-col>
<!--            <a-col :span="7">-->
<!--              <a-form-item label="中心桩号">-->
<!--                <a-input placeholder="请输入中心桩号" v-decorator="['pileNumber', { rules: [{ required: true, message: '请输入中心桩号!'}]}]"-->
<!--                         :allowClear="true"/>-->
<!--              </a-form-item>-->
<!--            </a-col>-->
            <a-col :span="7">
              <a-form-item label="结束桩号">
                <a-input placeholder="请输入结束桩号" v-decorator="['endNumber', { rules: [{ required: true, message: '请输入结束桩号!'}]}]"
                         :allowClear="true"/>
              </a-form-item>
            </a-col>
            <a-col :span="7">
            </a-col>
          </a-row>

          <a-row type="flex" justify="space-around">
            <a-col :span="span">
              <a-form-item label="设计安全等级">
                <a-select placeholder="请选择边坡类型" v-decorator="['safeLevel', { rules: [{ required: false,}] }]">
                  <template v-for="item in levels">
                    <a-select-option :key="item.DATA_ID" :value="item.DATA_ID">
                      {{ item.CLASS_VALUE }}
                    </a-select-option>
                  </template>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="span">
              <a-form-item label="评定时间">
                <a-date-picker valueFormat="YYYY-MM-DD" placeholder="请选择评定时间" v-decorator="['assessDate', { rules: [{ required: false}]}]"
                               :allowClear="true" style="width: 100%"/>
              </a-form-item>
            </a-col>
            <a-col :span="span">
              <a-form-item label="简称">
                <a-input placeholder="请输入简称，首字母大写" v-decorator="['shortName', { rules: [{ required: false }] }]"
                         :allowClear="true"/>
              </a-form-item>
            </a-col>
            <a-col :span="span">
            </a-col>
          </a-row>

          <a-row type="flex" justify="space-around">
            <a-col :span="span">
              <a-form-item label="所属路线">
                <a-input placeholder="请输入所属路线" v-decorator="['routeName', { rules: [{ required: false }] }]"
                         :allowClear="true"/>
              </a-form-item>
            </a-col>
            <a-col :span="span">
            </a-col>
            <a-col :span="span">
            </a-col>
          </a-row>

          <a-row type="flex" justify="space-around" style="margin-bottom: 20px">
            <a-col :span="11">
              <div class="flex-row-center">
                <span>标识地图：</span>
                <a-button type="primary" icon="environment" style="margin-left: 20px" @click="showModal=true">
                  标识地图
                </a-button>
                <a-input placeholder="经度" :readOnly="false" style="margin-left: 20px" class="flex-1"
                         :value="locationSelect?locationSelect.lng:''"/>
                <a-input placeholder="纬度" :readOnly="false" style="margin-left: 20px" class="flex-1"
                         :value="locationSelect?locationSelect.lat:''"/>
              </div>
            </a-col>
            <a-col :span="11">
              <a-input placeholder="地址" :readOnly="false" :value="locationSelect?locationSelect.address:''"/>
            </a-col>
          </a-row>

          <a-row type="flex" justify="space-around">
            <a-col :span="23">
              <a-form-item label="边坡简介" :wrapper-col="{ span: 24 }">
                <a-textarea placeholder="请输入边坡简介" v-decorator="['description', { rules: [{ required: false}]}]"
                            :allowClear="true" :auto-size="{ minRows: 2}"/>
              </a-form-item>
            </a-col>
          </a-row>

          <a-row type="flex" justify="space-around">
            <a-col :span="span">
              <a-form-item label="边坡结构图" :required="true" :wrapper-col="{ span: 24 }">
                <a-upload
                    accept=".jpg,.png,.jpeg"
                    list-type="picture-card"
                    :action="actionUrl"
                    :file-list="fileListSingle"
                    @change="uploadCallbackSingle"
                    @preview="handlePreview"
                >
                  <div v-if="fileListSingle&&fileListSingle.length ==0">
                    <a-icon :type="loadingSingle ? 'loading' : 'plus'"/>
                    <div>
                      点击上传
                    </div>
                  </div>
                </a-upload>
              </a-form-item>
            </a-col>

            <a-col :span="span">
              <a-form-item label="设计效果图" :required="true" :wrapper-col="{ span: 24 }">
                <a-upload
                    accept=".jpg,.png,.jpeg"
                    list-type="picture-card"
                    :action="actionUrl"
                    :file-list="fileListDesign"
                    @change="uploadCallbackDesign"
                    @preview="handlePreview"
                >
                  <div v-if="fileListDesign&&fileListDesign.length ==0">
                    <a-icon :type="loadingDesign ? 'loading' : 'plus'"/>
                    <div>
                      点击上传
                    </div>
                  </div>
                </a-upload>
              </a-form-item>
            </a-col>

            <a-col :span="span">
              <a-form-item label="边坡图片" :wrapper-col="{ span: 24 }">
                <a-upload
                    accept=".jpg,.png,.jpeg"
                    name="imgFile"
                    list-type="picture-card"
                    :action="actionUrl"
                    :file-list="fileList"
                    @change="uploadCallback"
                    @preview="handlePreview"
                    :multiple="true"
                    :remove="handleRemove"
                >
                  <div v-if="fileList&&fileList.length <12">
                    <a-icon :type="loading ? 'loading' : 'plus'"/>
                    <div>
                      点击上传
                    </div>
                  </div>
                </a-upload>
              </a-form-item>
            </a-col>
          </a-row>

          <div class="flex-row-center" style="height: 50px;justify-content: flex-end;margin: 30px 50px;">
            <a-button type="link" @click="back">
              取消
            </a-button>
            <a-button type="primary" html-type="submit">
              保存
            </a-button>
          </div>
        </a-form>
      </div>

    </div>
    <a-modal :visible="previewVisible" :footer="null" @cancel="previewVisible = false">
      <img alt="预览" style="width: 100%" :src="previewImage"/>
    </a-modal>
  </div>
</template>

<script>
import selectAddress from '../components/select_address';

export default {
  components: {
    selectAddress
  },
  data() {
    return {
      showModal: false,
      span: 7,
      form: this.$form.createForm(this, {name: 'sideSlope'}),
      id: undefined,
      types: [],
      levels:[],
      previewVisible: false,
      previewImage: '',
      actionUrl: '/client/api/uploadFile',
      loading: false,
      fileList: [],

      loadingSingle:false,
      fileListSingle:[],
      locationSelect: null,
      fileListDesign:[],
      loadingDesign:false,
    }
  },
  mounted() {
    let self = this;

    if (this.$route.query.id) {
      this.$util.showLoading(self);
      this.id = this.$route.query.id;
      this.$util.get('api/structureInfo/' + this.$route.query.id, {isDetail: 1,isImage:1}, true).then((res) => {
        self.bean = res.content;
        self.$util.hideLoading();
        let dao = res.content;
        self.form.setFieldsValue({
          structureName: dao.STRUCTURE_NAME,
          description: dao.DESCRIPTION,
          // pileNumber: dao.PILE_NUMBER,
          routeName: dao.ROUTE_NAME,
          shortName: dao.SHORT_NAME,
        })
        if(dao.sideSlopeInfoMap){
          self.form.setFieldsValue({
            slopeType: dao.sideSlopeInfoMap.SLOPE_TYPE,
            angle: dao.sideSlopeInfoMap.ANGLE,
            assessDate: dao.sideSlopeInfoMap.ASSESS_DATES,
            safeLevel: dao.sideSlopeInfoMap.SAFE_LEVEL,
            endNumber: dao.sideSlopeInfoMap.END_NUMBER,
            startNumber: dao.sideSlopeInfoMap.START_NUMBER,
          })

          let picListSingle = [];
          if(dao.sideSlopeInfoMap.IMG){
            picListSingle.push(
                {
                  uid: '-1024',
                  name:dao.sideSlopeInfoMap.IMG,
                  key: dao.sideSlopeInfoMap.IMG,
                  status: 'done',
                  url: self.$util.getServeImageUrl() + dao.sideSlopeInfoMap.IMG,
                }
            )
          }
          self.fileListSingle = picListSingle;

          let picListDesign = [];
          if(dao.sideSlopeInfoMap.DESIGN_IMG){
            picListDesign.push(
                {
                  uid: '-1024',
                  name:dao.sideSlopeInfoMap.DESIGN_IMG,
                  key: dao.sideSlopeInfoMap.DESIGN_IMG,
                  status: 'done',
                  url: self.$util.getServeImageUrl() + dao.sideSlopeInfoMap.DESIGN_IMG,
                }
            )
          }
          self.fileListDesign = picListDesign;
        }
        self.locationSelect = {
          lat: dao.LAT,
          lng: dao.LNG,
          address: dao.ADDRESS
        }
        if (dao.STRUCTURE_IMAGE_LIST && dao.STRUCTURE_IMAGE_LIST.length > 0) {
          let picList = [];
          for (let i = 0; i < dao.STRUCTURE_IMAGE_LIST.length; i++) {
            let item = dao.STRUCTURE_IMAGE_LIST[i];

            picList.push(
                {
                  uid: '-' + i,
                  name:item.FILE_NAME,
                  caption: item.FILE_NAME,
                  key: item.SAVE_NAME,
                  status: 'done',
                  url: self.$util.getServeImageUrl() + item.SAVE_NAME,
                }
            )
          }
          self.fileList = picList;
        }

      })
    }

    this.$util.get('api/dataList', {
      page: 1,
      pageSize: 100,
      classType: 'SLOPE_TYPE'
    }, true).then((res) => {
      self.types = res.content
    })

    this.$util.get('api/dataList', {
      page: 1,
      pageSize: 100,
      classType: 'SAFE_LEVEL'
    }, true).then((res) => {
      self.levels = res.content
    })
  },

  methods: {
    handleSubmit(e) {
      e.preventDefault();
      let self = this;
      this.form.validateFields((err, values) => {
        if (!err) {
          let p = {
            action: "ADD",
            structureName: values.structureName,
            slopeType: values.slopeType,
            description: values.description,
            angle: values.angle,
            assessDate: values.assessDate,
            safeLevel: values.safeLevel,
            endNumber: values.endNumber,
            // pileNumber: values.pileNumber,
            startNumber: values.startNumber,

            routeName: values.routeName,
            shortName: values.shortName,
          }

          if (self.locationSelect) {
            p.lat = self.locationSelect.lat;
            p.lng = self.locationSelect.lng;
            p.address = self.locationSelect.address;
          }

          let picList = [];
          if (self.fileList.length > 0) {
            for (let i = 0; i < self.fileList.length; i++) {
              console.log(i)
              let dao = self.fileList[i].response;
              if (dao && dao.errorCode == 0 && dao.content && dao.content.initialPreviewConfig && dao.content.initialPreviewConfig.length > 0) {
                picList.push({
                  key: dao.content.initialPreviewConfig[0].key,
                  caption: dao.content.initialPreviewConfig[0].caption,
                })
              } else {
                picList.push({
                  key: self.fileList[i].key,
                  caption: self.fileList[i].caption,
                })
              }
            }
          }
          let keys = [], caption = [];
          if (picList.length > 0) {
            for (let i = 0; i < picList.length; i++) {
              keys.push(picList[i].key)
              caption.push(picList[i].caption)
            }
          }
          p.imgCaptions = caption.join(',');
          p.imgKeys = keys.join(',');

          if (self.fileListSingle.length > 0) {
            let dao = self.fileListSingle[0].response;
            if (dao && dao.errorCode == 0 && dao.content && dao.content.initialPreviewConfig && dao.content.initialPreviewConfig.length > 0) {
              p.img = dao.content.initialPreviewConfig[0].key;
            }else{
              p.img = self.fileListSingle[0].key;
            }
          }else{
            this.$message.error('请上传边坡结构图');
            return;
          }
          if (self.fileListDesign.length > 0) {
            let dao = self.fileListDesign[0].response;
            if (dao && dao.errorCode == 0 && dao.content && dao.content.initialPreviewConfig && dao.content.initialPreviewConfig.length > 0) {
              p.designImg = dao.content.initialPreviewConfig[0].key;
            }else{
              p.designImg = self.fileListDesign[0].key;
            }
          }else{
            this.$message.error('请上传设计效果图')
            return;
          }
          if (this.bean) {
            p.action = 'EDIT';
            p.structureId = this.bean.STRUCTURE_ID;
          }
          this.$util.post('api/submitSideSlope', p).then((res) => {
            self.back();
          });
        }
      });
    },
    back() {
      this.$router.back();
    }, modalClose(params) {
      if (params)
        this.locationSelect = params.locationSelect;
      this.showModal = false;
    }, handleRemove(file) {
      file.status = '1';
      let name = '';
      if (file.response) {
        name = file.response.content.initialPreviewConfig[0].key
      } else {
        name = file.name
      }
      this.$util.post("api/deleteFile?saveName=" + name, {})
    }, uploadCallback({file, fileList}) {
      this.fileList = fileList;
    },uploadCallbackSingle({file, fileList}) {
      this.fileListSingle = fileList;
    },uploadCallbackDesign({file, fileList}) {
      this.fileListDesign = fileList;
    },
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await this.$util.getBase64(file.originFileObj);
      }
      this.previewImage = file.url || file.preview;
      this.previewVisible = true;
    },
  }
}
</script>

<style scoped>

</style>
